import axios from "axios";
import epubjs from "epubjs";
import localforage from "localforage";
import { isString, isBlob } from "@/utils/type";

Date.prototype.addDays = function(days) {
	let date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};

export default {
	namespaced: true,
	state: {
		books: []
	},
	getters: {},
	mutations: {
		update(state, payload) {
			state.books = payload["books"];
		}
	},
	actions: {
		async getLocalBooks({ commit }) {
			const books = await localforage.getItem("books");
			if (books) {
				commit("update", { books });
			}
		},
		setLocalBooks({ state }) {
			localforage.setItem("books", state.books);
		},
		async addLocalBooks({ commit, state }, { id, url }) {
			let file = null;
			if (isString(url)) {
				const response = await axios.get(url, { responseType: "blob" });
				file = response.data;
			} else if (isBlob(url)) {
				file = url;
			} else if (url.target && url.target.files) {
				file = url.target.files[0];
			} else {
				return -1;
			}

			let books = state.books;
			const epub = epubjs(file);
			return epub.opened
				.then(async () => {
					const metadata = epub.package.metadata;
					const src = await epub.coverUrl();
					books = books.filter(b => b.id !== id);
					if (src) {
						const canvas = document.createElement("canvas");
						const ctx = canvas.getContext("2d");
						let img = new Image();
						img.src = src;
						img.onload = () => {
							// �]�w canvas �e�����P�Ϥ�
							canvas.width = img.width;
							canvas.height = img.height;
							// �N��ø�s�A�ó]�wø�s���Ϥ��e��
							ctx.drawImage(img, 0, 0, img.width, img.height);
							const dataUrl = canvas.toDataURL("image/png");
							const book = {
								id,
								metadata,
								src: dataUrl,
								file,
								expiredDate: new Date().addDays(1)
								// expiredDate: new Date("2020-10-01")
							};
							books.push(book);
							commit("update", { books });
							localforage.setItem("books", books);
						};
					} else {
						const book = {
							id,
							metadata,
							file,
							expiredDate: new Date().addDays(1)
						};
						books.push(book);
						commit("update", { books });
						localforage.setItem("books", books);
					}
					// console.log(books.length)
				})
				.catch(() => {})
				.finally(() => {});
		},
		removeLocalBooks({ commit, state }, { id }) {
			const books = state.books.filter(b => b.id !== id);
			commit("update", { books });
			localforage.setItem("books", books);
		}
	}
};
