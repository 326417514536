<template>
	<div>
		<h2 v-focus tabindex="0">公告內容</h2>
		<div class="form-horizontal">
			<div class="form-group">
				<label for="id_category" class="control-label col-sm-1"
					><font style="color:red">*</font>類別</label
				>
				<div class="col-sm-11">
					<div
						v-if="mode == 'read'"
						class="panel panel-default"
						style="margin:0px; padding:5px 10px;"
					>
						{{ announcement.category }}
					</div>
					<template v-if="mode == 'write'">
						<select
							v-if="user.org == '1'"
							class="form-control"
							v-model="announcement.category"
						>
							<option value="平台消息">平台消息</option>
							<option value="天橋說書">天橋說書</option>
							<option value="新書推薦">新書推薦</option>
							<option value="志工快訊">志工快訊</option>
							<option value="每月書訊">每月書訊</option>
							<option value="校園公告">校園公告</option>
							<option value="校園平台消息">校園平台消息</option>
						</select>
					</template>
				</div>
			</div>

			<div class="form-group">
				<label for="id_title" class="control-label col-sm-1"
					><font style="color:red">*</font>標題</label
				>
				<div class="col-sm-11">
					<div
						v-if="mode == 'read'"
						class="panel panel-default"
						style="margin:0px; padding:5px 10px;"
					>
						{{ announcement.title }}
					</div>
					<template v-if="mode == 'write'">
						<input
							v-model="announcement.title"
							class="form-control"
							type="text"
						/>
					</template>
				</div>
			</div>

			<div class="form-group">
				<label for="id_content" class="control-label col-sm-1"
					><font style="color:red">*</font>內容</label
				>
				<div class="col-sm-11">
					<div
						v-if="mode == 'read'"
						v-html="marked.parse(announcement.content)"
						class="panel panel-default"
						style="margin:0px; padding:5px 10px;"
					></div>
					<div v-if="mode == 'write'">
						<code-mirror v-model="announcement.content" />
					</div>
				</div>
			</div>
		</div>

		<h2>附件檔案</h2>
		<file-manager
			:url="url"
			:permission="{
				create: { is_supermanager: true },
				read: { is_all: true },
				update: { is_supermanager: true },
				delete: { is_supermanager: true }
			}"
		></file-manager>
		<div class="form-group">
			<div class="col-sm-offset-1 col-sm-11">
				<!--原本btn-primary 改成 btn-manage-->
				<button
					v-if="mode == 'read'"
					class="btn btn-manage"
					@click="$router.go(-1)"
				>
					返回上一頁
				</button>
				<template v-if="user.is_manager === true">
					<button
						v-if="mode == 'read'"
						@click="write_mode()"
						class="btn btn-manage"
					>
						進行修改
					</button>
					<button
						v-if="mode == 'write'"
						@click="update()"
						class="btn btn-manage"
					>
						儲存
					</button>
					<button
						v-if="mode == 'write'"
						@click="cancel()"
						class="btn btn-manage"
					>
						取消
					</button>
					<button
						v-if="mode == 'write'"
						@click="deletes()"
						class="btn btn-danger"
					>
						刪除
					</button>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import CodeMirror from 'vue-codemirror6';
import { marked } from 'marked';

import { mapState } from "vuex";
import fileManager from "@/components/fileManager.vue";
import { announcementRest, announcementAction } from "@/api/genericUser.js";

export default {
	components: {
		CodeMirror,
		fileManager
	},
	data() {
		return {
			pk: this.$route.params.id,
			announcement: {},
			mode: "read", //read or write
			attachment_name: "",
			publicPath: process.env.BASE_URL,
			marked: marked,
		};
	},
	computed: {
		...mapState("user", ["user"]),
		org() {
			return this.user.org;
		},
		url() {
			return announcementAction.attachmentUrl(this.pk);
		}
	},
	metaInfo: {
		title: "公告內容"
	},
	mounted() {
		announcementRest
			.read(this.pk)
			.then(res => {
				this.announcement = res.data;
				// this.announcement.content=this.announcement.content.replaceAll(/<[^>]+>/g, '<p style="margin-top:0">')
			})
			.catch(res => {
				this.$errorHandler({ error: res });
			});
	},
	methods: {
		read_mode() {
			this.mode = "read";
		},
		write_mode() {
			this.mode = "write";
		},
		update() {
			announcementRest
				.partialupdate(this.pk, this.announcement)
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功更新公告" });
					this.mode = "read";
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		cancel() {
			announcementRest
				.read(this.pk)
				.then(res => {
					this.announcement.category = res.data.category;
					this.announcement.title = res.data.title;
					this.announcement.content = res.data.content;
					this.mode = "read";
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		deletes() {
			this.$root.$confirm
				.open({ status: 200, message: "是否確定刪除公告?" })
				.then(() => {
					return announcementRest.delete(this.pk);
				})
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功刪除公告" });
					this.$router.push({ name: "announcementList" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.control-label {
	&.col-sm-1 {
		font-size: 1.2rem;
		white-space: nowrap;
		margin: 1rem;
	}
}
</style>
