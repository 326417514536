export const license = `eDocumentService雲端千眼平台(以下簡稱「本站」)係依據本條款提供特定身心障礙者出版品重製服務。當您使用本服務時，即表示您已閱讀、瞭解並同意接受此條款之所有內容。本站有權於任何時間修改或變更此條款之內容，若您於任何修改或變更後繼續使用本服務，視為您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意此條款的內容，您應立即停止使用本站之服務。

一、本站委託方（人）須遵守之義務
本站之服務使用者為委託方（人），委託本站志工為重製代理人，部份委託方（人）受限於身體功能無法親自操作平台功能，故另行委託親人及好友為操作代理人。
重製代理人進行文件重製成電子版本，是接受服務使用者或平台管理單位委託進行重製。平台管理單位或服務使用者註冊後，進行上傳文件之動作即表示委託重製代理人進行重製電子版的文件。
根據著作權法第五十三條所定，本站所提供之著作重製物僅供著作權法53條規定之免責人使用，重製物雖可在所定障礙者、中央或地方政府機關、非營利機構或團體、依法立案之各級學校間散布或公開傳輸，但強烈建議每位服務使用者自行至本系統領取轉制成品，因每位服務使用者所獲之轉製成品皆有獨特之處理編碼。若轉製成品散布給非免責人，則以轉製成品特定編碼所指向之服務使用者為法律責任人。

二、本站代理方（人）須遵守之義務
根據著作權法第五十三條所定：「前項所定障礙者或其代理人為供該障礙者個人非營利使用，準用前項規定。」本站志工以及服務使用者所委託的親人好友即此處所指的代理人，代理人接受本站服務使用者之委託，得以協助重製成電子格式的檔案，或是協助服務使用者操作平台功能，但用途僅限專供該障礙者個人非營利使用。
2-1 本站所提供志工校對之影像圖檔專為轉製電子文件校對所使用，志工不得將在本站校對所獲之影像檔以任何形式違反著作權法之規範。本站志工註冊並領取文件僅表示接受服務使用者的委託以重製電子版文件，並無其他著作權上的使用權利，包括：重製、改作、公開傳輸、公開展示、散布等等，其違反著作權法的行為須自行負責。
2-2 由服務使用者委託親人好友操作網路平台或是代為下載電子文件，操作代理人不得將所獲之影像檔以任何形式違反著作權法之規範，包括：重製、改作、公開傳輸、公開展示、散布等等，其違反著作權法的行為須由操作代理人自行負責。

三、著作權法相關用詞定義
根據著作權法第三條所定，擷取部分用詞定義作為行為規範聲明，本站志工應避免將本站提供之校對影像圖檔有其他不受法律規範下之行為，如使用文件上觸犯以下行為，則其違法責任須自行負責。
(一) 重製
指以印刷、複印、錄音、錄影、攝影、筆錄或其他方法直接、間接、永久或暫時之重複製作。於劇本、音樂著作或其他類似著作演出或播送時予以錄音或錄影；或依建築設計圖或建築模型建造建築物者，亦屬之。
(二)公開口述
指以言詞或其他方法向公眾傳達著作內容。
(三) 公開傳輸
指以有線電、無線電之網路或其他通訊方法，藉聲音或影像向公眾提供或傳達著作內容，包括使公眾得於其各自選定之時間或地點，以上述方法接收著作內容。
(四)改作
指以翻譯、編曲、改寫、拍攝影片或其他方法就原著作另為創作。
(五)散布
指不問有償或無償，將著作之原件或重製物提供公眾交易或流通。
(六)公開展示
指向公眾展示著作內容。
(七)公開發表
指權利人以發行、播送、上映、口述、演出、展示或其他方法向公眾公開提示著作內容。

*歡迎瀏覽雲端千眼平台隱私權政策

當您開始使用雲端千眼平台，即表示您信賴我們對您個人資訊的處理方式。本《隱私權政策》旨在協助您瞭解本站如何蒐集、應用及保護您所提供的個人資訊，以下的隱私權政策，適用於您在本站活動時，所涉及的個人資料蒐集、運用與保護，當您註冊或是登入時，即視為您已閱讀本同意書，並同意遵守以下所有同意書之規範。
一、個人資料之蒐集與應用
1. 若欲使用本站所提供之各項功能，必須先申請本站帳戶才能使用，在申請過程中，本站會請您提供個人正確且完整之必要資料，如姓名、電子郵件、聯絡電話、生日、學歷等個人資訊，儲存在您的帳戶中。
2. 進行網頁瀏覽時，伺服器會自行記錄相關行徑，包括日期和時間、您所擷取之網頁、您所在之網址、您的瀏覽器種類、您對本站網頁所做行動等，做為本站增進網站服務的參考依據，此記錄為內部應用，絕不對外公佈。
3. 為了向所有使用者提供更優質的服務，當您造訪本站時，本站會使用各種技術收集並儲存資訊，這可能包括使用 Cookie 或類似技術來識別您的瀏覽器或裝置。若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie紀錄，但可能會導致網站某些功能無法正常執行。

二、個人資訊更新及帳戶安全
1. 無論您何時使用本站的服務，本站都致力讓您能夠維持並更新個人資訊。若該資訊有誤，我們會盡力提供管道讓您可以迅速更新或刪除資訊，但如果我們基於正當業務或法律目的考量必須保存該資訊時，則不在此列。凡為本站使用者，皆有義務維持並更新其所屬個人資料，並確保該資料為正確、最新以及完整。若您提供任何錯誤或不實的資料，本站有權拒絕您以該帳號使用網站之全部或部份功能。
2. 維護用戶資訊與密碼的資訊安全是本站與您共同的責任，本站會致力於保護用戶相關資訊，防止未經授權而遭到存取、竄改或揭露。也請您於每次關閉瀏覽器視窗時登出本站，以保護個人資料不會遭到他人讀取與濫用。
3. 凡為本站使用者之網路行為應遵循國內、外法律規範，並且對於個人所屬帳戶所發生之情事負全部責任。若您的密碼或帳號遭到盜用或有其他任何安全問題發生時，請立即以電子郵件通知本站，以便資訊人員盡快處理。

三、與第三人共用個人資料之政策除非適用下列任一情況，否則我們不會與本站以外的其他公司、機構或個人分享使用者的個人資訊：
1. 事先徵得您的同意：除非得到您的同意，本站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關。
2. 基於法律原因：若為達到下列目的，對個人資訊的存取、使用、保存或揭露屬合理必要行為時，我們便將與本站以外的其他公司、機構或個人分享個人資訊：
a. 符合任何適用法律、法規、法律函狀或強制性政府調閱要求的規定。
b. 配合檢調或司法單位合法的調查。
c. 配合政府機關依法進行之調查或使用。
d. 依法律規應或在合法範圍內，保護本站、使用者或公眾之權利、財產或安全不致遭受危害。
e. 與公務機關或學術研究機構合作，基於公共利益為統計或學術研究之必要，僅分享不含身分識別內容的資訊，且蒐集者依其揭露方式無從識別特定之當事人。
f. 當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。

四、資料安全：本站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以嚴格保護網站及您的個人資料。本站僅允許代表本站處理個人資訊而需要知悉該等資訊的本站團隊成員存取資訊，且相關人員均須遵守嚴格的保密義務合約，一旦未遵守義務便將受到相關的法律處分

五、隱私權保護政策之修正：本站隱私權保護政策將因應需求隨時進行修正，並且不會在未經您明確同意的情況下，即縮減本《隱私權政策》賦予您的權利。修正後的條款將刊登於網站上，若會員不同意修改的內容，請來信告知或暫停使用本站。若會員繼續使用，將視為會員已同意並接受本規範等增訂或修改內容之約束。

六、《隱私權政策》適用範圍及效力：本站的《隱私權政策》不適用於由其他網站連結或個人提供的服務，諸如由本站所連結到的其他網站皆不在此《隱私權政策》適用範圍之內。我們的《隱私權政策》不涵括其他為本站服務宣傳之公司或機構所採用的資訊做法。

本同意書所定之任何會員條款之全部或一部無效時，不影響其他條款之效力。關於本條款之解釋或適用，均以中華民國之法律為基準。使用者因使用本站而生之爭議，同意本誠信原則解決之，如有訴訟之必要時，同意以台灣台北地方法院為第一審管轄法院。`;
