import axios from "axios";
import jwt_decode from "jwt-decode";
import { host } from "@/api/base";

const tokenaxios = axios.create({
	baseURL: host + "api/token/"
});

export default {
	namespaced: true,
	state: {
		username: "default",
		accessExpCountdown: NaN,
		refreshExpCountdown: NaN,
		token: {
			access: null,
			refresh: null,
			accessObtainTime: null,
			refreshObtainTime: null
		},
		dialog: false
	},
	getters: {
		accessInfo: state => {
			try {
				return jwt_decode(state.token["access"]);
			} catch (err) {
				return null;
			}
		},
		refreshInfo: state => {
			try {
				return jwt_decode(state.token["refresh"]);
			} catch (err) {
				return null;
			}
		}
	},
	mutations: {
		updateAccessToken(state, { access, accessObtainTime }) {
			state.token.access = access;
			state.token.accessObtainTime = accessObtainTime;
			localStorage.setItem(
				"token_" + state.username,
				JSON.stringify(state.token)
			);
		},
		updateRefreshToken(state, { refresh, refreshObtainTime }) {
			state.token.refresh = refresh;
			state.token.refreshObtainTime = refreshObtainTime;
			localStorage.setItem(
				"token_" + state.username,
				JSON.stringify(state.token)
			);
		},
		setUsername(state, username) {
			state.username = username;
		},
		load(state) {
			let token = JSON.parse(localStorage.getItem("token_" + state.username));
			if (token) {
				state.token = token;
			} else {
				state.token = {
					access: null,
					refresh: null,
					accessObtainTime: null,
					refreshObtainTime: null
				};
			}
		},
		save(state) {
			localStorage.setItem(
				"token_" + state.username,
				JSON.stringify(state.token)
			);
		},
		remove(state) {
			state.token = {
				access: null,
				refresh: null,
				accessObtainTime: null,
				refreshObtainTime: null
			};
			localStorage.setItem(
				"token_" + state.username,
				JSON.stringify(state.token)
			);
		},
		calculateAccessExpCountdown(state) {
			try {
				const exp_date = state.token.accessObtainTime + 300000;
				state.accessExpCountdown = exp_date - Date.now();
			} catch (err) {
				console.log(JSON.stringify(err));
				state.accessExpCountdown = NaN;
			}
		},
		calculateRefreshExpCountdown(state) {
			try {
				const exp_date = state.token.refreshObtainTime + 86400000;
				state.refreshExpCountdown = exp_date - Date.now();
			} catch (err) {
				console.log(JSON.stringify(err));
				state.refreshExpCountdown = NaN;
			}
		},
		toggleDialog(state) {
			state.dialog = !state.dialog;
		}
	},
	actions: {
		refresh({ commit, state }) {
			return tokenaxios
				.post("refresh/", {
					refresh: state.token.refresh
				})
				.then(res => {
					commit("updateAccessToken", {
						access: res.data.access,
						accessObtainTime: Date.now()
					});
				});
		},
		obtain({ commit }, { username, password }) {
			return tokenaxios
				.post("", {
					username,
					password
				})
				.then(res => {
					commit("updateAccessToken", {
						access: res.data.access,
						accessObtainTime: Date.now()
					});
					commit("updateRefreshToken", {
						refresh: res.data.refresh,
						refreshObtainTime: Date.now()
					});
					return res;
				});
		}
	}
};
