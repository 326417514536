<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">問與答</h2>
		<tab :data="tab_data">
			<template #qanda="props">
				<qanda :category="props.item" />
			</template>
		</tab>
	</div>
</template>

<script>
import tab from "@/components/tab.vue";
import qanda from "./components/qanda.vue";

export default {
	components: {
		qanda,
		tab
	},
	data() {
		return {
			tab_data: [
				{
					display_name: "志工",
					value: "volunteer",
					type: "qanda",
					data: "volunteer"
				},
				{
					display_name: "視障者",
					value: "vip",
					type: "qanda",
					data: "vip"
				},
				{
					display_name: "平台",
					value: "platform",
					type: "qanda",
					data: "platform"
				}
			]
		};
	},
	metaInfo: {
		title: "問與答"
	}
};
</script>
