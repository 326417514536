<template>
	<div class="this-is-footer-wrapper purpse-for-flex-grow">
		<footer>
			<div class="container-fluid is-container-max-width">
				<div class="row">
					<ul>
						<li>
							<a
								class="is-pointer smaller-text overwrite-bootstrap"
								tabindex="0"
								@click="$router.push({ name: 'about' })"
								>關於雲端千眼</a
							>
						</li>
						<li>
							<a
								class="is-pointer smaller-text overwrite-bootstrap"
								tabindex="0"
								@click="$router.push({ name: 'recruit' })"
								>招募志工</a
							>
						</li>
						<li>
							<a
								class="is-pointer smaller-text overwrite-bootstrap"
								tabindex="0"
								@click="openCustomService"
								>客服</a
							>
						</li>
						<li>
							<a
								class="is-pointer smaller-text overwrite-bootstrap"
								tabindex="0"
								@click="clear"
								>清除暫存</a
							>
						</li>
						<li>
							<span class="smaller-text"
								>建議瀏覽器: 建議使用Firefox或Chrome等支援HTML5瀏覽器</span
							>
						</li>
						<li>
							<span class="smaller-text"
								>建議解析度: 建議解析度使用1366x768以上</span
							>
						</li>
						<li><span class="smaller-text">© eDocumentService 2023</span></li>
						<li>
							<a
								class="is-pointer smaller-text"
								href="https://www.forblind.org.tw/"
								target="blank"
								title="(另開新視窗)"
								><img
									class="logo"
									src="@/assets/logo202310.png"
									alt="社團法人台北市視障者家長協會"
							/></a>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
import { toggleTwak } from "../utils/general";

export default {
	components: {},
	data() {
		return {
			flag: false
		};
	},
	methods: {
		async clear() {
			try {
				await this.$root.$confirm.open({
					status: 200,
					message: "確認清除暫存?"
				});
				localStorage.clear();
			} catch (error) {
				return -1;
			}
			try {
				await this.$root.$message.open({
					status: 200,
					message: "已成功清除資料"
				});
			} catch (error) {
				return -1;
			}
			window.location.replace(process.env.BASE_URL);
		},
		openCustomService() {
			if (this.flag) {
				const iframes = document.getElementsByTagName("iframe");
				for (let i = 0; i < iframes.length; i++) {
					const iframe = iframes.item(i);
					if (iframe.title === "chat widget") {
						const bool =
							iframe.parentElement.style.display === "none" ? "block" : "none";
						iframe.parentElement.style.setProperty(
							"display",
							bool,
							"important"
						);
						break;
					}
				}
				return;
			}
			this.flag = true;
			toggleTwak();
		}
	}
};
</script>

<style scoped lang="scss">
// Stickey footer.
.purpse-for-flex-grow {
	display: grid;
	justify-content: stretch;
	justify-items: stretch;
	align-content: end;

	footer {
		margin-top: 2.5em;
		padding-top: 0.9em;
		padding-bottom: 0.9em;
		border-top: 1px solid #e5e5e5;
		background-color: #f5f5f5;

		.container-fluid {
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-content: center;
				align-items: center;
				width: 100%;
				// Remove unwanted style.
				margin-bottom: 0;
				// Remove unwanted style.
				padding-left: 0;
				list-style: none;

				li {
					margin: 0.5em 0.8em;

					.smaller-text {
						font-size: 0.92em;
					}
					a {
					}
					span {
						color: #777;
					}
					img.logo {
						width: 18em;
					}
				}
			}
		}
	}
}
</style>
