<template>
	<div class="book-repository">
		<h2 class="big-title" v-focus tabindex="0">平台書庫</h2>
		<tab
			:headinglevel="3"
			:data="tab_data"
			@tab-click="gotTo"
			:defaultTabIdx="defaultTabIdx"
		>
		</tab>
		<router-view></router-view>
	</div>
</template>

<script>
import tab from "@/components/tab.vue";

export default {
	components: {
		tab
	},
	data() {
		return {
			defaultTabIdx: 0,
			tab_data: [
				{
					display_name: "書籍推薦",
					value: "recommend"
				},
				{
					display_name: "每月書訊",
					value: "announcement"
				},
				{
					display_name: "索引",
					value: "index"
				},
				{
					display_name: "有聲資源",
					value: "media"
				},
				{
					display_name: "查詢",
					value: "search"
				}
			],
			routerList: [
				{
					name: "bookRepositoryRecommend",
					params: "newest"
				},
				{
					name: "bookRepositoryAnnouncement"
				},
				{
					name: "bookRepositoryIndex",
					params: "1"
				},
				{
					name: "bookRepositoryMedia"
				},
				{
					name: "bookInfoSearch"
				}
			]
		};
	},
	metaInfo: {
		title: "平台書庫"
	},
	created() {
		if (this.$route.name === "bookRepositoryIndex") this.defaultTabIdx = 1;
		if (this.$route.name === "bookInfoSearch") this.defaultTabIdx = 2;
	},
	methods: {
		gotTo(val) {
			const r = this.routerList[val];
			if (this.$route.name === r.name) return;
			this.$router.push({ name: r.name, params: { idx: r.params } });
		}
	}
};
</script>

<style scoped lang="scss">
.book-repository {
	.book_search_result {
		margin-left: 1rem;
	}
}
</style>
