<template>
	<div>
		<h2 v-focus tabindex="0" class="sr-only">目宿媒體</h2>
		<div v-if="topicIndex == 0">
			<img
				class="mb-3"
				src="@/assets/fisfisa/fisfisa-they.jpg"
				alt="他們在島嶼寫作"
			/>
			<img src="@/assets/fisfisa/fisfisa-we.jpg" alt="我們在島嶼朗讀" />
		</div>
		<div v-else-if="topicIndex == 1">
			<img src="@/assets/fisfisa/fisfisa-they.jpg" alt="他們在島嶼寫作" />
		</div>
		<div v-else-if="topicIndex == 2">
			<img src="@/assets/fisfisa/fisfisa-we.jpg" alt="我們在島嶼朗讀" />
		</div>
		<panel-group
			:data="tabData"
			:title="'分頁'"
			:headinglevel="3"
			:styleRow="styleRow"
			:styleNav="styleNav"
			@tab-click="gotTo"
		>
			<template #fisfisa-main>
				<div class="mt-5">
					<p>
						目宿媒體，2009年由和碩聯合科技董事長童子賢支持創立，著力於經營文學與影像兩大領域，公司名「目宿」乃是期許作品成為眾人「目光停留之處」。
					</p>
					<p>
						目宿媒體多年來，以當代影音媒介，致力傳遞文學的美好價值，創業作《他們在島嶼寫作》系列，交融激盪了台灣、香港文學大師與導演的才華，是台灣的文學電影中相當具有代表性的作品。後續也推出了新詩朗讀短片系列，並持續推廣影音與文學對話的藝術成果。
					</p>
				</div>
			</template>
			<template #fisfisa-they="props">
				<table-div
					:header="resource_file_header"
					:datas="props.item.table"
					:tdwidths="tdwidths"
				>
					<template #action="props">
						<template v-if="user.auth_guest">
							<button
								class="btn btn-outline-secondary"
								@click="readView(props.item)"
								:title="props.item.name"
							>
								觀看
							</button>
						</template>
						<template v-else>
							<div />
						</template>
					</template>
				</table-div>
				<p>
					目宿媒體於2011年推出《他們在島嶼寫作》第一系列，將六位台灣戰後文學家的生命與創作經歷搬上大螢幕，以台灣新一代導演的敘事手法，將文學家的生命與創作底蘊充分展現，期許藉此系列作品推出，讓人們更關注影響台灣文學數個世代的作家。
				</p>
				<p>
					2014年，時值《創世紀》詩刊六十年社慶，以「詩的照耀下」為題，再推出《無岸之河》及《如歌的行板》兩部詩人電影。2015年，第二系列七部電影聯合發表，呈現同時代的四部台灣文學家、三部香港文學家電影，拓展了「島嶼寫作」涵蓋的文學史脈絡可能性。
				</p>
				<p>
					2020年，希望將此系列影片推廣給更多不同社群的觀眾欣賞，因而挑選其中幾部，製作口述影像版本。
				</p>
				<p>
					註：因背景音或影片效果，播放時可能會有停頓，請稍候並安心收聽。
				</p>
			</template>
			<template #fisfisa-we="props">
				<table-div
					:header="resource_file_header"
					:datas="props.item.table"
					:tdwidths="tdwidths"
				>
					<template #action="props">
						<template v-if="user.auth_guest">
							<button
								class="btn btn-outline-secondary"
								@click="readView(props.item)"
								:title="props.item.name"
							>
								觀看
							</button>
						</template>
						<template v-else>
							<div />
						</template>
					</template>
				</table-div>
				<p>
					楊牧：<br />
					楊牧，本名王靖獻，1940年生於台灣花蓮。中學時代起使用葉珊作為筆名，在現代詩、藍星、創世紀等詩刊發表作品，在現代詩壇中開闢了一條抒情浪漫的道路。1964年自東海大學外文系畢業，進入愛荷華大學詩創作班，獲藝術碩士學位，後獲柏克萊大學比較文學博士學位。赴美後詩風漸趨雄健渾厚，並長於敘事詩寫作，文辭典贍雅麗，意象繁複紛奇，情韻醇厚，散文亦為人稱頌。<br />
					1972年改用楊牧作為新筆名，作品風格亦為之一變，在原有浪漫抒情之外，加上了冷靜與含蓄，並且積極介入現實層面問題的探討，提出許多對台灣社會的觀察、省思與批判，說理深切，展現濃厚的本土關懷。詩人學者焦桐曾說：「楊牧是台灣最勇於試煉文字、語法、也最卓然有成的巨匠。」
				</p>
				<p>
					余光中：<br />
					余光中，1928年生於南京，1950年來台。1954年與覃子豪等共同創辦「藍星詩社」，創作不輟，身兼詩人、散文家、評論家及翻譯者等多重身分，著／譯作等身。1959年獲美國愛荷華大學藝術碩士學位後歷任台、港多所大學教職，1985年任教高雄中山大學至今。<br />
					其詩歌美學特點，陳芳明認為：「在現代主義運動中扮演領者角色的余光中，與高度現代化的瘂弦與洛夫最大不同之處，便在於知性與感性之間結盟產生歧見。瘂弦、洛夫偏向於內心世界的挖掘，致力於情緒的疏離與現實的疏離，詩風較傾向主知。<br />
					余光中則在追求現代精神之餘，並不捨棄感性的表現。不僅如此，他並不避諱與傳統、歷史銜接，更不刻意避開親情、愛情等題材的處理」。重要作品有《五陵少年》、《蓮的聯想》、《敲打樂》、《在冷戰的年代》等。
				</p>
				<p>
					王文興：<br />
					王文興，1939年生於福建，1946年舉家遷台，高中畢業後入台大外文系，1960年與同學白先勇等人創辦《現代文學》雜誌，影響台灣文壇甚鉅。曾獲國家文藝獎、並獲法國政府頒與法國藝術及文學勳章的騎士勳位。<br />
					王文興自1958年發表第一篇作品以來，持續創作已逾五十年。他博通東西文化，並萃取其各種藝術之精華，融入其創作之中。其作品《家變》在1973年發表時撼動文壇，被視為異端，因其形式、內容甚至語言都是全新的，至今仍被討論不斷。<br />
					1974年開始創作第二篇長篇小說《背海的人》，費時二十五年完成，結構更複雜、難度更高，可見小說家以無比堅定的毅力繼續在藝術的道路上前行。
				</p>
				<p>
					瘂弦：<br />
					瘂弦，1932年生於河南，1949年從軍來台。1953年入復興崗學院的影劇系，1954年畢業分發至左營軍中廣播電台工作，與張默、洛夫共同創立「創世紀詩社」，人稱「創世紀鐵三角」。1954年開始寫詩的瘂弦1965年即封筆，不到百首的詩作中許多已成詩壇經典，如〈深淵〉、〈如歌的行板〉等，令人傳頌不已。代表詩集《深淵》。<br />
					早期詩作受中國1930年代詩人何其芳影響。進入現代主義時期後著力於語言的鍛造、嫻熟於使用超現實主義技巧，惟詩作有現實色彩與口語化的情調的特色，極富音樂性，並使用戲劇的觀點和短篇小說的技巧，以輕鬆幽默的口吻，展現他悲天憫人的人道情懷。除了詩業，瘂弦歷任《幼獅文藝》、《聯合報》副刊主編，是耕耘台灣文壇甚深的編輯人。
				</p>
			</template>
		</panel-group>
		<modal v-if="showModal" @closeModal="showModal = false">
			<template #header>
				<h4 class="modal-title">觀看 {{ playing }} 影片</h4>
			</template>
			<template #body>
				<div class="video-wrap">
					<player :options="videoOptions" :currentTime="currentTime"> </player>
					<!--<video controls>
						<source :src="currentSrc" />
					</video>-->
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
import axios from "axios";
import { file_resolve } from "@/api/base.js";
import { host } from "@/api/base";
import {
	compare,
	fillCell,
	groupByField,
	leftJoinObjectArray
} from "@/utils/dataProcess";
import { mapState } from "vuex";
import panelGroup from "@/components/panelGroup.vue";
import tableDiv from "@/components/tableDiv.vue";
import modal from "@/components/popup/modal.vue";
import player from "@/components/live/videoPlayer.vue";
import { logAction } from "@/api/logsystem.js";

export default {
	components: {
		panelGroup,
		tableDiv,
		modal,
		player
	},
	data() {
		return {
			playing: "",
			topicIndex: 0,
			styleRow: {
				margin: 0
			},
			styleNav: {
				margin: "3rem 0 0 0"
			},
			tabData: [
				{
					order: 0,
					display_name: "《目宿媒體簡介》",
					value: "0",
					type: "fisfisa-main",
					data: {},
					url: ""
				},
				{
					order: 1,
					display_name: "《他們在島嶼寫作》",
					value: "1",
					type: "fisfisa-they",
					data: {
						table: []
					},
					url: `${host}api/ddm/resource/fisfisa-they/`
				},
				{
					order: 2,
					display_name: "《我們在島嶼朗讀》",
					value: "2",
					type: "fisfisa-we",
					data: {
						table: []
					},
					url: `${host}api/ddm/resource/fisfisa-we/`
				}
			],
			resource_file_datas: [],
			showModal: false,
			videoOptions: {
				autoplay: true,
				controls: true,
				sources: [
					{
						src: "",
						type: "video/mp4"
					}
				],
				language: "zh"
			},
			currentSrc: "",
			currentTime: 200
		};
	},
	computed: {
		...mapState("user", ["user"]),
		resource_file_header() {
			let temp;
			if (this.user.auth_guest) {
				if (this.topicIndex == 1) {
					temp = {
						order: "序號",
						name: "片名",
						director: "導演",
						length: "片長",
						count: "瀏覽數",
						action: "動作"
					};
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.tdwidths = [8, 42, 10, 15, 10, 15];
				} else {
					temp = {
						order: "序號",
						name: "片名",
						length: "片長",
						count: "瀏覽數",
						action: "動作"
					};
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.tdwidths = [8, 42, 20, 15, 15];
				}
			} else {
				if (this.topicIndex == 1) {
					temp = {
						order: "序號",
						name: "片名",
						director: "導演",
						length: "片長",
						count: "瀏覽數",
						action: "動作"
					};
				} else {
					temp = {
						order: "序號",
						name: "片名",
						length: "片長",
						count: "瀏覽數",
						action: "動作"
					};
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.tdwidths = [8, 42, 20, 25];
				}
			}
			return temp;
		},
		tdwidths() {
			let temp;
			if (this.user.auth_guest) {
				if (this.topicIndex == 1) {
					temp = [8, 42, 10, 15, 10, 15];
				} else {
					temp = [8, 42, 20, 15, 15];
				}
			} else {
				if (this.topicIndex == 1) {
					temp = [8, 42, 10, 25, 15];
				} else {
					temp = [8, 43, 26, 24];
				}
			}
			return temp;
		}
	},
	mounted() {
		this.get_resource_file_datas();
	},
	methods: {
		gotTo(val) {
			this.topicIndex = val;
		},
		get_resource_file_datas() {
			const apiQuerys = [];
			this.tabData.forEach(vo => {
				if (vo.order === 0) {
					return -1;
				}
				const apiQuery = axios.get(vo.url, {});
				apiQuerys.push(apiQuery);
				apiQuery
					.then(res => {
						let temp = [];
						_.each(res.data, v => {
							let [order, name, director, length] = v.name.split("-");
							name = name.replace(",", "/");
							length = length.split(".")[0];
							const url = vo.url + v.name;
							temp.push({
								order,
								name,
								director,
								length,
								action: { url, name }
							});
						});
						vo.data.table = temp.sort(compare("order", "asc"));
					})
					.catch(() => {});
			});
			Promise.all(apiQuerys)
				.then(() => {
					return logAction.filter({ category: "fisfisa", action: "view" });
				})
				.then(res => {
					const logGroup = groupByField(
						res.data,
						"object",
						k => k,
						v => v
					);
					const logGroupCount = [];
					Object.keys(logGroup).forEach(v => {
						logGroupCount.push({
							name: v,
							count: logGroup[v].length
						});
					});
					this.tabData.forEach(vo => {
						if (vo.order === 0) {
							return -1;
						}
						vo.data.table = leftJoinObjectArray(
							vo.data.table,
							logGroupCount,
							"name"
						);
						vo.data.table = fillCell(
							vo.data.table,
							Object.keys(this.resource_file_header),
							0
						);
						vo.data.table = vo.data.table.sort(compare("order", "asc"));
					});
				});
		},
		readResource(url, name) {
			const u = `${url}${name}/`;
			axios.get(u, { responseType: "blob" }).then(file_resolve(name));
		},
		readView(item) {
			this.playing = item.name;
			this.$root.$logsystem({
				category: "fisfisa",
				action: "view",
				object: item.name
			});
			this.videoOptions.sources[0].src = item.url;
			this.currentSrc = item.url;
			this.showModal = true;
		}
	}
};
</script>

<style lang="scss" scoped>
img {
	width: 100%;
}
.video-wrap {
	width: 44vw;
	@media screen and (max-width: 767px) {
		width: auto;
	}
}
::v-deep .content {
	height: auto !important;
	@media screen and (max-width: 767px) {
		width: 300px;
	}
}
</style>
