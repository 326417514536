import formatISO9075 from "date-fns/formatISO9075";

export default {
	namespaced: true,
	state: {
		logDatas: []
	},
	getters: {},
	mutations: {
		load(state) {
			let logDatas = JSON.parse(localStorage.getItem("logDatas"));
			if (logDatas) {
				state.logDatas = logDatas;
			} else {
				state.logDatas = [];
			}
		},
		save(state) {
			localStorage.setItem("logDatas", JSON.stringify(state.logDatas));
		},
		addRecord(state, { path, code, message, userid }) {
			const time = formatISO9075(new Date());
			const browser = window.navigator.userAgent;
			const os = window.navigator.platform;
			const ip = "localhost";
			state.logDatas.push({
				time,
				browser,
				os,
				ip,
				path,
				code,
				message,
				userid
			});
		},
		clean(state) {
			state.logDatas = [];
		}
	},
	actions: {
		send({ commit }) {
			// TODO:傳送
			localStorage.removeItem("logDatas");
			commit("clean");
		}
	}
};
