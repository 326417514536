<template>
	<modal v-if="modalShow" @closeModal="$emit('closeModal')">
		<template #header>
			<h4 class="modal-title">索引類別選擇</h4>
		</template>
		<template #body>
			<div>
				<nav aria-label="路徑列導航">
					<ol class="breadcrumb">
						<li
							role="link"
							class="breadcrumb-item"
							style="cursor:pointer;"
							tabindex="0"
							@click="changePointer(-1)"
						>
							所有分類
						</li>
						<template v-for="(item, key) in pointer.path">
							<li
								v-if="key === pointer.path.length - 1"
								:key="key"
								class="breadcrumb-item active"
								style="cursor: pointer;"
								aria-current="page"
							>
								{{ item.name }}
							</li>
							<li
								v-else
								role="link"
								tabindex="0"
								class="breadcrumb-item active"
								style="cursor: pointer;"
								@click="changePointer(item.id)"
								:key="key"
							>
								{{ item.name }}
							</li>
						</template>
					</ol>
				</nav>
				<div
					v-for="item in pointer.childs"
					:key="item.id"
					style="display:flex;align-items:center;"
				>
					<input
						type="radio"
						:id="item.id"
						:value="item.id"
						v-model="selected"
						style="margin-right: 30px;"
					/>
					<a
						v-if="item.childs.length > 0"
						tabindex="0"
						@click="changePointer(item.id)"
						><label class="own-child" :for="item.id">{{ item.name }}</label></a
					>
					<div v-else>
						<label :for="item.id">{{ item.name }}</label>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<button @click="selectedAction" class="btn btn-primary">確定</button>
		</template>
	</modal>
</template>

<script>
import { treeSelectItem } from "@/utils/dataProcess.js";
import modal from "@/components/popup/modal.vue";
import { indexCategoryAction } from "@/api/ebookSystem.js";

export default {
	props: {
		modalShow: {
			type: Boolean,
			default: false
		}
	},
	components: {
		modal
	},
	data() {
		return {
			selected: 0,
			pointer: { childs: [], path: [] },
			indexcategory: {},
			treeSelectItem
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			indexCategoryAction.structure().then(res => {
				this.indexcategory = {
					id: -1,
					name: "所有分類",
					childs: [
						{
							id: 0,
							name: "不分類",
							childs: [],
							path: [{ id: 0, name: "不分類" }]
						}
					].concat(res.data)
				};
				this.changePointer(-1);
			});
		},
		changePointer(id) {
			this.pointer = treeSelectItem(this.indexcategory, "childs", "id", id);
		},
		selectedAction() {
			let selectedItem = treeSelectItem(
				this.indexcategory,
				"childs",
				"id",
				this.selected
			);
			this.$emit("selected", {
				id: this.selected,
				name: selectedItem.name,
				path: selectedItem.path
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.own-child {
	display: flex;
	align-items: center;
	&::after {
		content: ">";
		width: 20px;
		height: 20px;
		background: gray;
		color: white;
		border-radius: 5px;
		display: block;
		text-align: center;
		line-height: 20px;
		margin-left: 10px;
	}
}
</style>
