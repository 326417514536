<!--
*Usage example:
**Auto type detection (will assume string):
this.$root.$message.open({ status: 200, message: '好消息' })
**Auto type detection (will assume array):
this.$root.$message.open({ status: 200, message: [ '是一個', '好消息' ] })
**Type string:
this.$root.$message.open({ status: 200, type: 'string', message: '好消息' })
**Type html:
this.$root.$message.open({ status: 200, type: 'html', message: '<p>好 <a href="#">消息</a>.</p>' })
**Type array (inner items have to be string):
this.$root.$message.open({status: 200, type: 'array', message: ['是一個', '好消息'] })
**Type object (only one level deep allowed):
this.$root.$message.open({status: 200, type: 'object', message: { '壞消息': '下雨了', '好消息': '有帶傘' } })
**Status success (< 400):
this.$root.$message.open({status: 200, message: '不重要' })
this.$root.$message.open({status: 303, message: '不重要' })
**Status error (>= 400):
this.$root.$message.open({status: 404, message: '不重要' })
this.$root.$message.open({status: 500, message: '不重要' })
-->
<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div class="popup">
				<button class="close" @click="close">&times;</button>
				<div class="content">
					<div class="msgCtnr">
						<div>已準備好線上閱讀文件，點選閱讀按鈕進入</div>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<button class="btn btn-primary" @click="go">閱讀</button>
					</div>
				</div>
				<div class="statusCtnr">
					<font-awesome-icon
						class="status-icon"
						icon="check-circle"
						v-if="promptType === 'success'"
					/>
					<font-awesome-icon
						class="status-icon"
						icon="times-circle"
						v-else-if="promptType === 'error'"
					/>
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import Trap from "vue-focus-lock";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	data() {
		return {
			url: ""
		};
	},
	created() {
		this.$root.$read = this;
	},
	methods: {
		go() {
			window.open(this.url);
			this.ok();
		},
		open({ url }) {
			this.url = url;
			this.promptType = "success";
			this.isShow = true;
			this.promise = new Promise(resolve => {
				this.$on("cancel", () => {
					resolve();
				});
				this.$on("ok", () => {
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
				this.$off("cancel");
			});
			return this.promise;
		}
	}
};
</script>

<style scoped lang="scss">
@import "./style/common.scss";

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}
</style>
