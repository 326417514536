var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"person"},[_c('h2',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"big-title",attrs:{"tabindex":"0"}},[_vm._v("會員資料")]),_c('div',{staticClass:"this-is-person-content"},[(_vm.accessExpCountdown > 0)?[_c('tab',{attrs:{"data":[
					{ display_name: '變更會員資訊', type: 'modify-person-info' },
					{ display_name: '修改密碼', type: 'modify-password' },
					{
						display_name: '身心障礙手冊查閱編修',
						type: 'modify-disability-card'
					}
				]},scopedSlots:_vm._u([{key:"modify-person-info",fn:function(){return [_c('modify-person-info',{on:{"vuex-user-2-local":function($event){return _vm.vuexUser2local()}},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})]},proxy:true},{key:"modify-password",fn:function(){return [_c('modify-password')]},proxy:true},{key:"modify-disability-card",fn:function(){return [(
							_vm.vuexUser.disabilitycard_set &&
								_vm.vuexUser.disabilitycard_set.length >= 0
						)?_c('modify-disability-card',{attrs:{"pk":_vm.vuexUser.disabilitycard_set[0]}}):_c('modify-disability-card',{attrs:{"pk":''}})]},proxy:true}],null,false,1246519410)})]:[_c('p',[_vm._v("尚未登入")])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }