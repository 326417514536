<!--
*Usage example:
**Auto type detection (will assume string):
this.$root.$message.open({ status: 200, message: '好消息' })
**Auto type detection (will assume array):
this.$root.$message.open({ status: 200, message: [ '是一個', '好消息' ] })
**Type string:
this.$root.$message.open({ status: 200, type: 'string', message: '好消息' })
**Type html:
this.$root.$message.open({ status: 200, type: 'html', message: '<p>好 <a href="#">消息</a>.</p>' })
**Type array (inner items have to be string):
this.$root.$message.open({status: 200, type: 'array', message: ['是一個', '好消息'] })
**Type object (only one level deep allowed):
this.$root.$message.open({status: 200, type: 'object', message: { '壞消息': '下雨了', '好消息': '有帶傘' } })
**Status success (< 400):
this.$root.$message.open({status: 200, message: '不重要' })
this.$root.$message.open({status: 303, message: '不重要' })
**Status error (>= 400):
this.$root.$message.open({status: 404, message: '不重要' })
this.$root.$message.open({status: 500, message: '不重要' })
-->
<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div class="popup">
				<button class="close" @click="close">&times;</button>
				<div class="content">
					<!-- Purpose of this container: I just don't want to put things directely under .content,
					, because in the future more things might add into here and I want to keep structure more consistent. -->
					<div class="msgCtnr">
						<template v-if="data.type == 'html'">
							<div v-html="data.message"></div>
						</template>
						<template v-else-if="data.type == 'array'">
							<div v-for="(item, index) in data.message" :key="item">
								{{ index + 1 }}. {{ item }}
							</div>
						</template>
						<template v-else-if="data.type == 'object'">
							<div v-for="(value, key, index) in data.message" :key="key">
								{{ index + 1 }}. {{ key }} : {{ value }}
							</div>
						</template>
						<template v-else>
							<div class="preWrap">{{ data.message }}</div>
						</template>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<button class="btn btn-primary" @click="ok">確定</button>
					</div>
				</div>
				<div class="statusCtnr">
					<font-awesome-icon
						class="status-icon"
						icon="check-circle"
						v-if="promptType === 'success'"
					/>
					<font-awesome-icon
						class="status-icon"
						icon="times-circle"
						v-else-if="promptType === 'error'"
					/>
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import Trap from "vue-focus-lock";
import { isString, isArray, isObject } from "@/utils/type";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	created() {
		this.$root.$message = this;
	},
	methods: {
		open({ status, message, type }) {
			this.data.status = status;
			this.promptType = parseInt(status, 10) < 400 ? "success" : "error";
			this.data.message = message;
			if (!type) {
				if (isString(message)) {
					this.data.type = "string";
				} else if (isArray(message)) {
					this.data.type = "array";
				} else if (isObject(message)) {
					this.data.type = "object";
				} else {
					this.data.message = JSON.stringify(message);
					this.data.type = "string";
				}
			} else {
				this.data.type = type;
			}
			this.isShow = true;
			this.promise = new Promise(resolve => {
				this.$on("cancel", () => {
					resolve();
				});
				this.$on("ok", () => {
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
				this.$off("cancel");
			});
			return this.promise;
		}
	}
};
</script>

<style scoped lang="scss">
@import "./style/common.scss";

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}
</style>
