// Usage example in <template>: <font-awesome-icon icon="times" class="textAside" />.
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faUserSecret,
	faAngleLeft,
	faAngleRight,
	faAngleDoubleDown,
	faCamera,
	faCheck,
	faCheckCircle,
	faChevronRight,
	faCoffee,
	faExchangeAlt,
	faFilter,
	faHourglassStart,
	faHourglassEnd,
	faMinus,
	faPaperPlane,
	faPen,
	faQuestion,
	faPlus,
	faSearch,
	faStream,
	faSyncAlt,
	faTimes,
	faTimesCircle,
	faTrashAlt,
	faUserCheck,
	faList,
	faBars,
	faBriefcase,
	faEllipsisH,
	faUniversity,
	faCaretLeft,
	faCaretRight
} from "@fortawesome/free-solid-svg-icons";
// .Have to sync with 190512m191246.
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
	faUserSecret,
	faAngleLeft,
	faAngleRight,
	faAngleDoubleDown,
	faCamera,
	faCheck,
	faCheckCircle,
	faChevronRight,
	faCoffee,
	faExchangeAlt,
	faFilter,
	faHourglassStart,
	faHourglassEnd,
	faMinus,
	faPaperPlane,
	faPen,
	faQuestion,
	faPlus,
	faSearch,
	faStream,
	faSyncAlt,
	faTimes,
	faTimesCircle,
	faTrashAlt,
	faUserCheck,
	faList,
	faBars,
	faBriefcase,
	faEllipsisH,
	faUniversity,
	faCaretLeft,
	faCaretRight
);
// .Have to sync with 190512m191246.
export default FontAwesomeIcon;
