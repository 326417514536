<!--
	*Todo_treegb: change all about pages ,, .bs-callout and many structure details.
	*SOP: Do these convertion everytime:

	h4 -> h3
	h5 -> h4
	h6 -> h5

	And remove inlined style (font-size, color, margin).
-->
<template>
	<div class="any-about-page container this-is-terms-of-service">
		<h2 class="big-title">服務條款</h2>
		<p>
			eDocumentService雲端千眼平台(以下簡稱「本站」)係依據本條款提供特定身心障礙者出版品重製服務。當您使用本服務時，即表示您已閱讀、瞭解並同意接受此條款之所有內容。本站有權於任何時間修改或變更此條款之內容，若您於任何修改或變更後繼續使用本服務，視為您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意此條款的內容，您應立即停止使用本站之服務。
		</p>

		<div class="bs-callout bs-callout-danger">
			<h3>一、本站委託方（人）須遵守之義務</h3>
			<p>
				本站之服務使用者為委託方（人），委託本站志工為重製代理人，部份委託方（人）受限於身體功能無法親自操作平台功能，故另行委託親人及好友為操作代理人。
			</p>
			<p>
				重製代理人進行文件重製成電子版本，是接受服務使用者或平台管理單位委託進行重製。平台管理單位或服務使用者註冊後，進行上傳文件之動作即表示委託重製代理人進行重製電子版的文件。根據著作權法第五十三條所定，本站所提供之著作重製物僅供著作權法53條規定之免責人使用，重製物雖可在所定障礙者、中央或地方政府機關、非營利機構或團體、依法立案之各級學校間散布或公開傳輸，但強烈建議每位服務使用者自行至本系統領取轉制成品，因每位服務使用者所獲之轉製成品皆有獨特之處理編碼。若轉製成品散布給非免責人，則以轉製成品特定編碼所指向之服務使用者為法律責任人。
			</p>
			<p>
				根據著作權法第五十三條所定，本站所提供之著作重製物僅供著作權法53條規定之免責人使用，重製物雖可在所定障礙者、中央或地方政府機關、非營利機構或團體、依法立案之各級學校間散布或公開傳輸，但強烈建議每位服務使用者自行至本系統領取轉制成品，因每位服務使用者所獲之轉製成品皆有獨特之處理編碼。若轉製成品散布給非免責人，則以轉製成品特定編碼所指向之服務使用者為法律責任人。
			</p>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>二、本站代理方（人）須遵守之義務</h3>
			<p>
				根據著作權法第五十三條所定：「前項所定障礙者或其代理人為供該障礙者個人非營利使用，準用前項規定。」本站志工以及服務使用者所委託的親人好友即此處所指的代理人，代理人接受本站服務使用者之委託，得以協助重製成電子格式的檔案，或是協助服務使用者操作平台功能，但用途僅限專供該障礙者個人非營利使用。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.1 志工義務</h4>
			<p>
				本站所提供志工校對之影像圖檔專為轉製電子文件校對所使用，志工不得將在本站校對所獲之影像檔以任何形式違反著作權法之規範。本站志工註冊並領取文件僅表示接受服務使用者的委託以重製電子版文件，並無其他著作權上的使用權利，包括：重製、改作、公開傳輸、公開展示、散布等等，其違反著作權法的行為須自行負責。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.2 操作代理人義務</h4>
			<p>
				由服務使用者委託親人好友操作網路平台或是代為下載電子文件，操作代理人不得將所獲之影像檔以任何形式違反著作權法之規範，包括：重製、改作、公開傳輸、公開展示、散布等等，其違反著作權法的行為須由操作代理人自行負責。
			</p>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>三、著作權法相關用詞定義</h3>
			<p>
				根據著作權法第三條所定，擷取部分用詞定義作為行為規範聲明，本站志工應避免將本站提供之校對影像圖檔有其他不受法律規範下之行為，如使用文件上觸犯以下行為，則其違法責任須自行負責。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.1 重製</h4>
			<p>
				指以印刷、複印、錄音、錄影、攝影、筆錄或其他方法直接、間接、永久或暫時之重複製作。於劇本、音樂著作或其他類似著作演出或播送時予以錄音或錄影；或依建築設計圖或建築模型建造建築物者，亦屬之。
			</p>

			<h4>3.2 公開口述</h4>
			<p>指以言詞或其他方法向公眾傳達著作內容。</p>

			<h4>3.3 公開傳輸</h4>
			<p>
				指以有線電、無線電之網路或其他通訊方法，藉聲音或影像向公眾提供或傳達著作內容，包括使公眾得於其各自選定之時間或地點，以上述方法接收著作內容。
			</p>

			<h4>3.4 改作</h4>
			<p>指以翻譯、編曲、改寫、拍攝影片或其他方法就原著作另為創作。</p>

			<h4>3.5 散布</h4>
			<p>指不問有償或無償，將著作之原件或重製物提供公眾交易或流通。</p>

			<h4>3.6 公開展示</h4>
			<p>指向公眾展示著作內容。</p>

			<h4>3.7 公開發表</h4>
			<p>
				指權利人以發行、播送、上映、口述、演出、展示或其他方法向公眾公開提示著作內容。
			</p>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>四、國際與國內著作權資訊</h3>
			<p>
				目前我國著作權法對身心障礙族群之著作權，亦已參考國際諸多法源明定身心障礙盲人之權利，茲針對國際上對身心障礙族群的著作權合理使用規定與我國著作權法之合理使用範圍，分述如後。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>4.1 國際上對身心障礙族群的著作權合理使用規定情形及趨勢</h4>
			<p>
				2003年世界盲人組織調查發現，全球有三億多視障者，97%處於開發中國家，卻只能接受5%的資訊。身心障礙族群屬於社會之弱勢團體，其於資訊尋求過程中對於訊息的掌控能力較為薄弱，如能依法利用他人著作，除能促使身心障礙者之心智提昇，協助身心障礙者投入社會外，更能達成國家文化全面性的發展。況身心障礙族群屬社會之少數，於利用著作上，對於著作財產權人之影響亦屬輕微。
			</p>
			<p>
				國際上部份國家，包括日本、西班牙及美國之著作權法允許為讓身心障礙盲人之福利，可就已公該發表的著作為盲人以點字而重製。此外，世界智慧財產組織於2013年6月28日WIPO在摩洛哥馬拉喀什舉行外交會議，正式通過「促進盲人、視障者及其他印刷品閱讀障礙者接觸已發表著作之條約(下簡稱馬拉喀什條約)」，並於2016年9月30日生效，其目的在為前開障礙者之利益創設法定的著作權限制或例外，解決障礙人士接觸資訊較易不足之問題，以營造無歧視、機會均等之環境。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>4.2 我國著作權法規定身心障礙族群的合理使用範圍</h4>
			<p>
				為符馬拉喀什條約之精神，我國於2014年1月22日修正並經立法院三讀通過著作權法第53條、第80條之2及第87條之1等規定，將重製問題從法律面至實務面全面改良制度，希望使各種出版品能更快速、更普及的為特定身心障礙者提供重製物，增加其接觸資訊的機會，而著作權法(53、80-2、87-1)修正條文修改詳細內容如下：
			</p>

			<div class="indent-content">
				<h5>
					A. 擴大著作權法第53條－擴大重製權利、重製物可在免責人間互相流通傳輸
				</h5>
				<div class="indent-content">
					<p>著作權法第53條原文：</p>
					<div class="green-content">
						<p>
							「中央或地方政府機關、非營利機構或團體、依法立案之各級學校，為專供視覺障礙者、學習障礙者、聽覺障礙者或其他感知著作有困難之障礙者使用之目的，得以翻譯、點字、錄音、數位轉換、口述影像、附加手語或其他方式利用已公開發表之著作。前項所定障礙者或其代理人為供該障礙者個人非營利使用，準用前項規定。依前二項規定製作之著作重製物，得於前二項所定障礙者、中央或地方政府機關、非營利機構或團體、依法立案之各級學校間散布或公開傳輸。」
						</p>
					</div>
					<p>
						原著作權法第53條規定僅能由非營利機構或團體為視障、學習障礙及聽覺障礙者的使用重製出版品，修法後的重製權利擴大為「中央及地方機關、非營利機構或團體、依法設立之各級學校以及特定身心障礙者個人或其代理人」得以進行重製。具重製資格之個人或單位之間亦可互相分享重製物。
					</p>
				</div>

				<h5>B. 新增著作權法80條之2第9款－排除防盜拷限制</h5>
				<div class="indent-content">
					<p>著作權法第80條之2第9款原文：</p>
					<div class="green-content">
						<p>
							「著作權人所採取禁止或限制他人擅自進入著作之防盜拷措施，未經合法授權不得予以破解、破壞或以其他方法規避之。
							破解、破壞或規避防盜拷措施之設備、器材、零件、技術或資訊，未經合法授權不得製造、輸入、提供公眾使用或為公眾提供服務。
							前二項規定，於下列情形不適用之：
						</p>
						<ol class="often is-list-trad-chinese">
							<li>為維護國家安全者。</li>
							<li>中央或地方機關所為者。</li>
							<li>
								檔案保存機構、教育機構或供公眾使用之圖書館，為評估是否取得資
								料所為者。
							</li>
							<li>為保護未成年人者。</li>
							<li>為保護個人資料者。</li>
							<li>為電腦或網路進行安全測試者。</li>
							<li>為進行加密研究者。</li>
							<li>為進行還原工程者。</li>
							<li>
								為依第四十四條至第六十三條及第六十五條規定利用他人著作者。
							</li>
							<li>其他經主管機關所定情形。</li>
						</ol>
						<p>前項各款之內容，由主管機關定之，並定期檢討。」</p>
					</div>
					<p>
						新增第80條之2第9款，讓依據第53條目的而進行重製的行為，排除在防盜拷限制規定中。實務上常見之防盜拷為使用數位版權管理(DRM)機制，數位版權管理可以控制和限制數位化媒體內容的使用權。
					</p>
				</div>

				<h5>C. 新增著作權法第87條之1－排除跨國輸入重製物的法律限制</h5>
				<div class="indent-content">
					<p>著作權法第第87條之1原文：</p>
					<div class="green-content">
						<p>
							「有下列情形之一者，前條第四款『未經著作財產權人同意而輸入著作原件或其國外合法重製物者。』之規定，不適用之：
						</p>
						<ol class="often is-list-trad-chinese">
							<li>
								為供中央或地方機關之利用而輸入。但為供學校或其他教育機構之利
								用而輸入或非以保存資料之目的而輸入視聽著作原件或其重製物者，
								不在此限。
							</li>
							<li>
								為供非營利之學術、教育或宗教機構保存資料之目的而輸入視聽著作
								原件或一定數量重製物，或為其圖書館借閱或保存資料之目的而輸入
								視聽著作以外之其他著作原件或一定數量重製物，並應依第四十八條
								規定利用之。
							</li>
							<li>
								為供輸入者個人非散布之利用或屬入境人員行李之一部分而輸入著作
								原件或一定數量重製物者。
							</li>
							<li>
								中央或地方政府機關、非營利機構或團體、依法立案之各級學校，為
								專供視覺障礙者、學習障礙者、聽覺障礙者或其他感知著作有困難之
								障礙者使用之目的，得輸入以翻譯、點字、錄音、數位轉換、口述影
								像、附加手語或其他方式重製之著作重製物，並應依第五十三條規定
								利用之。
							</li>
							<li>
								附含於貨物、機器或設備之著作原件或其重製物，隨同貨物、機器或
								設備之合法輸入而輸入者，該著作原件或其重製物於使用或操作貨物
								、機器或設備時不得重製。
							</li>
							<li>
								附屬於貨物、機器或設備之說明書或操作手冊隨同貨物、機器或設備
								之合法輸入而輸入者。但以說明書或操作手冊為主要輸入者，不在此限。
							</li>
						</ol>
						<p>前項第二款及第三款之一定數量，由主管機關另定之。」</p>
					</div>
					<p>
						第87條之1，讓依據第53條目的而進行重製的行為，排除跨國輸入重製物的法律限制。
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "服務條款"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";
</style>
