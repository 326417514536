<template>
	<div>
		<tab :headinglevel="3" :data="tab_data">
			<template slot="notyetredeem">
				<h4 style="margin-top:30px;">勾選欲兌換之服務紀錄</h4>
				<hr style="margin-top:5px;" />
				<div style="margin-bottom:60px;">
					<button
						class="btn btn-primary mr-sm-2"
						@click="editrecord_select_all()"
					>
						全選
					</button>
					<button
						class="btn btn-primary mr-sm-2"
						@click="editrecord_checks = []"
					>
						全不選
					</button>
					<button
						class="btn btn-primary mr-sm-2"
						type="Button"
						@click="editrecord_select_inv()"
					>
						反向選
					</button>
					<table-div :datas="editrecords" :header="editrecords_columns">
						<template slot="id" slot-scope="props">
							<input
								type="checkbox"
								v-model="editrecord_checks"
								:value="props.item"
							/>
						</template>
					</table-div>
					<form-filter>
						<!--<form-item label="選擇兌換中心">
							<select v-model="org_select" class="form-control">
								<option v-for="item in org_list" :value="item.id">
									{{ item.name }}
								</option>
							</select>
						</form-item>-->
						<form-item>
							<button @click="serviceinfo_create()" class="btn btn-primary">
								兌換
							</button>
						</form-item>
					</form-filter>
				</div>
			</template>

			<template slot="redeeming">
				<table-div
					:datas="exchange_false_serviceinfos"
					:header="exchange_false_serviceinfos_columns"
				>
					<template slot="editrecord_set" slot-scope="props">
						<button
							class="btn btn-outline-secondary"
							@click="
								editrecords_detail(props.item);
								isShow = true;
							"
						>
							詳細服務紀錄
						</button>
					</template>
					<template slot="serviceinfo_delete" slot-scope="props">
						<button
							class="btn btn-outline-secondary"
							@click="serviceinfo_delete(props.item)"
						>
							取消申請
						</button>
					</template>
				</table-div>
			</template>

			<template slot="redeemed">
				<table-div
					:datas="exchange_true_serviceinfos"
					:header="exchange_true_serviceinfos_columns"
				>
					<template slot="editrecord_set" slot-scope="props">
						<button
							class="btn btn-outline-secondary"
							@click="
								editrecords_detail(props.item);
								isShow = true;
							"
						>
							詳細服務紀錄
						</button>
					</template>
				</table-div>
			</template>
		</tab>

		<div>
			<modal v-if="isShow" @closeModal="isShow = false">
				<template slot="header">
					<h4 class="modal-title">詳細服務紀錄</h4>
				</template>

				<template slot="body">
					<table-div
						:datas="detail_editrecords"
						:header="detail_editrecords_columns"
					/>
				</template>
			</modal>
		</div>
	</div>
</template>

<script>
import modal from "@/components/popup/modal.vue";
import tab from "@/components/tab.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";
import tableDiv from "@/components/tableDiv.vue";
import { editRecordRest } from "@/api/ebookSystem.js";
import {
	organizationRest,
	serviceInfoRest,
	userRest
} from "@/api/genericUser.js";
import moment from "moment";

export default {
	props: ["user_id"],
	components: {
		modal,
		tab,
		tableDiv,
		formItem,
		formFilter
	},
	data() {
		return {
			isShow: false,
			pk: "",
			user: {},
			tab_data: [
				{
					order: 0,
					display_name: "未兌換",
					value: "notyetredeem",
					type: "notyetredeem",
					data: ""
				},
				{
					order: 1,
					display_name: "審核中",
					value: "redeeming",
					type: "redeeming",
					data: ""
				},
				{
					order: 2,
					display_name: "已兌換",
					value: "redeemed",
					type: "redeemed",
					data: ""
				}
			],
			editrecords: [],
			editrecords_columns: {
				id: "核取",
				get_date: "服務時間",
				service_hours: "服務時數",
				stay_hours: "線上時數",
				category: "類型"
			},
			exchange_false_serviceinfos: [],
			exchange_false_serviceinfos_columns: {
				date: "兌換日期",
				service_hours: "服務時數",
				org: "兌換單位",
				editrecord_set: "服務紀錄",
				serviceinfo_delete: "動作"
			},
			exchange_true_serviceinfos: [],
			exchange_true_serviceinfos_columns: {
				date: "兌換日期",
				service_hours: "服務時數",
				org: "兌換單位",
				editrecord_set: "服務紀錄"
			},
			detail_editrecords: [],
			detail_editrecords_columns: {
				part: "文件",
				get_date: "服務時間",
				service_hours: "服務時數",
				stay_hours: "線上時數",
				category: "類型"
			},
			org_list: [],
			editrecord_checks: [],
			org_select: ""
		};
	},
	computed: {
		transferData() {
			let org = this.user.org || 1;
			return {
				editrecord_set: this.editrecord_checks,
				date: moment().format("YYYY-MM-DD"),
				service_hours: 1000,
				is_exchange: false,
				owner: this.pk,
				org: org
			};
		},
		service_hours() {
			let service_hour = 0;
			_.each(this.editrecords, v => {
				if (_.includes(this.editrecord_checks, v.id)) {
					service_hour += v.service_hours;
				}
			});
			return service_hour;
		}
	},
	watch: {
		async user_id(val) {
			this.pk = val;
			try {
				let res = await userRest.read(val);
				this.user = res.data;
				this.refresh();
			} catch (error) {
				this.$errorHandler({ error });
			}
		}
	},
	async mounted() {
		this.pk = this.user_id;
		let res = await userRest.read(this.user_id);
		this.user = res.data;
		this.refresh();
	},
	methods: {
		instance_set(event) {
			this.pk = event;
			this.refresh();
		},
		refresh() {
			if (!this.pk) return -1; // pk 值未設定時不傳值
			this.editrecords = [];
			this.editrecord_checks = [];
			this.exchange_false_serviceinfos = [];
			this.exchange_true_serviceinfos = [];
			let query = { editor_id: this.pk, exchange: "false" };
			editRecordRest
				.filter(query)
				.then(res => {
					_.each(res.data, v => {
						this.editrecords.push({
							id: v["id"],
							get_date: v["get_date"],
							service_hours: v["service_hours"],
							stay_hours: v["stay_hours"],
							category: v["category"]
						});
						this.editrecord_checks.push(v.id);
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});

			query = { owner_id: this.pk, is_exchange: "false" };
			serviceInfoRest
				.filter(query)
				.then(res => {
					_.each(res.data, v => {
						this.exchange_false_serviceinfos.push({
							date: v["date"],
							service_hours: v["service_hours"],
							org: v["orginfo"].name,
							editrecord_set: v.editrecordinfo_set,
							serviceinfo_delete: v.id
						});
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
			query = { owner_id: this.pk, is_exchange: "true" };
			serviceInfoRest
				.filter(query)
				.then(res => {
					_.each(res.data, v => {
						this.exchange_true_serviceinfos.push({
							date: v["date"],
							service_hours: v["service_hours"],
							org: v["orginfo"].name,
							editrecord_set: v.editrecordinfo_set
						});
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
			organizationRest.list().then(res => {
				this.org_list = res.data;
			});
		},
		editrecord_select_all() {
			_.each(this.editrecords, v => {
				this.editrecord_checks.push(v.id);
			});
		},
		editrecord_select_inv() {
			let temp = [];
			_.each(this.editrecords, v => {
				if (!_.includes(this.editrecord_checks, v.id)) {
					temp.push(v.id);
				}
			});
			this.editrecord_checks = temp;
		},
		serviceinfo_create() {
			if (this.editrecord_checks.length === 0) {
				this.$root.$message.open({
					status: 400,
					message: "請至少選擇一筆服務紀錄。"
				});
				return -1;
			}
			/*if (this.org_select == '') {
				this.$root.$message.open({ status: 400, message: '請選擇兌換中心。' })
				return -1
			}
			if(this.service_hours<60){
				this.$root.$message.open({status: 400, message: '單筆申請服務時數需超過1小時。'})
				return -1
			}*/
			serviceInfoRest
				.create(this.transferData)
				.then(res => {
					this.$root.$message.open({
						status: 200,
						message: "申請兌換時數" + res.data.service_hours + "，請等待核發。"
					});
					this.refresh();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		serviceinfo_delete(id) {
			this.$root.$confirm
				.open({
					status: 200,
					message: "是否確認取消申請該服務時數(id:" + id + ")？"
				})
				.then(() => {
					return serviceInfoRest.delete(id);
				})
				.then(() => {
					this.$root.$message.open({
						status: 400,
						message: "已取消申請兌換時數(id:" + id + ")，請重新選擇服務紀錄。"
					});
					this.refresh();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		editrecords_detail(editrecords) {
			this.detail_editrecords = editrecords;
		}
	}
};
</script>
