<!-- About: 輪播圖/slideshow/banner/carousel ... whatever you called it. -->
<template>
	<div
		class="slideshow wrapper-layer-1"
		:style="`--slideshow-transition: ${slideshowTransition}`"
	>
		<div class="wrapper-layer-2">
			<div class="wrapper-layer-3">
				<div
					v-for="(aImg, index) of slideshowImages"
					:class="['item', { active: index === slideshowIndex }]"
					:key="aImg.src"
				>
					<a class="link-of-image" :href="aImg.url">
						<img :src="aImg.src" :alt="aImg.title" />
					</a>
				</div>
			</div>
		</div>
		<div class="jump-button-wrapper">
			<div
				v-for="(aImg, index) of slideshowImages"
				:class="[
					'jump-button',
					'is-pointer',
					{ active: index === slideshowIndex }
				]"
				:key="aImg.src"
				@click="slideshowManualJump({ jump: index })"
				tabindex="0"
				:aria-label="
					'輪播' + (index + 1) + (index === slideshowIndex ? '當前頁' : '')
				"
				role="button"
			></div>
		</div>
		<div
			class="shift-arrow-wrapper left"
			role="button"
			tabindex="0"
			aria-label="上一頁"
			@click="slideshowManualJump({ shift: -1 })"
		>
			<div class="arrow-outer-box is-pointer">
				<font-awesome-icon icon="caret-left" class="textAside left" />
			</div>
		</div>
		<div
			class="shift-arrow-wrapper right"
			role="button"
			tabindex="0"
			aria-label="下一頁"
			@click="slideshowManualJump({ shift: 1 })"
		>
			<div class="arrow-outer-box is-pointer">
				<font-awesome-icon icon="caret-right" class="textAside right" />
			</div>
		</div>
		<!-- For screen readers. -->
		<div class="textfornvda">
			<h3>輪播圖片說明</h3>
			<ul>
				<li v-for="(item, index) in slideshowImages" :key="index">
					<div v-html="markdown2html(item.content)"></div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import showdown from "showdown";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	name: "slideshow",
	components: {
		FontAwesomeIcon
	},
	props: {
		slideshowImagesRaw: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			slideshowIndex: 0,
			slideshowInterval: 5000,
			// For css only. In "ms".
			slideshowTransition: 2900,
			slideshowIntervalId: null
			// *For testing purpose only:
			// ---------------------------
			// slideshowImages: [
			// 	{
			// 		title: '111',
			// 		src: require('@/assets/dummy_1200x400_1.jpeg'),
			// 		url: 'http://www.example.com/1',
			// 		content: 'this is dummy image 1'
			// 	},
			// 	{
			// 		title: '222',
			// 		src: require('@/assets/dummy_1200x400_2.jpeg'),
			// 		url: 'http://www.example.com/2',
			// 		content: 'this is dummy image 2'
			// 	},
			// 	{
			// 		title: '333',
			// 		src: require('@/assets/dummy_1200x400_3.jpeg'),
			// 		url: 'http://www.example.com/3',
			// 		content: 'this is dummy image 3'
			// 	},
			// ],
		};
	},
	methods: {
		slideshowAutoShift() {
			this.slideshowIntervalId = setInterval(() => {
				if (this.slideshowIndex + 1 >= this.slideshowImages.length) {
					this.slideshowIndex = 0;
					return;
				}
				this.slideshowIndex++;
			}, this.slideshowInterval);
		},
		slideshowManualJump({ shift, jump } = {}) {
			// Arrow button (either left or right) get clicked.
			if (typeof shift !== "undefined") {
				let newIndex = this.slideshowIndex + shift;
				// Purpose: Reset the "timmer", after each manual clicked.
				this.slideshowClearInterval();

				// If exists index is 0, 1, 2, newIndex becomes "3" (not possible).
				if (newIndex >= this.slideshowImages.length) {
					newIndex = 0;
				}
				// If exists index is 0, 1, 2, newIndex becomes "-1" (not possible).
				if (newIndex < 0) {
					newIndex = this.slideshowImages.length - 1;
				}
				this.slideshowIndex = newIndex;
				this.slideshowAutoShift();
			}
			// Dot button (any one) get clicked.
			if (typeof jump !== "undefined") {
				// Purpose: Reset the "timmer", after each manual clicked.
				this.slideshowClearInterval();
				this.slideshowIndex = jump;
				this.slideshowAutoShift();
			}
		},
		slideshowClearInterval() {
			clearInterval(this.slideshowIntervalId);
		},
		markdown2html(text) {
			const converter = new showdown.Converter();
			const html = converter.makeHtml(text);
			return html;
		}
	},
	computed: {
		slideshowImages() {
			return this.slideshowImagesRaw.map(item => ({
				title: item.title,
				src: item.src,
				url: item.url,
				content: item.content
			}));
		}
	},
	mounted() {
		this.slideshowAutoShift();
	},
	beforeDestroy() {
		this.slideshowClearInterval();
	}
};
</script>

<style scoped lang="scss">
// *Purpose of class .wrapper-layer-{1,2,3}: Using with { --aspect-ratio }, the slideshow overall will finally become
// this aspect ratio, and image, arrow buttons, jump buttons, will fit inside.
.slideshow/*.wrapper-layer-1*/ {
	// (1.3333).
	--aspect-ratio: calc(4 / 3);
	// *Jump button:
	// -------------
	--jump-button-width: 0.9em;
	--jump-button-margin: 0.5em;
	--jump-button-border-width: calc(0.0625em * 0);
	// *Shift button:
	// -------------
	--arrow-outer-box-width: 4em; //4em
	--arrow-outer-box-height: calc(var(--arrow-outer-box-width) * 1.7); //*1.7
	--arrow-icon-font-size: 5em; //1.6

	position: relative;
	width: 100%;
	max-width: 120em;
	margin-left: auto;
	margin-right: auto;

	@keyframes opac {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.wrapper-layer-2 {
		position: relative;
		width: 100%;
		padding-top: calc(100% / var(--aspect-ratio));

		.wrapper-layer-3 {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			.item {
				display: none;
				width: 100%;
				height: 100%;
				// "--slideshow-transition" ,, source , came from javascript.
				animation: opac calc(1ms * var(--slideshow-transition));

				&.active {
					display: block;
				}

				// &:not(.active) {
				// 	display: none;
				// }

				a/*.link-of-image*/ {
					display: inline-block;
					width: 100%;
					height: 100%;

					img {
						object-fit: fit;
						object-position: 50% 50%;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
	.jump-button-wrapper {
		position: absolute;
		left: 50%;
		bottom: 1em;
		display: grid;
		grid-auto-columns: auto;
		grid-auto-flow: column;
		transform: translateX(-50%);

		.jump-button {
			width: var(--jump-button-width);
			height: var(--jump-button-width);
			margin: var(--jump-button-margin);
			background-color: #bbb;
			border: var(--jump-button-border-width) solid #222;
			border-radius: 50%;
			opacity: 0.9;
			transition: all 0.2s;

			&.active {
				background-color: #fff;
			}
			&:hover {
				background-color: #fff;
			}
		}
	}
	.shift-arrow-wrapper {
		position: absolute;

		&.left,
		&.right {
			top: 0;
			bottom: 0;
			width: calc(var(--arrow-outer-box-width) + 1.2em);
			display: grid;
			justify-content: center;
			align-content: center;

			.arrow-outer-box {
				display: grid;
				justify-content: center;
				align-content: center;
				width: var(--arrow-outer-box-width);
				height: var(--arrow-outer-box-height);
				//border: 1px solid hsla(0deg, 0%, 80%, 0.3);
				//background-color: hsla(0deg, 0%, 0%, 0.14);
				color: black; //hsla(0deg, 0%, 80%, 1);
				border-radius: 0.3em;
				transition: all 0.3s;

				&:hover {
					//border: 1px solid hsla(0deg, 0%, 80%, 0.5);
					color: hsla(0deg, 0%, 80%, 1); //hsla(0deg, 0%, 100%, 1);
					// transform: scale(1.05);
				}
				> * {
					font-size: var(--arrow-icon-font-size);
				}
			}
		}
		&.left {
			left: 0;

			.arrow-outer-box {
			}
		}
		&.right {
			right: 0;

			.arrow-outer-box {
			}
		}
	}
	.textfornvda {
		// Purpose: Prevent css breaks when this component move to different location
		// that have no "".textfornvda" styles for other uses, though this is obvious (when style breaks) and you found out why easily.
		position: absolute;
	}
}

@media (min-width: media-query-length($em: $breakpoint_xs)) {
	.slideshow/*.wrapper-layer-1*/ {
		// (1.7777).
		--aspect-ratio: calc(16 / 9);
	}
}

@media (min-width: media-query-length($em: $breakpoint_md)) {
	.slideshow/*.wrapper-layer-1*/ {
		// (2.1333).
		--aspect-ratio: calc(960 / 450);
	}
}

@media screen and (max-width: 750px) {
	.textAside {
		&.right {
			left: 4.5rem;
		}
		&.left {
			right: 4.5rem;
		}
	}
}
</style>
