<template>
	<div class="container">
		<h2 v-focus tabindex="0">書籍詳細</h2>
		<tab
			:data="[
				{ display_name: '總覽', type: 'overall' },
				{ display_name: '校對紀錄', type: 'editrecord' }
			]"
		>
			<template #overall>
				<h3>總覽</h3>
				<div>
					<button
						class="btn btn-outline-secondary"
						@click="downloadModalShow = true"
					>
						下載
					</button>
					<button
						class="btn btn-outline-secondary"
						@click="deleteBook"
					>
						下架
					</button>
					<table-div :header="overall_header" :datas="overall_datas">
						<template #action="props">
							<button
								v-if="props.item.status === 1"
								class="btn btn-outline-secondary"
								@click="
									assignPk = props.item.ISBN_part;
									assignModalShow = true;
								"
							>
								指派
							</button>
							<button
								v-if="props.item.status === 4"
								class="btn btn-outline-secondary"
								@click="onactive(props.item.ISBN_part)"
							>
								再校對
							</button>
						</template>
					</table-div>
				</div>
			</template>
			<template #editrecord>
				<h3>校對紀錄</h3>
				<div class="row">
					<div class="col-sm-3 col-md-3">
						<div class="panel-group y-scroll-box">
							<div class="panel panel-default">
								<div class="panel-heading">
									<h4 class="panel-title">
										<span class="glyphicon glyphicon-folder-close"></span
										>&nbsp;&nbsp;分段列表
									</h4>
								</div>
								<div class="panel-collapse">
									<ul class="list-group">
										<li
											class="list-group-item"
											v-for="(item, index) in book.ebook_set"
											:key="index"
										>
											<a v-on:click="read(index)" tabindex="0">{{
												item.bookname + item.part
											}}</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-9 col-md-9">
						<h4>分段基本資訊</h4>
						<ul class="list-content">
							<li v-for="(value, key) in info" :key="key">
								{{ key }}:{{ value }}
							</li>
						</ul>
						<h4>分段校對紀錄</h4>
						<table-div
							:header="editrecord_header"
							:datas="editrecord_datas"
						></table-div>
					</div>
				</div>
			</template>
		</tab>
		<modal v-if="downloadModalShow" @closeModal="downloadModalShow = false">
			<template #header>
				<h4 class="modal-title">取得書籍</h4>
			</template>
			<template #body>
				<div class="text-center warning-dont-spread">
					**強烈建議您，所下載書籍僅供個人閱讀使用，請勿任意轉傳，以免觸法，詳細規範請參照，本平台服務條款**
				</div>
				<div class="form-horizontal">
					<template v-for="v in ['fileformat', 'password']">
						<formdrf
							:model_info="downloadModel[v]"
							:field="v"
							v-model="downloadData[v]"
							:key="v"
						></formdrf>
					</template>
				</div>
			</template>
			<template #footer>
				<button class="btn btn-primary" @click="download">確定</button>
			</template>
		</modal>
		<modal v-if="assignModalShow" @closeModal="assignModalShow = false">
			<template #header>
				<h4 class="modal-title">指派 {{ assignPk }} 校對者</h4>
			</template>
			<template #body>
				<ebook-assign :pk="assignPk" ref="ea" />
			</template>
			<template #footer>
				<button
					@click="
						$refs.ea.save_assign();
						assignModalShow = false;
					"
				>
					指派
				</button>
			</template>
		</modal>
	</div>
</template>

<script>
import {
	bookRest,
	bookAction,
	ebookRest,
	ebookAction
} from "@/api/ebookSystem.js";
import formdrf from "@/components/form.vue";
import modal from "@/components/popup/modal.vue";
import tab from "@/components/tab.vue";
import tableDiv from "@/components/tableDiv.vue";
import ebookAssign from "@/components/components/ebookAssign.vue";

export default {
	components: {
		formdrf,
		modal,
		tab,
		tableDiv,
		ebookAssign
	},
	data() {
		return {
			assignModalShow: false,
			assignPk: "",
			downloadModalShow: false,
			downloadData: {
				fileformat: "epub",
				password: ""
			},
			downloadModel: {
				fileformat: {
					label: "類型",
					type: "select",
					choices: [
						{
							display_name: "電子書(epub)",
							value: "epub"
						},
						{
							display_name: "純文字(txt)",
							value: "txt"
						}
					]
				},
				password: {
					label: "密碼",
					type: "password"
				}
			},
			pk: this.$route.params.id,
			book: {},
			overall_header: {
				part: "段數",
				status: "狀態",
				edited_page: "頁數",
				get_date: "領取日期",
				deadline: "期限",
				action: "動作"
			},
			overall_datas: [],
			editrecord_header: {
				number_of_times: "次數",
				get_date: "領取日期",
				stay_hours: "線上時數",
				service_hours: "服務時數",
				editor: "校對者",
				category: "類型"
			},
			ebook_index: 0,
			isbn_part: ""
		};
	},
	computed: {
		ebook() {
			if (this.book.ebook_set) {
				return this.book.ebook_set[this.ebook_index];
			} else {
				return null;
			}
		},
		editrecord_datas() {
			let temp = [];
			if (this.ebook) {
				_.each(this.ebook.editrecord_set, v => {
					temp.push({
						number_of_times: v.number_of_times,
						get_date: v.get_date,
						stay_hours: v.stay_hours,
						service_hours: v.service_hours,
						editor: v.editor,
						category: v.category
					});
				});
				return temp;
			} else {
				return null;
			}
		},
		info() {
			if (this.ebook) {
				return {
					段數: this.ebook.part,
					狀態: this.ebook.status,
					時數: this.ebook.service_hours,
					"頁數/總頁數": this.ebook.edited_page + 1 + "/50",
					預計完成日期: this.ebook.deadline
				};
			} else {
				return null;
			}
		}
	},
	metaInfo: {
		title: "書籍詳細"
	},
	mounted() {
		this.get_overall_datas();
	},
	methods: {
		get_overall_datas() {
			bookRest
				.read(this.pk)
				.then(res => {
					this.book = res.data;
					return ebookRest.reads(res.data.ebook_set);
				})
				.then(res => {
					this.book.ebook_set = res.data;
					this.overall_datas = [];
					_.each(this.book.ebook_set, v => {
						this.overall_datas.push({
							part: v.part,
							status: v.status,
							edited_page: v.edited_page + 1,
							get_date: v.get_date,
							deadline: v.deadline,
							action: v
						});
					});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		read(index) {
			this.ebook_index = index;
		},
		onactive(ISBN_part) {
			this.$root.$confirm
				.open({ status: 200, message: "是否確定再校對?" })
				.then(() => {
					return ebookAction.onactive(ISBN_part);
				})
				.then(() => {
					this.$root.$message.open({
						status: 400,
						message: "成功將分段再校對"
					});
					this.get_overall_datas();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		download() {
			bookAction
				.download(this.pk, {
					...this.downloadData
				})
				.then(() => {
					this.downloadModalShow = false;
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		deleteBook() {
			this.$root.$confirm.open({status: 200, message: '執行下架後這本書籍在平台中的歷史紀錄將一併刪除，您確定要將此書下架嗎？'})
				.then(() => {
					return bookRest.delete(this.pk);
				})
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message: "成功下架書籍",
					});
				})
				.then(() => {
					this.$router.push({ name: "home" });
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.warning-dont-spread {
	color: $color-soft-red;
	margin-bottom: 1rem;
	// font-size: 16px;
}
</style>
