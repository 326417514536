<template>
	<div class="any-about-page container this-is-contact">
		<h2 class="big-title">聯絡資訊</h2>
		<p style="margin-top: 1.5em;">服務專線：02-2717-7722分機21</p>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "聯絡資訊"
	}
};
</script>
<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
</style>
