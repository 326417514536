<template>
	<div class="form-item mr-sm-2">
		<label class=" mr-sm-2" v-if="label">
			<font-awesome-icon v-if="icon" :icon="icon" />
			{{ label }}
		</label>
		<slot></slot>
		<!-- <input
      :type="type"
      v-model="value"
      class="form-control"
      :placeholder="noLabel ? label : ''"
      :class="{'mb-2 mr-sm-2': noLabel ? true : false}"
    > -->
	</div>
</template>

<script>
export default {
	props: {
		row: {
			type: Boolean
		},
		icon: {
			type: String
		},
		label: {
			type: String
		},
		type: {
			type: String
		},
		noLabel: {
			type: Boolean
		}
	}
};
</script>

<style scoped lang="scss">
.form-item {
	display: flex;
	align-items: center;
	@media screen and (max-width: 767px) {
		margin-bottom: 10px;
	}
	label {
		// width: 130px;
		line-height: 36px;
		margin-bottom: 0px;
		white-space: nowrap;
	}
}
</style>
