import axios from "axios";
import { restFactory, host } from "./base.js";
import { set_token } from "./token.js";

const user = axios.create({
	baseURL: host + "genericUser/api/users/"
});

user.interceptors.request.use(set_token, error => {
	// Do something with request error
	return Promise.reject(error);
});

export const userRest = restFactory(user);
export const userAction = {
	generateResetPassword: ({ value }) =>
		user.post("action/generate_reset_password/", {
			value
		}),
	verifyResetPassword: ({ code, newPassword1, newPassword2 }) =>
		user.post(`action/verify_reset_password/`, {
			code,
			newPassword1,
			newPassword2
		}),
	setPassword: (pk, { oldPassword, newPassword1, newPassword2 }) =>
		user.post(`${pk}/action/set_password/`, {
			oldPassword,
			newPassword1,
			newPassword2
		}),
	generateCode: ({ user_id, category, value }) =>
		user.get(`action/generate_code`, {
			params: {
				user_id,
				category,
				value
			}
		}),
	verifyCode: ({ code }) =>
		user.get(`action/verify_code`, {
			params: {
				code
			}
		})
};
