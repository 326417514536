<template>
	<div class="container" v-if="book_update">
		<h2 v-focus tabindex="0">編輯 - {{ book_update.book_info && book_update.book_info.bookname }}</h2>
		<div class="book-edit">
			<template v-for="v in ['priority', 'owner', 'category']">
				<formdrf
					:model_info="bookModel[v]"
					:field="v"
					v-model="book_update[v]"
					:key="v"
				></formdrf>
			</template>
			<div class="btns">
				<button type="button" class="btn btn-primary" @click="updates">
					確認
				</button>
				<button
					type="button"
					class="btn btn-light"
					@click="$router.push({ name: 'bookManagerSuper' })"
				>
					取消
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import formdrf from "@/components/form.vue";
import { bookRest, bookSimpleRest } from "@/api/ebookSystem.js";
import { userRest } from "@/api/genericUser.js";
import { mapState } from "vuex";

export default {
	data() {
		return {
			bookModel: {
				priority: {
					label: "權重",
					type: "select",
					required: true,
					choices: [
						{
							value: 1,
							display_name: "1"
						},
						{
							value: 2,
							display_name: "2"
						},
						{
							value: 3,
							display_name: "3"
						},
						{
							value: 4,
							display_name: "4"
						},
						{
							value: 5,
							display_name: "5"
						},
						{
							value: 6,
							display_name: "6"
						},
						{
							value: 7,
							display_name: "7"
						},
						{
							value: 8,
							display_name: "8"
						},
						{
							value: 9,
							display_name: "9"
						}
					]
				},
				owner: {
					label: "擁有者",
					type: "select",
					required: true,
					choices: []
				},
				organization: {
					label: "單位",
					type: "select",
					required: true,
					choices: []
				},
				category: {
					label: "類型",
					type: "select",
					required: true,
					choices: []
				},
			},
			isbn: "",
			book_update: {}
		};
	},
	components: {
		formdrf,
	},
	computed: {
		...mapState(["organizations", "categorys"]),
		computedOrg() {
			return (
				[
					...this.organizations.map(t => {
						return {
							display_name: t.name,
							value: t.id
						};
					})
				]
			);
		},
		computedCategory() {
			return (
				[
					...[
						{
							display_name: "不分類",
							value: "0"
						}
					],
					...this.categorys.map(t => {
						return {
							display_name: t.name,
							value: t.id
						};
					})
				]
			);
		},
	},
	methods: {
		updates() {
			bookSimpleRest
				.partialupdate(this.book_update.ISBN, {
					priority: this.book_update.priority,
					owner: this.book_update.owner,
					category: this.book_update.category,
				})
				.then(() => {
					this.$root.$message
						.open({ status: 200, message: "成功更新資料" })
						.then(() => {
							this.$router.push({ name: "bookManagerSuper" });
						});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		}
	},
	async mounted() {
		const { isbn } = this.$route.params;
		let res;
		res = await userRest.filter({ role: "guest" });
		res.data.forEach(v => {
			this.bookModel.owner.choices.push({
				value: v.id,
				display_name: v.username
			});
		});
		this.bookModel.organization.choices = this.computedOrg;
		this.bookModel.category.choices = this.computedCategory;
		res = await bookRest.read(isbn);
		this.book_update = res.data;
	}
};
</script>
<style lang="scss" scoped>
.book-edit {
	width: 50%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
}
.btns {
	margin-top: 10px;
	text-align: right;
}
</style>
