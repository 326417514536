<template>
	<div class="audio-player">
		<div class="audio__btn-wrap">
			<div class="audio__play-rate">
				<select
					class="custom-select"
					name="playbackRates"
					id="playbackRates"
					aria-label="播放速度"
					@change="handleSetPlaybackRate"
				>
					<option
						v-for="rate in playbackRates"
						:key="rate"
						:value="rate"
						:selected="playbackRate === rate"
					>
						{{ rate.toFixed(1) + "x" }}
					</option>
				</select>
			</div>
			<button
				v-if="!isPlaying"
				class="audio__play-start btn btn-manage"
				@click.stop="play"
			>
				play
			</button>
			<button
				v-else
				class="audio__play-pause btn btn-manage"
				@click.stop="pause"
			>
				pause
			</button>

			<div class="audio__play-volume-icon-wrap">
				<button class="btn" @click.stop="handleVolumeIconTouchstart">
					volume
				</button>

				<transition name="fade-volume">
					<div
						v-show="isShowVolume"
						ref="playVolumeWrap"
						class="audio__play-volume-wrap"
						role="progressbar"
						:aria-valuenow="currentVolume * 100"
						aria-valuemax="100"
						@click.stop="handleVolumeClick"
					>
						<div
							ref="playVolume"
							class="audio__play-volume"
							:style="{ height: currentVolume * 100 + '%' }"
						/>
					</div>
				</transition>
			</div>

			<div v-show="isShowErrorMessage" class="audio__notice">
				{{ noticeMessage }}
			</div>
		</div>

		<div
			ref="audioProgressWrap"
			class="audio__progress-wrap"
			@click.stop="handleClickProgressWrap"
		>
			<div ref="audioProgress" class="audio__progress" />
			<div
				ref="audioProgressPoint"
				class="audio__progress-point"
				@mousedown="handleProgressPanstart"
				@mouseup="handleProgressPanend"
				@mousemove="handleProgressPanmove"
			/>
		</div>

		<div class="audio__time-wrap">
			<div class="audio__current-time">
				{{ currentTimeFormatted }}
			</div>
			<div class="audio__duration">
				{{ durationFormatted }}
			</div>
		</div>

		<audio
			ref="audio"
			class="audio-player__audio"
			:src="audioList[currentPlayIndex]"
			v-bind="$attrs"
			@ended="onEnded"
			@timeupdate="onTimeUpdate"
			@loadedmetadata="onLoadedmetadata"
		>
			Your browser does not support the <code>audio</code> element.
		</audio>
	</div>
</template>

<script>
export default {
	name: "AudioPlayer",
	inheritAttrs: false,
	props: {
		audioList: {
			default: null,
			type: Array
		},
		isLoop: {
			type: Boolean,
			default: true
		},
		progressInterval: {
			default: 1000,
			type: Number
		},
		playbackRates: {
			type: Array,
			default: () => [0.5, 1, 1.5, 2]
		}
	},
	emits: [
		"pause",
		"play-prev",
		"play-next",
		"timeupdate",
		"loadedmetadata",
		"ended",
		"progress-start",
		"progress-end",
		"progress-move",
		"progress-click",
		"playing",
		"play",
		"play-error"
	],
	data() {
		return {
			isPlaying: false,
			isDragging: false,
			isShowErrorMessage: false,
			isShowVolume: false,
			timer: null,
			noticeMessage: "",
			duration: "",
			currentPlayIndex: 0,
			currentTime: "",
			currentVolume: 1,
			playbackRate: 1
		};
	},
	computed: {
		currentTimeFormatted() {
			return this.currentTime ? this.formatTime(this.currentTime) : "00:00";
		},
		durationFormatted() {
			return this.duration ? this.formatTime(this.duration) : "00:00";
		}
	},
	mounted() {},
	beforeDestroy() {
		this.pause();
	},
	methods: {
		handleVolumeIconTouchstart() {
			this.isShowVolume = !this.isShowVolume;
		},
		handleVolumeClick(event) {
			let playVolumeWrapRect = this.$refs.playVolumeWrap.getBoundingClientRect();
			let pageY = event.y;
			let offsetTop;
			let volume;

			offsetTop = Math.round(playVolumeWrapRect.bottom - pageY);
			volume = offsetTop / this.$refs.playVolumeWrap.offsetHeight;
			volume = Math.min(volume, 1);
			volume = Math.max(volume, 0);

			this.$refs.audio.volume = volume;
			this.currentVolume = volume;
		},
		// 播放速度
		handleSetPlaybackRate(event) {
			const rate = event.target.value;
			this.playbackRate = +rate;
			this.$refs.audio.playbackRate = +rate;
		},
		handleShowErrorMessage(opts = {}) {
			this.noticeMessage = opts.message;
			this.isShowErrorMessage = true;

			window.setTimeout(() => {
				this.isShowErrorMessage = false;
			}, opts.duration || 3000);
		},
		onLoadedmetadata(event) {
			this.duration = this.$refs.audio.duration;
			this.$emit("loadedmetadata", event);
		},
		onTimeUpdate(event) {
			this.$emit("timeupdate", event);
		},
		formatTime(second) {
			let hour;
			hour = Math.floor(second / 60);
			second = Math.ceil(second % 60);
			hour += "";
			second += "";
			hour = hour.length === 1 ? "0" + hour : hour;
			second = second.length === 1 ? "0" + second : second;
			return hour + ":" + second;
		},
		// 播放完畢
		onEnded(event) {
			window.setTimeout(() => {
				this.pause();
				this.$emit("ended", event);
			}, 1000);
		},
		handleProgressPanstart(event) {
			this.isDragging = true;
			this.$emit("progress-start", event);
		},

		handleProgressPanend(event) {
			this.$refs.audio.currentTime = this.currentTime;
			this.play();
			this.isDragging = false;
			this.$emit("progress-end", event);
		},

		handleProgressPanmove(event) {
			if (!this.isDragging) {
				return;
			}

			let pageX = event.x;
			let bcr = event.target.getBoundingClientRect();
			let targetLeft = parseInt(getComputedStyle(event.target).left);
			let offsetLeft = targetLeft + (pageX - bcr.left);

			offsetLeft = Math.min(
				offsetLeft,
				this.$refs.audioProgressWrap.offsetWidth
			);
			offsetLeft = Math.max(offsetLeft, 0);
			this.setPointPosition(offsetLeft);
			this.$refs.audioProgress.style.width = offsetLeft + "px";
			this.currentTime =
				(offsetLeft / this.$refs.audioProgressWrap.offsetWidth) * this.duration;
			this.$emit("progress-move", event);
		},
		handleClickProgressWrap(event) {
			let target = event.target;
			let offsetX = event.offsetX;

			if (target === this.$refs.audioProgressPoint) {
				return;
			}

			// 當前播放時間
			this.currentTime =
				(offsetX / this.$refs.audioProgressWrap.offsetWidth) * this.duration;
			this.$refs.audio.currentTime = this.currentTime;
			this.setPointPosition(offsetX);
			this.$refs.audioProgress.style.width = offsetX + "px";
			this.play();
			this.$emit("progress-click", event);
		},

		// 進度點位置
		setPointPosition(offsetLeft) {
			this.$refs.audioProgressPoint.style.left =
				offsetLeft - this.$refs.audioProgressPoint.offsetWidth / 2 + "px";
		},

		// 播放中
		playing() {
			if (this.isDragging) {
				return;
			}

			let offsetLeft =
				(this.$refs.audio.currentTime / this.$refs.audio.duration) *
				this.$refs.audioProgressWrap.offsetWidth;

			this.currentTime = this.$refs.audio.currentTime;
			this.$refs.audioProgress.style.width = offsetLeft + "px";
			this.setPointPosition(offsetLeft);
			this.$emit("playing");
		},
		play() {
			let handlePlay = () => {
				this.$refs.audio
					.play()
					.then(() => {
						this.$nextTick(() => {
							this.clearTimer();
							this.timer = window.setInterval(
								this.playing,
								this.progressInterval
							);
							this.isPlaying = true;
						});
						this.$emit("play");
					})
					.catch(data => {
						this.handleShowErrorMessage({
							message: data.message
						});

						this.$emit("play-error", data);
					});
			};
			handlePlay();
		},
		pause() {
			this.$refs.audio.pause();
			this.$nextTick(() => {
				this.clearTimer();
				this.isPlaying = false;
				this.$emit("pause");
			});
		},
		clearTimer() {
			window.clearInterval(this.timer);
			this.timer = null;
		}
	}
};
</script>

<style lang="scss" scoped>
$functional-button-gap: 4px;

@keyframes fadeVolume {
	from {
		height: 0;
	}
	to {
		height: 50px;
	}
}
@keyframes fadeRate {
	from {
		max-height: 0;
	}
	to {
		max-height: 120px;
	}
}
.fade-volume-enter-active {
	animation: fadeVolume 0.3s;
}
.fade-volume-leave-active {
	animation: fadeVolume 0.3s reverse;
}
.fade-rate-enter-active {
	animation: fadeRate 0.3s;
}
.fade-rate-leave-active {
	animation: fadeRate 0.3s reverse;
}
.audio-player {
	margin: 0 2px;

	.audio__btn-wrap {
		position: relative;
		display: flex;
		align-items: center;
	}

	.audio__play-volume-icon-wrap {
		position: relative;
		cursor: pointer;
		touch-action: none;
		user-select: none;
		-webkit-user-drag: none;
		margin-left: $functional-button-gap;

		.audio__play-volume-wrap {
			$button-width: 20px;

			position: absolute;
			width: $button-width;
			height: 50px;
			bottom: 36px;
			left: calc(50% - #{$button-width} / 2);
			background-color: #ddd;
			border-radius: 10px;
		}

		.audio__play-volume-wrap .audio__play-volume {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 10px;
			background-color: #2d76b5;
		}
	}

	.audio__play-rate {
		position: relative;
		line-height: 21px;
		cursor: pointer;
		touch-action: none;
		user-select: none;
		-webkit-user-drag: none;
		font-size: 16px;
		margin-right: $functional-button-gap;
	}

	.audio__play-start,
	.audio__play-pause {
		margin: 0 $functional-button-gap;
		cursor: pointer;
		touch-action: none;
		user-select: none;
		-webkit-user-drag: none;
	}

	.audio__progress-wrap {
		position: relative;
		background: #ddd;
		height: 4px;
		border-radius: 3px;
		margin: 20px 4px 0 4px;
		cursor: pointer;
		touch-action: none;
		user-select: none;
		-webkit-user-drag: none;
	}

	.audio__progress {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 0;
		border-radius: 3px;
	}

	.audio__progress-point {
		position: absolute;
		left: -8px;
		top: 50%;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		margin-top: -8px;

		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			width: 8px;
			height: 8px;
			margin: -4px 0 0 -4px;
			background: #2d76b5;
			border-radius: 50%;
		}
	}

	.audio__time-wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 7px;
	}

	.audio__current-time {
		font-size: 10px;
		color: #888;
	}

	.audio-player__audio {
		display: block;
		margin: 0 auto;
	}
}
.audio__notice {
	position: absolute;
	bottom: -15px;
	color: rgb(189, 178, 178);
	border-radius: 4px;
	font-size: 12px;
}
</style>
