<!-- Todo (2020/02/05): This tab.vue version is newer than loveAndHappiness (only CSS changes), so update it (LAH) in the future. -->
<template>
	<div class="tab">
		<nav class="tab-header">
			<a
				v-for="(item, index) in data"
				:key="index"
				:class="['tab-item', { active: tab_index === index }]"
				@click="
					tab_index = index;
					$emit('tab-click', index);
				"
				tabindex="0"
				:aria-expanded="String(index === tab_index)"
				role="link"
				>{{ item.display_name }}</a
			>

			<div class="visual-purpose-long-border-bottom"></div>
		</nav>

		<div
			class="tab-content"
			v-for="(item, index) in data"
			:style="{ display: display(index) }"
			:key="index"
		>
			<h2 v-if="headinglevel === 2" class="sr-only">{{ item.display_name }}</h2>
			<h3 v-if="headinglevel === 3" class="sr-only">{{ item.display_name }}</h3>
			<h4 v-if="headinglevel === 4" class="sr-only">{{ item.display_name }}</h4>
			<h5 v-if="headinglevel === 5" class="sr-only">{{ item.display_name }}</h5>
			<h6 v-if="headinglevel === 6" class="sr-only">{{ item.display_name }}</h6>

			<div class="tab-item" v-if="$scopedSlots[item.type]">
				<slot :name="item.type" :item="item.data"></slot>
			</div>
			<div class="tab-item" v-else>{{ item.data }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: Array, // define type
		headinglevel: Number,
		defaultTabIdx: Number
		// For screen reader only.
	},
	data() {
		return {
			tab_index: 0
		};
	},
	methods: {
		display(index) {
			return this.tab_index == index ? "" : "none";
		},
		next() {
			this.tab_index = this.tab_index + 1;
		},
		previous() {}
	},
	mounted() {
		if (this.defaultTabIdx) {
			this.tab_index = this.defaultTabIdx;
		}
	}
};
</script>

<style scoped lang="scss">
.tab {
	--line-color: #{scale-color($color-grey, $lightness: 75%)};
	--border-radius-degree: 8;
	--hover-transition: 0.2s;

	margin-top: 2em;

	.tab-header {
		display: flex;
		justify-content: flex-start;
		// align-content: stretch;

		// Shared :before style.
		.tab-item,
		.visual-purpose-long-border-bottom {
			&:before {
				position: absolute;
				content: "";
				box-sizing: border-box;
				top: -0px;
				bottom: -1px;
				left: -0px;
				right: -0px;
				border-width: 1px;
				border-style: solid;
				border-color: transparent;
				border-radius: calc(var(--border-radius-degree) * 0.0625em)
					calc(var(--border-radius-degree) * 0.0625em) 0 0;
			}
		}

		.tab-item {
			position: relative;
			box-sizing: border-box;
			padding: 0.7em 1.2em;
			color: $color-water-blue;
			transition: all var(--hover-transition);
			cursor: pointer;

			&:before {
				z-index: -1;
				border-bottom-color: var(--line-color);

				// For background-clip:
				// =======================
				background-clip: content-box;
				padding: calc(3 * 0.0625em);
				// =======================
			}
			&:hover {
				color: scale-color($color-water-blue, $lightness: -24%);
				&:before {
					background-color: #eee;
					transition: all var(--hover-transition);
				}
			}
			&.active {
				color: #555;
				// Don't use bold font-style, because it increase the spacing, maving everyting nearby,
				// causing visually tiny "glitchy".
				// font-weight: bold;

				&:before {
					border-top-color: var(--line-color);
					border-bottom-color: transparent;
					border-left-color: var(--line-color);
					border-right-color: var(--line-color);
				}
			}

			// Purpose: Overwritting some anchor defualt style.
			&:hover,
			&:focus,
			&:link,
			&:visited {
				outline: none;
			}
		}
		.visual-purpose-long-border-bottom {
			position: relative;
			flex-grow: 1;

			&:before {
				border-bottom-color: var(--line-color);
			}
		}
	}
	.tab-content {
		margin-top: 2.5em;
	}
}
</style>
