<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">校園管理</h2>
		<ul>
			<template v-for="(item, index) in items">
				<template v-if="item.type == 'folder'">
					<li :key="index">
						<a href="#">{{ index + 1 }}. {{ item.display_name }}</a>
						<ul>
							<template v-for="(item, index_folder) in item.items">
								<template v-if="item.type == 'item'">
									<li class="nav-item" :key="index_folder">
										<a
											tabindex="0"
											class="nav-link"
											@click.prevent="linkToUrlOrRoute(item)"
											>{{ index + 1 }}-{{ index_folder + 1 }}.
											{{ item.display_name }}</a
										>
									</li>
								</template>
								<template v-if="item.type == 'action'">
									<li class="nav-item" :key="index_folder">
										<a class="nav-link" href="#" @click="item.action(that)"
											>{{ index + 1 }}-{{ index_folder + 1 }}.
											{{ item.display_name }}</a
										>
									</li>
								</template>
							</template>
						</ul>
					</li>
				</template>
				<template v-if="item.type == 'item'">
					<li class="nav-item" :key="item">
						<a
							tabindex="0"
							class="nav-link"
							@click.prevent="linkToUrlOrRoute(item)"
							>{{ index + 1 }}. {{ item.display_name }}</a
						>
					</li>
				</template>
				<template v-if="item.type == 'action'">
					<li class="nav-item" :key="item">
						<a class="nav-link" href="#" @click="item.action(that)"
							>{{ index + 1 }}. {{ item.display_name }}</a
						>
					</li>
				</template>
			</template>
		</ul>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { nav_item_manage_school } from "../header/navItemData.js";
import { item_show } from "../header/navItemMethod.js";

export default {
	components: {},
	data() {
		return {};
	},
	computed: {
		items() {
			return item_show(nav_item_manage_school, this.user);
		},
		...mapState("user", ["user"])
	},
	metaInfo: {
		title: "校園管理"
	},
	methods: {
		linkToUrlOrRoute(item) {
			function collapseBootstrapNavbarManually() {
				let navbarToggleBtn = document.getElementsByClassName(
					"navbar-toggler"
				)[0];
				navbarToggleBtn.click();
			}
			if (typeof item.url !== "undefined") {
				window.location.replace(item.url);
			} else if (typeof item.route !== "undefined") {
				this.$router.push(item.route);
				collapseBootstrapNavbarManually();
			} else if (typeof item.routePath !== "undefined") {
				this.$router.push({ path: item.routePath });
				collapseBootstrapNavbarManually();
			} else {
				this.$router.push({ name: item.routeName });
				collapseBootstrapNavbarManually();
			}
		}
	}
};
</script>

<style>
ul.manager_school {
	line-height: 1.7em;
}
</style>
