<template>
	<div>
		<h2>分段審核</h2>
		<div>
			<h3>
				<span
					class="glyphicon glyphicon-check"
					aria-hidden="true"
					style="margin-top:20px;"
				></span>
				Step1: 基本資料
			</h3>
			<hr style="margin-top:5px;" />
			<ul class="list-content">
				<li v-for="(value, key) in info" :key="key">{{ key }}:{{ value }}</li>
			</ul>
			<!-- step 2 -->
			<h3>
				<span
					class="glyphicon glyphicon-check"
					aria-hidden="true"
					style="margin-top:20px;"
				></span>
				Step2: 校對資訊
			</h3>
			<hr style="margin-top:5px;" />
			<tab
				:data="[
					{ display_name: '差異分析', type: 'analysis' },
					{ display_name: '校對文字', type: 'text' },
					{ display_name: '掃描影像', type: 'image' }
				]"
			>
				<template v-slot:analysis>
					<table-div :datas="[analysis]" :header="analysisHeader" />
				</template>
				<template v-slot:text>
					<p class="y-scroll-box" v-html="finish_content"></p>
				</template>
				<template v-slot:image>
					<eBookViewer :pk="pk" :height="400" :images="image" />
				</template>
			</tab>
			<!--step 3-->
			<h3>
				<span
					class="glyphicon glyphicon-check"
					aria-hidden="true"
					style="margin-top:20px;"
				></span>
				Step3: 審核結果
			</h3>
			<hr style="margin-top:5px;" />
			<template v-if="ebook.status == 3">
				<div class="form-group">
					<input type="radio" id="success" value="success" v-model="result" />
					<label for="success">成功</label>
					<br />
					<input type="radio" id="error" value="error" v-model="result" />
					<label for="error">退回</label>
					<br />
					<template v-if="result == 'error'">
						<label for="reason">原因</label>
						<input type="text" id="reason" v-model="reason" />
					</template>
				</div>
				<button class="btn btn-primary" @click="$router.go(-1)">上一頁</button>
				<button class="btn btn-primary" @click="review()">送出</button>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import eBookViewer from "@/components/book/viewer";
import tab from "@/components/tab.vue";
import tableDiv from "@/components/tableDiv.vue";
import { ebookRest, ebookAction, editRecordAction } from "@/api/ebookSystem.js";
import { userAction } from "@/api/genericUser";

export default {
	components: {
		eBookViewer,
		tab,
		tableDiv
	},
	data() {
		return {
			pk: this.$route.params.id,
			ebook: {},
			userExtra: {},
			current_editrecord: {},
			image: {},
			analysis: {},
			analysisHeader: {
				edit_distance: "編輯距離",
				len_block: "相同區數",
				same_character: "相同字數",
				delete_count: "刪除字數",
				insert_count: "新增字數",
				src_count: "原始字數",
				dst_count: "校後字數",
				diff_count: "增長字數"
			},
			finish_content: "",
			reason: "",
			result: "success" //success or error
		};
	},
	computed: {
		info() {
			return {
				服務時間: this.ebook.get_date,
				線上時數: this.ebook.service_hours,
				校對者帳號: this.userExtra.username,
				校對者姓名: this.userExtra.first_name + this.userExtra.last_name,
				已校段數: this.userExtra.editrecord_count
			};
		},
		...mapState(["mode"])
	},
	metaInfo: {
		title: "分段審核"
	},
	mounted() {
		this.get_ebook_data();
	},
	methods: {
		get_ebook_data() {
			ebookRest
				.read(this.pk)
				.then(res => {
					this.image = res.data.scan_image;
					this.ebook = res.data;
					this.current_editrecord = res.data.current_editrecord;
					return userAction.editorExtraInfo(this.ebook.editor);
				})
				.then(res => {
					this.userExtra = res.data;
					return editRecordAction.analysis(this.current_editrecord.id);
				})
				.then(res => {
					this.analysis = res.data;
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
			ebookAction
				.readEdit({ pk: this.pk })
				.then(res => {
					this.finish_content = res.data.finish;
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		review() {
			let transferData = {
				pk: this.pk,
				number_of_times: this.ebook.number_of_times,
				result: this.result,
				reason: this.reason
			};
			ebookAction
				.review(transferData)
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message: "審核已完成"
					});
				})
				.then(() => {
					this.$router.go(-1);
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
<style lang="scss" scoped>
.y-scroll-box {
	height: 230px;
	overflow: auto;
}
.btn {
	&.btn-primary {
		margin-right: 1rem;
	}
}
</style>
