<!--
*About: General modal to display contents given though slot from parent.
*Usage example: See comment id 200219m210115 (bottom of this page).
-->
<template>
	<Trap>
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div
				:class="['popup', size ? `popup--${size}` : '']"
				:style="{ width: width }"
			>
				<div class="title-area">
					<slot name="header"><h3 class="title">header</h3></slot>
					<button class="close" @click="close">&times;</button>
				</div>
				<hr />
				<div class="content">
					<!-- Purpose of this container: I just don't want to put things directely under .content,
					, because in the future more things might add into here and I want to keep structure more consistent. -->
					<div class="msgCtnr">
						<slot name="body"></slot>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<slot name="footer"
							><button class="btn btn-danger" @click="close">關閉</button></slot
						>
					</div>
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import Trap from "vue-focus-lock";

export default {
	name: "modal",
	components: {
		Trap
	},
	props: {
		// Useless, since we store isShow state at parent component, not here.
		// ------------------------------------------------------------------------
		// isShow: {
		// 	type: Boolean,
		// 	default: false,
		// },
		size: {
			default: "",
			type: String
		},
		width: {
			default: "auto",
			type: String
		}
	},
	methods: {
		close() {
			this.$emit("closeModal");
		}
	}
};
</script>

<style scoped lang="scss">
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 1030;
}

.popup {
	--popup-background: rgb(245, 245, 245);

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	// Purpose of using grid layout: The size of .popup controls by 2 keys:
	// 1. The size of .popup .content.
	// 2. If The size of .popup exceed max-height (.popup .content have too many content),
	// then makes .popup .content overflow (scroll bar).
	// Notice the "minmax(0, 1fr)" tricks plays the important role here.
	display: grid;
	grid-template-columns: auto;
	// Rows currently contains these 3 things: 1. button.close 2. .content 3. "確定" button.
	grid-template-rows: auto minmax(0, 1fr) auto;
	grid-row-gap: 1em;
	padding: 16px 20px;
	max-width: 80%;
	min-width: calc(0.0625em * 300);
	max-height: 75%;
	background-color: var(--popup-background);
	// color: $color-orange-light;
	color: scale-color($color-grey-dark, $lightness: 30%);
	border-radius: 5px;
	transition: all 1s ease-in-out;
	box-shadow: 0 0 8px scale-color($color-sky-blue, $alpha: -60%);
	// border: 1px solid hsl(0deg, 0%, 30%);
	@media screen and (max-width: 767px) {
		max-width: 100vw;
		width: 95vw;
	}
	hr {
		width: 100%;
	}
	::v-deep .title-area {
		display: grid;
		grid-template-columns: 1fr auto;
		align-content: start;

		.title {
			font-size: 1.23rem;
			justify-self: center;
			font-weight: bold;
		}
		button.close {
			font-size: 30px;
			justify-self: end;
			transition: all 200ms;
			font-weight: bold;
			text-decoration: none;
			color: $color-grey;

			&:focus {
				outline: none;
			}
		}
	}
	::v-deep .content {
		// Globally (downward) changing font-size.
		font-size: 1em;
		// Purpose: When overflow, I don't want the edge of text stick with the scrollbar
		// (browser native) too close.
		padding-right: 0.5em;
		overflow: auto;
		height: calc(75vh - 144px);
		.msgCtnr {
		}
	}

	&--large {
		width: 95%;
		max-height: 90%;
	}
}
</style>

<!--
	*Comment id 200219m210115:
	----------------------------
	<modal v-if="showMyModal" @closeModal="showMyModal = false">
		<template slot="header">
			<h3 class="title">Title of my modal</h3>
		</template>
		<template slot="body">
			<p>1. Content of my modal</p>
			<p>2. Content of my modal</p>
		</template>
		<template slot="footer">
			<button 
				class="btn btn-primary"
				onclick="closeDialog(this)" 
				@click="resetOTP"
			>custom close button</button>
		</template>
	</modal>
-->
