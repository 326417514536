<template>
	<div>
		<h2 class="big-title">管理索引分類</h2>
		<div>
			<div>
				<nav aria-label="路徑列導航">
					<ol class="breadcrumb">
						<li
							class="breadcrumb-item"
							style="cursor:pointer;"
							tabindex="0"
							@click="changePointer(-1)"
						>
							所有分類
						</li>
						<li
							tabindex="0"
							v-for="(item, key) in pointer.path"
							:key="key"
							class="breadcrumb-item active"
							style="cursor: pointer;"
							aria-current="page"
							@click="changePointer(item.id)"
						>
							{{ item.name }}
						</li>
					</ol>
				</nav>
			</div>
			<div>
				<h3 style="margin-left: 0;">{{ pointer.name }}</h3>
				<button-withIcon name="新增" iconName="plus" @click="addModal()" />
			</div>
			<div
				style="display:flex;margin:1rem;"
				v-for="(item, key) in pointer.childs"
				:key="key"
			>
				<a
					style="width: 20%;cursor:pointer;"
					tabindex="0"
					@click="changePointer(item.id)"
					><label :for="item.id">{{ item.name }}</label></a
				>
				<button-withIcon
					@click="updateModal(item)"
					name="更新"
					iconName="exchange-alt"
				/>
				<button-withIcon
					v-if="item.childs && item.childs.length === 0"
					@click="remove(item.id)"
					name="删除"
					iconName="trash-alt"
					btnColor="btn-danger"
				/>
			</div>
			<div v-if="pointer.childs.length === 0">無索引分類，請點選新增</div>
		</div>
		<modal v-if="modalShow" @closeModal="modalShow = false">
			<template #header>
				<h4 v-if="icPk == 0">索引分類新增</h4>
				<h4 v-else>索引分類更新</h4>
			</template>
			<template #body>
				<formdrf
					:model_info="icModel['name']"
					:field="'name'"
					v-model="icData['name']"
				></formdrf>
			</template>
			<template #footer>
				<button v-if="icPk == 0" class="btn btn-manage" @click="add()">
					新增
				</button>
				<button v-else class="btn btn-manage" @click="update()">更新</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { treeSelectItem } from "@/utils/dataProcess.js";
import { indexCategoryRest, indexCategoryAction } from "@/api/ebookSystem.js";
import formdrf from "@/components/form2.vue";
import modal from "@/components/popup/modal.vue";
import buttonWithIcon from "@/components/buttonWithIcon.vue";

export default {
	components: {
		formdrf,
		modal,
		buttonWithIcon
	},
	metaInfo: {
		title: "索引分類管理"
	},
	data() {
		return {
			icPk: 0,
			icData: {
				name: ""
			},
			icModel: {
				name: {
					label: "名稱",
					type: "string",
					required: true
				}
			},
			modalShow: false,
			pointer: { childs: [] },
			indexcategory: {},
			treeSelectItem
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			indexCategoryAction.structure().then(res => {
				this.indexcategory = {
					id: -1,
					name: "所有分類",
					childs: res.data
				};
				this.changePointer(-1);
			});
		},
		changePointer(id) {
			this.pointer = treeSelectItem(this.indexcategory, "childs", "id", id);
		},
		addModal() {
			this.modalShow = true;
			this.icPk = 0;
		},
		updateModal(data) {
			this.modalShow = true;
			this.icPk = data.id;
			this.icData.name = data.name;
		},
		add() {
			indexCategoryRest
				.create({
					id: this.icPk,
					name: this.icData.name,
					parent: this.pointer.id === -1 ? null : this.pointer.id
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "成功新增索引分類"
					});
					this.modalShow = false;
					this.getData();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		update() {
			indexCategoryRest
				.partialupdate(this.icPk, this.icData)
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "成功更新索引分類"
					});
					this.modalShow = false;
					this.getData();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		remove(id) {
			this.$root.$confirm
				.open({
					status: 200,
					message: "是否確認刪除該索引分類(id:" + id + ")？"
				})
				.then(() => {
					return indexCategoryRest.delete(id);
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "成功刪除索引分類"
					});
					this.modalShow = false;
					this.getData();
				});
		}
	}
};
</script>
