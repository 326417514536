<template>
	<div>
		<nav class="viewer-btns">
			<ul class="pager">
				<li>
					<a tabindex="0" aria-label="上一頁" @click="changePage(-1)">上一頁</a>
				</li>

				<li>
					<a tabindex="0" aria-label="至最初頁" @click="changePage('first')"
						>最初頁</a
					>
				</li>

				<li>
					<div style="display:inline-block; float:none; padding: 0 2px;">
						<select
							class="form-control"
							v-model="nowPage"
							@change="$emit('changed', $event.target.value)"
						>
							<template v-for="(value, key) in images">
								<option v-if="edited_page == key" :value="key" :key="value"
									>{{ value }}-上次校對頁數</option
								>
								<option v-else :value="key" :key="value">{{ value }}</option>
							</template>
						</select>
					</div>
				</li>

				<li>
					<a tabindex="0" aria-label="至最後頁" @click="changePage('end')"
						>最後頁</a
					>
				</li>

				<li>
					<a tabindex="0" aria-label="下一頁" @click="changePage(1)">下一頁</a>
				</li>

				<slot></slot>
			</ul>
		</nav>

		<div :style="{ height: height + 'px' }">
			<div id="scanPage">
				<img
					:src="displayImage"
					alt="文件掃描原檔"
					name="scanPage"
					style="opacity:0; height: 0px;"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import { ebookAction } from "@/api/ebookSystem.js";

export default {
	props: {
		pk: {
			type: String,
			default: ""
		},
		images: Object,
		edited_page: {
			default: null,
			type: Number
		},
		height: Number
	},
	data() {
		return {
			options: {},
			viewerId: null,
			nowPage: 0,
			displayImage: "#"
		};
	},
	created() {
		this.$parent.$on("updateViewerHeight", this.refreshViewer);
	},
	mounted() {
		if (this.edited_page) {
			this.nowPage = this.edited_page;
		}
		if (!isNaN(this.nowPage)) {
			ebookAction
				.getImageData(this.pk, this.nowPage)
				.then(res => {
					this.displayImage = URL.createObjectURL(res.data);
					this.$nextTick(() => {
						this.refreshViewer();
					});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
					console.log(JSON.stringify(res.response));
				});
		}
	},
	methods: {
		refreshViewer: function() {
			// Destroy the viewer and remove the instance.
			this.viewerId && this.viewerId.destroy();
			this.viewerId = new Viewer(document.getElementById("scanPage"), {
				inline: true,
				button: false,
				navbar: false,
				title: false,
				toolbar: true,
				tooltip: true,
				movable: true,
				zoomable: true,
				rotatable: true,
				scalable: false,
				transition: true,
				fullscreen: false,
				keyboard: false,
				viewed() {
					let imgview_ratio = localStorage.getItem("imgview_ratio") || 0.3;
					this.viewer.zoomTo(imgview_ratio - 0);
				},
				zoomed(event) {
					const ratio = event.detail.ratio;
					localStorage.setItem("imgview_ratio", ratio);
				}
			});
		},
		changePage: function(value) {
			if (value === "first") this.nowPage = 0;
			else if (value === "end") this.nowPage = 49;
			else {
				const page = parseInt(this.nowPage) + value;
				if (page >= 0 && page < 50) {
					this.nowPage = page;
				} else {
					this.$root.$message.open({ status: 400, message: "超過頁數範圍惹~" });
				}
			}
			this.$emit("changed", this.nowPage);
		}
	},
	watch: {
		nowPage() {
			if (!isNaN(this.nowPage)) {
				ebookAction.getImageData(this.pk, this.nowPage).then(res => {
					this.displayImage = URL.createObjectURL(res.data);
					this.$nextTick(() => {
						this.refreshViewer();
					});
				});
			}
		},
		edited_page(val) {
			let page = val;
			if (Number.isNaN(page)) {
				page = 0;
			}
			console.log(page);
			this.nowPage = page;
		}
	}
};
</script>

<style lang="scss">
li.viewer-prev,
li.viewer-play,
li.viewer-next,
li.viewer-flip-horizontal,
li.viewer-flip-vertical {
	display: none;
}

.viewer-btns {
	display: block;
}

.pager {
	padding-left: 0;
	margin: 20px 0;
	text-align: center;
	list-style: none;
	margin: 0px 0px 10px 0px;

	li {
		display: inline;
		margin-right: 2px;
		font-size: 12px;
		a,
		span {
			display: inline-block;
			padding: 5px 10px;
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 15px;
		}
		a {
			color: #337ab7;
			text-decoration: none;
			&:hover,
			&:active {
				text-decoration: none;
				background-color: #eee;
			}
		}
		.viewer-select {
			padding: 0.3rem 0.4rem;
		}
	}
}
</style>
