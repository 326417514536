export function getBlobAsText(blob) {
	return new Promise(function(resolve, reject) {
		try {
			const reader = new FileReader();
			reader.readAsText(blob);
			reader.onload = e => {
				resolve(e.target.result);
			};
		} catch (error) {
			reject();
		}
	});
}
