<template>
	<div class="any-about-page container this-is-copyright">
		<h2>著作權法</h2>

		<div class="bs-callout bs-callout-danger">
			<h3>一、第 53 條</h3>
			<p>
				中央或地方政府機關、非營利機構或團體、依法立案之各級學校，為專供視覺障礙者、學習障礙者、聽覺障礙者或其他感知著作有困難之障礙者使用之目的，得以翻譯、點字、錄音、數位轉換、口述影像、附加手語或其他方式利用已公開發表之著作。前項所定障礙者或其代理人為供該障礙者個人非營利使用，準用前項規定。
			</p>
			<p>
				依前二項規定製作之著作重製物，得於前二項所定障礙者、中央或地方政府機關、非營利機構或團體、依法立案之各級學校間散布或公開傳輸。
			</p>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>二、補充說明</h3>
			<p>身心障礙族群適用著作權法合理使用情形之說明。</p>
			<p>
				詳見
				<a
					href="https://www.tipo.gov.tw/ct.asp?xItem=206749&ctNode=6983&mp=1"
					style="font-weight: bold"
					>全文</a
				>
				(資料來源：經濟部智慧財產局)。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.1 前言</h4>
			<p>
				著作權法除了保護著作人的權益外，另一項重要任務在於調和社會公益，並促進國家文化發展。如過度保護著作權人權益，反而會阻礙了學術、文化的交流發展與知識的傳遞，與著作權法之「促進國家文化發展」及「調和社會公益」的立法目的，互相牴觸，所以世界各國的著作權法，大都訂定所謂「合理使用」條款，作為一種平衡著作權人與利用著作人間權益的機制。
			</p>
			<p>
				「合理使用」是指基於教育、研究、評論、報導或個人非營利使用等目的，在法律所允許的條件下，得於適當範圍內逕行利用他人著作，不須經著作權人同意，不會構成侵害著作權。「合理使用」是著作權法對於著作權人權利所做的限制。此一限制規定在法律上都被審慎地規範，以避免與著作的正常利用相衝突，或不合理地損害著作人的法定權益。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.2 國際上對身心障礙族群的著作權合理使用規定情形及趨勢</h4>
			<p>
				身心障礙族群屬於社會之弱勢團體，其於資訊尋求過程中對於訊息的掌控能力較為薄弱，如能依法利用他人著作，除能促使身心障礙者之心智提昇，協助身心障礙者投入社會外，更能達成國家文化全面性的發展。況身心障礙族群屬社會之少數，於利用著作上，對於著作財產權人之影響亦屬輕微。因此，國際上部份國家，包括日本、西班牙及美國之著作權法允許為讓身心障礙盲人之福利，可就已公該發表的著作為盲人以點字而重製，也就是說在此種情形下，重製他人已公該發表著作的行為屬於著作權法的合理使用，並不會發生著作權的侵權問題。
			</p>
			<p>
				此外，世界智慧財產組織於2013年6月28日WIPO在摩洛哥馬拉喀什舉行外交會議，正式通過「促進盲人、視障者及其他印刷品閱讀障礙者接觸已發表著作之條約(下簡稱馬拉喀什條約)」，並於2016年9月30日生效，其目的在為前開障礙者之利益創設法定的著作權限制或例外，解決障礙人士接觸資訊較易不足之問題，以營造無歧視、機會均等之環境。我國雖然非聯合國會員國，對於身心障礙族群權益於國際趨勢發展十分關注，為符馬拉喀什條約之精神，業於2014年1月22日修正並經立法院三讀通過著作權法第53條、第80條之2及第87條之1等規定，已符合馬拉喀什的要求。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.3 我國著作權法規定身心障礙族群的合理使用範圍</h4>
			<p>
				我國於74年著作權法修正時考量各國之立法趨勢，並參考國內盲人團體之建議，增訂有關盲人利用著作之合理使用規定，並於81年為周全立法意旨進行文字修正，嗣於92年、99年修正著作權法時，擴大身心障礙者的適用範圍，將學習障礙者亦納入適用範圍。103年修正係為與「馬拉喀什條約」接軌，擴大包括中央或地方政府機關、非營利機構或團體、依法立案之各級學校，均得為視、聽覺障礙者製作無障礙格式版本。另新增視、聽覺障礙者本身或其代理人也可以製作無障礙格式版本供個人非營利使用，且為製作上述無障礙格式版本，可規避或破解著作權人所採取之科技保護措施。而為促進無障礙格式版本的資源共享，避免重複製作造成資源浪費，合法製作之無障礙格式版本除可以在上述機關、團體或學校及視聽覺障礙者間流通外，並可由上述機關、團體或學校自國外輸入專供視聽覺障礙者使用(現行著作權法第53條、第80條之2及第87條之1等規定)。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.4 結語</h4>
			<p>
				資訊社會科技於今日雖然發展蓬勃，然身心障礙族群發展空間仍面臨種種挑戰，身心障礙族群屬於社會弱勢團體，其於資訊尋求過程中對於訊息的掌控能力較一般人更為薄弱，限制了享有教育的權利及從事研究的機會，此問題為各國所重視。為維護著作權人之權益，並貫徹調和社會公共利益之目的，於避免破壞正常市場運作機制下，著作權法訂定合理使用或限制及例外的規定，為身心障礙族群排除利用不便，使其依法利用他人著作，除能為障礙族群創造更平等、友善的資訊環境、促使心智提昇，協助身心障礙族群投入社會外，更能達成國家文化全面性的發展。
			</p>
		</div>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "著作權法"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";
</style>
