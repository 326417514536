<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">校對順序</h2>
		<table-div :header="bookorder_header" :datas="bookorder_datas"> </table-div>
	</div>
</template>

<script>
import { bookOrderRest } from "@/api/ebookSystem.js";
import tableDiv from "@/components/tableDiv.vue";

export default {
	components: {
		tableDiv
	},
	data() {
		return {
			bookorder_header: {
				order: "順序",
				bookname: "書名",
				status: "狀態"
			},
			bookorder_datas: []
		};
	},
	metaInfo: {
		title: "校對順序"
	},
	mounted: function() {
		this.getData();
	},
	methods: {
		getData() {
			bookOrderRest.list().then(res => {
				let filter_data = [];
				_.each(res.data, v => {
					filter_data.push({
						order: v.order,
						bookname: v.bookname,
						status: v.status
					});
				});
				this.bookorder_datas = filter_data;
			});
		}
	}
};
</script>
