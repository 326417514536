<template>
	<div class="any-about-page container this-is-service-hours-description">
		<h2>服務計算說明</h2>
		<p>有關平台上服務時數的計算方式，請參照下列說明：</p>
		<ol class="often is-list-trad-chinese">
			<li>
				服務時數的計算方式是必須在五天內完成所領取的1份校對文件（50頁），才能夠將累積的服務時數歸入帳戶。
			</li>
			<li>
				超過五天未進行校對，則文件由系統自動收回，服務時數無法累積在帳戶中。
			</li>
			<li>
				停留在頁面的時數稱為線上時數，實際校對時數則稱為服務時數，兩者的時數並不相等，系統將會依照操作行為自行判斷是否在進行校對工作。
			</li>
			<li>
				服務時數採取《分鐘》計算，意即600（分鐘）等於10小時。（＊原則上是以無條件進位法計算，低於5分鐘內則不予採計）
			</li>
			<li>
				如欲查詢服務時數，可於校對完畢一份文件的1-2個工作天後，至平台網站右上角的
				帳號>>服務時數，在頁面中即可查詢。
			</li>
			<li>
				服務時數的查詢可隨時於 帳號 >> 服務時數
				的頁面查閱，如需申請服務時數證明，則兌換單位可選擇由台北市視障者家長協會發放，若是各校資源教室所聘雇之計時人員，則兌換單位則選擇該校資源教室進行申請，相關兌換方式將依各校資源教室規定辦理。
			</li>
			<li>
				線上服務志工如欲申請本會所開立之服務時數證明，將一律提供彩色版服務時數證明電子檔，上面已蓋有本會印章、服務月份、服務時數等資料。如有特殊格式需求，請來信或來電洽詢。（已申請工讀費者，恕不開立服務時數證明）
			</li>
			<li>
				若時數累計有疑義，或是有其他系統方面的操作問題，請與系統維護單位，將會盡可能協助您排除問題。聯絡方式如下：<br />
				信箱 a9203045@forblind.org.tw；電話 02-27177722#20 周先生。
			</li>
		</ol>

		<p>
			感謝每一位志工的付出，您的「一臂之力」幫助視障朋友在學習上也能「不落人後」，歡迎你們向親朋好友、學弟學妹、同事夥伴，廣為宣傳雲端千眼-校園版，讓這一份善心持續流傳下去。
		</p>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "服務計算說明"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
// @import '~@/style/general_optional/callout.scss';
</style>
