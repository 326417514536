<template>
	<div class="this-is-platform">
		<router-view></router-view>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss"></style>
