import axios from "axios";
import { restFactory, host } from "./base.js";

const log = axios.create({
	baseURL: host + "logs"
});

export const logRest = restFactory(log);
export const logAction = {
	filter: ({ category, action, object }) => {
		let params = {};
		if (category) {
			params = { ...params, ...{ category } };
		}
		if (action) {
			params = { ...params, ...{ action } };
		}
		if (object) {
			params = { ...params, ...{ object } };
		}
		return log.get(`action/filter/`, { params });
	}
};
