<template>
	<div>
		<template>
			<table-div
				:header="file_manager_header"
				:datas="resource_file_datas"
				:tdwidths="tdwidths"
			>
				<template #action="props">
					<button
						v-if="button_permission(user, permission, 'read')"
						class="btn btn-outline-secondary"
						@click="readResource(props.item.name)"
					>
						下載
					</button>
					<button
						v-if="button_permission(user, permission, 'update')"
						class="btn btn-outline-secondary"
						@click="
							uploadData.resourceName = props.item.name;
							uploadModalMode = 'update';
							uploadModalShow = true;
						"
					>
						更新
					</button>
					<button
						v-if="button_permission(user, permission, 'delete')"
						class="btn btn-outline-secondary"
						@click="
							uploadData.resourceName = props.item.name;
							uploadModalMode = 'delete';
							uploadModalShow = true;
						"
					>
						刪除
					</button>
				</template>
			</table-div>
			<button
				v-if="button_permission(user, permission, 'create')"
				class="btn btn-outline-secondaryl"
				@click="
					uploadData.resourceName = '';
					uploadModalMode = 'create';
					uploadModalShow = true;
				"
			>
				新建
			</button>
		</template>
		<modal v-if="uploadModalShow" @closeModal="uploadModalShow = false">
			<template #header>
				<h4 class="modal-title">檔案上傳</h4>
			</template>
			<template #body>
				<div class="form-horizontal">
					<div v-if="!(uploadModalMode === 'delete')">
						<div class="form-group">
							<label for="id_resource_name" class="control-label col-sm-3"
								>檔案名稱：</label
							>
							<div v-if="uploadModalMode === 'create'" class="col-sm-3">
								<input
									id="id_resource_name"
									class="form-control"
									v-model="uploadData.resourceName"
									type="text"
								/>
							</div>
							<div v-if="uploadModalMode === 'update'" class="col-sm-3 likebtn">
								{{ uploadData.resourceName }}
							</div>
						</div>
						<div class="form-group">
							<label class="control-label col-sm-3">檔案選擇：</label>
							<div class="col-sm-6">
								<input id="id_resource" type="file" @change="changeFile" />
							</div>
						</div>
					</div>
					<div v-else>
						<h3>確定要刪除文件？</h3>
					</div>
				</div>
			</template>
			<template #footer>
				<button
					v-if="!(uploadModalMode === 'delete')"
					@click="uploadResource()"
					class="btn btn-outline-secondary"
				>
					上傳
				</button>
				<button
					v-else
					class="btn btn-outline-secondary"
					@click="deleteResource()"
				>
					刪除
				</button>
				<button
					@click="uploadModalShow = false"
					class="btn btn-outline-secondary"
				>
					關閉
				</button>
			</template>
		</modal>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { file_resolve } from "@/api/base.js";
import modal from "@/components/popup/modal.vue";
import tableDiv from "@/components/tableDiv.vue";

const getExt = filename => {
	// 依照名稱找副檔名
	let ext = filename.split(".").pop();
	if (ext == filename) return "";
	return ext;
};

export default {
	props: {
		url: {
			type: String,
			default: ""
		},
		permission: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		modal,
		tableDiv
		//'file_upload': components['file_upload'],
	},
	data() {
		return {
			tdwidths: [8, 42, 10, 15, 25],
			uploadModalMode: "",
			uploadModalShow: false,
			uploadData: {
				resourceName: "",
				resourceFile: null
			},
			file_manager_header: {
				order: "序號",
				name: "檔案名稱",
				size: "檔案大小",
				mtime: "修改日期",
				action: "動作"
			},
			resource_file_datas: []
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	mounted() {
		this.get_resource_file_datas();
	},
	methods: {
		button_permission(u, p, crud) {
			if (u.is_editor && p[crud].is_editor) {
				return true;
			} else if (u.is_guest && p[crud].is_guest) {
				return true;
			} else if (u.is_manager && p[crud].is_manager) {
				return true;
			} else if (u.is_supermanager && p[crud].is_supermanager) {
				return true;
			} else if (u.is_manager && p[crud]["org" + u.org]) {
				return true;
			} else if (p[crud].is_all) {
				return true;
			} else {
				return false;
			}
		},
		get_resource_file_datas() {
			this.resource_file_datas = [];
			axios
				.get(this.url, {})
				.then(res => {
					let index = 0;
					_.each(res.data, v => {
						this.resource_file_datas.push({
							order: index,
							name: v.name,
							size: v.size,
							mtime: v.mtime.slice(0, 10),
							action: v
						});
						index = index + 1;
					});
				})
				.catch(() => {
					// this.$errorHandler({ error: res, show: false });
				});
		},
		changeFile(e) {
			const file = e.target.files[0];
			this.uploadData.resourceFile = file;
		},
		readResource(name) {
			const url = `${this.url}${name}/`;
			axios.get(url, { responseType: "blob" }).then(file_resolve(name));
		},
		uploadResource() {
			let url = `${this.url}${this.uploadData.resourceName}/`;
			let resourceName = this.uploadData.resourceName;
			let resourceFile = this.uploadData.resourceFile;
			let nameExtension = "";
			let fileExtension = "";
			if (resourceFile) {
				fileExtension = getExt(resourceFile.name);
			} else {
				this.$root.$message.open({ status: 400, message: "尚未選擇上傳附件" });
				return false;
			}
			if (resourceName && !(resourceName == "")) {
				nameExtension = getExt(resourceName);
			} else {
				this.$root.$message.open({ status: 400, message: "尚未輸入檔案名稱" });
				return false;
			}
			if (fileExtension && nameExtension) {
				if (fileExtension !== nameExtension) {
					this.$root.$confirm
						.open({
							status: 200,
							message: "檔案附檔名不一致，是否自動加上副檔名?"
						})
						.then(() => {
							this.uploadData.resourceName = `${this.uploadData.resourceName}.${fileExtension}`;
							this.uploadFile(url, resourceFile);
						});
				} else {
					this.uploadFile(url, resourceFile);
				}
			} else if (fileExtension && !nameExtension) {
				this.$root.$confirm
					.open({
						status: 200,
						message: "檔案名稱未包含附檔名，確定後系統將自動加入"
					})
					.then(() => {
						this.uploadData.resourceName = `${this.uploadData.resourceName}.${fileExtension}`;
						this.uploadFile(
							`${this.url}${this.uploadData.resourceName}/`,
							resourceFile
						);
					});
			} else {
				this.uploadFile(url, resourceFile);
			}
		},
		uploadFile(url, file) {
			console.log(url);
			const formData = new FormData();
			formData.append("object", file);
			axios
				.post(url, formData, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				})
				.then(() => {
					this.get_resource_file_datas();
					this.clear();
					this.$root.$message.open({ status: 200, message: "成功上傳檔案" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		deleteResource() {
			axios
				.delete(`${this.url}${this.uploadData.resourceName}/`, {})
				.then(() => {
					this.get_resource_file_datas();
					this.clear();
					this.$root.$message.open({ status: 200, message: "成功刪除檔案" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		clear() {
			this.uploadModalMode = "";
			this.uploadModalShow = false;
			this.uploadData.resourceName = "";
			this.uploadData.resourceFile = null;
		}
	}
};
</script>
