export const item_permission = (u, p) => {
	if (p.includes("true")) {
		return true;
	}
	if (p.includes("false")) {
		return false;
	}
	if (p.includes("anonymous")) {
		// If not yet login?
		if (!u || !u.id) {
			return true;
		} else {
			return false;
		}
	}
	// If already login?
	if (u && u.id) {
		if (p.includes("login")) {
			return true;
		}
		const roles = [
			"is_editor",
			"is_guest",
			"auth_editor",
			"auth_guest",
			"is_manager",
			"is_supermanager",
			"is_superuser"
		];
		const pset = new Set(p)
		const uset = new Set(roles.filter(k => u[k]))
		const intersect = new Set([...pset].filter(i => uset.has(i)));
		return intersect.size > 0?true :false
	} else {
		return false;
	}
};
export const item_show = (nav_item, user) => {
	let items = [];
	_.each(nav_item, v => {
		if (item_permission(user, v.permission)) {
			let item = {
				type: v.type,
				display_name: v.display_name
			};
			if (v.type === "folder") {
				item.items = item_show(v.items, user);
				if (item.items.length > 0) {
					items.push(item);
				}
			}
			if (v.type === "item") {
				item.url = v.url;
				item.routePath = v.routePath;
				item.routeName = v.routeName;
				item.route = v.route;
				items.push(item);
			}
			if (v.type === "action") {
				item.action = v.action;
				items.push(item);
			}
		}
	});
	return items;
};
