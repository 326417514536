<template>
	<div>
		<h3 class="sub-title">{{ org.name }}</h3>
		<div>
			<form-filter>
				<form-item label="類別">
					<select v-model="search_role" class="form-control">
						<option value="all">全部</option>
						<option value="editor">志工</option>
						<option value="guest">視障者</option>
						<option value="unauth">視障者(未驗證)</option>
					</select>
				</form-item>
				<form-item label="關鍵字查詢">
					<input
						@keyup.enter="user_refresh('search')"
						v-model="search_value"
						class="form-control"
						type="text"
						placeholder="輸入欲查詢資訊"
					/>
				</form-item>
				<form-item>
					<button
						type="button"
						class="btn btn-primary"
						@click="user_refresh('search')"
					>
						搜尋
					</button>
				</form-item>
			</form-filter>
			<div
				v-if="search_counts && search_counts > 0"
				class="alert alert-info"
				role="alert"
			>
				共取得 {{ search_counts }} 筆資料
			</div>

			<table-div :header="user_header" :datas="datas" :tdwidths="tdwidths">
				<template #action="props">
					<center>
						<button
							class="btn btn-outline-secondary"
							@click="
								pointer(props.item);
								muModalShow = true;
							"
						>
							基本資料修改
						</button>
					</center>

					<center>
						<button
							class="btn btn-outline-secondary"
							@click="
								pointer(props.item);
								permissionModalShow = true;
							"
						>
							權限設定
						</button>
					</center>

					<center>
						<button
							class="btn btn-outline-secondary"
							v-if="props.item.is_guest"
							@click="
								pointer(props.item);
								mdModalShow = true;
							"
						>
							<div v-if="props.item.disabilitycard_set.length > 0">
								手冊查閱編修
							</div>
							<div v-else>手冊新建登錄</div>
						</button>
					</center>

					<center>
						<button
							type="button"
							class="btn btn-outline-secondary"
							v-if="props.item.is_editor"
							@click="
								pointer(props.item);
								srModalShow = true;
							"
						>
							服務紀錄
						</button>
					</center>
				</template>
			</table-div>
		</div>
		<modal v-if="muModalShow" width="600px" @closeModal="muModalShow = false">
			<template #header>
				<h4 class="modal-title">使用者資料管理</h4>
			</template>
			<template #body>
				<manage-user-info :user_id="pointer_user_id" />
			</template>
		</modal>
		<modal v-if="mdModalShow" @closeModal="mdModalShow = false">
			<template #header>
				<h4 class="modal-title">身心障礙手冊登錄</h4>
			</template>
			<template #body>
				<manage-disabilitycard :pk="pointer_disabilitycard_id" page="person" :owner_id="pointer_user_id" />
			</template>
		</modal>
		<modal v-if="srModalShow" width="600px" @closeModal="srModalShow = false">
			<template #header>
				<h4 class="modal-title">服務紀錄</h4>
			</template>
			<template #body>
				<serviceinfo-record :user_id="pointer_user_id" />
			</template>
		</modal>
		<modal
			v-if="permissionModalShow"
			width="600px"
			@closeModal="permissionModalShow = false"
		>
			<template #header>
				<h4 class="modal-title">權限設定</h4>
			</template>
			<template #body>
				<manage-user-permission :user_id="pointer_user_id" />
			</template>
		</modal>
	</div>
</template>
<script>
import modal from "@/components/popup/modal.vue";
import tableDiv from "@/components/tableDiv.vue";
import manageDisabilitycard from "@/components/components/manageDisabilitycard.vue";
import manageUserInfo from "@/components/components/manageUserInfo.vue";
import manageUserPermission from "@/components/components/manageUserPermission.vue";
import serviceinfoRecord from "@/components/serviceinfoRecord.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";
import { userRest } from "@/api/genericUser";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		manageDisabilitycard,
		manageUserInfo,
		manageUserPermission,
		modal,
		tableDiv,
		serviceinfoRecord,
		formFilter,
		formItem
	},
	data() {
		return {
			tdwidths: [25, 10, 30, 15, 20],
			mdModalShow: false,
			muModalShow: false,
			srModalShow: false,
			permissionModalShow: false,
			pointer_user_id: 0,
			user_header: {
				username: "使用者名稱",
				name: "姓名",
				email: "電子信箱",
				phone: "聯絡電話",
				action: "動作"
			},
			datas: [],
			search_role: "all", //all/editor/guest
			search_value: "",
			search_counts: null,
		};
	},
	computed: {
		query() {
			let temp;
			if (this.search_role === "unauth") {
				temp = { search: this.search_value, auth: "false" };
			} else {
				temp = { search: this.search_value, role: this.search_role };
			}
			if (!(this.org.id == 0)) {
				temp["org_id"] = this.org.id;
			}
			return temp;
		}
	},
	methods: {
		pointer(user) {
			this.pointer_user_id = user.id;
			this.pointer_disabilitycard_id =
				user.disabilitycard_set.length > 0 ? user.disabilitycard_set[0] : "";
		},
		user_refresh(reason) {
			userRest
				.filter(this.query)
				.then(res => {
					this.datas = [];
					_.each(res.data, v => {
						this.datas.push({
							username: v.username,
							name: v.name,
							email: v.email,
							phone: v.phone,
							action: v
						});
					});
					if (reason === "search") {
						this.search_counts = this.datas.length;
						this.$root.$message.open({
							status: 200,
							message: "查詢完成，共取得 " + this.datas.length + " 筆資料"
						});
					}
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.alert {
	margin-top: 1rem;
}
.form {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	.form-group {
		display: flex;
		align-items: center;
		margin-left: 1rem;
	}
}
</style>
