<template>
	<div>
		<h2 v-focus tabindex="0" class="sr-only">法鼓全集</h2>
		<img src="@/assets/ddm/ddm_all.jpg" alt="法鼓山全集" />
		<panel-group
			:data="tab_data"
			:title="'冊數'"
			:headinglevel="3"
			:styleRow="styleRow"
			:styleNav="styleNav"
		>
			<template #ddm="props">
				<table-div
					:header="resource_file_header"
					:datas="props.item"
					:tdwidths="tdwidths"
				>
					<template #action="props">
						<template v-if="user.auth_guest">
							<button
								class="btn btn-outline-secondary"
								@click="readView(props.item)"
							>
								閱讀
							</button>
							<button
								class="btn btn-outline-secondary"
								@click="readResource(props.item)"
							>
								下載
							</button>
						</template>
					</template>
				</table-div>
			</template>
		</panel-group>
	</div>
</template>

<script>
import axios from "axios";
import { file_resolve } from "@/api/base.js";
import { host } from "@/api/base";
import { compare } from "@/utils/dataProcess";
import { mapState } from "vuex";
import tab from "@/components/panelGroup.vue";
import tableDiv from "@/components/tableDiv.vue";

export default {
	components: {
		panelGroup: tab,
		tableDiv
	},
	data() {
		return {
			styleRow: {
				margin: 0
			},
			styleNav: {
				margin: "3rem 0 0 0"
			},
			url: `${host}api/ddm/resource/107/`,
			tab_data: [
				{
					order: 0,
					display_name: "法鼓全集第一輯：教義論述類",
					value: "第一輯",
					type: "ddm",
					data: []
				},
				{
					order: 1,
					display_name: "法鼓全集第二輯：佛教史類",
					value: "第二輯",
					type: "ddm",
					data: []
				},
				{
					order: 2,
					display_name: "法鼓全集第三輯：文集類",
					value: "第三輯",
					type: "ddm",
					data: []
				},
				{
					order: 3,
					display_name: "法鼓全集第四輯：禪修類、法門指導類",
					value: "第四輯",
					type: "ddm",
					data: []
				},
				{
					order: 4,
					display_name: "法鼓全集第五輯：佛教入門類",
					value: "第五輯",
					type: "ddm",
					data: []
				},
				{
					order: 5,
					display_name: "法鼓全集第六輯：自傳、遊記類",
					value: "第六輯",
					type: "ddm",
					data: []
				},
				{
					order: 6,
					display_name: "法鼓全集第七輯：經典釋義類",
					value: "第七輯",
					type: "ddm",
					data: []
				},
				{
					order: 7,
					display_name: "法鼓全集第八輯：生活佛法類",
					value: "第八輯",
					type: "ddm",
					data: []
				},
				{
					order: 8,
					display_name: "法鼓全集第九輯：外文書",
					value: "第九輯",
					type: "ddm",
					data: []
				}
			],
			tdwidths: [8, 42, 10, 15, 25],
			resource_file_header: {
				order: "序號",
				name: "檔案名稱",
				size: "檔案大小",
				mtime: "修改日期",
				action: "動作"
			},
			resource_file_datas: []
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	mounted() {
		this.get_resource_file_datas();
	},
	methods: {
		get_resource_file_datas() {
			this.resource_file_datas = [];
			axios.get(this.url, {}).then(res => {
				let temp = [];
				let index = 0;
				_.each(res.data, v => {
					temp.push({
						name: v.name.slice(2, v.name.length),
						size: v.size,
						mtime: v.mtime.slice(0, 10),
						action: v.name
					});
					index = index + 1;
				});
				_.each(temp, v => {
					_.each(this.tab_data, c => {
						if (v.name.includes(c.value)) {
							c.data.push({ ...v, ...{ order: c.data.length + 1 } });
						}
					});
				});
				this.resource_file_datas = temp.sort(compare("name", "asc"));
			});
		},
		readResource(name) {
			const url = `${this.url}${name}/`;
			axios.get(url, { responseType: "blob" }).then(file_resolve(name));
		},
		readView(name) {
			window.open(
				this.$router.resolve({ name: "ddmView", params: { id: name } }).href,
				"_blank"
			);
		}
	}
};
</script>

<style lang="scss" scoped>
img {
	width: 100%;
}
</style>
