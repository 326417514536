import addDays from "date-fns/addDays";
import jwt_decode from "jwt-decode";
import { v4 as uuid4 } from "uuid";
import router from "@/router";

export const logsystemFactory = ({ to, from, rest }) => {
	const current = router.currentRoute;
	const interactLog = async ({ category, action, object, extra }) => {
		let session;
		try {
			session = JSON.parse(localStorage.getItem("session"));
		} catch (err) {
			session = null;
		}
		if (session) {
			let expire;
			const today = new Date();
			if (session.expiredDate) {
				const expiredDate = new Date(session.expiredDate);
				/*const expiredDate = parse(
					"2021-12-27:11:40:41",
					"yyyy-MM-dd:HH:mm:SS",
					new Date()
				);*/
				expire = today.valueOf() >= expiredDate.valueOf();
			} else {
				expire = true;
			}
			if (expire) {
				session = {
					id: uuid4(),
					expiredDate: new addDays(new Date(), 365)
				};
				localStorage.setItem("session", JSON.stringify(session));
			}
		} else {
			session = {
				id: uuid4(),
				expiredDate: new addDays(new Date(), 365)
			};
			localStorage.setItem("session", JSON.stringify(session));
		}
		let user_id;
		try {
			const token_object = JSON.parse(localStorage.getItem("token_default"));
			user_id = jwt_decode(token_object.refresh).user_id;
		} catch (err) {
			user_id = "0";
		}
		const time = Math.floor(new Date().getTime() / 1000);
		let extraadd = {};
		if (to) {
			extraadd = { ...extraadd, ...{ to: to.fullPath } };
		}
		if (from) {
			extraadd = { ...extraadd, ...{ from: from.fullPath } };
		}
		const logRec = {
			category,
			action,
			object,
			extra: { ...extra, ...extraadd },
			time,
			session: session.id.replaceAll("-", ""),
			user: user_id,
			env: { "navigator.userAgent": navigator.userAgent },
			place: current.fullPath
		};
		if (process.env.NODE_ENV === "production") {
			try {
				await rest.create(logRec);
				console.log("log success");
			} catch (error) {
				console.log(JSON.stringify(error.type));
			}
		}
	};
	return interactLog;
};
