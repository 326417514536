import store from "@/store";

export const set_token = async config => {
	store.commit("token/calculateAccessExpCountdown");
	store.commit("token/calculateRefreshExpCountdown");
	if (store.state.token.accessExpCountdown > 10000) {
		config.headers["Authorization"] =
			"Bearer " + store.state.token.token.access;
	} else if (store.state.token.refreshExpCountdown > 0) {
		await store.dispatch("token/refresh");
		store.commit("token/calculateAccessExpCountdown");
		store.commit("token/calculateRefreshExpCountdown");
		config.headers["Authorization"] =
			"Bearer " + store.state.token.token.access;
	}
	return config;
};
