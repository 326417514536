<template>
	<div id="book_shelf" class="container">
		<h2 class="big-title" v-focus tabindex="0">我的書櫃</h2>
		<tab
			:headinglevel="3"
			:data="[
				{ display_name: '正借閱書籍', type: 'checkout' },
				{ display_name: '借閱歷史紀錄', type: 'checkin' },
			]"
		>
			<template #checkout>
				<table-div
					:datas="checkout_list"
					:header="checkout_header"
					:tdwidths="[58, 11, 11, 20]"
				>
					<template #action="props">
						<div class="btnn-group">
							<div class="layer-2">
								<button
									aria-label="閱讀(另開新分頁)"
									class="btn btn-primary btn-sm"
									@click="readView(props.item.id)"
								>
									閱讀
								</button>
								<button
									class="btn btn-primary btn-sm"
									@click="
										downloadBookName = props.item.object.book_info.bookname;
										downloadPk = props.item.id;
										downloadModalShow = true;
									"
								>
									下載
								</button>
								<button
									class="btn btn-primary btn-sm"
									@click="check_inout(props.item.id, 'check_in')"
								>
									歸還
								</button>
							</div>
						</div>
					</template>
				</table-div>
			</template>
			<template #checkin>
				<table-div
					:datas="checkin_list"
					:header="checkin_header"
					:tdwidths="[78, 11, 11]"
				>
				</table-div>
			</template>
		</tab>
		<modal v-if="downloadModalShow" @closeModal="downloadModalShow = false">
			<template #header>
				<h4 class="modal-title">取得 《{{ downloadBookName }}》 書籍</h4>
			</template>
			<template #body>
				<div class="text-center warning-dont-spread">
					**強烈建議您，所下載書籍僅供個人閱讀使用，請勿任意轉傳，以免觸法，詳細規範請參照，本平台服務條款**
				</div>
				<div class="form-horizontal">
					<template v-for="v in ['fileformat', 'password']">
						<formdrf
							:model_info="downloadModel[v]"
							:field="v"
							v-model="downloadData[v]"
							:key="v"
						></formdrf>
					</template>
					<div class="confirm-checkbox">
						<input
							v-model="downloadData.confirm"
							id="confirm"
							type="checkbox"
						/>
						<label for="confirm"
							>本書只限下載之會員使用，不得重製、轉換格式、複製全部或部份，供他人使用。</label
						>
					</div>
				</div>
			</template>
			<template #footer>
				<button class="btn btn-primary" @click="download">確定</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { file_resolve, host } from "@/api/base";
import { mapState, mapActions } from "vuex";
import formdrf from "@/components/form.vue";
import modal from "@/components/popup/modal.vue";
import tab from "@/components/tab.vue";
import tableDiv from "@/components/tableDiv.vue";
import { libraryRecordRest, libraryRecordAction } from "@/api/ebookSystem.js";

export default {
	components: {
		formdrf,
		modal,
		tab,
		tableDiv,
	},
	data() {
		return {
			host: host,
			downloadModalShow: false,
			downloadPk: 0,
			downloadBookName: "",
			downloadData: {
				fileformat: "epub",
				password: "",
				confirm: false,
			},
			downloadModel: {
				fileformat: {
					label: "類型",
					type: "select",
					choices: [
						{
							display_name: "電子書(epub)",
							value: "epub",
						},
						{
							display_name: "純文字(txt)",
							value: "txt",
						},
					],
				},
				password: {
					label: "密碼",
					type: "password",
				},
			},
			checkout_header: {
				bookname: "書名",
				check_out_time: "借出日期",
				check_in_time: "到期日期",
				action: "動作",
			},
			checkout_list: [],
			checkin_header: {
				bookname: "書名",
				check_out_time: "借出日期",
				check_in_time: "歸還日期",
			},
			checkin_list: [],
		};
	},
	computed: {
		...mapState("user", ["user"]),
	},
	watch: {
		user() {
			this.refresh();
		},
	},
	metaInfo: {
		title: "我的書櫃",
	},
	mounted() {
		this.refresh();
	},
	methods: {
		...mapActions("epub", ["addLocalBooks"]),
		async readView(pk) {
			const epub = await libraryRecordAction.getEpub(pk);
			await this.addLocalBooks({ id: String(pk), url: epub });
			this.$root.$read.open({ url: `/Access8EpubWeb/book/${pk}` });
		},
		refresh() {
			if (!this.user.id) {
				return false;
			}

			let query = {};
			query = { owner_id: this.user.id, status: "true" };
			libraryRecordRest
				.filter(query)
				.then((res) => {
					let filter_data = [];
					_.each(res.data, (v) => {
						try {
							filter_data.push({
								bookname: v.object.book_info.bookname,
								check_out_time: v.check_out_time
									? v.check_out_time.slice(0, 10)
									: "",
								check_in_time: v.check_in_time
									? v.check_in_time.slice(0, 10)
									: "",
								action: v,
							});
						} catch (exception) {
							console.log(`${exception.name}: ${exception.message}`);
							this.$errorHandler({ error: exception });
						}
					});
					this.checkout_list = filter_data;
				})
				.catch((res) => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
			query = { owner_id: this.user.id, status: "false" };
			libraryRecordRest.filter(query).then((res) => {
				let filter_data = [];
				_.each(res.data, (v) => {
					try {
						filter_data.push({
							bookname: v.object.book_info.bookname,
							check_out_time: v.check_out_time
								? v.check_out_time.slice(0, 10)
								: "",
							check_in_time: v.check_in_time
								? v.check_in_time.slice(0, 10)
								: "",
						});
					} catch (exception) {
						console.log(`${exception.name}: ${exception.message}`);
						this.$errorHandler({ error: exception });
					}
				});
				this.checkin_list = filter_data;
			});
		},
		check_inout(pk, action) {
			libraryRecordAction
				.checkInout({ pk, action })
				.then(() => {
					let message = "";
					if (action === "check_in") {
						message = "成功歸還書籍";
						this.$root.$message.open({ status: 200, message: message });
					}
					if (action === "check_out") {
						message = "成功借閱書籍";
						this.$root.$message.open({ status: 200, message: message });
					}
					this.refresh();
				})
				.catch((res) => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		async download() {
			if (this.downloadData.confirm === false) return alert("請勾選下載聲明");
			if (!this.downloadData.password) return alert("請輸入密碼");
			libraryRecordAction
				.download(this.downloadPk, {
					...this.downloadData,
				})
				.then(
					file_resolve(
						`${this.downloadPk}${this.downloadBookName}.${this.downloadData.fileformat}`
					)
				)
				.then(() => {
					this.downloadModalShow = false;
				})
				.catch(async (res) => {
					this.$errorHandler({ error: res });
				});
		},
		viewLibrary(pk) {
			/* this.$router.push({
				name: 'libraryView',
				params: {id: pk},
			});*/
			window.open(
				this.$router.resolve({ name: "libraryView", params: { id: pk } }).href,
				"_blank"
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.warning-dont-spread {
	color: $color-soft-red;
	margin-bottom: 1rem;
	// font-size: 16px;
}

.confirm-checkbox {
	display: flex;
	margin-left: 1rem;
	gap: 1rem;
}

::v-deep .content {
	height: calc(70vh - 144px) !important;
}
</style>
