import Vue from "vue";
import Vuex from "vuex";
import epub from "./epub";
import log from "./log";
import popup from "./popup";
import token from "./token";
import user from "./user";

import { organizationRest } from "@/api/genericUser";
import { categoryRest } from "@/api/ebookSystem";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		epub,
		log,
		popup,
		token,
		user
	},
	state: {
		organizations: [],
		categorys: [],
	},
	getters: {},
	mutations: {
		updateOrganizations(state, payload) {
			state.organizations = payload;
		},
		updateCategorys(state, payload) {
			state.categorys = payload;
		},
	},
	actions: {
		getOrganizations({ commit }) {
			organizationRest.list().then(response => {
				commit("updateOrganizations", response.data);
			});
		},
		getCategorys({ commit }) {
			categoryRest.list().then(response => {
				commit("updateCategorys", response.data);
			});
		},
	}
});
