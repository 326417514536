import { userRest } from "@/api/user";

export default {
	namespaced: true,
	state: {
		user: {}
	},
	getters: {},
	mutations: {
		update(state, user) {
			state.user = user;
		}
	},
	actions: {
		read({ commit }, id) {
			return userRest
				.read(id)
				.then(res => {
					commit("update", res.data);
					return res.data;
				})
				.catch(err => {
					console.log(JSON.stringify(err));
				});
		},
		update({ commit }, { id, user }) {
			return userRest.update(id, user).then(res => {
				commit("update", res.data);
				return res.data;
			});
		}
	}
};
