<template>
	<div id="service">
		<form-filter>
			<!--<form-item label="領取">
				<select
					v-if="choice_org"
					class="form-control"
					v-model="org_id"
					@change="refresh()"
				>
					<option :value="0" selected="selected">全部</option>
					<option
						v-for="item in orgs"
						:key="item.id"
						:value="item.id"
						>{{ item.name }}</option
					>
				</select>
			</form-item>-->
			<form-item label="領取">
				<select
					id="id_category"
					name="category"
					class="form-control"
					v-model="category_id"
					@change="refresh()"
				>
					<option value="all">未選擇</option>
					<option
						v-for="(category, key) in cats"
						:value="category.id"
						:key="key"
						>{{ category.name }}</option
					>
				</select>
				類型的文件
			</form-item>
			<form-item>
				<button class="btn btn-primary" type="button" @click="get_ebook()">
					領文件
				</button>
			</form-item>
		</form-filter>

		<div class="editor-info">
			<div class="editor-info__list" v-if="user.is_manager">
				<div class="editor-info__status">
					可領取：<strong>{{ status["1"] }}</strong>
				</div>
				<div class="editor-info__status">
					校對中：<strong>{{ status["2"] }}</strong>
				</div>
				<div class="editor-info__status">
					待審核：<strong>{{ status["3"] }}</strong>
				</div>
			</div>
			<div class="text-danger">
				注意：未於期限內完成校對動作將自動歸還文件，將無法計入當月服務時數
			</div>
		</div>

		<div>
			<h3 class="page-header">正校對文件</h3>
			<table-div :datas="edit_ebook" :header="edit_ebook_header">
				<template slot="action" slot-scope="props">
					<div class="btnn-group">
						<div class="layer-2">
							<button
								class="btn btn-primary btn-sm"
								@click="editEbook(props.item)"
							>
								編輯
							</button>
							<button
								class="btn btn-primary btn-sm"
								@click="reback_ebook(props.item)"
							>
								還文件
							</button>
							<button
								v-if="props.item.soonDelay && !props.item.delay"
								class="btn btn-primary btn-sm"
								@click="delay_ebook(props.item)"
							>
								延期
							</button>
						</div>
					</div>
				</template>
			</table-div>
		</div>

		<div>
			<h3 class="page-header">已退回文件</h3>
			<table-div :datas="reject_ebook" :header="reject_ebook_header">
				<template slot="action" slot-scope="props">
					<div class="btnn-group">
						<div class="layer-2">
							<button
								class="btn btn-primary btn-sm"
								@click="editEbook(props.item)"
							>
								編輯
							</button>
							<button
								class="btn btn-primary btn-sm"
								@click="reback_ebook(props.item)"
							>
								還文件
							</button>
							<button
								v-if="props.item.soonDelay && !props.item.delay"
								class="btn btn-primary btn-sm"
								@click="delay_ebook(props.item)"
							>
								延期
							</button>
						</div>
					</div>
				</template>
			</table-div>
		</div>

		<div>
			<h3 class="page-header">審核中的文件</h3>
			<table-div :datas="finish_ebook" :header="finish_ebook_header">
				<template slot="action" slot-scope="props">
					<button
						class="btn btn-outline-secondary"
						@click="reedit_ebook(props.item)"
					>
						再編輯
					</button>
				</template>
			</table-div>
		</div>
	</div>
</template>

<script>
import addDays from "date-fns/addDays";
import formatISO from "date-fns/formatISO";
import { mapState } from "vuex";
import tableDiv from "@/components/tableDiv.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";
import { ebookRest, ebookAction, categoryRest } from "@/api/ebookSystem.js";
import { organizationRest } from "@/api/genericUser.js";

export default {
	props: ["choice_org"],
	components: {
		tableDiv,
		formItem,
		formFilter
	},
	data() {
		return {
			edit_ebook_header: {
				document: "文件",
				page: "頁數/總頁數",
				get_date: "領取日期",
				deadline: "期限",
				service_hours: "線上時數",
				status: "狀態",
				action: "動作"
			},
			edit_ebook: [],
			reject_ebook_header: {
				document: "文件",
				page: "頁數/總頁數",
				get_date: "領取日期",
				deadline: "期限",
				service_hours: "線上時數",
				status: "狀態",
				action: "動作"
			},
			reject_ebook: [],
			finish_ebook_header: {
				document: "文件",
				service_hours: "服務時數",
				get_date: "領取日期",
				category: "類型",
				action: "動作"
			},
			finish_ebook: [],
			org_id: 0,
			category_id: "all",
			status: {
				"0": 0,
				"1": 0,
				"2": 0
			}
		};
	},
	computed: {
		...mapState("user", {
			user: "user"
		}),
		...mapState({
			orgs: "organizations",
			"cats": "categorys",
		})
	},
	watch: {
		org_id() {
			this.refresh();
		}
	},
	mounted() {
		this.refresh();
	},
	methods: {
		refresh() {
			if (!this.user.id) {
				return false;
			}

			let query = {};

			query = { editor_id: this.user.id, status: "2" };

			ebookRest
				.filter(query)
				.then(res => {
					this.edit_ebook = [];
					this.reject_ebook = [];
					_.each(res.data, v => {
						v.pk = v.ISBN_part;
						v.soonDelay = addDays(new Date(), 1) > new Date(v.deadline);
						if (v.reject) {
							this.reject_ebook.push({
								document: v["bookname"] + v["part"],
								page: v.edited_page + 1 + "/50",
								get_date: v.get_date,
								deadline: v.deadline,
								service_hours: v.service_hours,
								status: v.status,
								action: v
							});
						} else {
							this.edit_ebook.push({
								document: v["bookname"] + v["part"],
								page: v.edited_page + 1 + "/50",
								get_date: v.get_date,
								deadline: v.deadline,
								service_hours: v.service_hours,
								status: v.status,
								action: v
							});
						}
					});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});

			query = { editor_id: this.user.id, status: "3" };

			ebookRest
				.filter(query)
				.then(res => {
					this.finish_ebook = [];
					_.each(res.data, v => {
						v.pk = v.ISBN_part;
						this.finish_ebook.push({
							document: v["bookname"] + v["part"],
							service_hours: v.service_hours,
							get_date: v.get_date,
							category: v.current_editrecord.category,
							action: v
						});
					});
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});

			if (this.org_id === 0) {
				query = {};
			} else {
				query = { org_id: this.org_id };
			}

			if (!this.user.is_manager) {
				return false;
			}

			ebookAction
				.statusInfo(query)
				.then(res => {
					this.status = res.data.detail;
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		get_ebook() {
			if(this.category_id === "all"){
				return this.$root.$message.open({
					status: 400,
					message:
						"請選擇一個類別",
				});
			}

			ebookAction
				.service({
					org_id: this.org_id,
					category_id: this.category_id
				})
				.then(res => {
					return this.$root.$message.open({
						status: 200,
						message:
							"成功取得文件：" + res.data.data.bookname + res.data.data.part
					});
				})
				.then(() => {
					this.refresh();
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		reback_ebook({ pk }) {
			this.$root.$confirm
				.open({
					status: 200,
					message:
						"還文件後該文件將提供其他校對者領取，且無法獲得校對時數，是否確定還文件?"
				})
				.then(() => {
					return ebookAction.changeStatus({
						pk: pk,
						direction: "-1",
						status: "active"
					});
				})
				.then(res => {
					return this.$root.$message.open({
						status: 200,
						message:
							"成功歸還文件：" + res.data.data.bookname + res.data.data.part
					});
				})
				.then(() => {
					this.refresh();
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		async delay_ebook({ pk, deadline }) {
			let res;
			try {
				await this.$root.$confirm.open({
					status: 200,
					message: "到期日將延後2天，但僅能延後1次，是否確定延期?"
				});
				res = await ebookRest.partialupdate(pk, {
					deadline: formatISO(addDays(new Date(deadline), 2)).slice(0, 10),
					delay: true
				});
				this.$root.$message.open({
					status: 200,
					message: "成功延期文件：" + res.data.bookname + res.data.part
				});
				this.refresh();
			} catch (error) {
				this.$errorHandler({ error: error });
			}
		},
		reedit_ebook({ pk }) {
			ebookAction
				.changeStatus({
					pk: pk,
					direction: "-1",
					status: "edit"
				})
				.then(res => {
					return this.$root.$message.open({
						status: 200,
						message:
							"成功再編輯文件：" + res.data.data.bookname + res.data.data.part
					});
				})
				.then(() => {
					this.refresh();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		editEbook({ pk }) {
			this.$store.commit("token/calculateAccessExpCountdown");
			this.$store.commit("token/calculateRefreshExpCountdown");
			if (this.$store.state.token.refreshExpCountdown > 36000000) {
				this.$router.push({
					name: "ebookEdit",
					params: { id: pk }
				});
			} else {
				this.$root.$token.open().then(() => {
					this.$router.push({
						name: "ebookEdit",
						params: { id: pk }
					});
				});
			}
		}
	}
};
</script>

<style lang="scss">
@import "~@/style/pages/editor-service.scss";
</style>
