<template>
	<div>
		<div class="btnn-group is-margin-y is-justify-content-start">
			<div class="layer-2">
				<button
					class="btn btn-primary"
					@click="$router.push({ name: 'qandaList' })"
				>
					回列表
				</button>
				<button v-if="pk == 0" class="btn btn-primary" @click="rest('create')">
					新增
				</button>
				<button v-else class="btn btn-primary" @click="rest('update')">
					更新
				</button>
			</div>
		</div>
		<template v-for="v in Object.keys(model)">
			<formdrf
				:model_info="model[v]"
				:field="v"
				v-model="value[v]"
				:key="v"
			></formdrf>
		</template>
	</div>
</template>

<script>
import { qAndARest } from "@/api/genericUser.js";
import formdrf from "@/components/form2.vue";

export default {
	components: {
		formdrf
	},
	data() {
		return {
			pk: 0,
			value: {
				category: "volunteer",
				question: "",
				answer: "",
				order: 0
			},
			model: {
				category: {
					label: "類別",
					type: "select",
					choices: [
						{
							value: "volunteer",
							display_name: "志工"
						},
						{
							value: "vip",
							display_name: "視障者"
						},
						{
							value: "platform",
							display_name: "平台"
						}
					]
				},
				question: {
					label: "問題",
					type: "text",
					required: true
				},
				answer: {
					label: "答案",
					type: "textarea",
					required: true
				},
				order: {
					label: "權重",
					type: "text",
					required: true
				}
			}
		};
	},
	async mounted() {
		try {
			await this.rest("read");
		} catch (error) {
			console.log(error);
			this.$errorHandler({ error });
		}
	},
	methods: {
		async rest(action) {
			this.pk = this.$route.params.id;
			let res;
			if (action == "create") {
				try {
					res = await qAndARest.create(this.value);
					this.$root.$message.open({ status: 200, message: "成功完成動作" });
				} catch (error) {
					console.log(error);
					this.$errorHandler({ error });
				}
			} else if (action == "read") {
				try {
					res = await qAndARest.read(this.$route.params.id);
					_.each(this.value, (v, k) => {
						this.value[k] = res.data[k];
					});
				} catch (error) {
					console.log(error);
					this.$errorHandler({ error });
				}
			} else if (action == "update") {
				try {
					res = await qAndARest.partialupdate(this.pk, this.value);
					res = await qAndARest.read(this.pk);
					_.each(this.value, (v, k) => {
						this.value[k] = res.data[k];
					});
					this.$root.$message.open({ status: 200, message: "成功完成動作" });
				} catch (error) {
					console.log(error);
					this.$errorHandler({ error });
				}
			} else if (action == "delete") {
				try {
					await this.$confirm.open({
						status: 200,
						message: "確定刪除此項目？"
					});
					res = await qAndARest.delete(this.pk);
				} catch (error) {
					console.log(error);
					this.$errorHandler({ error });
				}
			}
		},
		async() {}
	}
};
</script>

<style scoped lang="scss"></style>
