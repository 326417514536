<template>
	<button
		type="button"
		class="btn btn-with-icon mr-sm-2"
		:class="btnColor"
		@click="$emit('click')"
		:style="indexStyle"
	>
		<div class="icon-wrap">
			<font-awesome-icon :icon="iconName" />
		</div>
		<span>{{ name }}</span>
	</button>
</template>
<script>
export default {
	props: {
		indexStyle: {
			type: Object
		},
		name: {
			type: String
		},
		iconName: {
			type: String
		},
		btnColor: {
			type: String,
			default: "btn-primary"
		}
	}
};
</script>
<style lang="scss" scoped>
.btn-with-icon {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.icon-wrap {
		margin-right: 10px;
	}
	@media screen and (max-width: 767px) {
		.icon-wrap {
			margin: 0px;
		}
		span {
			display: none;
		}
	}
}
</style>
