<template>
	<div>
		<div class="tab-content">
			<h3 class="sub-title">{{ org.name }}</h3>
			<div id="ebook_review_list">
				<table-div :header="ebook_header" :datas="ebook_datas">
					<template slot="action" slot-scope="props">
						<a
							role="button"
							class="btn btn-outline-secondary"
							@click="
								$router.push({
									name: 'ebookReview',
									params: { id: props.item.ISBN_part }
								})
							"
							>審核</a
						>
					</template>
				</table-div>
			</div>
		</div>
	</div>
</template>

<script>
import tableDiv from "@/components/tableDiv.vue";
import { ebookRest } from "@/api/ebookSystem.js";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		tableDiv
	},
	data: function() {
		return {
			ebook_header: {
				bookname: "文件",
				part: "段數",
				action: "動作"
			},
			ebook_datas: []
		};
	},
	mounted() {
		this.review_list();
	},
	methods: {
		review_list() {
			const query = {
				status: 3
			};
			if (!(this.org.id == 0)) {
				query["org_id"] = this.org.id;
			}
			ebookRest
				.filter(query)
				.then(res => {
					this.ebook_datas = [];
					_.each(res.data, v => {
						this.ebook_datas.push({
							bookname: v.bookname,
							part: v.part,
							action: v
						});
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
