<template>
	<div class="form-horizontal">
		<h3 class="big-title">公告發佈</h3>
		<div v-if="user.org == '1'" class="form-group">
			<label for="id_category" class="control-label col-sm-1"
				><font style="color:red">*</font>類別</label
			>
			<div class="col-sm-11">
				<select class="form-control" v-model="category">
					<option value="">---------</option>
					<option value="平台消息">平台消息</option>
					<option value="天橋說書">天橋說書</option>
					<option value="新書推薦">新書推薦</option>
					<option value="志工快訊">志工快訊</option>
					<option value="每月書訊">每月書訊</option>
				</select>
			</div>
		</div>

		<div class="form-group">
			<label for="id_title" class="control-label col-sm-1"
				><font style="color:red">*</font>標題</label
			>
			<div class="col-sm-11">
				<input
					maxlength="30"
					class="form-control"
					type="text"
					placeholder="title"
					required
					v-model="title"
				/>
			</div>
		</div>

		<div class="form-group">
			<label for="id_content" class="control-label col-sm-1"
				><font style="color:red">*</font>內容</label
			>
			<div class="col-sm-11">
				<code-mirror v-model="content" />
			</div>
		</div>

		<div class="form-group">
			<div class="col-sm-offset-1 col-sm-11">
				<button type="button" class="btn btn-primary" @click="create()">
					發佈
				</button>
				<button type="button" class="btn btn-danger" @click="cancel()">
					取消
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import CodeMirror from 'vue-codemirror6';

import { mapState } from "vuex";
import { announcementRest } from "@/api/genericUser.js";

export default {
	components: {
		CodeMirror,
	},
	data() {
		return {
			title: "",
			category: "",
			content: "",
			tinymce_init: {},
			publicPath: process.env.BASE_URL
		};
	},
	computed: {
		...mapState("user", ["user"]),
		org() {
			return this.user.org;
		},
		transferData() {
			return {
				org: this.org,
				title: this.title,
				category: this.category,
				content: this.content
			};
		}
	},
	metaInfo: {
		title: "公告發佈"
	},
	methods: {
		cancel() {},
		create() {
			announcementRest
				.create(this.transferData)
				.then(() => {
					return this.$root.$message.open({
						status: 400,
						message: "成功新建公告"
					});
				})
				.then(() => {
					//window.location.replace('/routing/genericUser/announcement/' +data.data['id'] +'/')
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
