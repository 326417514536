<template>
	<div class="any-about-page container this-is-origin">
		<div
			class="bs-callout bs-callout-primary"
			v-for="(item, index) of datas"
			:key="index"
		>
			<div @click="show(index)">
				<h3
					class="question-font-active"
					:title="title"
					tabindex="0"
					v-if="activeIndex === index"
				>
					{{ item.question }}
				</h3>
				<h3 class="question-font" :title="title" tabindex="0" v-else>
					{{ item.question }}
				</h3>
			</div>
			<div>
				<span
					class="answer-font"
					v-show="activeIndex === index"
					v-html="markdown2html(item.answer)"
				></span>
			</div>
		</div>
	</div>
</template>

<script>
import showdown from "showdown";

export default {
	props: {
		title: String,
		datas: Array
	},
	data() {
		return {
			boxshow: "",
			activeIndex: -1
		};
	},
	methods: {
		show(index) {
			//this.boxshow=!this.boxshow;
			this.activeIndex = index;
		},
		markdown2html(text) {
			let converter = new showdown.Converter();
			let html = converter.makeHtml(text);
			return html;
		}
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";

.question-font-active {
	font-size: 20px;
	display: inline-grid;
	margin-left: 0;
}
.question-font {
	font-size: 20px;
	display: inline-grid;
	margin-left: 0;
	cursor: pointer;
}
.question-font:hover {
	border-bottom: 1px solid
		scale-color($color-water-blue, $lightness: 0%, $saturation: 0%);
	padding-bottom: 5px;
}
.answer-font {
	font-size: 17px;
	line-height: 1.7;
}
</style>
