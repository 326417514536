<template>
	<div
		v-if="model_info"
		:class="['form-group', { 'input-required': model_info.required }]"
		:style="styleObject"
	>
		<!-- Label text for this input. -->
		<label class="label" :for="field"
			><span>{{ labelCssTextJustifyEffect(model_info.label) }}</span></label
		>

		<!-- Several input/select/textarea/... elements
		(notice the "v-if", only one will be used at a time). -->
		<div class="input-container">
			<!-- Input type = 'string', 'text', 'password', 'email'. -->

			<input
				v-if="['string', 'text', 'password', 'email'].includes(model_info.type)"
				class="form-control"
				:type="model_info.type"
				:id="field"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@change="$emit('change', $event.target.value)"
				:required="model_info.required"
				:readonly="readonly == true"
			/>

			<!-- Input type = 'boolean', 'checkbox'. -->
			<div
				class="custom-control custom-control-inline custom-checkbox"
				v-if="['boolean', 'checkbox'].includes(model_info.type)"
			>
				<input
					class="custom-control-input"
					type="checkbox"
					:id="field"
					:value="value"
					@change="$emit('input', $event.target.checked)"
					:checked="value == true"
					:readonly="readonly == true"
				/>
				<label class="custom-control-label" :for="field"
					><!-- This is visual-purpose label, just to make bootstrap styling works. --></label
				>
			</div>

			<!-- Input type = 'date'. -->
			<input
				v-if="model_info.type === 'date'"
				:id="field"
				:value="value"
				@input="$emit('input', $event.target.value)"
				type="date"
				pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
				:required="model_info.required"
				:readonly="readonly == true"
			/>

			<!-- Input type = 'select'. -->
			<select
				v-if="model_info.type === 'select'"
				class="custom-select"
				:id="field"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@change="$emit('change', $event.target.value)"
				:disabled="readonly == true"
			>
				<option
					v-for="(el, idx) in model_info.choices"
					:value="el.value"
					:key="idx"
				>
					{{ el.display_name }}
				</option>
			</select>

			<!-- Input type = 'radio'. -->
			<template v-for="(el, idx) in model_info.choices">
				<div
					v-if="model_info.type === 'radio'"
					class="custom-control custom-control-inline custom-radio"
					:key="idx"
				>
					<input
						class="custom-control-input"
						type="radio"
						:id="field + el.value"
						:value="el.value"
						@input="$emit('input', $event.target.value)"
						@change="$emit('change', $event.target.value)"
						:checked="el.value == value"
						:disabled="readonly == true"
					/>
					<!-- .":for" is served for Boostrap. -->
					<label class="custom-control-label" :for="field + el.value">{{
						el.display_name
					}}</label>
				</div>
			</template>

			<!-- Input type = 'textarea'. -->
			<textarea
				v-if="model_info.type === 'textarea'"
				class="form-control"
				:id="field"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@change="$emit('change', $event.target.value)"
				rows="10"
				:readonly="readonly == true"
			></textarea>

			<editor
				v-if="model_info.type === 'tinymce'"
				:init="tinymceInit"
				:id="field"
				:value="value"
				@input="$emit('input', $event)"
				@change="$emit('change', $event)"
				:disabled="readonly == true"
			></editor>
		</div>

		<!-- When there is extra message to be be shown. -->
		<label
			class="extra-info"
			v-if="!!model_info.remark && !(model_info.remark === '')"
			style="text-align:left;"
			>{{ model_info.remark }}</label
		>
	</div>
</template>

<script>
import "tinymce/tinymce";
import editor from "@tinymce/tinymce-vue";

import "tinymce/themes/silver/theme.min.js";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/plugins/table";
import "tinymce/plugins/searchreplace";

import { labelCssTextJustifyEffect } from "@/utils/general.js";

export default {
	props: {
		styleObject: {
			type: Object
		},
		model_info: {
			type: Object,
			default: () => {}
		},
		field: {
			type: String,
			default: ""
		},
		value: [String, Boolean, Number],
		readonly: {
			type: Boolean,
			default: false
		}
	},
	components: {
		editor
	},
	data() {
		return {
			labelCssTextJustifyEffect,
			tinymceInit: {}
		};
	},
	created() {
		this.tinymceInit = {
			language: "zh_TW",
			plugins: " table searchreplace ",
			menubar: false, // 隐藏最上方menu
			toolbar1:
				"undo redo | cut copy paste | bullist numlist | table | searchreplace | fontsizeselect ",
			fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt"
		};
	}
	// computed: {
	// 	// Purpose: So css text-align: justify property will works on chinese character too.
	// 	// Example: Convert string
	// 	// "你好嗎??! abcd efg123"
	// 	// to
	// 	// "你 好 嗎 ? ? ! abcd efg123".
	// 	labelCssTextJustifyEffect () {
	// 		let oldText = this.model_info.label;
	// 		let old1Array = oldText.split('');
	// 		let newText = '';
	// 		old1Array.forEach((aCharacter, index, thisArray) => {
	// 			if ((index - 1 >=0) && (thisArray[index - 1].match(/(\w|\d)/) === null)) {
	// 				newText += ' ';
	// 				newText += aCharacter;
	// 			} else {
	// 				newText += aCharacter;
	// 			}
	// 		});
	// 		// Prevent sequential whitespaces, convert them to single whitespace.
	// 		newText = newText.replace(/\s\s+/g, ' ');
	// 		return newText;
	// 	},
	// },
};
</script>

<style lang="scss" scoped>
// @import '~@/style/components/_form.scss';

.form-group {
	--margin-left: calc(var(--form-item-required-symbol-spacing) + 0em);

	display: grid;
	grid-template-columns: var(--form-item-col1-width) 1fr;
	grid-auto-rows: auto;
	align-items: start;
	grid-column-gap: var(--form-group-grid-gap);
	grid-row-gap: 0.3em;
	grid-template-areas:
		"label input-container"
		".     extra-info";
	margin-top: 1.3em;
	margin-bottom: 1.3em;
	margin-left: var(--margin-left);

	// Purpose: Overwriting bootstrap style.
	label {
		margin-bottom: 0;
	}

	.label,
	.input-container,
	.extra-info {
	}
	.label {
		grid-area: label;
		text-align: justify;
		text-align-last: justify;
	}
	.input-container {
		grid-area: input-container;
		// line-height: 0;
	}
	.extra-info {
		grid-area: extra-info;
		color: $color-red-dark;
		font-size: 0.9em;
		line-height: 1.5;
	}

	input[type="checkbox"] {
	}
}

@media (min-width: media-query-length($em: $breakpoint_xs)) {
	.form-group {
		// grid-template-areas:
		// 	"label"
		// 	"input-container"
		// 	"extra-info";

		.label,
		.input-container,
		.extra-info {
		}
		.label {
		}
	}
}
</style>
