<template>
	<div class="this-is-modify-password">
		<template v-for="v in ['oldPassword', 'newPassword1', 'newPassword2']">
			<formdrf
				:model_info="model[v]"
				:field="v"
				v-model="password[v]"
				:key="v"
			></formdrf>
		</template>
		<div class="btnn-group is-margin-y is-justify-content-start">
			<div class="layer-2">
				<button class="btn btn-primary" @click="check">儲存</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { userAction } from "@/api/user";
import formdrf from "@/components/form.vue";

export default {
	name: "ModifyPassword",
	components: {
		formdrf
	},
	data() {
		return {
			model: {
				oldPassword: {
					label: "舊密碼",
					type: "password",
					required: true,
					remark: ""
				},
				newPassword1: {
					label: "新密碼",
					type: "password",
					required: true,
					remark: ""
				},
				newPassword2: {
					label: "確認新密碼",
					type: "password",
					required: true,
					remark: ""
				}
			},
			password: {
				oldPassword: "",
				newPassword1: "",
				newPassword2: ""
			}
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	methods: {
		clear() {
			_.each(this.password, (v, k) => {
				this.password[k] = "";
			});
		},
		check() {
			let err = [];

			if (this.password.oldPassword === "") {
				err.push("舊密碼不得為空");
			}
			if (this.password.newPassword1 === "") {
				err.push("新密碼不得為空");
			}
			if (this.password.newPassword2 === "") {
				err.push("確認新密碼不得為空");
			}
			if (!(this.password.newPassword1 === this.password.newPassword2)) {
				err.push("新密碼與確認新密碼不符");
			}

			if (err.length > 0) {
				this.$root.$message.open({ status: 400, message: err });
			} else {
				this.change();
			}
		},
		change() {
			userAction
				.setPassword(this.user.id, this.password)
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message: "成功更改密碼"
					});
				})
				.catch(err => {
					this.$errorHandler({ error: err });
				});
		}
	}
};
</script>

<style lang="scss" scoped></style>
