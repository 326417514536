<template>
	<div class="this-is-modify-disability-card">
		<div class="row">
			<!-- The left side, card, 2 input or 2 images. -->
			<div class="col-auto col-xl-5">
				<!-- Buttons such as 建立,編輯,儲存,取消,啟用,停用:
				-------------------------------------------------- -->
				<div class="btnn-group is-margin-y is-justify-content-start">
					<div class="layer-2">
						<template v-if="mode == 'read'">
							<template v-if="user.is_manager">
								<button
									v-if="String(disabilitycard['is_active']) === 'true'"
									@click="active(false)"
									class="btn btn-primary"
								>
									停用
								</button>
								<button
									v-if="String(disabilitycard['is_active']) === 'false'"
									@click="active(true)"
									class="btn btn-primary"
								>
									啟用
								</button>
							</template>
							<button @click="mode = 'write'" class="btn btn-primary">
								編輯
							</button>
						</template>
						<template v-if="mode == 'write'">
							<button class="btn btn-primary" @click="cancel">取消</button>
							<button class="btn btn-primary" @click="update()">儲存</button>
						</template>
						<template v-if="mode == 'create'">
							<button class="btn btn-primary" @click="create()">建立</button>
						</template>
					</div>
				</div>
				<!-- Card ,, front side:
				------------------------- -->
				<!-- Image still/only exist in front-end. -->
				<p v-if="mode === 'write'">
					請上傳 jpg 或 png 檔案，其他檔案無法上傳。
				</p>
				<img
					v-if="local_front"
					class="img-thumbnail"
					alt="身心障礙手冊正面"
					:src="local_front_url"
				/>
				<!-- Image come from back-end. -->
				<img v-else class="img-thumbnail" alt="身心障礙手冊正面" :src="front" />
				<!-- File upload button. -->
				<input
					v-if="mode === 'write'"
					class="img-thumbnail-input"
					type="file"
					@change="changeFront"
				/>
				<!-- Card ,, back side:
				------------------------- -->
				<img
					v-if="local_back"
					class="img-thumbnail"
					alt="身心障礙手冊反面"
					:src="local_back_url"
				/>
				<img v-else class="img-thumbnail" alt="身心障礙手冊反面" :src="back" />
				<input
					v-if="mode === 'write'"
					class="img-thumbnail-input"
					type="file"
					@change="changeBack"
				/>
			</div>
			<!-- The right side, form. -->
			<div class="col-auto col-xl-7" style="margin-top: 2.3em;">
				<template v-if="mode == 'read'">
					<table class="table">
						<template
							v-for="v in [
								'owner',
								'identity_card_number',
								'name',
								'address',
								'category',
								'level',
								'identification_date',
								'renew_date',
							]"
						>
							<tr :key="v">
								<th scope="row">{{ model[v].label }} :</th>
								<td>
									<template v-if="model[v].choices">
										<template v-for="item in model[v].choices">
											<template v-if="disabilitycard[v] === item.value">
												{{ item.display_name }}
											</template>
										</template>
									</template>
									<template v-else>
										{{ disabilitycard[v] }}
									</template>
								</td>
							</tr>
						</template>
					</table>
				</template>
				<template v-if="mode == 'write'">
					<template v-if="page === 'manager'">
						<formdrf
							:model_info="model['owner']"
							:field="'owner'"
							v-model="disabilitycard_temp['owner']"
							:key="'owner'"
						></formdrf>
					</template>
					<template
						v-for="v in [
							'name',
							'address',
							'category',
							'level',
							'identification_date',
							'renew_date',
						]"
					>
						<formdrf
							:model_info="model[v]"
							:field="v"
							v-model="disabilitycard_temp[v]"
							:key="v"
						></formdrf>
					</template>
				</template>
				<template v-if="mode == 'create'">
					<template v-if="page === 'manager'">
						<formdrf
							:model_info="model['owner']"
							:field="'owner'"
							v-model="disabilitycard_temp['owner']"
							:key="'owner'"
						></formdrf>
					</template>
					<template
						v-for="v in [
							'identity_card_number',
							'name',
							'address',
							'category',
							'level',
							'identification_date',
							'renew_date',
						]"
					>
						<formdrf
							:model_info="model[v]"
							:field="v"
							v-model="disabilitycard_temp[v]"
							:key="v"
						></formdrf>
					</template>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { host } from "@/api/base";
import { compare } from "@/utils/dataProcess.js";
import formdrf from "@/components/form.vue";
import {
	userAction,
	disabilityCardRest,
	disabilityCardAction,
} from "@/api/genericUser";
import { labelCssTextJustifyEffect } from "@/utils/general.js";

export default {
	props: {
		pk: {
			type: String,
			default: "",
		},
		page: {
			type: String,
			default: "person",
		},
		owner_id: {
			type: Number,
			default: 0,
		},
	},
	components: {
		formdrf,
	},
	data() {
		return {
			mode: "read",
			model: {
				identity_card_number: {
					label: "身份證字號",
					type: "string",
					required: true,
				},
				owner: {
					label: "擁有者",
					type: "select",
					// Will be updated by backend later.
					choices: [],
				},
				name: {
					label: "姓名",
					type: "string",
					required: true,
				},
				address: {
					label: "地址",
					type: "string",
					required: true,
				},
				category: {
					label: "類別",
					type: "select",
					required: true,
					choices: [
						{
							value: "vi",
							display_name: "視障",
						},
						{
							value: "ld",
							display_name: "學障",
						},
					],
				},
				level: {
					label: "程度",
					type: "select",
					required: true,
					choices: [
						{
							value: "mild",
							display_name: "輕度",
						},
						{
							value: "moderate",
							display_name: "中度",
						},
						{
							value: "severe",
							display_name: "重度",
						},
						{
							value: "profound",
							display_name: "極重度",
						},
					],
				},
				identification_date: {
					label: "鑑定日期",
					type: "date",
					required: true,
					remark: "請填寫西元年(4位)-月(2位)-日(2位)。範例：2020-01-01",
				},
				renew_date: {
					label: "重新鑑定日期",
					type: "date",
					required: true,
					remark: "請填寫西元年(4位)-月(2位)-日(2位)。範例：2020-01-01",
				},
			},
			disabilitycard: {
				identity_card_number: "",
				name: "",
				address: "",
				identification_date: "",
				renew_date: "",
				level: "mild",
				category: "vi",
				owner: "",
				is_active: "",
			},
			disabilitycard_temp: {
				identity_card_number: "",
				name: "",
				address: "",
				identification_date: "",
				renew_date: "",
				level: "mild",
				category: "vi",
				owner: "",
				is_active: "",
			},
			front: null,
			back: null,
			local_back: null,
			local_front: null,
			local_back_url: null,
			local_front_url: null,
			user_list: [],
			labelCssTextJustifyEffect,
		};
	},
	computed: {
		...mapState("user", ["user"]),
		url() {
			if (this.pk === -1) {
				return host + "/genericUser/api/disabilitycards/";
			} else {
				return host + "/genericUser/api/disabilitycards/" + this.pk + "/";
			}
		},
	},
	watch: {
		pk() {
			this.pkSetted();
		},
	},
	mounted() {
		userAction
			.select({ role: "guest" })
			.then((res) => {
				res.data.result.forEach((v) => {
					this.user_list.push({
						display_name: v.username,
						value: v.id,
						is_guest: v.is_guest,
					});
				});
				this.user_list.sort(compare("display_name", "asc"));
				return disabilityCardRest.options();
			})
			.then(() => {
				this.model.owner.choices = this.user_list;
			});
		this.pkSetted();
	},
	methods: {
		pkSetted() {
			if (this.pk == "") {
				this.mode = "create";
				/*_.each(this.disabilitycard, (v, k) => {
					this.disabilitycard[k] = "";
				});
				_.each(this.disabilitycard_temp, (v, k) => {
					this.disabilitycard_temp[k] = "";
				});*/
			} else {
				this.refresh();
			}
		},
		refresh() {
			this.mode = "read";
			this.clear();
			this.getFront();
			this.getBack();
			disabilityCardRest
				.read(this.pk)
				.then((res) => {
					_.each(this.disabilitycard, (v, k) => {
						this.disabilitycard[k] = res.data[k];
						this.disabilitycard_temp[k] = res.data[k];
					});
				})
				.catch((res) => {
					this.mode = "create";
					this.$errorHandler({ error: res });
				});
		},
		getFront() {
			disabilityCardAction.getFront(this.pk).then((response) => {
				let url = URL.createObjectURL(new Blob([response]));
				this.front = url;
			});
		},
		getBack() {
			disabilityCardAction.getBack(this.pk).then((response) => {
				let url = URL.createObjectURL(new Blob([response]));
				this.back = url;
			});
		},
		active(status) {
			this.disabilitycard.is_active = this.disabilitycard_temp.is_active = status;
			disabilityCardRest
				.partialupdate(this.pk, { is_active: status })
				.then(() => {
					if (status) {
						this.$root.$message.open({ status: 200, message: "成功啟用手冊" });
					} else if (!status) {
						this.$root.$message.open({ status: 200, message: "成功停用手冊" });
					}
				})
				.catch((err) => {
					this.$errorHandler({ error: err });
				});
		},
		checkInput() {
			let err = [];
			const data = this.disabilitycard_temp;
			const rdc = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ][0-9]{9}$/;
			if (!rdc.test(data.identity_card_number)) {
				err.push("身份證格式錯誤，需為首位大寫字母+9位數字");
			}
			_.each(this.model, (v, k) => {
				if (v.required && data[k] === "") {
					err.push(`${v.label}為必填`);
				}
			});
			return err;
		},
		create() {
			const err = this.checkInput();
			if (err.length > 0) {
				this.$root.$message.open({ status: 400, message: err });
				return -1;
			}
			this.disabilitycard_temp.is_active = "false";
			if (this.page === "person") {
				const owner_id = this.owner_id === 0 ? this.user.id : this.owner_id;
				this.disabilitycard_temp.owner = owner_id;
			}
			disabilityCardAction
				.createUpdate(this.disabilitycard_temp)
				.then((res) => {
					this.pk = res.data.identity_card_number;
					return this.upload();
				})
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功新建手冊" });
					this.refresh();
				})
				.catch((res) => {
					this.$errorHandler({ error: res });
				});
		},
		update() {
			this.disabilitycard = _.clone(this.disabilitycard_temp);
			const err = this.checkInput();
			if (err.length > 0) {
				this.$root.$message.open({ status: 400, message: err });
				return -1;
			}
			this.disabilitycard_temp.is_active = "false";
			if (this.page === "person") {
				const owner_id = this.owner_id === 0 ? this.user.id : this.owner_id;
				this.disabilitycard_temp.owner = owner_id;
			}
			disabilityCardAction
				.createUpdate(this.disabilitycard_temp)
				.then(() => {
					return this.upload();
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "成功修改手冊資料",
					});
					this.mode = "read";
					this.refresh();
				})
				.catch((res) => {
					this.$errorHandler({ error: res });
				});
		},
		cancel() {
			this.disabilitycard_temp = _.clone(this.disabilitycard);
			this.mode = "read";
			this.clear();
		},
		clear() {
			this.local_front = null;
			this.local_back = null;
			this.local_front_url = null;
			this.local_back_url = null;
		},
		changeFront(e) {
			const file = e.target.files[0];
			this.local_front = file;
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				//this.local_front_url = URL.createObjectURL(new Blob(e.target.result));
				this.local_front_url = URL.createObjectURL(file);
			};
		},
		changeBack(e) {
			const file = e.target.files[0];
			this.local_back = file;
			const reader = new FileReader();

			reader.readAsDataURL(file);
			reader.onload = () => {
				//this.local_back_url = URL.createObjectURL(new Blob(e.target.result));
				this.local_back_url = URL.createObjectURL(file);
			};
		},
		upload() {
			let frontAPI = null;
			if (this.local_front) {
				frontAPI = disabilityCardAction.postFront(this.pk, this.local_front);
			}
			let backAPI = null;
			if (this.local_back) {
				backAPI = disabilityCardAction.postBack(this.pk, this.local_back);
			}
			if (frontAPI && backAPI) {
				return Promise.all([frontAPI, backAPI])
					.then(() => {
						this.$root.$message.open({ status: 200, message: "成功上傳手冊" });
					})
					.catch((res) => {
						this.$errorHandler({ error: res });
					});
			} else if (frontAPI) {
				return Promise.all([frontAPI])
					.then(() => {
						this.$root.$message.open({
							status: 200,
							message: "成功上傳手冊正面",
						});
					})
					.catch((res) => {
						this.$errorHandler({ error: res });
					});
			} else if (backAPI) {
				return Promise.all([backAPI])
					.then(() => {
						this.$root.$message.open({
							status: 200,
							message: "成功上傳手冊反面",
						});
					})
					.catch((res) => {
						this.$errorHandler({ error: res });
					});
			} else {
				return Promise.resolve();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.img-thumbnail,
.img-thumbnail-input {
	margin-top: 0.3em;
	margin-bottom: 0.3em;
}
.img-thumbnail {
	margin-top: 0.5em;
}
.img-thumbnail-input {
	max-width: 100%;
}
</style>
