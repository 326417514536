<template>
	<div class="any-about-page container this-is-development">
		<h2 class="big-title">開發資訊</h2>
		<p>維護中</p>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "開發資訊"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
</style>
