<template>
	<div>
		<h2 v-focus tabindex="0" class="sr-only">法鼓文化</h2>
		<img src="@/assets/ddm/ddm_culture.jpg" alt="法鼓山文化" />
		<bookinfo-repository
			:data="{ datas: bookinfo_datas, header: bookinfo_columns }"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { bookInfoRest } from "@/api/ebookSystem.js";
import bookinfoRepository from "../components/bookinfoRepository.vue";

export default {
	components: {
		bookinfoRepository
	},
	data() {
		return {
			bookinfo_datas: []
		};
	},
	computed: {
		...mapState("user", ["user"]),
		bookinfo_columns() {
			if (this.user.auth_guest) {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					order: "版次",
					author: "作者",
					house: "出版社",
					date: "出版日期",
					action: "動作"
				};
			} else {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					order: "版次",
					author: "作者",
					house: "出版社",
					date: "出版日期"
				};
			}
		}
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			const query = { index_category_id: 159 };
			bookInfoRest.filter(query).then(res => {
				let filter_data = [];
				_.each(res.data, o => {
					filter_data.push({
						ISBN: o["ISBN"],
						bookname: o["bookname"],
						bookbinding: o["bookbinding"],
						order: o["order"],
						author: o["author"],
						house: o["house"],
						date: o["date"],
						action: o["ISBN"]
					});
				});
				this.bookinfo_datas = filter_data;
			});
		}
	}
};
</script>

<style lang="scss" scoped>
img {
	width: 100%;
}
</style>
