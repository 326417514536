import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { host } from "@/api/base.js";
import { logsystemFactory } from "@/utils/logsystem";
import { logRest } from "@/api/logsystem";

import layout from "@/components/layout";
import layoutFull from "@/components/layoutFull";

import home from "@/components/home";
import siteMap from "@/components/siteMap";

import user from "@/components/user/user";
import person from "@/components/user/person/person.vue";
import login from "@/components/user/login";
import register from "@/components/user/register";
import retrieve from "@/components/user/retrieve";

import platform from "@/components/platform/platform";
import announcement from "@/components/platform/announcement";
import announcementCreate from "@/components/platform/announcementCreate";
import announcementList from "@/components/platform/announcementList";
import bannercontentManager from "@/components/platform/bannercontentManager";
import indexCategoryManager from "@/components/platform/indexCategoryManager";
import ddm from "@/components/platform/ddmCategory";
import ddmCulture from "@/components/platform/ddmCulture";
import ddmCulture2 from "@/components/platform/ddmCulture2";
import heart from "@/components/platform/heart";
import fisfisa from "@/components/platform/fisfisa";
import qandaPage from "@/components/platform/qandaPage";
import sendUserEmail from "@/components/platform/sendUserEmail";
import statisticsConditionPage from "@/components/platform/statisticsConditionPage";
import statisticsMonthPage from "@/components/platform/statisticsMonthPage";
import statisticsMonth from "@/components/platform/components/statisticsMonth";
import volunteer from "@/components/platform/volunteer";

import manage from "@/components/manage/manage";
import manageItem from "@/components/manage/item";
import manageItemSchool from "@/components/manage/itemSchool";
import bookManagerPage from "@/components/manage/bookManagerPage";
import bookEdit from "@/components/book/bookEdit";
import bookOrderPage from "@/components/manage/bookOrderPage";
import bookReviewListPage from "@/components/manage/bookReviewListPage";
import categoryManagerPage from "@/components/manage/categoryManagerPage";
import disabilitycardManagerPage from "@/components/manage/disabilitycardManagerPage";
import ebookReviewListPage from "@/components/manage/ebookReviewListPage";
import serviceinfoListCheckPage from "@/components/manage/serviceinfoListCheckPage";
import userManagerPage from "@/components/manage/userManagerPage";

import book from "@/components/book/book";
import bookDetail from "@/components/book/bookDetail";
import bookGen from "@/components/book/bookGen";
import bookPerson from "@/components/book/bookPerson";
import bookRepository from "@/components/book/bookRepository";
import bookRepositorySchool from "@/components/book/bookRepositorySchool";
import bookRepositoryCategorySchool from "@/components/book/bookRepositoryCategorySchool";
import bookRepositoryOrgSchool from "@/components/book/bookRepositoryOrgSchool";
import bookReview from "@/components/book/bookReview";
import bookShelf from "@/components/book/bookShelf";
import ebookEdit from "@/components/book/ebookEdit";
import ebookReview from "@/components/book/ebookReview";

import about from "@/components/about/about";
import contact from "@/components/about/contact";
import copyright from "@/components/about/copyright";
import development from "@/components/about/development";
import editRules from "@/components/about/editRules";
import origin from "@/components/about/origin";
import privacyAndSecurity from "@/components/about/privacyAndSecurity";
import recruit from "@/components/about/recruit";
import serviceHoursDescription from "@/components/about/serviceHoursDescription";
import termsOfService from "@/components/about/termsOfService";
import userGuide from "@/components/about/userGuide";

import service from "@/components/editor/service";
import serviceRecord from "@/components/editor/serviceRecord";

import ebookSystem from "@/components/admin/ebookSystem";
import genericUser from "@/components/admin/genericUser";
import qandaDetail from "@/components/admin/qandaDetail";
import qandaList from "@/components/admin/qandaList";
import RecommendationSubjectDetail from "@/components/admin/RecommendationSubjectDetail";
import RecommendationSubjectList from "@/components/admin/RecommendationSubjectList";

import video from "@/components/live/index";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "layout",
		meta: {
			label: "首頁"
		},
		component: layout,
		redirect: "/",
		children: [
			{
				path: "",
				name: "home",
				meta: {
					label: "首頁"
				},
				component: home
			},
			{
				path: "siteMap",
				name: "siteMap",
				meta: {
					label: "網站導覽"
				},
				component: siteMap
			},
			{
				path: "user",
				name: "user",
				meta: {
					label: "會員專區"
				},
				component: user,
				redirect: v => {
					return v.path + "/person";
				},
				children: [
					{
						path: "person",
						name: "person",
						meta: {
							label: "會員資料",
							permission: ["login"]
						},
						component: person
					},
					{
						path: "login",
						name: "login",
						meta: {
							label: "登入會員"
						},
						component: login
					},
					{
						path: "register",
						name: "register",
						meta: {
							label: "註冊會員"
						},
						component: register
					},
					{
						path: "retrieve",
						name: "retrieve",
						meta: {
							label: "忘記帳密"
						},
						component: retrieve
					},
					{
						path: "auth-contact",
						name: "verify",
						meta: {
							label: "通訊資料驗證"
						},
						component: () => import("../components/user/verify.vue")
					},
					{
						path: "reset-password",
						name: "verify_reset_password",
						meta: {
							label: "重設密碼"
						},
						component: () => import("../components/user/resetPassword.vue")
					}
				]
			},
			{
				path: "platform",
				name: "platform",
				meta: {
					label: "平台專區"
				},
				component: platform,
				redirect: v => {
					return v.path + "/announcementList";
				},
				children: [
					{
						path: "announcementCreate",
						name: "announcementCreate",
						meta: {
							label: "公告發佈",
							permission: ["is_manager"]
						},
						component: announcementCreate
					},
					{
						path: "announcement/:id",
						name: "announcement",
						meta: {
							label: "公告內容"
						},
						component: announcement
					},
					{
						path: "announcementList",
						name: "announcementList",
						meta: {
							label: "公告列表"
						},
						component: announcementList
					},
					{
						path: "bannercontentManager",
						name: "bannercontentManager",
						meta: {
							label: "管理首頁 Banner",
							permission: ["is_manager"]
						},
						component: bannercontentManager
					},
					{
						path: "indexCategoryManager",
						name: "indexCategoryManager",
						meta: {
							label: "管理索引分類",
							permission: ["is_supermanager"]
						},
						component: indexCategoryManager
					},
					{
						path: "ddm",
						name: "ddm",
						meta: {
							label: "法鼓全集"
						},
						component: ddm
					},
					{
						path: "ddmCulture2",
						name: "ddmCulture2",
						meta: {
							label: "法鼓山出版書籍"
						},
						component: ddmCulture2
					},
					{
						path: "ddmCulture",
						name: "ddmCulture",
						meta: {
							label: "法鼓文化"
						},
						component: ddmCulture
					},

					{
						path: "heart",
						name: "heart",
						meta: {
							label: "用心看世界"
						},
						component: heart
					},
					{
						path: "fisfisa",
						name: "fisfisa",
						meta: {
							label: "目宿媒體"
						},
						component: fisfisa
					},
					{
						path: "qandaPage",
						name: "qandaPage",
						meta: {
							label: "問與答"
						},
						component: qandaPage
					},
					{
						path: "sendUserEmail",
						name: "sendUserEmail",
						meta: {
							label: "傳送訊息",
							permission: ["is_supermanager"]
						},
						component: sendUserEmail
					},
					{
						path: "statisticsSummary",
						name: "statisticsSummary",
						meta: {
							label: "統計摘要"
						},
						component: () =>
							import("../components/platform/statisticsSummary.vue")
					},
					{
						path: "rawConditionPage/ebookStatusEventlog",
						name: "rawConditionEbookStatusEventlogSuper",
						meta: {
							label: "校對事件紀錄"
						},
						component: () =>
							import("../components/platform/rawConditionPage.vue"),
						props: {
							mode: "supermanager",
							title: "校對事件紀錄",
							url: `${host}api/raw/ebook_status_eventlog/`
						}
					},
					{
						path: "statisticsConditionPage/bookRead",
						name: "statisticsConditionBookReadSuper",
						meta: {
							label: "書籍閱讀統計"
						},
						component: statisticsConditionPage,
						props: {
							mode: "supermanager",
							title: "書籍閱讀統計",
							url: `${host}api/statistics/book_read/`,
							instance_header: {
								order: "項次",
								bookname: "書名",
								author: "作者",
								house: "出版社",
								date: "出版日期",
								upload_date: "上傳時間",
								library: "借閱",
								download: "下載"
							},
							instance_callback: {
								bookname: v => v.bookname,
								author: v => v.author
							}
						}
					},
					{
						path: "statisticsConditionPage/serviceinfo",
						name: "statisticsConditionServiceinfoSuper",
						meta: {
							label: "志工服務時數統計"
						},
						component: statisticsConditionPage,
						props: {
							mode: "supermanager",
							title: "志工服務時數統計",
							url: `${host}api/statistics/serviceinfo/`,
							instance_header: {
								order: "項次",
								username: "姓名",
								email: "信箱",
								phone: "電話",
								count: "時數"
							},
							instance_callback: {}
						}
					},
					{
						path: "statisticsConditionPage/userRead",
						name: "statisticsConditionUserReadSuper",
						meta: {
							label: "視障者閱讀統計"
						},
						component: statisticsConditionPage,
						props: {
							mode: "supermanager",
							title: "視障者閱讀統計",
							url: `${host}api/statistics/user_read/`,
							instance_header: {
								order: "項次",
								user: "姓名",
								join: "註冊日",
								library: "借閱",
								download: "下載"
							},
							instance_callback: {}
						}
					},
					{
						path: "statisticsMonthPage",
						name: "statisticsMonth",
						meta: {
							label: "統計"
						},
						component: statisticsMonthPage,
						children: [
							{
								path: "bookDownload",
								name: "statisticsMonthBookDownload",
								meta: {
									label: "統計書籍下載"
								},
								component: statisticsMonth,
								props: {
									page: "bookDownload",
									params: {}
								}
							},
							{
								path: "bookDownload/epub",
								name: "statisticsMonthBookDownloadEpub",
								meta: {
									label: "統計書籍下載(epub)"
								},
								component: statisticsMonth,
								props: {
									page: "bookDownload",
									params: { file_format: "epub" }
								}
							},
							{
								path: "bookDownload/txt",
								name: "statisticsMonthBookDownloadTxt",
								meta: {
									label: "統計書籍下載(txt)"
								},
								component: statisticsMonth,
								props: {
									page: "bookDownload",
									params: { file_format: "txt" }
								}
							},
							{
								path: "userDownload",
								name: "statisticsMonthUserDownload",
								meta: {
									label: "統計使用者下載"
								},
								component: statisticsMonth,
								props: {
									page: "userDownload",
									params: {}
								}
							},
							{
								path: "userEditrecord",
								name: "statisticsMonthUserEditrecord",
								meta: {
									label: "統計使用者校對"
								},
								component: statisticsMonth,
								props: {
									page: "userEditrecord",
									params: {}
								}
							}
						]
					},
					{
						path: "volunteer",
						name: "volunteer",
						meta: {
							label: "志工專區"
						},
						component: volunteer
					}
				]
			},
			{
				path: "manage",
				name: "manage",
				meta: {
					label: "管理專區"
				},
				component: manage,
				redirect: v => {
					return v.path + "/item";
				},
				children: [
					{
						path: "item",
						name: "manageItem",
						meta: {
							label: "平台管理"
						},
						component: manageItem
					},
					{
						path: "itemSchool",
						name: "manageItemSchool",
						meta: {
							label: "校園管理"
						},
						component: manageItemSchool
					},
					{
						path: "bookManagerSuper",
						name: "bookManagerSuper",
						meta: {
							label: "書籍全單位管理",
							keepAlive: true,
							permission: ["is_supermanager"]
						},
						component: bookManagerPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "bookManager",
						name: "bookManager",
						meta: {
							label: "書籍管理",
							keepAlive: true,
							permission: ["is_manager"]
						},
						component: bookManagerPage,
						props: {
							mode: "manager"
						}
					},
					{
						path: "bookOrder",
						name: "bookOrder",
						meta: {
							label: "書籍校對順序"
						},
						component: bookOrderPage
					},
					{
						path: "bookReviewListSuper",
						name: "bookReviewListSuper",
						meta: {
							label: "上傳書籍審核",
							permission: ["is_supermanager"]
						},
						component: bookReviewListPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "bookReviewList",
						name: "bookReviewList",
						meta: {
							label: "上傳書籍審核",
							permission: ["is_manager"]
						},
						component: bookReviewListPage,
						props: {
							mode: "manager"
						}
					},
					{
						path: "categoryManagerSuper",
						name: "categoryManagerSuper",
						meta: {
							label: "書籍類型全單位管理",
							permission: ["is_supermanager"]
						},
						component: categoryManagerPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "categoryManager",
						name: "categoryManager",
						meta: {
							label: "書籍類型管理",
							permission: ["is_manager"]
						},
						component: categoryManagerPage,
						props: {
							mode: "manager"
						}
					},
					{
						path: "disabilitycardManagerSuper",
						name: "disabilitycardManagerSuper",
						meta: {
							label: "身障手冊全單位管理",
							permission: ["is_supermanager"]
						},
						component: disabilitycardManagerPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "disabilitycardManager",
						name: "disabilitycardManager",
						meta: {
							label: "身障手冊管理",
							permission: ["is_manager"]
						},
						component: disabilitycardManagerPage,
						props: {
							mode: "manager"
						}
					},
					{
						path: "ebookReviewListSuper",
						name: "ebookReviewListSuper",
						meta: {
							label: "校對文件審核",
							permission: ["is_supermanager"]
						},
						component: ebookReviewListPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "ebookReviewList",
						name: "ebookReviewList",
						meta: {
							label: "校對文件審核",
							permission: ["is_manager"]
						},
						component: ebookReviewListPage,
						props: {
							mode: "manager"
						}
					},
					{
						path: "serviceinfoListCheckSuper",
						name: "serviceinfoListCheckSuper",
						meta: {
							label: "服務時數確認",
							permission: ["is_supermanager"]
						},
						component: serviceinfoListCheckPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "serviceinfoListCheck",
						name: "serviceinfoListCheck",
						meta: {
							label: "服務時數確認",
							permission: ["is_manager"]
						},
						component: serviceinfoListCheckPage,
						props: {
							mode: "manager"
						}
					},
					{
						path: "userManagerSuper",
						name: "userManagerSuper",
						meta: {
							label: "使用者全單位管理",
							permission: ["is_supermanager"]
						},
						component: userManagerPage,
						props: {
							mode: "supermanager"
						}
					},
					{
						path: "userManager",
						name: "userManager",
						meta: {
							label: "書籍管理",
							keepAlive: true,
							permission: ["is_manager"]
						},
						component: userManagerPage,
						props: {
							mode: "manager"
						}
					}
				]
			},
			{
				path: "book",
				name: "book",
				meta: {
					label: "書籍平台"
				},
				component: book,
				redirect: v => {
					return v.path + "/bookRepository";
				},
				children: [
					{
						path: "bookCreate",
						name: "bookCreate",
						meta: {
							label: "掃描檔上傳",
							permission: ["auth_guest", "is_supermanager", "is_manager"]
						},
						component: bookGen,
						props: {
							mode: "create"
						}
					},
					{
						path: "bookUpload",
						name: "bookUpload",
						meta: {
							label: "電子檔上傳",
							permission: ["is_supermanager"]
						},
						component: bookGen,
						props: {
							mode: "upload"
						}
					},
					{
						path: "bookDetail/:id",
						name: "bookDetail",
						meta: {
							label: "書籍詳細"
						},
						component: bookDetail
					},
					{
						path: "bookEdit/:isbn",
						name: "bookEdit",
						meta: {
							label: "書籍資料管理",
							permission: ["is_manager"]
						},
						component: bookEdit
					},
					{
						path: "bookPerson",
						name: "bookPerson",
						meta: {
							label: "校對進度",
							permission: ["auth_guest"]
						},
						component: bookPerson
					},
					{
						path: "bookRepository",
						name: "bookRepository",
						meta: {
							label: "平台書庫"
						},
						component: bookRepository,
						redirect: v => {
							return v.path + "/recommend/newest";
						},
						children: [
							{
								path: "recommend/:idx",
								name: "bookRepositoryRecommend",
								meta: {
									label: "推薦"
								},
								component: () =>
									import("../components/book/bookRepositoryRmd.vue")
							},
							{
								path: "announcement",
								name: "bookRepositoryAnnouncement",
								meta: {
									label: "書訊"
								},
								component: () =>
									import("../components/book/bookRepositoryAnnouncement.vue")
							},
							{
								path: "index/:idx",
								name: "bookRepositoryIndex",
								meta: {
									label: "索引"
								},
								component: () =>
									import("../components/book/bookRepositoryIdx.vue")
							},
							{
								path: "media",
								name: "bookRepositoryMedia",
								meta: {
									label: "有聲資源"
								},
								component: () =>
									import("../components/book/bookRepositoryMedia.vue")
							},
							{
								path: "bookSearch",
								name: "bookInfoSearch",
								meta: {
									label: "搜尋"
								},
								component: () =>
									import("../components/book/bookRepositorySearch.vue")
							}
						]
					},
					{
						path: "bookRepositorySchool",
						name: "bookRepositorySchool",
						meta: {
							label: "平台書庫"
						},
						component: bookRepositorySchool,
						children: [
							{
								path: "category/:categoryId",
								name: "bookRepositorySchoolCategory",
								component: bookRepositoryCategorySchool
							},
							{
								path: "org/:orgId",
								name: "bookRepositorySchoolOrg",
								component: bookRepositoryOrgSchool
							}
						]
					},
					{
						path: "bookRepositoryDetail/:id",
						name: "bookRepositoryDetail",
						meta: {
							label: "書籍詳目"
						},
						component: () =>
							import("@/components/book/bookRepositoryDetail.vue")
					},
					{
						path: "bookReview/:id",
						name: "bookReview",
						meta: {
							label: "書籍審核",
							permission: ["is_manager"]
						},
						component: bookReview
					},
					{
						path: "bookShelf",
						name: "bookShelf",
						meta: {
							label: "我的書櫃",
							permission: ["auth_guest"]
						},
						component: bookShelf
					},
					{
						path: "ebookReview/:id",
						name: "ebookReview",
						meta: {
							label: "校對審核",
							permission: ["is_manager"]
						},
						component: ebookReview
					},
					{
						path: "promotion",
						name: "promotion",
						meta: {
							label: "主題選書"
						},
						component: () => import("@/components/recommend.vue")
					}
				]
			},
			{
				path: "editor",
				name: "editor",
				meta: {
					label: "志工服務"
				},
				component: user,
				redirect: v => {
					return v.path + "/service";
				},
				children: [
					{
						path: "service",
						name: "service",
						meta: {
							label: "校對服務",
							permission: ["auth_editor"]
						},
						component: service
					},
					{
						path: "serviceRecord",
						name: "serviceRecord",
						meta: {
							label: "服務紀錄"
						},
						component: serviceRecord
					}
				]
			},
			{
				path: "about",
				name: "about",
				meta: {
					label: "關於資訊"
				},
				component: about,
				redirect: v => {
					return v.path + "/origin";
				},
				children: [
					{
						path: "contact",
						name: "contact",
						meta: {
							label: "聯絡資訊"
						},
						component: contact
					},
					{
						path: "copyright",
						name: "copyright",
						meta: {
							label: "著作權法"
						},
						component: copyright
					},
					{
						path: "development",
						name: "development",
						meta: {
							label: "開發資訊"
						},
						component: development
					},
					{
						path: "editRules",
						name: "editRules",
						meta: {
							label: "校對規則說明"
						},
						component: editRules
					},
					{
						path: "origin",
						name: "origin",
						meta: {
							// (Useless) Old name.
							// label: '關於雲端千眼',
							label: "平台濫觴"
						},
						component: origin
					},
					{
						path: "privacyAndSecurity",
						name: "privacyAndSecurity",
						meta: {
							label: "隱私權及資訊安全政策"
						},
						component: privacyAndSecurity
					},
					{
						path: "recruit",
						name: "recruit",
						meta: {
							label: "招募志工"
						},
						component: recruit
					},
					{
						path: "serviceHoursDescription",
						name: "serviceHoursDescription",
						meta: {
							label: "服務計算說明"
						},
						component: serviceHoursDescription
					},
					{
						path: "termsOfService",
						name: "termsOfService",
						meta: {
							label: "服務條款"
						},
						component: termsOfService
					},
					{
						path: "userGuide",
						name: "userGuide",
						meta: {
							label: "平台使用指南"
						},
						component: userGuide
					}
				]
			},
			{
				path: "ebookSystem",
				name: "ebookSystem",
				meta: {
					label: "ebookSystem"
				},
				component: ebookSystem,
				children: []
			},
			{
				path: "genericUser/admin",
				name: "genericUser",
				meta: {
					label: "genericUser"
				},
				component: genericUser,
				children: [
					{
						path: "qandaList",
						name: "qandaList",
						meta: {
							label: "問與答列表"
						},
						component: qandaList,
						props: {
							instance_header: {
								id: "序號",
								name: "名稱",
								action: "動作"
							},
							instance_callback: {
								id: v => v.id,
								name: v => v.question,
								action: v => v.id
								/*question: v => {
									const converter = new showdown.Converter();
									const html = converter.makeHtml(v.question);
									return html;
								},
								answer: v => {
									const converter = new showdown.Converter();
									const html = converter.makeHtml(v.answer);
									return html;
								},*/
							},
							instance_batch_options: [
								{
									value: "delete",
									display_name: "刪除"
								}
							]
						}
					},
					{
						path: "qandaDetail/:id",
						name: "qandaDetail",
						meta: {
							label: "qanda detail"
						},
						component: qandaDetail
					},
					{
						path: "RecommendationSubjectList",
						name: "RecommendationSubjectList",
						meta: {
							label: "推薦主題列表"
						},
						component: RecommendationSubjectList,
						props: {
							instance_header: {
								id: "序號",
								title: "主題",
								action: "動作"
							},
							instance_callback: {
								id: v => v.id,
								title: v => v.title,
								action: v => v.id
							},
							instance_batch_options: [
								{
									value: "delete",
									display_name: "刪除"
								}
							]
						}
					},
					{
						path: "RecommendationSubjectDetail/:id",
						name: "RecommendationSubjectDetail",
						meta: {
							label: "推薦主題詳細"
						},
						component: RecommendationSubjectDetail
					}
				]
			}
		]
	},
	{
		path: "/video",
		name: "video",
		component: video
	},
	{
		path: "/full/",
		name: "layoutFull",
		meta: {
			label: "首頁"
		},
		component: layoutFull,
		children: [
			{
				path: "ebookEdit/:id",
				name: "ebookEdit",
				meta: {
					label: "編輯",
					permission: ["auth_editor"]
				},
				component: ebookEdit
			}
		]
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.afterEach(async (to, from) => {
	store.commit("token/calculateAccessExpCountdown");
	store.commit("token/calculateRefreshExpCountdown");
	if (store.state.token.accessExpCountdown > 10000) {
	} else if (store.state.token.refreshExpCountdown > 0) {
		store.dispatch("token/refresh").then(() => {
			store.commit("token/calculateAccessExpCountdown");
			store.commit("token/calculateRefreshExpCountdown");
		});
	}

	let pageName = to.name;
	if (process.env.NODE_ENV === "production") {
		const logsystem = logsystemFactory({ to, from, rest: logRest });
		const logRec = logsystem({
			category: "browser",
			action: "router",
			object: pageName
		});
	}
});

export default router;
