var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"btnn-group is-margin-y is-justify-content-start"},[_c('div',{staticClass:"layer-2"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.save()}}},[_vm._v("儲存")])])]),_vm._l(([
			'is_hot',
			'is_active',
			'is_manager',
			'is_editor',
			'is_guest',
			'auth_email',
			'auth_phone'
		]),function(v){return [_c('formdrf',{key:v,attrs:{"model_info":_vm.permissionModel[v],"field":v},model:{value:(_vm.permissionData[v]),callback:function ($$v) {_vm.$set(_vm.permissionData, v, $$v)},expression:"permissionData[v]"}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }