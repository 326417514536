var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"genericUser_register"}},[_c('div',{staticClass:"form-group text-center"},[_c('h2',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"tabindex":"0"}},[_vm._v("註冊")])]),_c('div',{staticClass:"form-horizontal",staticStyle:{"margin-top":"30px"}},[_vm._l(([
				'username',
				'password',
				'confirm_password',
				'name',
				'email',
				'phone',
				'birthday',
				'education',
				'is_book',
				'org',
				'role',
				'userterms',
				'termscheck'
			]),function(v){return [_c('formdrf',{key:v,attrs:{"model_info":_vm.model_infos[v],"field":v},model:{value:(_vm.infos[v]),callback:function ($$v) {_vm.$set(_vm.infos, v, $$v)},expression:"infos[v]"}})]}),_c('div',{staticStyle:{"text-align":"center"}},[_c('input',{staticClass:"btn btn-primary",attrs:{"type":"button","value":"註冊"},on:{"click":function($event){return _vm.checkInput()}}}),_c('input',{staticClass:"btn btn-danger",attrs:{"type":"reset","value":"清除"},on:{"click":function($event){return _vm.clearInput()}}})])],2),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label col-sm-2 col-sm-offset-2"})])}]

export { render, staticRenderFns }