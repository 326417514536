export const checkItemPermission = (u, p) => {
	/*if (u.is_supermanager === true) {
		return true;
	}*/
	if (p.includes("true")) {
		return true;
	}
	if (p.includes("false")) {
		return false;
	}
	if (p.includes("anonymous")) {
		// If not yet login?
		if (!u || !u.id) {
			return true;
		} else {
			return false;
		}
	}
	// If already login?
	if (u && u.id) {
		if (p.includes("login")) {
			return true;
		}
		const roles = [
			"is_editor",
			"is_guest",
			"auth_editor",
			"auth_guest",
			"is_manager",
			"is_supermanager",
			"is_superuser"
		];
		const pset = new Set(p)
		const uset = new Set(roles.filter(k => u[k]))
		const intersect = new Set([...pset].filter(i => uset.has(i)));
		return intersect.size > 0?true :false
	} else {
		return false;
	}
};
