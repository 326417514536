<template>
	<div class="row">
		<div :class="imageClass">
			<eBookViewer
				ref="viewer"
				:pk="pk"
				:images="image"
				:edited_page="Number(old_edited_page)"
				:height="viewerHeight"
				@changed="changePage"
			></eBookViewer>

			<hr />
			<div class="scroll-height" @mousedown="startAdjustHeight($event)">
				<FontAwesomeIcon icon="angle-double-down"></FontAwesomeIcon>
			</div>
		</div>
		<div :class="textClass">
			<Editor
				v-model="current_editrecord.edit"
				:init="tinymceInit"
				@input="editorChange"
			></Editor>
		</div>
	</div>
</template>

<script>
import "tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";

import "tinymce/themes/silver/theme.min.js";
import "tinymce/icons/default/icons";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.inline.min.css";
import "tinymce/plugins/table";
import "tinymce/plugins/searchreplace";

import eBookViewer from "@/components/book/viewer";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";
import { ebookRest, ebookAction } from "@/api/ebookSystem.js";
import { addMark } from "@/utils/ebookEdit";

function beforeunloadFun(event) {
	event.returnValue = "Are you sure you want to leave?";
}

export default {
	components: {
		eBookViewer,
		Editor,
		FontAwesomeIcon
	},
	data() {
		return {
			leave: false,
			timer: null,
			pk: this.$route.params.id,
			old_edited_page: "0",
			edited_page: "0",
			image: {},
			imgSize: 100,
			idel_min: 0,
			change_count: 0,
			current_editrecord: {
				finish: "",
				edit: ""
			},
			textClass: "col-md-6 col-sm-12",
			imageClass: "col-md-6 col-sm-12",
			old_position: {},
			new_position: {},
			isMousedown: false,
			viewerHeight: 455,
			content: "",
			tinymceInit: {},
			publicPath: process.env.BASE_URL,
			options: {}
		};
	},
	mounted() {
		this.reloadPage();
		this.timer = setInterval(() => {
			this.recordPerMins();
			this.detectIdel();
		}, 60000);
		window.addEventListener("beforeunload", beforeunloadFun);
	},
	created() {
		const self = this;
		const preEditorHeight = localStorage.getItem("book_editor_height") || 400;
		const preViewerHeight = localStorage.getItem("viewer_height");

		if (preViewerHeight) {
			this.viewerHeight = Math.round(preViewerHeight);
		}

		this.tinymceInit = {
			skin_url: `${this.publicPath}tinymce/skins/ui/oxide`,
			content_css: `${this.publicPath}tinymce/skins/content/default/content.css`,
			language_url: `${this.publicPath}tinymce/langs/zh_TW.js`,
			language: "zh_TW",
			plugins: " table searchreplace ",
			menubar: false, // 隐藏最上方menu
			toolbar1: "標記 | 載入全文	| 存檔 | 完成 | 關閉 | 切換版型 | 還原校對內容",
			toolbar2:
				"undo redo | cut copy paste | bullist numlist | table | searchreplace | fontsizeselect ",
			fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
			height: preEditorHeight,

			setup: editor => {
				editor.on("init", function() {
					editor.getBody().style.fontSize = "14pt";
				});
				editor.on("ResizeEditor", function(e) {
					const iframeHeight = e.target.editorContainer.clientHeight;
					localStorage.setItem("book_editor_height", Math.round(iframeHeight));
				});

				editor.ui.registry.addButton("載入全文", {
					text: "載入全文",
					onAction: function() {
						ebookAction
							.updateEdit(self.pk, {
								type: "load",
								finish: "",
								edit: "",
								page: ""
							})
							.then(() => {
								// success alert
								self.$root.$message.open({
									status: 200,
									message: "成功載入全文"
								});
								localStorage.removeItem("ebookEdit");
								self.reloadPage();
							})
							.catch(res => {
								this.$errorHandler({ error: res });
							});
					}
				});

				editor.ui.registry.addMenuButton("標記", {
					text: "標記",
					fetch: function(callback) {
						const items = [
							{
								type: "menuitem",
								text: "圖片標記",
								onAction: function() {
									const message = `<p>
										<img id="${self.edited_page}" alt="this is a picture" height="42" width="42">
									</p>`;
									let result = addMark(message, editor);
									if (!result) {
										this.$root.$message.open({
											status: 400,
											message: "請勿於空白行插入標記"
										});
									}
								}
							},
							{
								type: "menuitem",
								text: "未知標記",
								onAction: function() {
									const message = `<span 
										id="${self.edited_page}"
										class="unknown" 
										style="color: red;" 
									>{???}</span>`;
									editor.insertContent(message);
								}
							},
							/*{
								type: "menuitem",
								text: "數學標記",
								onAction: function() {
									const message = `<p>
										<span 
											id="${self.edited_page}"
											class="mathml" 
											style="color: blue;" 
										>mathml</span>
									</p>`;
									let result = addMark(message, editor);
									if(!result){
										this.$root.$message.open({status: 400, message: "請勿於空白行插入標記"})
									}
								}
							},*/
							{
								type: "menuitem",
								text: "進度標記",
								onAction: function() {
									const message = "<p>|----------|</p>";
									let result = addMark(message, editor);
									if (!result) {
										self.$root.$message.open({
											status: 400,
											message: "請勿於空白行插入標記"
										});
									}
								}
							}
						];
						callback(items);
					}
				});

				editor.ui.registry.addButton("存檔", {
					text: "存檔",
					onAction: function() {
						if (editor.getContent().indexOf("<p>|----------|</p>") < 0) {
							self.$root.$message.open({
								status: 400,
								message:
									"未存檔成功，您提交的內容未包含特殊標記，無法得知校對進度，若已全數完成請按下完成按紐"
							});
							return -1;
						}

						editor.save();

						ebookAction
							.updateEdit(self.pk, {
								type: "save",
								finish: self.current_editrecord.finish,
								edit: self.current_editrecord.edit,
								page: self.edited_page
							})
							.then(() => {
								// success alert
								self.$root.$message.open({
									status: 200,
									message: "成功儲存文件"
								});
								localStorage.removeItem("ebookEdit");
								self.reloadPage();
							})
							.catch(res => {
								this.$errorHandler({ error: res });
							});
					}
				});

				editor.ui.registry.addButton("完成", {
					text: "完成",
					onAction: function() {
						if (editor.getContent().indexOf("<p>|----------|</p>") > 0) {
							self.$root.$message.open({
								status: 400,
								message:
									"未存檔成功，您提交的內容包含特殊標記，若已完成請將內容中之特殊標記刪除，若未全數完成請按下存檔按紐"
							});
							return -1;
						}

						editor.save();
						ebookAction
							.updateEdit(self.pk, {
								type: "finish",
								finish: self.current_editrecord.finish,
								edit: self.current_editrecord.edit,
								page: self.edited_page
							})
							.then(() => {
								// success alert
								self.leave = true;
								return self.$root.$message.open({
									status: 200,
									message: "成功完成校對文件"
								});
							})
							.then(() => {
								localStorage.removeItem("ebookEdit");
								self.$router.go(-1);
							})
							.catch(res => {
								this.$errorHandler({ error: res });
							});
					}
				});

				editor.ui.registry.addButton("關閉", {
					text: "關閉",
					onAction: function() {
						localStorage.removeItem("ebookEdit");
						self.$router.go(-1);
					}
				});

				editor.ui.registry.addButton("切換版型", {
					text: "切換版型",
					onAction: function() {
						if (self.imageClass.indexOf("col-md-6") > -1) {
							self.imageClass = "col-md-12";
							self.textClass = "col-md-12";
						} else {
							self.imageClass = "col-md-6 col-sm-12";
							self.textClass = "col-md-6 col-sm-12";
						}
						self.$refs.viewer.refreshViewer();
					}
				});

				editor.ui.registry.addButton("還原校對內容", {
					text: "還原校對內容",
					onAction: function() {
						ebookAction
							.recoverContent({ pk: self.pk })
							.then(() => {
								return self.$root.$confirm.open({
									status: 200,
									message: "是否確定還原校對內容，執行後現有的校對資料將被還原?"
								});
							})
							.then(() => {
								self.$root.$message.open({
									status: 200,
									message: "成功還原校對內容"
								});
								localStorage.removeItem("ebookEdit");
								self.reloadPage();
							})
							.catch(res => {
								this.$errorHandler({ error: res });
							});
					}
				});
			}
		};
	},
	beforeDestroy() {
		clearInterval(this.timer);
		window.removeEventListener("beforeunload", beforeunloadFun);
	},
	methods: {
		reloadPage() {
			this.leave = false;
			Promise.all([
				ebookRest.read(this.pk),
				ebookAction.readEdit({ pk: this.pk })
			])
				.then(([bookRes, editRes]) => {
					this.image = bookRes.data.scan_image;
					this.old_edited_page = bookRes.data.edited_page;
					this.edited_page = bookRes.data.edited_page;
					const bookName = bookRes.data.bookname;
					const part = bookRes.data.ISBN_part.split("-")[1];

					document.title = `${bookName}"-part"${part}`;
					this.current_editrecord = {
						finish: editRes.data.finish,
						edit: editRes.data.edit
					};
					let temp = JSON.parse(localStorage.getItem("ebookEdit"));
					if (temp && temp.pk == this.pk) {
						this.$root.$confirm
							.open({
								status: 200,
								message:
									"暫存區內有資料可能是上次編輯時異常關閉頁面，是否還原內容?"
							})
							.then(() => {
								this.old_edited_page = temp.edited_page;
								this.edited_page = temp.edited_page;
								this.current_editrecord = {
									finish: temp.finish,
									edit: temp.edit
								};
							})
							.catch(() => {});
					}
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		changePage(value) {
			this.edited_page = value;
		},
		recordPerMins() {
			// 每 60s 傳送 change count 給後端

			let store = {
				pk: this.pk,
				page: this.edited_page,
				finish: this.current_editrecord.finish,
				edit: this.current_editrecord.edit
			};
			localStorage.setItem("ebookEdit", JSON.stringify(store));
			ebookAction
				.editLog(this.pk, {
					online: this.change_count,
					page: this.edited_page
				})
				.then(() => {
					this.change_count = 0;
				})
				.catch(res => {
					this.$errorHandler({ error: res });
				});
		},
		detectIdel() {
			// 每 60s 計算使用者閒置時間
			if (this.idel_min > 30) {
				this.idel_min = 0;
				this.leave = true;
				this.$router.go(-1);
				return;
			}
			this.idel_min++;
		},
		editorChange() {
			this.idel_min = 0;
			this.change_count++;
		},
		startAdjustHeight(e) {
			e.preventDefault();
			this.isMousedown = true;
			this.old_position = { x: e.clientX, y: e.clientY };
			document.addEventListener("mousemove", this.movingHeight);
			document.addEventListener("mouseup", this.endAdjustHeight);
		},
		movingHeight(e) {
			e.preventDefault();
			this.new_postion = { x: e.clientX, y: e.clientY };
			if (this.old_position && this.isMousedown) {
				const diff_height = this.new_postion.y - this.old_position.y;
				this.viewerHeight = this.viewerHeight + diff_height;
				this.old_position = { x: e.clientX, y: e.clientY };
			}
		},
		endAdjustHeight() {
			this.isMousedown = false;
			localStorage.setItem("viewer_height", Math.round(this.viewerHeight));
			document.removeEventListener("mousemove", this.movingHeight);
			document.removeEventListener("mouseup", this.endAdjustHeight);
			this.$emit("updateViewerHeight");
		}
	},
	beforeRouteLeave(to, from, next) {
		if (!this.leave) {
			this.$root.$confirm
				.open({ status: 200, message: "請問您確定要離開本頁面嗎？" })
				.then(() => {
					next();
				})
				.catch(err => {
					this.$errorHandler({ error: err });
					next(false);
				});
		} else {
			next();
		}
	}
};
</script>

<style scoped lang="scss">
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 0.5em 0 0 0;
	padding: 0;
}

.scroll-height {
	position: absolute;
	right: 50%;
	cursor: n-resize;
	display: block;
}
</style>
