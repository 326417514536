import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import VueGtm from "@gtm-support/vue2-gtm";
import FontAwesomeIcon from "./utils/fortawesomeSubset.js";
import "bootstrap";
import Video from "video.js";
import zh from "video.js/dist/lang/zh-TW.json";
import "video.js/dist/video-js.css";
import messages from "./message";
import { getBlobAsText } from "@/utils/blob";
import { isBlob } from "@/utils/type";

/*Vue.use(VueGtm, {
	id: "GTM-P8LQTBD",
	queryParams: {
		// Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
		gtm_auth: "BZ_7_UZT7R8jZnHNATD7ew",
		gtm_preview: "env-1",
		gtm_cookies_win: "x"
	},
	defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
	compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
	nonce: "2726c7f26c", // Will add `nonce` to the script tag
	enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	debug: true, // Whether or not display console logs debugs (optional)
	loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	ignoredViews: ["homepage"], // Don't trigger events for specified router names (optional)
	trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
});*/

Video.addLanguage("zh", zh);

Vue.prototype.$video = Video;

Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.directive("focus", {
	inserted: function(el) {
		el.focus();
	}
});

Vue.prototype.$errorHandler = async function({ error, show = true }) {
	let code, message;
	if (
		error.response &&
		error.response.status >= 400 &&
		error.response.status < 500
	) {
		code = error.response.status;
		if (isBlob(error.response.data)) {
			const data = await getBlobAsText(error.response.data);
			message = JSON.parse(data).detail;
		} else {
			if (error.response.data.detail) {
				message = error.response.data.detail;
			} else {
				message = error.response.data;
			}
		}
	} else if (error.response && error.response.status >= 500) {
		code = error.response.status;
		if (isBlob(error.response.data)) {
			const data = await getBlobAsText(error.response.data);
			message = data;
		} else {
			message = error.response.data;
		}
	} else {
		code = error.name;
		message = error.message;
	}
	this.$store.commit("log/addRecord", {
		path: this.$route.fullPath,
		code,
		message,
		userid: this.$store.state.user.user.id || 0
	});

	const userMessage = messages[message];
	if (userMessage) {
		message = userMessage;
	}

	if (show) {
		if (
			error.response &&
			error.response.status >= 400 &&
			error.response.status < 500
		) {
			this.$root.$message.open({
				status: code,
				message
			});
		} else {
			this.$root.$log.open();
		}
	}
};

import VueMeta from "vue-meta";
import "./registerServiceWorker";
Vue.use(VueMeta);

const vueinstance = new Vue({
	router,
	store,
	render: h => h(App)
});
vueinstance.$mount("#app");

export default vueinstance;
