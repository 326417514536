<template>
	<div class="layout">
		<div class="main-container">
			<a class="sr-only" href="#id_main">移至主內容</a>
			<com-header></com-header>
			<main class="container" role="main" id="id_main">
				<keep-alive>
					<router-view v-if="$route.meta.keepAlive" />
				</keep-alive>
				<router-view v-if="!$route.meta.keepAlive" />
			</main>
			<com-footer></com-footer>
		</div>
		<com-token />
		<countdown />
		<countdown-multiple />
		<message />
		<confirm />
		<read />
		<log />
		<api />
		<auth />
	</div>
</template>

<script>
import comHeader from "./header/header.vue";
import comFooter from "./footer.vue";
import comToken from "./token.vue";
import countdown from "./popup/countdown";
import CountdownMultiple from "./popup/CountdownMultiple";
import message from "./popup/message";
import confirm from "./popup/confirm";
import read from "./popup/read";
import log from "./popup/log";
import api from "./popup/api";
import auth from "./popup/auth";
import { mapActions } from "vuex";

export default {
	name: "layout",
	components: {
		comHeader,
		comFooter,
		comToken,
		countdown,
		CountdownMultiple,
		message,
		confirm,
		read,
		log,
		api,
		auth
	}
};
</script>

<style lang="scss" scoped>
main {
	// Some empty spacing.
	margin-top: 1.2em;
}
.layout {
	height: 100%;

	.main-container {
		min-height: 100%;
		display: flex;
		flex-direction: column;
		align-content: stretch;
		align-items: stretch;

		> * {
			flex-basis: auto;
			flex-grow: 0;
		}
		// Stickey footer.
		> .purpse-for-flex-grow {
			flex-grow: 1;
		}
	}
}
</style>
