<template>
	<div class="period-wrap">
		<form-item icon="hourglass-start" label="開始日期" no-label>
			<input @input="out" type="date" class="form-control" v-model="begin" />
		</form-item>
		<form-item
			icon="hourglass-end"
			type="date"
			label="結束日期"
			v-model="end"
			no-label
		>
			<input @input="out" type="date" class="form-control" v-model="end" />
		</form-item>
	</div>
</template>

<script>
import formItem from "@/components/formItem.vue";

export default {
	props: {
		value: {
			begin: "",
			end: ""
		}
	},
	data() {
		return {
			begin: "",
			end: ""
		};
	},
	methods: {
		out() {
			this.$emit("input", {
				begin: this.begin,
				end: this.end
			});
		}
	},
	components: {
		formItem
	},
	mounted() {
		this.begin = this.value.begin;
		this.end = this.value.end;
	}
};
</script>

<style lang="scss" scoped>
.period-wrap {
	display: flex;
	align-items: center;
	@media screen and (max-width: 767px) {
		display: block;
	}
}

.inputBox {
	margin: 0.6rem;
}
.form-group {
	display: flex;
}
</style>
