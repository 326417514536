export const isString = value => {
	return typeof value === "string" || value instanceof String;
};

export const isNumber = value => {
	return typeof value === "number" && isFinite(value);
};

export const isArray = value => {
	return value && typeof value === "object" && value.constructor === Array;
};

export const isFunction = value => {
	return typeof value === "function";
};

export const isObject = value => {
	return value && typeof value === "object" && value.constructor === Object;
};

export const isNull = value => {
	return value === null;
};

export const isUndefined = value => {
	return typeof value === "undefined";
};

export const isBoolean = value => {
	return typeof value === "boolean";
};

export const isRegExp = value => {
	return value && typeof value === "object" && value.constructor === RegExp;
};

export const isError = value => {
	return value instanceof Error && typeof value.message !== "undefined";
};

export const isDate = value => {
	return value instanceof Date;
};

export const isSymbol = value => {
	return typeof value === "symbol";
};

export const isBlob = value => {
	return value instanceof Blob;
};
