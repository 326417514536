<!--
*Usage example:
this.$root.$confirm.open({status: 200, message: '您確定要離開這個網頁瀏覽？您尚未將編輯過的表單資料送出，請問您確定要離開本頁面嗎？'})
Other usage example, refer "./message.vue" (but not sure if all the interface/logic is the same or not).
-->
<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div class="popup">
				<button class="close" @click="close">&times;</button>
				<div class="content">
					<!-- Purpose of this container: I just don't want to put things directely under .content,
					, because in the future more things might add into here and I want to keep structure more consistent. -->
					<div class="msgCtnr">
						<template v-if="data.type == 'html'">
							<div v-html="data.message"></div>
						</template>
						<template v-else-if="data.type == 'array'">
							<div v-for="(item, index) in data.message" :key="item">
								{{ index + 1 }}. {{ item }}
							</div>
						</template>
						<template v-else-if="data.type == 'object'">
							<div v-for="(value, key, index) in data.message" :key="key">
								{{ index + 1 }}. {{ key }} : {{ value }}
							</div>
						</template>
						<template v-else>
							<div class="preWrap">{{ data.message }}</div>
						</template>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<button class="btn btn-outline-dark" @click="cancel">取消</button>
						<button class="btn btn-primary" @click="ok">確定</button>
					</div>
				</div>
				<div class="statusCtnr">
					<font-awesome-icon
						class="status-icon"
						icon="check-circle"
						v-if="promptType === 'success'"
					/>
					<font-awesome-icon
						class="status-icon"
						icon="times-circle"
						v-else-if="promptType === 'error'"
					/>
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import Trap from "vue-focus-lock";
import { isString, isArray, isObject } from "@/utils/type";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	created() {
		this.$root.$confirm = this;
	},
	methods: {
		open({ status, message, type }) {
			this.data.status = status;
			this.promptType = parseInt(status, 10) < 400 ? "success" : "error";
			this.data.message = message;
			if (!type) {
				if (isString(message)) {
					this.data.type = "string";
				} else if (isArray(message)) {
					this.data.type = "array";
				} else if (isObject(message)) {
					this.data.type = "object";
				} else {
					this.data.message = JSON.stringify(message);
					this.data.type = "string";
				}
			} else {
				this.data.type = type;
			}
			this.isShow = true;
			this.promise = new Promise((resolve, reject) => {
				this.$on("cancel", () => {
					reject();
				});
				this.$on("ok", () => {
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
				this.$off("cancel");
			});
			return this.promise;
		}
	}
};
</script>

<style scoped lang="scss">
@import "./style/common.scss";

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}
</style>
