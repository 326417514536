<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div class="popup">
				<button class="close" @click="close">&times;</button>
				<div class="content">
					<div class="msgCtnr">
						<div class="preWrap">{{ message }}</div>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<button class="btn btn-primary" @click="ok">關閉</button>
						<button class="btn btn-primary" @click="view">檢視</button>
						<!--<button class="btn btn-outline-dark" @click="report">回報</button>-->
					</div>
				</div>
				<div class="statusCtnr">
					<font-awesome-icon class="status-icon" icon="times-circle" />
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import Trap from "vue-focus-lock";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	created() {
		this.$root.$log = this;
		this.load();
		window.addEventListener("unload", () => {
			return this.save();
		});
	},
	data() {
		return {
			message: ""
		};
	},
	computed: {
		logDatasLength() {
			return this.$store.state.log.logDatas.length;
		}
	},
	/*watch: {
		logDatasLength: {
			handler(newVal) {
				if (newVal > 100) {
					this.send();
					this.$root.$message.open({
						status: 200,
						message: "日誌已送出"
					});
				}
			},
			immediate: true
		}
	},*/
	methods: {
		...mapMutations("log", ["load", "save"]),
		...mapActions("log", ["send"]),
		open() {
			this.isShow = true;
			this.message = "發生非預期錯誤已紀錄日誌";
			this.promise = new Promise(resolve => {
				this.$on("ok", () => {
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
			});
			return this.promise;
		},
		view() {
			const fileName = "errorLog.csv"; //匯出的檔名
			let data = "browser,code,ip,path,message,os,time,userid\n";
			this.$store.state.log.logDatas.forEach(item => {
				const { browser, code, ip, path, message, os, time, userid } = item;
				data += `"${browser}",${code},${ip},${path},${message},${os},${time},${userid}\n`;
			});
			const blob = new Blob([data], {
				type: "application/octet-stream"
			});
			const href = URL.createObjectURL(blob);
			const link = document.createElement("a");
			document.body.appendChild(link);
			link.href = href;
			link.download = fileName;
			link.click();
			this.isShow = false;
			this.send();
			this.$emit("ok");
		},
		report() {
			this.send();
			this.$emit("ok");
		}
	}
};
</script>

<style scoped lang="scss">
@import "./style/common.scss";

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}
</style>
