<template>
	<div>
		<h2>文件審核</h2>
		<div>
			<div>
				<h3>
					<span class="glyphicon glyphicon-check" aria-hidden="true"></span>
					Step1: 基本資料
				</h3>
				<hr />
				<ul class="list-content">
					<li v-for="(value, key) in info" :key="key">{{ key }}:{{ value }}</li>
				</ul>
			</div>
			<!-- step 2 -->
			<div>
				<h3>
					<span class="glyphicon glyphicon-check" aria-hidden="true"></span>
					Step2: 掃描內容
				</h3>
				<hr />
				<button
					v-for="item in book.ebook_set"
					:key="item"
					class="btn btn-manage content"
					@click="
						viewPk = item;
						viewModalShow = true;
					"
				>
					分段{{ item.split("-")[1] }}
				</button>
			</div>
			<!--step 3-->
			<div>
				<h3>
					<span class="glyphicon glyphicon-check" aria-hidden="true"></span>
					Step3: 審核結果
				</h3>
				<hr />
				<template v-if="book.status == 0">
					<div class="form-group">
						<input type="radio" id="success" value="success" v-model="result" />
						<label for="success">成功</label>
						<br />
						<input type="radio" id="error" value="error" v-model="result" />
						<label for="error">退回</label>
						<br />
						<template v-if="result == 'error'">
							<label for="reason">原因</label>
							<input type="text" id="reason" v-model="reason" />
						</template>
					</div>
					<button class="btn btn-primary" @click="$router.go(-1)">
						上一頁
					</button>
					<button
						class="btn btn-primary"
						@click="review()"
						type="submit"
						id="send_id"
						name="send"
					>
						送出
					</button>
				</template>
			</div>
		</div>
		<modal
			:size="'large'"
			v-if="viewModalShow"
			@closeModal="viewModalShow = false"
		>
			<template #header>
				<h4 class="modal-title">掃描內容瀏覽 {{ viewPk }}</h4>
			</template>
			<template #body>
				<scanBookEditor :pk="viewPk"></scanBookEditor>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { bookRest, bookAction } from "@/api/ebookSystem.js";
import modal from "@/components/popup/modal.vue";
import scanBookEditor from "@/components/book/scanBookEditor.vue";

export default {
	components: {
		modal,
		scanBookEditor
	},
	data() {
		return {
			pk: this.$route.params.id,
			book: {},
			reason: "",
			result: "success", //success or error
			viewModalShow: false,
			viewPk: 0,
			viewData: {}
		};
	},
	computed: {
		info() {
			try {
				return {
					ISBN: this.book.book_info.ISBN,
					書名: this.book.book_info.bookname,
					作者: this.book.book_info.author,
					出版社: this.book.book_info.house,
					出版日期: this.book.book_info.date,
					裝訂冊數: this.book.book_info.bookbinding
				};
			} catch (err) {
				// this.$errorHandler({ error: err, show: false });
				return {};
			}
		},
		...mapState(["mode"])
	},
	metaInfo: {
		title: "文件審核"
	},
	mounted() {
		this.get_book_data();
	},
	methods: {
		get_book_data() {
			bookRest.read(this.pk).then(res => {
				this.book = res.data;
			});
		},
		review() {
			bookAction
				.review({
					pk: this.pk,
					result: this.result,
					reason: this.reason
				})
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message: "審核已完成"
					});
				})
				.then(() => {
					this.$router.go(-1);
				});
		}
	}
};
</script>

<style scoped lang="scss">
.list-content {
	list-style-type: disc;
	line-height: 2rem;
	font-size: 1.1rem;
}

hr {
	margin-top: 5px;
}

.glyphicon {
	&.glyphicon-check {
		//不知道這個用途是什麼
		margin-top: 20px;
	}
}
</style>
