<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">平台管理</h2>
		<tab
			:data="[
				{ display_name: '業務管理', type: 'items', data: itemManage.business },
				{
					display_name: '統計資訊',
					type: 'items',
					data: itemManage.statistics
				},
				{ display_name: '內容管理', type: 'items', data: itemManage.content }
			]"
		>
			<template #items="props">
				<ul>
					<template v-for="(item, index) in props.item">
						<template v-if="item.type == 'folder'">
							<li :key="index">
								<a href="#">{{ index + 1 }}. {{ item.display_name }}</a>
								<ul>
									<template v-for="(item, index_folder) in item.items">
										<template v-if="item.type == 'item'">
											<li class="nav-item" :key="index_folder">
												<a
													tabindex="0"
													class="nav-link"
													@click.prevent="linkToUrlOrRoute(item)"
													>{{ index + 1 }}-{{ index_folder + 1 }}.
													{{ item.display_name }}</a
												>
											</li>
										</template>
										<template v-if="item.type == 'action'">
											<li class="nav-item" :key="index_folder">
												<a class="nav-link" href="#" @click="item.action(that)"
													>{{ index + 1 }}-{{ index_folder + 1 }}.
													{{ item.display_name }}</a
												>
											</li>
										</template>
									</template>
								</ul>
							</li>
						</template>
						<template v-if="item.type == 'item'">
							<li class="nav-item" :key="index">
								<a
									tabindex="0"
									class="nav-link"
									@click.prevent="linkToUrlOrRoute(item)"
									>{{ index + 1 }}. {{ item.display_name }}</a
								>
							</li>
						</template>
						<template v-if="item.type == 'action'">
							<li class="nav-item" :key="index">
								<a class="nav-link" href="#" @click="item.action(that)"
									>{{ index + 1 }}. {{ item.display_name }}</a
								>
							</li>
						</template>
					</template>
				</ul>
			</template>
		</tab>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { itemManage } from "../header/navItemData.js";
import tab from "@/components/tab.vue";

export default {
	components: {
		tab
	},
	data() {
		return {
			itemManage: itemManage
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	metaInfo: {
		title: "平台管理"
	},
	methods: {
		linkToUrlOrRoute(item) {
			function collapseBootstrapNavbarManually() {
				let navbarToggleBtn = document.getElementsByClassName(
					"navbar-toggler"
				)[0];
				navbarToggleBtn.click();
			}
			if (typeof item.url !== "undefined") {
				window.location.replace(item.url);
			} else if (typeof item.route !== "undefined") {
				this.$router.push(item.route);
				collapseBootstrapNavbarManually();
			} else if (typeof item.routePath !== "undefined") {
				this.$router.push({ path: item.routePath });
				collapseBootstrapNavbarManually();
			} else {
				this.$router.push({ name: item.routeName });
				collapseBootstrapNavbarManually();
			}
		}
	}
};
</script>

<style>
ul.manager_school {
	line-height: 1.7em;
}
ul {
	list-style-type: none;
}
</style>
