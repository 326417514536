export default {
	namespaced: true,
	state: {
		showMessage: false
	},
	mutations: {
		toggleShowMessage(state, bool) {
			state.showMessage = bool;
		}
	}
};
