<template>
	<div style="padding:20px 20px; background-color:#fafafa;">
		<div class="row">
			<div class="col-sm-6">
				<eBookViewer ref="viewer" :pk="pk" :height="400" :images="image">
					<li>
						<a href="#" aria-label="文字放大" @click="changeFontSize">
							文字{{ fontSize - 40 }}%
						</a>
					</li>
				</eBookViewer>
			</div>

			<div class="col-sm-6">
				<div class="textSection">
					<div :style="{ fontSize: fontSize + '%', lineHeight: '1.8em' }">
						{{ content }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import eBookViewer from "@/components/book/viewer";
import { ebookAction, ebookRest } from "@/api/ebookSystem.js";
import { escape } from "lodash";

export default {
	props: ["pk"],
	components: {
		eBookViewer
	},
	data() {
		return {
			image: {},
			content: "",
			fontSize: 140
		};
	},
	mounted() {
		if (this.pk) {
			this.refresh();
		}
	},
	methods: {
		async refresh() {
			//read text data
			Promise.all([
				ebookRest.read(this.pk),
				ebookAction.getContent(this.pk)
			]).then(([imageRes, contentRes]) => {
				this.image = imageRes.data.scan_image;
				this.content = escape(contentRes.data);
			});
		},
		changeFontSize() {
			if (this.fontSize < 200) {
				this.fontSize = this.fontSize + 20;
			} else {
				this.fontSize = 140;
			}
		}
	},
	watch: {
		pk: function() {
			this.refresh();
		}
	}
};
</script>

<style>
.textSection {
	height: 450px;
	overflow-y: scroll;
	color: #000;
	background-color: #fff;
	white-space: pre-wrap;
	word-wrap: break-word;
	word-break: break-all;
}
</style>
