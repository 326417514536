import axios from "axios";
import epubjs from "epubjs";
import localforage from "localforage";

export const base_table = {
	props: {
		datas: Array, // define type
		header: Object,
		tdwidths: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	data() {
		return {
			numPerPage: 10,
			pagenow: 1,
			showPrevMore: false,
			showNextMore: false,
			pagerCount: 7,
			orderby: "",
			orderByColumn: ""
		};
	},
	computed: {
		numpage() {
			return Math.ceil(this.numrow / this.numPerPage);
		},
		numrow() {
			return this.datas.length;
		},
		start() {
			return this.numPerPage * (this.pagenow - 1);
		},
		end() {
			return Math.min(this.numPerPage * this.pagenow, this.numrow);
		},
		pagers() {
			const pagerCount = this.pagerCount;
			const halfPagerCount = (this.pagerCount - 1) / 2;
			const currentPage = Number(this.pagenow);
			const pageCount = Number(this.numpage);
			let showPrevMore = false;
			let showNextMore = false;
			if (pageCount > pagerCount) {
				if (currentPage > pagerCount - halfPagerCount) {
					showPrevMore = true;
				}
				if (currentPage < pageCount - halfPagerCount) {
					showNextMore = true;
				}
			}
			const array = [];
			if (showPrevMore && !showNextMore) {
				const startPage = pageCount - (pagerCount - 2);
				for (let i = startPage; i < pageCount; i++) {
					array.push(i);
				}
			} else if (!showPrevMore && showNextMore) {
				for (let i = 2; i < pagerCount; i++) {
					array.push(i);
				}
			} else if (showPrevMore && showNextMore) {
				const offset = Math.floor(pagerCount / 2) - 1;
				for (let i = currentPage - offset; i <= currentPage + offset; i++) {
					array.push(i);
				}
			} else {
				for (let i = 2; i < pageCount; i++) {
					array.push(i);
				}
			}
			this.showPrevMore = showPrevMore;
			this.showNextMore = showNextMore;
			return array;
		}
	},
	watch: {
		datas() {
			// if (this.numpage < this.pagenow) {
			this.pagin_change(0);
			this.$nextTick(() => {
				this.pagin_change(1);
			});
			// this.pagenow = 1;
			// }
		}
	},
	methods: {
		pagin_change(oper) {
			const pagerCountOffset = this.pagerCount - 2;
			if (String(oper) === "+1") {
				this.pagenow += 1;
			} else if (String(oper) === "-1") {
				this.pagenow -= 1;
			} else if (String(oper) === "quickprev") {
				this.pagenow = this.pagenow - pagerCountOffset;
			} else if (String(oper) === "quicknext") {
				this.pagenow = this.pagenow + pagerCountOffset;
			} else {
				this.pagenow = parseInt(oper, 10);
			}
			this.pagenow = Math.max(this.pagenow, 0);
			this.pagenow = Math.min(this.pagenow, this.numpage);
		},
		tdStyles(index) {
			if (
				this.tdwidths.reduce(
					(prev, next) => parseInt(prev) + parseInt(next),
					0
				) !== 100
			) {
				return null;
			}
			return { width: `${this.tdwidths[index]}%` };
		},
		order(orderByColumn) {
			if (this.orderByColumn === orderByColumn) {
				if (this.orderby === "asc") {
					this.orderby = "desc";
				} else if (this.orderby === "desc") {
					this.orderby = "asc";
				} else {
					this.orderby = "desc";
				}
			} else {
				this.orderby = "desc";
			}
			this.orderByColumn = orderByColumn;
			this.datas.sort(this.compare(this.orderByColumn, this.orderby));
		},
		ariaSort(key) {
			let result = "none";
			if (this.orderByColumn === key) {
				if (this.orderby === "asc") {
					result = "ascending";
				} else if (this.orderby === "desc") {
					result = "descending";
				}
			}
			return result;
		},
		compare(key, order) {
			function desc(a, b) {
				if (a[key] < b[key]) return 1;
				if (a[key] > b[key]) return -1;
				return 0;
			}
			function asc(a, b) {
				if (a[key] < b[key]) return -1;
				if (a[key] > b[key]) return 1;
				return 0;
			}
			if (order === "desc") {
				return desc;
			}
			if (order === "asc") {
				return asc;
			}
			return asc;
		}
	}
};

export const split_table = {
	props: {
		datas: Array, // define type
		header: Object
	},
	data() {
		return {
			split_count: 3
		};
	},
	computed: {
		split_header() {
			const header = {};
			for (let i = 0; i < this.split_count; i++) {
				_.each(this.header, (v, k) => {
					header[k + String(i)] = v;
				});
			}
			return header;
		},
		split_datas() {
			const datas = [];
			this.datas.forEach((v, i) => {
				if (i % this.split_count === 0) {
					datas.push({});
				}
				const index = Math.floor(i / this.split_count);
				_.each(this.header, (vh, kh) => {
					datas[index][kh + String(i % this.split_count)] = v[kh];
				});
			});
			return datas;
		},
		numrow() {
			return this.split_datas.length;
		}
	}
};

export const epubApp = {
	data() {
		return {
			books: []
		};
	},
	methods: {
		async getLocalBooks() {
			const books = await localforage.getItem("books");
			if (books) {
				this.books = books;
			}
		},
		setLocalBooks() {
			localforage.setItem("books", this.books);
		},
		async addLocalBooks(id, url) {
			let response = await axios.get(url, { responseType: "blob" });
			let file = response.data;
			const epub = epubjs(file);
			epub.opened
				.then(async () => {
					const metadata = epub.package.metadata;
					const src = await epub.coverUrl();
					if (src) {
						const canvas = document.createElement("canvas");
						const ctx = canvas.getContext("2d");
						let img = new Image();
						img.src = src;
						img.onload = () => {
							// �]�w canvas �e�����P�Ϥ�
							canvas.width = img.width;
							canvas.height = img.height;
							// �N��ø�s�A�ó]�wø�s���Ϥ��e��
							ctx.drawImage(img, 0, 0, img.width, img.height);
							const dataUrl = canvas.toDataURL("image/png");
							const book = {
								id,
								metadata,
								src: dataUrl,
								file,
								expiredDate: null
								// expiredDate: new Date("2020-10-01")
							};
							this.books.push(book);
							this.setLocalBooks();
						};
					} else {
						const book = {
							id,
							metadata,
							file,
							expiredDate: null
						};
						this.books.push(book);
						this.setLocalBooks();
					}
				})
				.catch(() => {})
				.finally(() => {});
		},
		removeLocalBooks(id) {
			this.books = this.books.filter(b => b.id !== id);
			this.setLocalBooks();
		}
	},
	mounted() {
		this.getLocalBooks();
	}
};
