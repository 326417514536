<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div class="popup">
				<div class="title-area">
					<h3 class="title">處理中</h3>
					<button class="close" @click="close">&times;</button>
				</div>
				<hr />
				<div class="content">
					<div class="msgCtnr">
						<div class="progress">
							<div
								class="progress-bar animate"
								role="progressbar"
								style="border-radius: 10px;"
								:style="{ width: percent + '%' }"
								:aria-valuenow="percent"
								aria-valuemin="0"
								aria-valuemax="100"
							>
								{{ percent }}%
							</div>
						</div>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<button class="btn btn-primary" @click="ok">關閉</button>
					</div>
				</div>
				<div class="statusCtnr">
					<font-awesome-icon class="status-icon" icon="times-circle" />
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import Trap from "vue-focus-lock";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	created() {
		this.$root.$api = this;
		window.addEventListener("progress", e => {
			this.percent = e.detail.percent;
			this.isShow = true;
		});
	},
	data() {
		return {
			percent: 0
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.promise = new Promise(resolve => {
				this.$on("ok", () => {
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
			});
			return this.promise;
		}
	},
	watch: {
		percent(val) {
			if (val === 100) {
				setTimeout(() => {
					this.isShow = false;
				}, 1000);
			}
		}
	}
};
</script>

<style scoped lang="scss">
@import "./style/common.scss";

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}
.animate {
	transition: all 1s;
}
</style>
