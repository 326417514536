<template>
	<div style="width: 600px;background: black;">
		<div v-if="loading">讀取中...</div>
		<video-player
			v-if="videoOptions.sources.length > 0"
			:options="videoOptions"
		></video-player>
	</div>
</template>

<script>
import videoPlayer from "./videoPlayer";
import { getVideo } from "@/api/video";

export default {
	components: {
		videoPlayer
	},
	data() {
		return {
			loading: false,
			videoOptions: {
				autoplay: true,
				controls: true,
				sources: [
					{
						src:
							"https://www.twvip.org/loveandhappiness/api/announcements/15/resource/content/image/detail_cover.mp4/",
						type: "video/mp4"
					}
				]
			}
		};
	},
	methods: {
		getVideo() {
			this.loading = true;
			try {
				return getVideo();
			} finally {
				this.loading = false;
			}
		}
	}
	// async mounted() {
	// 	const res = await this.getVideo()
	// 	let blob = new Blob([res.data], { type:"application/octet-stream" })
	// 	this.videoOptions.sources.push({
	// 		src: URL.createObjectURL(blob),
	// 		type:'video/mp4',
	// 		width: '100%',
	// 	})
	// }
};
</script>

<style></style>
