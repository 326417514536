<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">ebookSystem</h2>
		<router-view></router-view>
	</div>
</template>

<script>
export default {};
</script>
