import { render, staticRenderFns } from "./qapageBlock.vue?vue&type=template&id=01eed548&scoped=true&"
import script from "./qapageBlock.vue?vue&type=script&lang=js&"
export * from "./qapageBlock.vue?vue&type=script&lang=js&"
import style0 from "./qapageBlock.vue?vue&type=style&index=0&id=01eed548&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01eed548",
  null
  
)

export default component.exports