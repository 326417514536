<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { logsystemFactory } from "@/utils/logsystem";
import { logRest } from "@/api/logsystem";

export default {
	name: "App",
	metaInfo() {
		return {
			title: this.title,
			titleTemplate: subTitle => {
				let title;
				if (subTitle) {
					title = `${subTitle} | 雲端千眼平台`;
					return title;
				} else {
					title = `雲端千眼平台`;
					return title;
				}
			}
		};
	},
	computed: {
		title() {
			let title;
			try {
				title = this.$route.matched[this.$route.matched.length - 1].meta.label;
				// title = this.$router.currentRoute.meta.label;
			} catch (error) {
				title = "";
				this.$errorHandler({ error });
			}
			return title;
		}
	},
	async mounted() {
		try {
			await this.getOrganizations();
		} catch (error) {
			this.$errorHandler({ error });
		}

		try {
			await this.getCategorys();
		} catch (error) {
			this.$errorHandler({ error });
		}
		try {
			await this.getLocalBooks();
		} catch (error) {
			this.$errorHandler({ error });
		}
		this.$root.$logsystem = logsystemFactory({ rest: logRest });
	},
	methods: {
		...mapActions(["getOrganizations", "getCategorys"]),
		...mapActions("epub", ["getLocalBooks"])
	}
};
</script>

<style lang="scss">
// Useless?
// @import url('../node_modules/font-awesome/css/font-awesome.min.css');

// *Moved to "vue.config.js":
// @import '@/style/main.scss';

#app {
	height: 100%;
}
</style>
