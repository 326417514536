<template>
	<div>
		<h2 v-focus tabindex="0" class="sr-only">法鼓山出版書籍</h2>
		<table-div
			:header="resource_file_header"
			:datas="resource_file_datas"
			:tdwidths="tdwidths"
		>
			<template #action="props">
				<template v-if="user.auth_guest">
					<button
						class="btn btn-outline-secondary"
						@click="readView(props.item)"
					>
						閱讀
					</button>
					<button
						class="btn btn-outline-secondary"
						@click="readResource(props.item)"
					>
						下載
					</button>
				</template>
			</template>
		</table-div>
	</div>
</template>

<script>
import axios from "axios";
import { file_resolve } from "@/api/base.js";
import { host } from "@/api/base";
import { compare } from "@/utils/dataProcess";
import { mapState, mapActions } from "vuex";
import tableDiv from "@/components/tableDiv.vue";

export default {
	components: {
		tableDiv
	},
	data() {
		return {
			styleRow: {
				margin: 0
			},
			styleNav: {
				margin: "3rem 0 0 0"
			},
			url: `${host}api/ddm/resource/culture2/`,
			tdwidths: [8, 42, 10, 15, 25],
			resource_file_header: {
				order: "序號",
				name: "檔案名稱",
				size: "檔案大小",
				mtime: "修改日期",
				action: "動作"
			},
			resource_file_datas: []
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	mounted() {
		this.get_resource_file_datas();
	},
	methods: {
		...mapActions("epub", ["addLocalBooks"]),
		get_resource_file_datas() {
			this.resource_file_datas = [];
			axios.get(this.url, {}).then(res => {
				let temp = [];
				_.each(res.data, v => {
					temp.push({
						name: v.name,
						size: v.size,
						mtime: v.mtime.slice(0, 10),
						action: v.name
					});
				});
				this.resource_file_datas = temp.sort(compare("name", "asc"));
				this.resource_file_datas.forEach((v, i) => {
					v.order = i + 1;
				});
			});
		},
		readResource(name) {
			const url = `${this.url}${name}/`;
			axios.get(url, { responseType: "blob" }).then(file_resolve(name));
		},
		async readView(name) {
			const url = `${this.url}${name}/`;
			await this.addLocalBooks({ id: `ddmCulture2-${name}`, url: url });
			this.$root.$read.open({
				url: `/Access8EpubWeb/book/ddmCulture2-${name}`
			});
		}
	}
};
</script>

<style lang="scss" scoped>
img {
	width: 100%;
}
</style>
