<!-- Please use termsOfService.vue as standard template, for all /about related page. -->
<template>
	<div class="any-about-page container this-is-origin">
		<h2 class="big-title">平台濫觴</h2>

		<div class="bs-callout bs-callout-primary">
			<h3>一、關於『雲端千眼平台』</h3>
			<p>
				莎士比亞有句名言：「生活里沒有書籍，就好像大地沒有陽光；智慧里沒有書籍，就好像鳥兒沒有翅膀。」而『雲端千眼平台』的建立，主要便是希望透過平台，讓視障者能夠閱讀重製轉化後的電子書，並且透過平台自動化管理，減少視障者在取得資訊所遇到的困難。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>1.1 「閱讀」是獲得知識的基石</h4>
			<p>
				從紙本書籍的開始，文字紀錄著人類的智慧結晶，而透過閱讀，人類得以將所吸收的資訊內化為自身的知識基礎，不僅能擴展視野，也是培養創造力、批判思考的重要能力，而這些事情對於一般人來說如同生活般容易，對於視障者卻充滿重重困難。
				我們認為透過「閱讀」獲得「知識」的權益，對於視障者尤為重要，使其藉由閱讀獲取基本知識能力的學習，彌補先天生理因素及後天環境的差異，未來將能夠有選擇職業、工作及生活模式的機會，而不會因為資訊不平等，在毫無選擇或選擇機會甚少的情況下，只能從事基礎的傳統性工作。所以我們為了幫助視障者知識資訊的取得，以提升其知能、充實生活內涵，增闊其視野而建置了『雲端千眼平台』。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>1.2 視障者在『閱讀』方面所遇到的困難</h4>
			<p class="text-success no-text-indent">【資訊上取得的困難】</p>
			<p>
				對於一般人來說，想要獲取所需資訊，可以透過實體書店、圖書館或是線上資料庫查詢，獲得想要閱讀的各類書籍以及報章雜誌，而這些對於一般人來說是再簡單不過的生活行為，但是對於視障者卻是限制重重，長期以來，因為書籍出版以紙本為形式，視障者在閱讀文字資訊的尋求上，便因視力的障礙而受限。
			</p>

			<p class="text-success no-text-indent">
				【使用現代科技輔具所遇到的困難】
			</p>
			<p>
				視障者獲取文字資訊的途徑目前大約分為兩種，第一種為傳統的盲文(點字書)，第二種為使用現代科技輔具，而使用現代科技輔具閱讀紙本文字的先決條件為，紙本文字必須被轉化為文字式電子檔，但現今因書籍出版版權因素(出版商因版權問題多不願主動提供文字式電子檔)，視障者獲取的資訊總是破碎與非即時的，且因為一般紙本為使視障者能順利閱讀，必須經過時間重新繕打、掃描及校對，其過程所須等待的時間及花費的金錢，往往讓視障者放棄閱讀。
			</p>

			<p class="text-success no-text-indent">
				【過度依賴傳統盲文(點字書)之問題】
			</p>
			<p>
				傳統使用盲文點字的方式，提供視障者閱讀的媒介，但點字形式的文字，缺乏漢字的文字認知概念，因此若在長久依賴之下，視障者常會出現嚴重的同音異字的錯別字現象，而這可能會嚴重影響到其未來的就業發展，在現今依賴電腦文書作業的世代，視障者若因同音異字無法辨識而難以精準的繕打文字，則可能影響就業發展。
			</p>
		</div>

		<div class="bs-callout bs-callout-primary">
			<h3>二、『雲端千眼平台』的目標</h3>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.1 使用文字式電子書的趨勢及優勢</h4>
			<p>
				文字式電子書能透過特殊軟體的搭配，訓練視障者文字上的辨識及認知，建立字型的概念，且文字式電子書除了能夠讓視障者透過本身熟悉的科技輔具閱讀，也能提供給弱視者透過放大功能之輔具使用。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.2 聚集重製電子文件的產能</h4>
			<p>
				『雲端千眼平台』藉由網路平台宣傳以及與各機構合作，招募大量志工進行重製書籍服務，且因服務方式不限地點並可透過網路進行，因此更能聚集。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.3 提供廣泛的書籍閱讀機會</h4>
			<p>
				目前服務機構主要提供給視障者閱讀使用的重製書籍種類偏少，亦無法顧及使用者不同所需的專業需求，尤其對於視障者來說，冷門書籍、專業書籍等等的閱讀權利則礙於服務機構被動地提供形式而受限。因此，『雲端千眼平台』便是希望透過視障者服務，主動對於感興趣的書籍資訊提出需求，進而主動使用此平台取得希望獲取閱讀的資訊，此為視障者｢主動」選擇閱讀，而非傳統多由服務機構選書或票選，被動被選擇可閱讀的書籍。
			</p>
			<p>
				在視障者有主動使用意願之下，我們希望透過長期推廣，未來能讓更多社會大眾及出版商了解視障族群的需求，進而願意提供新書的原始文字電子檔，減少書籍重製的數量。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.4 改良特殊符號閱讀方式，資勛接收更完整</h4>
			<p>
				傳統盲文(點字書)對於特殊符號、科學符號、流程圖、表格…等等，必須與文字分開對應使用，在閱讀的理解上因分步驟而耗時、費力，因此若能透過文字式電子檔，則能夠將特殊符號、科學符號、流程圖、表格…等等與文字一同完整呈現，如此一來，視障者的閱讀便能與一般人幾乎無異，使視障者對於資訊的接收更加完整。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.5 實踐馬拉喀什精神–建立平台、分工合作、資源共享、跨境流通</h4>
			<p>
				《馬拉喀什條約》為有助於盲人、視覺機能障礙者或其他對印刷品閱讀障礙者接觸已公開發行著作之條約，主要以鼓勵各方建立平台、分工合作、資源共享及跨境流通，以利視障者接觸資訊。
			</p>
			<p>
				在著作權的限制下，訂定例外條款，允許已出版的著作能製作成無障礙格式版本並流通，以促成視障與閱讀障礙導致無法閱讀印刷作品或因肢體障礙而不能持書或翻書的人可以接觸或使用作品。
			</p>
			<p>『雲端千眼平台』致力於實踐《馬拉喀什條約》精神：</p>
			<div class="indent-content">
				<p class="list-like-p">
					<span class="text-success">建立平台</span
					>：透過軟體開發平台，建立完善重製流程， 並且自動化管理。
				</p>
				<p class="list-like-p">
					<span class="text-success">分工合作</span
					>：透過資料庫紀錄，分配文件資料對應服務志工，更快更有效率。
				</p>
				<p class="list-like-p">
					<span class="text-success">資源共享</span
					>：平台設有集中式存放重製後電子文件空間，不僅能提供重製服務，更能提供查詢已存入空間之重製後文件，達到資源共享。
				</p>
				<p class="list-like-p">
					<span class="text-success">跨境流通</span
					>：平台以網路型式進行服務，不受地區之限制。
				</p>
			</div>
		</div>

		<div class="bs-callout bs-callout-primary">
			<h3>三、『雲端千眼平台』功能說明</h3>
			<p>
				為了解決紙本文件閱讀困難、轉製電子文件費時費力等等使得視障者閱讀障礙的問題，我們開發了雲端千眼平台，透過群眾參與的方式，一同於雲端上編輯軟體轉換的錯誤，達到提供視障者快速、即時的轉製電子文件的服務。為了群眾參與，在執行上有兩件事必需思考：
			</p>
			<ul class="often">
				<li>如何管理眾多的參與者?</li>
				<li>可提供何種功能提昇使用者體驗?</li>
			</ul>
			<p>
				簡單的說就是 管理 與 服務
				層面。從上述兩點出發，我們開發了一系列的功能，滿足在轉製電子文件流程中志工、視障者、管理者各面向的需求。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.1 管理面</h4>

			<ol class="often is-list-upper-latin">
				<li>
					系統自動化，減少人工管理，達到彈性與可擴充性
					<ol class="often is-list-trad-chinese">
						<li>
							系統採自動式分配文件，服務志工可隨時登入系統領取文件進行編輯轉製，可彈性應付志工人數與服務量增大時，人工處理困難的問題，達到自動化管理。
						</li>
						<li>
							系統設置自動回收機制：系統依預計完成時限，自動回收逾期未編輯之文件，重新開放讓其他服務志工領取編輯，以避免部分志工領取過久而未於期限內完成，影響文件轉製時程。
						</li>
						<li>
							服務志工可由系統即時得知編輯轉製文件之已完成頁數、完成時限、服務時數等資訊。
						</li>
					</ol>
				</li>
				<li>
					嚴謹的時數記錄與統計分析
					<ol class="often is-list-trad-chinese">
						<li>
							透過「鍵盤輸入偵測」，於編輯器內偵測實際鍵盤輸入情形，確保志工確實有編輯動作，以避免掛網卻無編輯而領取志工時數。
						</li>
						<li>
							透過「文字差異分析比對」，將已提交之轉製後文件與初始文件進行比對，計算編輯字數，推算校對比例，判定比例是否
							在常態範圍區間，做為品質控管之參考依據。
						</li>
						<li>
							透過「校對產能推估」，分析服務時數與編輯次數二者關系，以利推估文件校對產能，可預期文件轉製所需時間。
						</li>
					</ol>
				</li>
				<!--<li>
					著作權保護
					<ol class="often is-list-trad-chinese">
						<li>
							透過「個人化浮水印」，依據不同志工給予獨一無二的掃描影像檔，每位志工所獲得的掃描影像檔皆會動態的印上使用者名稱浮水印，防止惡意散佈、重製等等違反著作權規範之行為。
						</li>
					</ol>
				</li>-->
			</ol>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.2 服務面</h4>

			<ol class="often is-list-upper-latin">
				<li>
					友善、易懂、易操作的志工服務編輯介面
					<ol class="often is-list-trad-chinese">
						<li>
							設有直向排列與橫向排列兩種版面配置選擇，以利志工切換橫書及直書介面。
						</li>
						<li>
							可縮放掃描影像，讓志工自行調整掃描影像大小至最舒適的編輯狀態。
						</li>
						<li>
							針對大量文字編輯重置作業，系統更設置記憶校對文件進度功能。當按下標記鈕便會插入標記符號，檔案儲存後，便能記憶文件校對進度位置，並儲存目前掃描影像頁數，以利服務志工再次進入服務頁面時，能夠馬上接續文字編輯轉製，可省去大量時間，避免重複尋找校對之進度。
						</li>
					</ol>
				</li>
				<li>
					系統錯誤偵測更正機制
					<ol class="often is-list-trad-chinese">
						<li>
							透過系統機器自然語言學習，對文件進行自動偵測錯誤更正，以修正志工疏漏之文字，提升文件品質。
						</li>
						<li>
							設立閱讀者回饋機制，當視障者在閱讀文件時，發覺有明顯文字錯誤(日->曰)，可提交回饋更正文件，且該更正回報並立即寫入系統，系統進行機器學習，以提昇自動偵測錯誤更正精準度。
						</li>
					</ol>
				</li>
				<li>
					友善、資訊即時的視障者體驗
					<ol class="often is-list-trad-chinese">
						<li>
							視障者可即時查詢申請之轉製文件完成進度，包括已完成轉製校對頁數、預計完成時間等資訊，使服務有時間可預期性。
						</li>
						<li>
							系統設置平均分配書籍頁面，以每五十頁為單位，分配給予服務志工，因此視障者可先行閱讀完成之分段內容，不需等待一整本書所有分段完成才能閱讀，加快取得資訊時間。
						</li>
						<li>
							系統依據不同瀏覽裝置呈現不同使用者介面，不論是電腦、平版、手機皆能舒適的使用系統，方便視障者可彈性選擇使用系統服務的裝置，而不局限於僅能使用電腦操作。
						</li>
					</ol>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "平台濫觴"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";

.any-about-page {
	// *Useless:
	// ---------------
	// // Purpose: Make it smaller than previous settings,
	// // because the number character change from 一 二 三 to 〇 〇 〇:
	// // ----------------------------------------------------------------
	// --list-like-p-indent-spacing: 1.3em;
}
</style>
