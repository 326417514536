<template>
	<div class="person">
		<h2 v-focus tabindex="0" class="big-title">會員資料</h2>
		<div class="this-is-person-content">
			<template v-if="accessExpCountdown > 0">
				<tab
					:data="[
						{ display_name: '變更會員資訊', type: 'modify-person-info' },
						{ display_name: '修改密碼', type: 'modify-password' },
						{
							display_name: '身心障礙手冊查閱編修',
							type: 'modify-disability-card'
						}
					]"
				>
					<template v-slot:modify-person-info>
						<modify-person-info
							v-model="user"
							@vuex-user-2-local="vuexUser2local()"
						></modify-person-info>
					</template>
					<template v-slot:modify-password>
						<modify-password />
					</template>
					<template v-slot:modify-disability-card>
						<modify-disability-card
							v-if="
								vuexUser.disabilitycard_set &&
									vuexUser.disabilitycard_set.length >= 0
							"
							:pk="vuexUser.disabilitycard_set[0]"
						/>
						<modify-disability-card v-else :pk="''" />
					</template>
				</tab>
			</template>
			<template v-else>
				<p>尚未登入</p>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import tab from "@/components/tab.vue";
import ModifyPersonInfo from "./modify-person-info.vue";
import ModifyPassword from "./modify-password.vue";
import ModifyDisabilityCard from "@/components/components/manageDisabilitycard.vue";

export default {
	name: "Person",
	components: {
		tab,
		ModifyPersonInfo,
		ModifyPassword,
		ModifyDisabilityCard
	},
	data() {
		return {
			user: {
				id: "",
				username: "",
				password: "",
				confirm_password: "",
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				birthday: "",
				education: "",
				is_book: "",
				org: ""
			}
		};
	},
	computed: {
		...mapState("token", ["accessExpCountdown", "refreshExpCountdown"]),
		...mapState("user", {
			vuexUser: "user"
		})
	},
	methods: {
		vuexUser2local() {
			_.each(this.user, (v, k) => {
				this.user[k] = this.vuexUser[k];
			});
		}
	},
	watch: {
		vuexUser() {
			this.vuexUser2local();
		}
	},
	mounted() {
		this.vuexUser2local();
	}
};
</script>

<style lang="scss" scoped></style>
