<!--
	*Accessibility issue between using table vs using Grid layout (display: grid):
	**See, CSS Grid Layout and Accessibility:
	https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/CSS_Grid_Layout_and_Accessibility
	**See, the "bug" of "display: contents" regarding ARIA "role":
	https://hiddedevries.nl/en/blog/2018-04-21-more-accessible-markup-with-display-contents
	**See, Chromium, issue, element not exposed to accessibility tree when it is set to "display: contents":
	https://bugs.chromium.org/p/chromium/issues/detail?id=835455
	**See, the support of css "display: contents" in caniuse.com:
	https://caniuse.com/#search=display%3A%20contents
	*Todo: Except the "tdwidths" props, there need to have more props to pass the min and max width of each column.
	At the same time inside this component, each table cell have to insert one inner child - <div>,
	and set max-width min-width on it.
-->
<template>
	<div class="table-div">
		<div
			class="this-is-table-and-nav-wrapper-if-have-any-data"
			v-if="datas && datas.length > 0"
		>
			<div class="table" role="table">
				<div role="rowgroup" class="thead">
					<div role="row" class="tr">
						<div
							v-for="(value, key, idx) in header"
							:key="idx"
							role="columnheader"
							class="cell"
							:style="tdStyles(idx)"
							:aria-sort="ariaSort(key)"
						>
							<span class="text">
								{{ value }}
								<template v-if="key === orderByColumn">
									<template v-if="orderby === 'asc'">▲</template>
									<template v-if="orderby === 'desc'">▼</template>
								</template>
							</span>
							<span
								role="button"
								class="re-order"
								tabindex="0"
								@click="order(key)"
								@keyup.enter="order(key)"
								:aria-description="`依${value}排序`"
							></span>
						</div>
					</div>
				</div>
				<div role="rowgroup" class="tbody">
					<template v-for="(entry, index) in datas">
						<div
							v-if="index >= start && index < end"
							class="tr"
							role="row"
							:key="index"
						>
							<template v-for="(value, key, idx) in header">
								<div
									v-if="$scopedSlots[key]"
									role="cell"
									class="cell"
									:data-title="value"
									:key="idx"
									:style="tdStyles(idx)"
								>
									<slot :name="key" :item="entry[key]"></slot>
								</div>
								<div
									v-else
									role="cell"
									class="cell"
									:data-title="value"
									:key="idx"
									:style="tdStyles(idx)"
								>
									{{ entry[key] }}
								</div>
							</template>
						</div>
					</template>
				</div>
			</div>
			<a tabindex="0" @click="reclean" class="sr-only">重整</a>
			<nav v-if="numpage > 1" style="">
				<ul class="pagination">
					<li class="page-item">
						<a class="page-link" tabindex="0" @click="pagin_change('-1')">
							<font-awesome-icon icon="angle-left" class="textAside" />上一頁
						</a>
					</li>

					<li
						:class="{ active: pagenow === 1 }"
						v-if="numpage > 0"
						class="page-item"
					>
						<a
							class="page-link"
							tabindex="0"
							@click="pagin_change(1)"
							:aria-description="pagenow === 1 ? '當前頁' : ''"
							>1</a
						>
					</li>

					<li v-if="showPrevMore">
						<a
							class="page-link"
							tabindex="0"
							@click="pagin_change('quickprev')"
							aria-description="往前顯示頁數"
							>...</a
						>
					</li>

					<li
						v-for="pager in pagers"
						:key="pager"
						:class="{ active: pagenow === pager }"
						class="page-item"
					>
						<a
							class="page-link"
							tabindex="0"
							@click="pagin_change(pager)"
							:aria-description="pagenow === pager ? '當前頁' : ''"
							>{{ pager }}</a
						>
					</li>

					<li v-if="showNextMore">
						<a
							class="page-link"
							tabindex="0"
							@click="pagin_change('quicknext')"
							aria-description="往後顯示頁數"
							>...</a
						>
					</li>

					<li
						:class="{ active: pagenow === numpage }"
						class="page-item"
						v-if="numpage > 1"
						:aria-description="pagenow === numpage ? '當前頁' : ''"
					>
						<a class="page-link" tabindex="0" @click="pagin_change(numpage)">{{
							numpage
						}}</a>
					</li>

					<li class="page-item">
						<a class="page-link" tabindex="0" @click="pagin_change('+1')"
							>下一頁</a
						>
					</li>
				</ul>
			</nav>
		</div>
		<div class="no-data" v-else>無資料</div>
	</div>
</template>
<script>
import { base_table } from "@/utils/base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";
export default {
	components: {
		"font-awesome-icon": FontAwesomeIcon
	},
	mixins: [base_table],
	methods: {
		reclean() {
			this.pagin_change(0);
			this.$nextTick(() => {
				this.pagin_change(1);
			});
		}
	}
};
</script>

<style lang="scss" scoped>
$border-color: #ddd;

.pagination {
	display: inline-flex; //置中
}
nav {
	text-align: center;
	cursor: pointer;
}

.table-div,
#debug-200301m235957 {
	.no-data {
		margin-top: 3em;
		text-align: center;
	}
	.table {
		//table-layout:fixed;
		word-wrap: break-word;
		max-width: 100%;
		word-break: break-all;

		display: table;
		width: 100%;
		//margin: calc(0.0625em * 25) 0;
		border-collapse: collapse;
		// *No need border-spacing, since border-collapse will make 2 border merge into one border.
		// border-spacing: 0px;
		margin-top: 3rem;
		.thead,
		.tbody {
			.tr {
				display: table-row;
				text-align: center;

				&:hover {
				}
				.cell {
					display: table-cell;
					padding: calc(0.0625em * 5);
					border: 1px solid $border-color;

					// *Because some content have unexpectedly too long string
					// (no any white space or symbols in the middle):
					// *I prefer using: overflow-wrap, not rather than word-break.
					// -----------------------------------------------------------------------------------------
					// overflow-wrap: break-word;
					// word-break: break-all;
					// Useless:
					// &:nth-child(3) {
					// 	width: 50px !important;
					// 	border: 1px solid yellow !important;
					// }
				}
			}
		}
		.thead {
			display: table-header-group;
			background-color: scale-color($border-color, $lightness: 30%);
			.tr {
				&:hover {
				}
				.cell {
					position: relative;
					padding: calc(0.0625em * 10) calc(0.0625em * 8);
					border: 1px solid scale-color($border-color, $lightness: -7%);
					.text {
						font-weight: bold;
						line-height: 1.42857;
						vertical-align: middle;
					}
					.re-order {
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
					}
				}
			}
		}
		.tbody {
			display: table-row-group;
			.tr {
				&:hover {
					background-color: #f9f9f9;
				}
				.cell {
					vertical-align: middle;
					line-height: 1.42857;
					padding: calc(0.0625em * 8);
				}
			}
		}
	}
}
// (Desktop first).
@media (max-width: media-query-length($em: $breakpoint_md)) {
	$tr-border-color: scale-color(
		$color-water-blue,
		$lightness: 40%,
		$saturation: -30%
	);
	.table-div,
	#debug-200301m235959 {
		.table {
			display: block;
			//margin: 1em auto;

			.tbody {
				.tr {
					display: block;
					margin-top: 1.8em;
					margin-bottom: 1.8em;
					padding: 0.4em 1.3em;
					border: 1px solid $tr-border-color;
					border-radius: calc(0.0625em * 10) !important;
					box-shadow: 0 0 calc(0.0625em * 12)
						scale-color($tr-border-color, $alpha: -60%);
					overflow: hidden;
					text-align: left;
					&:hover {
					}
					.cell {
						display: block;
						// Overwrite desktop.
						border: 0;
						border-bottom: 1px solid $border-color;
						padding-left: 0.6em;
						padding-right: 0.6em;
						// Overwrite desktop.
						width: unset !important;
						&:last-child {
							// Overwrite.
							border-bottom: 0;
						}
						&:before {
							content: attr(data-title);
							display: block;
							width: auto;
							font-weight: 900;
							font-size: 1.2em;
						}
					}
				}
			}
			.thead {
				display: none;
				.tr {
					&:hover {
					}
					.cell {
					}
				}
			}
			.tbody {
				display: block;
				.tr {
					&:hover {
					}
					.cell {
					}
				}
			}
		}
		nav {
		}
	}
}
</style>
