<template>
	<div class="any-about-page container this-is-recuruit">
		<h2 v-focus tabindex="0">招募志工</h2>
		<div class="bs-callout bs-callout-success">
			<p class="text-success">
				雲端千眼招募志工影片：86,400分鐘=視障與書的距離
			</p>
			<div class="iframe-wrapper keeping-aspect-ratio-layer-1">
				<div class="keeping-aspect-ratio-layer-2">
					<div class="keeping-aspect-ratio-layer-3">
						<iframe
							src="https://www.youtube.com/embed/Dnm6pUsdbuk"
							style="border:0;"
							allowfullscreen
						></iframe>
					</div>
				</div>
			</div>
			<!--<p class="text-success">雲端千眼招募志工影片：讓我們一起Eye在雲端</p>-->
			<!--<iframe width="560" height="315" src="https://www.youtube.com/embed/FxxPaULkGPA" frameborder="0" allowfullscreen></iframe>-->
		</div>

		<div class="bs-callout bs-callout-primary">
			<h3>一、前言</h3>
			<ol class="often is-list-decimal">
				<li>您是否認同閱讀的重要性？</li>
				<li>您是否認同累積知識才能提升個人能力，進而改變生活品質？</li>
				<li>
					對大部分人們而言，書籍與知識的取得唾手可得，但可曾想過：如此簡單的美好，對視障者而言即便克服種種障礙，卻依舊無法輕易享受？
				</li>
			</ol>
		</div>

		<div class="bs-callout bs-callout-primary">
			<h3>二、視障者面臨困境及平台成立動機</h3>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.1 書本、資訊取得的困難</h4>
			<p>
				長期以來，因書籍大多仍以紙本形式出版，使得視障者在閱讀文字資訊時便會因視力的障礙而受限。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.2 過度依賴傳統點字之問題</h4>
			<p>
				傳統使用點字作為視障者閱讀的媒介，但點字缺乏漢字的文字認知概念，視障者常會出現嚴重同音異字的錯別字現象，而這將會嚴重影響到其未來的就業發展。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>2.3 使用現代科技輔具所遇到的困難</h4>
			<p>
				除了傳統的點字取得文字資訊之外，另一種取得途徑為使用現代科技輔具，而使用現代科技輔具閱讀的先決條件為：紙本文字必須先轉為文字式電子檔。現今除出版商因版權問題而不願主動提供文字式電子檔，使得視障者獲取的資訊總是破碎與非即時之外；也因一般紙本為使視障者能順利閱讀，必須經重新撰打、掃描及校對，其過程所須等待的時間及花費的金錢，往往讓視障者放棄閱讀。
			</p>
			<p>
				視障者因上述限制，既無法輕易取得電子書，也無法閱讀紙本書，導致長期處於資訊不充足、不平等的劣勢，在毫無選擇或選擇機會甚少的情況下，只能從事基礎的傳統性工作，因此而形成了：不易取得知識、不易提升個人技能，使得無足夠就業機會可選擇，最後導致不易擁有良好生活的惡性循環。而「eDocumentService雲端千眼平台」的存在，目的為了終止此惡性循環。加入我們，您就可以為推廣閱讀、散播知識盡一份力，為視障者的世界帶來璀璨光束，引領他們能順利走進書海、投入知識的懷抱當中。
			</p>
		</div>

		<div class="bs-callout bs-callout-primary">
			<h3>三、志工任務及平台介紹</h3>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.1 運用雲端編輯技術，改變傳統志工型式</h4>
			<p>
				「eDocumentService雲端千眼平台」招募大量志工於線上進行重製書籍服務，不如一般志工型態需固定排班或前往特定地點，只要您有時間、有網路，無論何時何地皆能進行志工服務，讓您的愛不再受到限制！
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.2 彈性選擇承接校對文件量</h4>
			<p>
				為不讓志工服務成為一種束縛與負擔，系統切分縮小文件量，完成分段後在評估自身時間領取下部份文件。
			</p>
			<p>
				您欲使世界更美好的愛不應受時間、地點等限制而有所動搖。以往需每周排班的志工老是無法參與？不想參加事前志工培訓，想更直接、更快速為世界貢獻嗎？那麼，「eDocumentService雲端平台」會是您的最佳選擇，在這裡，一個人的微薄之力，也能改變一群人的世界廣度。現在，就加入我們吧！
			</p>
		</div>

		<div class="bs-callout bs-callout-success">
			<p class="text-success">
				「我們可能無法做偉大的事，但可以用偉大的愛做小事。」──泰瑞莎修女
			</p>
			<p class="text-success">
				「有能力的人，請你把你的光照得更遠一點。」──龍應台
			</p>
		</div>

		<div class="bs-callout bs-callout-primary">
			<h3>聯絡我們</h3>
			<ul class="often">
				<li><a href="https://www.edocumentservice.org/">雲端千眼平台</a></li>
				<!--<div>※<a href="https://www.facebook.com/groups/1740566082878636/">FB社群志工</a></div>-->
				<li>平台提供志工時數證明，詳情請電洽(02)2717-7722 分機13 陳專員</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";
@import "~@/style/general_optional/video-iframe.scss";
</style>
