<template>
	<div>
		<h2 class="big-title" v-focus tabindex="-1">校對服務</h2>
		<service-org :choice_org="true"> </service-org>
	</div>
</template>

<script>
import serviceOrg from "@/components/serviceOrg.vue";

export default {
	components: {
		serviceOrg
	},
	metaInfo: {
		title: "校對服務"
	}
};
</script>
