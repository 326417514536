<template>
	<bookinfo-repository :datas="books"></bookinfo-repository>
</template>

<script>
import { mapState } from "vuex";
import { bookInfoRest } from "@/api/ebookSystem.js";
import bookinfoRepository from "../components/bookinfoRepository.vue";

export default {
	components: {
		bookinfoRepository
	},
	data() {
		return {
			books: []
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	metaInfo: {
		title: "平台書庫"
	},
	mounted() {
		const categoryId = this.$route.params.categoryId;
		this.category_read(categoryId);
	},
	methods: {
		category_read(category) {
			let param;
			this.book = [];
			try {
				if (category.endsWith("null") && category.split("-").length === 2) {
					let org_id = category.split("-")[0];
					let category_id = category.split("-")[1];
					param = { org_id: org_id, category_id: category_id };
				} else {
					param = { category_id: category };
				}
			} catch (err) {
				param = { category_id: category };
				this.$errorHandler({ error: err });
			}
			bookInfoRest
				.filter(param)
				.then(res => {
					this.books = [];
					_.each(res.data, b => {
						b["action"] = b.ISBN;
						this.books.push(b);
					});
				})
				.catch(res => {
					// alertmessage("error", o2j(res.response.data));
					this.$errorHandler({ error: res });
				});
		}
	},
	watch: {
		"$route.params.categoryId": {
			handler: function() {
				this.books = [];
				const categoryId = this.$route.params.categoryId;
				this.category_read(categoryId);
			}
		}
	}
};
</script>

<style scoped lang="scss">
// .fade-enter-active, .fade-leave-active {
//   transition: opacity .5s;
// }

// .fade-leave-active {
//   transition: opacity .1s;
// }
// .fade-enter, .fade-leave-to {
//   opacity: 0;
// }

// .heading-btns {
// 	margin-left: 1.5rem;
// }

.btn-outline-secondary {
	background-color: #e7e7e7; /* Green */
	border: 2px solid black;
	color: black;
	padding: 13px 16px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 15px;
	transition-duration: 0.2s;
	border-radius: 8px;
	float: right;
}
.btn-outline-secondary:hover {
	background-color: whitesmoke;
}
h3 {
	font-size: 17pt;
}
/deep/ .table {
	margin-top: 23px !important;
}
a {
	text-decoration: none;
	color: blue;
	&:hover {
		text-decoration: none;
		a {
			color: #2d76b5;
		}
	}
}
.no-pd-left {
	padding-left: 0;
}
.panel-title {
	margin: 0px;
	padding: 12px 20px;
	font-size: 15px;
	font-weight: bold;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem 0.24rem 0 0;
}
.list-group-item {
	&:hover {
		background: darkgray;
		color: #225a8a;
	}
	&:first-child {
		border-top: 0px !important;
		border-radius: 0 0 0.25rem 0.25rem;
	}
}
.depart-name {
	margin-left: -13px;
}
</style>
