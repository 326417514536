<template>
	<div id="genericUser_register">
		<div class="form-group text-center">
			<h2 v-focus tabindex="0">註冊</h2>
		</div>
		<div class="form-horizontal" style="margin-top:30px;">
			<template
				v-for="v in [
					'username',
					'password',
					'confirm_password',
					'name',
					'email',
					'phone',
					'birthday',
					'education',
					'is_book',
					'org',
					'role',
					'userterms',
					'termscheck'
				]"
			>
				<formdrf
					:model_info="model_infos[v]"
					:field="v"
					v-model="infos[v]"
					:key="v"
				></formdrf>
			</template>
			<div style="text-align: center;">
				<input
					type="button"
					class="btn btn-primary"
					value="註冊"
					@click="checkInput()"
				/>
				<input
					type="reset"
					class="btn btn-danger"
					value="清除"
					@click="clearInput()"
				/>
			</div>
			<!-- <div class="form-group">
				<label class="control-label col-sm-2 col-sm-offset-2" for="userterms"><font style="color:red">*</font><span>使用者條款隱私權政策</span></label>
				<div class="col-sm-7">
					<textarea id="userterms" class="form-control" readonly style="width:100%;height: 300px; resize: none;">
						{{ license }}
					</textarea>
					<label>
						<input type="checkbox" v-model="isPrivacyAgree">
						<span>我同意此條款</span>
					</label>
				</div>
			</div> -->
		</div>
		<div class="form-group">
			<label class="control-label col-sm-2 col-sm-offset-2"></label>
		</div>
	</div>
</template>

<script>
import formdrf from "@/components/form2.vue";
import { userRest, organizationRest } from "@/api/genericUser";
import { license } from "./license.js";

export default {
	components: {
		formdrf
	},
	data() {
		return {
			license,
			usertermsContent: "",
			// isPrivacyAgree: true,
			infos: {
				username: "",
				email: "",
				name: "",
				phone: "",
				birthday: "",
				education: "",
				is_book: true,
				org: "1",
				password: "",
				confirm_password: "",
				role: "editor",
				disabilitycard_set: [],
				userterms: license,
				termscheck: true
			},
			model_infos: {
				username: {
					label: "使用者名稱",
					type: "string",
					required: true,
					remark: "請使用小寫英文字母或數字，首字必需為小寫英文字母"
				},
				password: {
					label: "密碼",
					type: "password",
					required: true,
					remark: ""
				},
				confirm_password: {
					label: "確認密碼",
					type: "password",
					required: true,
					remark: ""
				},
				name: {
					label: "名字",
					type: "string",
					required: true,
					remark: "請填寫中文名字"
				},
				email: {
					label: "電子信箱",
					type: "email",
					required: true,
					remark: "請填寫電子信箱"
				},
				phone: {
					label: "聯絡電話",
					type: "string",
					required: true,
					remark: "請填寫手機"
				},
				birthday: {
					label: "生日",
					type: "date",
					required: true,
					remark: "請填寫生日，範例日期格式：1989-02-19"
				},
				education: {
					label: "教育程度",
					type: "select",
					remark: "請選擇教育程度",
					choices: [
						{
							value: "",
							display_name: "---------"
						},
						{
							value: "國小",
							display_name: "國小"
						},
						{
							value: "國中",
							display_name: "國中"
						},
						{
							value: "高中",
							display_name: "高中"
						},
						{
							value: "學士",
							display_name: "學士"
						},
						{
							value: "碩士",
							display_name: "碩士(以上)"
						}
					]
				},
				is_book: {
					label: "訂閱訊息",
					type: "checkbox",
					remark: "訂閱訊息"
				},
				org: {
					label: "所屬單位",
					type: "select",
					remark: "請選擇所屬單位",
					choices: []
				},
				role: {
					label: "角色",
					type: "select",
					remark: "請填寫角色",
					choices: [
						{
							value: "editor",
							display_name: "志工"
						},
						{
							value: "guest",
							display_name: "身障者"
						}
					]
				},
				userterms: {
					label: "使用者條款隱私權政策",
					break: true,
					type: "textarea",
					required: true,
					disabled: true
				},
				termscheck: {
					type: "checkbox",
					cbValue: "我同意此條款",
					label: ""
				}
			}
		};
	},
	computed: {
		licenseShow: () => {
			return license;
		}
	},

	metaInfo: {
		title: "註冊"
	},
	mounted() {
		organizationRest
			.list()
			.then(res => {
				_.each(res.data, v => {
					this.model_infos.org.choices.push({
						value: v.id,
						display_name: v.name
					});
				});
			})
			.catch(res => {
				// if (res.response && res.response.data) {
				// 	this.$root.$message.open({ status: 400, message: res.response.data });
				// } else {
				// 	this.$root.$message.open({
				// 		status: 400,
				// 		message: JSON.stringify(res)
				// 	});
				// }
				this.$errorHandler({ error: res });
			});
	},
	methods: {
		checkInput() {
			let err = [];
			let username = this.infos.username;
			if (username.length < 5) {
				err.push("使用者名稱需5個字元以上");
			} else if (username.length > 150) {
				err.push("使用者名稱需150個字元以下");
			} else {
				let r1 = /^[a-z]{1}/;
				if (!r1.test(username)) {
					err.push("使用者名稱字首非小寫英文");
				}
				let r2 = /^[a-z0-9_]+$/;
				if (!r2.test(username)) {
					err.push("使用者名稱需使用小寫英文字母、數字或底線");
				}
			}
			let password = this.infos.password;
			if (password.length < 8) {
				err.push("密碼需8個字元以上");
			} else if (password.length > 150) {
				err.push("密碼需150個字元以下");
			} else {
				let r1 = /^[a-zA-Z0-9_]+$/;
				if (!r1.test(password)) {
					err.push("密碼需使用大、小寫英文字母、數字與底線");
				}
				let passwordcf = this.infos.confirm_password;
				if (password !== passwordcf) {
					err.push("密碼與確認密碼不相符");
				}
			}
			//name
			let name = this.infos.name;
			if (name === "") {
				err.push("姓名不得為空");
			}
			//email
			const email = this.infos.email;
			if (!(email === "")) {
				let r1 = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
				if (!r1.test(email)) {
					err.push("電子信箱格式錯誤");
				}
			}

			//phone
			const phone = this.infos.phone;
			if (!(phone === "")) {
				let r1 = /^[0-9]{10}$/;
				if (!r1.test(phone)) {
					err.push("手機號碼格式錯誤");
				}
			}
			//birthday
			let birthday = this.infos.birthday;
			if (birthday === "") {
				err.push("生日不得為空");
			} else {
				let r1 = /^([0-9]{4})[-]{1}([0-9]{2})[-]{1}([0-9]{2})$/;
				if (!r1.test(birthday)) {
					err.push(
						"生日格式錯誤：a.使用年月日的順序。 b.年需使用西元年為4位數字，月與日為2位數字，不足時需補0。 c.年月日中間使用-為分隔，例(1989-02-19)。"
					);
				}
			}
			//education
			let education = this.infos.education;
			if (education === "") {
				err.push("教育程度尚未選擇");
			}
			let org = this.infos.org;
			if (org === "") {
				err.push("所屬單位尚未選擇");
			}
			//role
			let role = this.infos.role;
			if (role === "") {
				err.push("角色尚未選擇");
			}
			//Privacy agree
			if (this.infos.termscheck !== true) {
				err.push("需同意使用者條款");
			}

			if (role === "editor") {
				if (phone === "" || email === "") {
					err.push("電子郵件與聯絡電話需填寫");
				}
			} else if (role === "guest") {
				if (phone === "" && email === "") {
					err.push("電子郵件或聯絡電話需擇一填寫");
				}
			}

			if (err.length > 0) {
				this.$root.$message.open({ status: 400, message: err });
				return false;
			}
			this.register();
			return true;
		},
		clearInput() {},
		register() {
			if (this.infos.role === "editor") {
				this.infos.is_editor = true;
				this.infos.is_guest = false;
			} else if (this.infos.role === "guest") {
				this.infos.is_editor = false;
				this.infos.is_guest = true;
			}
			userRest
				.create(this.infos)
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message:
							"成功註冊帳號，請至「帳號>個人資料」頁面驗證手機與電子信箱，視障者需額外登錄身障手冊，方可開始使用。"
					});
				})
				.then(() => {
					this.$router.push({ name: "login" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style scoped lang="scss"></style>
