<template>
	<div class="row tab" :style="styleRow">
		<nav class="menu" :style="styleNav">
			<ul class="nav flex-column nav-pills">
				<div class="tab-item font-bold">
					<div>
						<font-awesome-icon icon="briefcase" />
						<div style="margin-left: 10px;">
							{{ title }}
						</div>
					</div>
				</div>
				<a
					v-for="(item, index) in data"
					:key="index"
					:class="['tab-item', { active: tab_index === index }]"
					@click="
						tab_index = index;
						$emit('tab-click', index);
					"
					tabindex="0"
					:aria-expanded="String(index === tab_index)"
					role="link"
				>
					{{ item.display_name }}
				</a>
			</ul>
		</nav>
		<!-- <select v-model="tab_index" class="custom-select">
			<option 
				v-for="(item, index) in data"
				:key="index"
				:value="index"
			>
				{{ item.display_name }}
			</option>
		</select> -->
		<cust-select v-model="tab_index" :optionList="data">
			<template slot="option" slot-scope="{ item }">{{
				item.display_name
			}}</template>
		</cust-select>
		<div
			class="tab-content"
			v-for="(item, index) in data"
			:style="{ display: display(index) }"
			:key="index"
		>
			<h2 v-if="headinglevel === 2" class="sr-only">{{ item.display_name }}</h2>
			<h3 v-if="headinglevel === 3" class="sr-only">{{ item.display_name }}</h3>
			<h4 v-if="headinglevel === 4" class="sr-only">{{ item.display_name }}</h4>
			<h5 v-if="headinglevel === 5" class="sr-only">{{ item.display_name }}</h5>
			<h6 v-if="headinglevel === 6" class="sr-only">{{ item.display_name }}</h6>
			<div class="tab-item" v-if="$scopedSlots[item.type]">
				<slot :name="item.type" :item="item.data"></slot>
			</div>
			<div class="tab-item" style="float:right" v-else>{{ item.data }}</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		data: Array, // define type
		headinglevel: Number,
		styleRow: {
			type: Object
		},
		styleNav: {
			type: Object
		}
		// For screen reader only.
	},
	data() {
		return {
			tab_index: 0
		};
	},
	methods: {
		display(index) {
			return this.tab_index == index ? "" : "none";
		},
		next() {
			this.tab_index = this.tab_index + 1;
		},
		previous() {}
	},
	components: {
		custSelect: () => import("./custSelect")
	}
};
</script>

<style scoped lang="scss">
.tab {
	nav {
		width: 25%;
		ul {
			padding-left: 0;
			margin-right: 20px;
			@media screen and (max-width: 767px) {
				display: none;
			}
			.tab-item {
				border: 1px solid lightgray;
				color: blue;
				font-size: 15px;
				border-bottom: 0px;
				cursor: pointer;
				display: flex;
				padding: 15px;
				&:hover {
					color: scale-color($color-water-blue, $lightness: -24%);
					background: lightgray;
				}

				&.active {
					color: #555;
					text-decoration: none;
				}

				// Purpose: Overwritting some anchor defualt style.
				&:hover,
				&:focus,
				&:link,
				&:visited {
					outline: none;
				}
				&.font-bold {
					font-weight: bold;
					cursor: auto;
					display: flex;
					align-items: center;
					div {
						display: flex;
						align-items: center;
					}
				}
				&:first-child {
					border-radius: 5px 5px 0 0;
					position: relative;
					color: black;
					background: white;
				}
				&:last-child {
					border-radius: 0 0 2px 2px;
					border-bottom: 1px solid lightgray;
				}
			}
		}
	}
	select {
		display: none;
		@media screen and (max-width: 767px) {
			display: block;
			width: 90%;
			margin: 0 auto;
		}
	}
	.tab-content {
		width: 75%;
		@media screen and (max-width: 767px) {
			width: 90%;
			margin: 0 auto;
			/deep/ h3 {
				margin-top: 25px;
			}
		}
	}
	.tab-header {
		//display: list-item;
		//justify-content: flex-start;
		// align-content: stretch;
		float: left;

		// Shared :before style.
		.tab-item,
		.visual-purpose-long-border-bottom {
		}

		.tab-item {
			position: relative;
			box-sizing: border-box;
			padding: 0.7em 1.2em;
			color: $color-water-blue;
			transition: all var(--hover-transition);
			cursor: pointer;
			text-decoration: none;

			&:hover {
				color: scale-color($color-water-blue, $lightness: -24%);
				background: lightgray;
			}

			&.active {
				color: #555;
				// Don't use bold font-style, because it increase the spacing, maving everyting nearby,
				// causing visually tiny "glitchy".
				// font-weight: bold;
			}

			// Purpose: Overwritting some anchor defualt style.
			&:hover,
			&:focus,
			&:link,
			&:visited {
				outline: none;
			}
		}

		.visual-purpose-long-border-bottom {
			position: relative;
			flex-grow: 1;
		}
	}
}
a {
	display: list-item;
}
</style>
