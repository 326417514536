<template>
	<div>
		<h2 v-focus tabindex="0">公告列表</h2>
		<tab :headinglevel="3" :data="data">
			<template #table="props">
				<table-div :datas="props.item" :header="announcement_columns">
					<template #action="props">
						<a
							class="btn btn-manage"
							role="button"
							@click="
								$router.push({
									name: 'announcement',
									params: { id: props.item }
								})
							"
							>閱讀全文</a
						>
					</template>
				</table-div>
			</template>
		</tab>
	</div>
</template>

<script>
import tab from "@/components/tab.vue";
import tableDiv from "@/components/tableDiv.vue";
import { announcementRest } from "@/api/genericUser.js";

export default {
	components: {
		tab,
		tableDiv
	},
	data() {
		return {
			announcement_columns: {
				title: "標題",
				datetime: "發佈日期",
				action: "動作"
			},
			data: [
				{
					display_name: "平台消息",
					value: "平台消息",
					type: "table",
					data: []
				},
				{
					display_name: "天橋說書",
					value: "天橋說書",
					type: "table",
					data: []
				},
				{
					display_name: "新書推薦",
					value: "新書推薦",
					type: "table",
					data: []
				},
				{
					display_name: "志工快訊",
					value: "志工快訊",
					type: "table",
					data: []
				}
			]
		};
	},
	metaInfo: {
		title: "公告列表"
	},
	mounted() {
		this.get_table_data();
	},
	methods: {
		get_table_data() {
			_.each(this.data, v => {
				let query = { category: v.value };
				announcementRest.filter(query).then(res => {
					let filter_data = [];
					_.each(res.data, v => {
						filter_data.push({
							id: v.id,
							title: v["title"],
							datetime: v["datetime"],
							action: v.id
						});
					});
					v.data = filter_data;
				});
			});
		}
	}
};
</script>
