<template>
	<header>
		<!--
			*Available Bootstrap class:
			**.navbar: required class.
			**.navbar-expand-lg: Set breakpoint at "lg" size.
			**.bg-dark: Use dark background theme.
			**.navbar-dark: Use proper text color for dark background theme.
			**.fixed-top: See https://getbootstrap.com/docs/4.1/components/navbar/#placement.
		-->
		<nav
			class="navbar navbar-expand-xl bg-dark navbar-dark fixed-top"
			role="navigation"
		>
			<!-- *Purpose: Except background color block/bar will have left and right edge sticked with
			viewport, the rest parts/content inside will all center aligned. --->
			<div class="container-fluid is-container-max-width">
				<!-- Brand text. -->
				<a
					class="sr-only"
					tabindex="0"
					@click="$router.push({ name: 'home' })"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					:aria-expanded="removeae"
					><h1>eDocumentService</h1></a
				>

				<a
					class="navbar-brand"
					aria-hidden="true"
					tabindex="-1"
					@click="$router.push({ name: 'home' })"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					:aria-expanded="removeae"
					>eDocumentService</a
				>

				<!--
					*Hamburger button.
					*Remember to sync "navbarSupportedContent" (or any id name you like) in 3 places.
				-->
				<button
					class="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="功能選單"
				>
					<span class="sr-only">功能選單</span>
					<span class="navbar-toggler-icon" aria-hidden="true"></span>
				</button>

				<!-- Todo: active .nav-item feature is unfinished. -->
				<div id="navbarSupportedContent" class="collapse navbar-collapse">
					<!-- For nav_item_mode_normal_main and nav_item_mode_school_main. -->
					<ul class="navbar-nav mr-auto">
						<template v-for="(item, index) in nav_item_main_computed">
							<template v-if="item.type == 'folder'">
								<li class="nav-item dropdown" :key="index">
									<a
										class="nav-link dropdown-toggle"
										tabindex="0"
										:id="`navbarDropdown_${index}`"
										data-toggle="dropdown"
										role="link"
										aria-haspopup="true"
										aria-expanded="false"
										>{{ item.display_name }}</a
									>
									<div
										class="dropdown-menu"
										:aria-labelledby="`navbarDropdown_${index}`"
									>
										<ul class="nopoint">
											<template v-for="(item, key) in item.items">
												<li :key="key">
													<template v-if="item.type == 'item'">
														<a
															tabindex="0"
															class="dropdown-item"
															@click.prevent="linkToUrlOrRoute(item)"
															data-toggle="collapse"
															data-target="#navbarSupportedContent"
															aria-controls="navbarSupportedContent"
															:aria-expanded="removeae"
															>{{ item.display_name }}</a
														>
													</template>
													<template v-if="item.type == 'action'">
														<a
															tabindex="0"
															class="dropdown-item"
															@click="item.action(that)"
															data-toggle="collapse"
															data-target="#navbarSupportedContent"
															aria-controls="navbarSupportedContent"
															:aria-expanded="removeae"
															>{{ item.display_name }}</a
														>
													</template>
												</li>
											</template>
										</ul>
									</div>
								</li>
							</template>
							<template v-if="item.type == 'item'">
								<li class="nav-item" :key="index">
									<a
										tabindex="0"
										class="nav-link"
										@click.prevent="linkToUrlOrRoute(item)"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										:aria-expanded="removeae"
										>{{ item.display_name }}</a
									>
								</li>
							</template>
							<template v-if="item.type == 'action'">
								<li class="nav-item" :key="index">
									<a
										class="nav-link"
										tabindex="0"
										@click="item.action(that)"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										:aria-expanded="removeae"
									>
										{{ item.display_name }}</a
									>
								</li>
							</template>
						</template>
					</ul>

					<!-- For nav_item_mode_normal_right and nav_item_mode_school_right. -->
					<ul class="navbar-nav navbar-right">
						<template v-for="(item, index) in nav_item_right_computed">
							<template v-if="item.type == 'folder'">
								<li class="nav-item dropdown" :key="index">
									<a
										class="nav-link dropdown-toggle"
										tabindex="0"
										:id="`navbarDropdown_${index}`"
										data-toggle="dropdown"
										role="link"
										aria-haspopup="true"
										aria-expanded="false"
										>{{ item.display_name }}</a
									>
									<div
										class="dropdown-menu"
										:aria-labelledby="`navbarDropdown_${index}`"
									>
										<ul class="nopoint">
											<template v-for="(item, key) in item.items">
												<li :key="key">
													<template v-if="item.type == 'item'">
														<a
															tabindex="0"
															class="dropdown-item"
															@click.prevent="linkToUrlOrRoute(item)"
															data-toggle="collapse"
															data-target="#navbarSupportedContent"
															aria-controls="navbarSupportedContent"
															:aria-expanded="removeae"
															>{{ item.display_name }}</a
														>
													</template>
													<template v-if="item.type == 'action'">
														<a
															tabindex="0"
															class="dropdown-item"
															@click="item.action(that)"
															data-toggle="collapse"
															data-target="#navbarSupportedContent"
															aria-controls="navbarSupportedContent"
															:aria-expanded="removeae"
															>{{ item.display_name }}</a
														>
													</template>
												</li>
											</template>
										</ul>
									</div>
								</li>
							</template>
							<template v-if="item.type == 'item'">
								<li class="nav-item" :key="index">
									<a
										class="nav-link"
										tabindex="0"
										@click.prevent="linkToUrlOrRoute(item)"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										:aria-expanded="removeae"
										>{{ item.display_name }}</a
									>
								</li>
							</template>
							<template v-if="item.type == 'action'">
								<li class="nav-item" :key="index">
									<a
										class="nav-link"
										tabindex="0"
										@click="item.action(that)"
										data-toggle="collapse"
										data-target="#navbarSupportedContent"
										aria-controls="navbarSupportedContent"
										:aria-expanded="removeae"
										>{{ item.display_name }}</a
									>
								</li>
							</template>
						</template>
						<li class="nav-item"></li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
</template>

<script>
import {
	nav_item_mode_normal_main,
	nav_item_mode_normal_right
} from "./navItemData.js";
import { item_show } from "./navItemMethod.js";
import { mapState, mapMutations } from "vuex";

export default {
	data() {
		return {
			item_show,
			removeae: false,
			that: this
		};
	},
	computed: {
		nav_item_main_computed() {
			return this.item_show(nav_item_mode_normal_main, this.user);
		},
		nav_item_right_computed() {
			return this.item_show(nav_item_mode_normal_right, this.user);
		},
		...mapState(["mode"]),
		...mapState("user", ["user"])
	},
	methods: {
		...mapMutations(["setMode"]),
		...mapMutations("token", ["remove"]),
		// Purpose: Link to a url, to a route path, or to a route name, by given data.
		linkToUrlOrRoute(item) {
			function collapseBootstrapNavbarManually() {
				let navbarToggleBtn = document.getElementsByClassName(
					"navbar-toggler"
				)[0];
				navbarToggleBtn.click();
			}
			if (typeof item.url !== "undefined") {
				window.location.replace(item.url);
			} else if (typeof item.route !== "undefined") {
				this.$router.push(item.route);
				// collapseBootstrapNavbarManually();
			} else if (typeof item.routePath !== "undefined") {
				this.$router.push({ path: item.routePath });
				// collapseBootstrapNavbarManually();
			} else {
				this.$router.push({ name: item.routeName });
				// collapseBootstrapNavbarManually();
			}
			const width = document.documentElement.clientWidth;
			if (width < 768) {
				collapseBootstrapNavbarManually();
			}
			this.removeae = true;
			setTimeout(() => {
				this.removeae = false;
			}, 100);
		}
	}
};
</script>

<style lang="scss" scoped>
// *It works, but I don't need this now, I use <nav class="navbar bg-dark"> and
// also custom variable with {{ $dark: red; }}, instead.
// .navbar {
//   background-color: red;
// }

header {
	// *Purpose: Don't let below content ,, the toppest part , been overlap by .navbar,
	// since .navbar is {{ position: fixed }}.
	// *Value: Arbitrary value that is big enough.
	height: 4.5em;
	// border: 1px solid blue;

	a {
		text-decoration: none;
	}

	.navbar {
		// Purpose: Always show pointer for each nav item (of bootstrap), even that nav-item have no url (not a link).
		.nav-link,
		/*.dropdown-menu*/ .dropdown-item {
			cursor: pointer;
		}
		// Purpose: On mobile, when the height of navbar is overflow, provide scrollbar.
		.navbar-collapse {
			--arbitrary-rough-value-big-enough-to-solve-overflow-problem: 5em;
			max-height: calc(
				100vh -
					var(--arbitrary-rough-value-big-enough-to-solve-overflow-problem)
			);
			overflow-y: auto;
		}
	}
}

@media (min-width: media-query-length($em: map-get($grid-breakpoints, xl))) {
	header {
		.navbar {
			// Unset, to fix unexpected unfunctional problem.
			.navbar-collapse {
				max-height: unset;
				overflow-y: unset;
			}
		}
	}
}

.nopoint {
	list-style-type: none;
	padding: 0px;
}
</style>
