var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"big-title",attrs:{"tabindex":"0"}},[_vm._v("平台管理")]),_c('tab',{attrs:{"data":[
			{ display_name: '業務管理', type: 'items', data: _vm.itemManage.business },
			{
				display_name: '統計資訊',
				type: 'items',
				data: _vm.itemManage.statistics
			},
			{ display_name: '內容管理', type: 'items', data: _vm.itemManage.content }
		]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('ul',[_vm._l((props.item),function(item,index){return [(item.type == 'folder')?[_c('li',{key:index},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(index + 1)+". "+_vm._s(item.display_name))]),_c('ul',[_vm._l((item.items),function(item,index_folder){return [(item.type == 'item')?[_c('li',{key:index_folder,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.linkToUrlOrRoute(item)}}},[_vm._v(_vm._s(index + 1)+"-"+_vm._s(index_folder + 1)+". "+_vm._s(item.display_name))])])]:_vm._e(),(item.type == 'action')?[_c('li',{key:index_folder,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){return item.action(_vm.that)}}},[_vm._v(_vm._s(index + 1)+"-"+_vm._s(index_folder + 1)+". "+_vm._s(item.display_name))])])]:_vm._e()]})],2)])]:_vm._e(),(item.type == 'item')?[_c('li',{key:index,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.linkToUrlOrRoute(item)}}},[_vm._v(_vm._s(index + 1)+". "+_vm._s(item.display_name))])])]:_vm._e(),(item.type == 'action')?[_c('li',{key:index,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){return item.action(_vm.that)}}},[_vm._v(_vm._s(index + 1)+". "+_vm._s(item.display_name))])])]:_vm._e()]})],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }