<template>
	<div>
		<modal v-if="tokenModalShow" @closeModal="close">
			<template slot="header">
				<h4 class="modal-title">請輸入帳號密碼</h4>
			</template>
			<template slot="body">
				<div class="form-horizontal">
					<template v-for="v in ['username', 'password']">
						<formdrf
							:model_info="loginModel[v]"
							:field="v"
							v-model="loginData[v]"
							:key="v"
						></formdrf>
					</template>
				</div>
			</template>
			<template slot="footer">
				<button class="btn btn-primary" @click="login">送出</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import formdrf from "@/components/form.vue";
import modal from "@/components/popup/modal.vue";
import { userAction } from "@/api/genericUser";
import { isNumber } from "@/utils/type";

export default {
	props: {},
	name: "token",
	components: {
		formdrf,
		modal
	},
	data() {
		return {
			promise: null,
			loginData: {
				username: "",
				password: ""
			},
			loginModel: {
				username: {
					label: "帳號",
					type: "text",
					required: true
				},
				password: {
					label: "密碼",
					type: "password",
					required: true
				}
			}
		};
	},
	computed: {
		...mapState("token", {
			tokenModalShow: "dialog"
		}),
		...mapState("token", ["accessExpCountdown", "refreshExpCountdown"]),
		...mapGetters("token", ["accessInfo", "refreshInfo"]),
		...mapState("user", ["user"])
	},
	watch: {
		accessInfo() {
			this.readUser(this.accessInfo.user_id).then(async () => {
				if (this.user.is_guest) {
					let res, expire_countdown;
					try {
						res = await userAction.guestExtraInfo(this.user.id);
						expire_countdown = res.data.expire_countdown;
					} catch (error) {
						expire_countdown = null;
					}
					if (
						isNumber(expire_countdown) &&
						expire_countdown <= 30 &&
						expire_countdown >= 0
					) {
						this.$root.$message.open({
							status: 200,
							message: "身障手冊將於30日內到期請更新身障手冊"
						});
					} else if (isNumber(expire_countdown) && expire_countdown < 0) {
						this.$root.$message.open({
							status: 200,
							message: "身障手冊已到期請更新身障手冊"
						});
					}
				}
			});
		}
	},
	created() {
		this.$root.$token = this;
	},
	mounted() {
		this.load();
		// same for route
		this.$store.commit("token/calculateAccessExpCountdown");
		this.$store.commit("token/calculateRefreshExpCountdown");
		if (this.$store.state.token.accessExpCountdown > 0) {
		} else if (this.$store.state.token.refreshExpCountdown > 0) {
			this.$store.dispatch("token/refresh").then(() => {
				this.$store.commit("token/calculateAccessExpCountdown");
				this.$store.commit("token/calculateRefreshExpCountdown");
			});
		}
	},
	methods: {
		...mapActions("user", {
			readUser: "read"
		}),
		...mapActions("token", ["obtain"]),
		...mapMutations("token", [
			"load",
			"calculateAccessExpCountdown",
			"calculateRefreshExpCountdown",
			"toggleDialog"
		]),
		login() {
			if (
				this.loginData["username"] == "" ||
				this.loginData["password"] == ""
			) {
				this.$root.$message.open({
					status: 400,
					message: ["帳號或密碼不可為空白"]
				});
				return -1;
			}
			// const sessionLogin = userAction.login(this.loginData.username, this.loginData.password)

			const tokenLogin = this.obtain({
				username: this.loginData.username,
				password: this.loginData.password
			});

			Promise.all([tokenLogin])
				.then(() => {
					this.toggleDialog(false);
					this.calculateAccessExpCountdown();
					this.$emit("ok");
				})
				.catch(err => {
					this.$emit("cancel");
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		open() {
			this.toggleDialog(true);
			this.promise = new Promise((resolve, reject) => {
				this.$on("cancel", () => {
					console.log("reject");
					reject();
				});
				this.$on("ok", () => {
					console.log("resolve");
					resolve();
				});
			});
			return this.promise;
		},
		close() {
			this.toggleDialog(false);
			this.$emit("cancel");
		}
	}
};
</script>

<style scoped lang="scss"></style>
