import _ from "lodash";

export const compare = (key, order) => {
	const desc = (a, b) => {
		if (a[key] < b[key]) return 1;
		if (a[key] > b[key]) return -1;
		return 0;
	};
	const asc = (a, b) => {
		if (a[key] < b[key]) return -1;
		if (a[key] > b[key]) return 1;
		return 0;
	};
	if (order === "desc") {
		return desc;
	}
	if (order === "asc") {
		return asc;
	}
	return asc;
};

// function fillFieldCell(array, fields, value) {
// 	_.each(array, v => {
// 		_.each(fields, field => {
// 			if (!Object.getOwnPropertyDescriptor(v, field)) v[field] = value;
// 		});
// 	});
// }

// function genMonth(v) {
// 	let today = new Date();
// 	let year, month, date;

// 	year = today.getFullYear();
// 	month = today.getMonth() + 1;
// 	date = today.getDate();

// 	let year_begin, month_begin, year_end, month_end;

// 	if (month - v <= 0) {
// 		year_begin = year - 1;
// 		month_begin = month - v + 12;
// 	} else {
// 		year_begin = year;
// 		month_begin = month - v;
// 	}

// 	if (month - v + 1 <= 0) {
// 		year_end = year - 1;
// 		month_end = month - v + 1 + 12;
// 	} else if (month - v + 1 > 12) {
// 		year_end = year + 1;
// 		month_end = month - v + 1 - 12;
// 	} else {
// 		year_end = year;
// 		month_end = month - v + 1;
// 	}

// 	let begin_time, end_time;

// 	if (!(v === 0)) {
// 		begin_time = year_begin.toString() + "-" + month_begin.toString() + "-01";
// 		end_time = year_end.toString() + "-" + month_end.toString() + "-01";
// 	} else {
// 		begin_time = year.toString() + "-" + month.toString() + "-01";
// 		end_time = year.toString() + "-" + month.toString() + "-" + date.toString();
// 	}

// 	return {
// 		begin_time: begin_time,
// 		end_time: end_time
// 	};
// }

function extend(srcObj1, srcObj2) {
	let tempObj = _.clone(srcObj2);
	_.each(srcObj1, (v, k) => {
		tempObj[k] = v;
	});
	return tempObj;
}

export const exist = (srcArr, key, value) => {
	for (let i = 0; i < srcArr.length; i++) {
		if (srcArr[i][key] == value) {
			return true;
		}
	}
	return false;
};

export const innerJoinObjectArray = (srcArr1, srcArr2, key) => {
	let mergeArr = [];
	srcArr2.forEach(srcObj2 =>
		(existObj => {
			let mergeObj = {};
			if (existObj.length) {
				mergeObj = extend(srcArr1[srcArr1.indexOf(existObj[0])], srcObj2);
				mergeArr.push(mergeObj);
			} else {
				//mergeArr.push(srcObj2);
			}
		})(srcArr1.filter(v => v[key] === srcObj2[key]))
	);
	return mergeArr;
};

export const leftJoinObjectArray = (srcArr1, srcArr2, key) => {
	let mergeArr = [];
	srcArr2.forEach(srcObj2 =>
		(existObj => {
			let mergeObj = {};
			if (existObj.length) {
				mergeObj = extend(srcArr1[srcArr1.indexOf(existObj[0])], srcObj2);
				mergeArr.push(mergeObj);
			} else {
				//mergeArr.push(srcObj2);
			}
		})(srcArr1.filter(v => v[key] === srcObj2[key]))
	);
	srcArr1.forEach(v => {
		if (!exist(mergeArr, key, v[key])) {
			mergeArr.push(v);
		}
	});
	return mergeArr;
};

export const joinObjectArray = (srcArr1, srcArr2, key) => {
	let mergeArr = [];
	srcArr2.forEach(srcObj2 =>
		(existObj => {
			let mergeObj = {};
			if (existObj.length) {
				mergeObj = extend(srcArr1[srcArr1.indexOf(existObj[0])], srcObj2);
				mergeArr.push(mergeObj);
			} else {
				//mergeArr.push(srcObj2);
			}
		})(srcArr1.filter(v => v[key] === srcObj2[key]))
	);
	srcArr2.forEach(v => {
		if (!exist(mergeArr, key, v[key])) {
			mergeArr.push(v);
		}
	});

	srcArr1.forEach(v => {
		if (!exist(mergeArr, key, v[key])) {
			mergeArr.push(v);
		}
	});
	return mergeArr;
};

export const groupByField = (arrSrc, field, applyKey, applyValue) => {
	let temp = {};
	arrSrc.forEach(v => {
		let key = applyKey(v[field]);
		temp[key] = temp[key] || [];
		temp[key].push(applyValue(v));
	});
	return temp;
};

export const fillCell = (array, fields, value) => {
	_.each(array, function(v) {
		_.each(fields, function(field) {
			if (!Object.getOwnPropertyDescriptor(v, field)) v[field] = value;
		});
	});
	return array;
};

export const treeSelectItem = (srcObj, childKey, key, value) => {
	let pointer = srcObj;
	let result = null;
	if (pointer[key] === value) {
		result = pointer;
	} else if (!pointer[childKey]) {
		result = null;
	} else {
		for (let i = 0; i < pointer[childKey].length; i++) {
			result = treeSelectItem(pointer[childKey][i], childKey, key, value);
			if (result) break;
		}
	}
	return result;
};
