<template>
	<div class="player-area">
		<!--<video
			ref="videoPlayer"
			class="video-js vjs-default-skin"
			data-setup='{ "playbackRates": [0.5, 1, 1.5, 2], "fluid": true }'
		></video>-->

		<audio-player ref="audioPlayer" :audio-list="[url]" />
	</div>
</template>

<script>
// import localforage from "localforage";
import AudioPlayer from "@/components/components/AudioPlayer";

export default {
	components: {
		AudioPlayer
	},
	props: {
		options: {
			type: Object,
			default() {
				return {};
			}
		},
		currentTime: {
			type: Number
		}
	},
	data() {
		return {
			url: "",
			player: null,
			playList: []
		};
	},
	async mounted() {
		// const videoList = await localforage.getItem("playlist");
		// if (videoList) {
		// 	this.playList = videoList;
		// }
		// let video;
		// if (this.playList) {
		// 	video = this.playList.find(v => v.url === this.options.sources[0].src);
		// }
		this.url = this.options.sources[0].src;
		// this.$refs.videoPlayer.oncontextmenu = () => {
		// 	return false;
		// };
		/*this.player = this.$video(this.$refs.videoPlayer, this.options, () => {});
		if (video) {
			setTimeout(() => {
				this.player.currentTime(video.time);
			}, 3000);
		}*/
	}
	// async beforeDestroy() {
	// 	if (this.player) {
	// 		const src = this.options.sources[0].src;
	// 		const rec = this.playList.find(v => v.url === src);
	// 		if (rec) {
	// 			rec.time = this.player.currentTime();
	// 		} else {
	// 			this.playList.push({
	// 				url: src,
	// 				time: this.player.currentTime()
	// 			});
	// 		}
	// 		await localforage.setItem("playlist", this.playList);
	// 		this.player.dispose();
	// 	}
	// }
};
</script>

<style>
.player-area {
	padding: 50px 0;
}
</style>
