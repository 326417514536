<template>
	<div class="form">
		<div class="form-wrap">
			<slot></slot>
		</div>
		<hr />
	</div>
</template>

<script>
export default {
	props: {
		inLine: {
			type: Boolean
		}
	}
};
</script>

<style scoped lang="scss">
.form {
	.form-wrap {
		display: flex;
		flex-wrap: wrap;
		@media screen and (max-width: 767px) {
			display: block;
		}
	}
	hr {
		width: 100%;
	}
}
</style>
