<template>
	<div id="retrieve">
		<div class="form-group text-center">
			<h2 v-focus tabindex="0">忘記密碼</h2>
		</div>
		<div class="form-horizontal">
			<template v-for="v in ['value']">
				<formdrf
					:model_info="model_infos[v]"
					:field="v"
					v-model="infos[v]"
					:key="v"
					@keyup.enter.native="reset_password()"
				></formdrf>
			</template>
			<div class="form-group">
				<div class="col-sm-3 col-sm-offset-2">
					<button
						class="form-control btn btn-primary"
						@click="reset_password()"
					>
						送出
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { userAction } from "@/api/user";
import formdrf from "@/components/form.vue";

export default {
	components: {
		formdrf
	},
	data() {
		return {
			infos: {
				value: ""
			},
			model_infos: {
				value: {
					label: "帳號或信箱",
					type: "string",
					required: true
				}
			}
		};
	},
	metaInfo: {
		title: "忘記密碼"
	},
	methods: {
		reset_password() {
			userAction
				.generateResetPassword({
					value: this.infos.value
				})
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message:
							"已將重設密碼連結發送至您的電子信箱，請點選信件內之連結以重設密碼。"
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
