<template>
	<div>
		<div class="btnn-group is-margin-y is-justify-content-start">
			<div class="layer-2">
				<button class="btn btn-primary" @click="save()">儲存</button>
			</div>
		</div>
		<template
			v-for="v in [
				'is_hot',
				'is_active',
				'is_manager',
				'is_editor',
				'is_guest',
				'auth_email',
				'auth_phone'
			]"
		>
			<formdrf
				:model_info="permissionModel[v]"
				:field="v"
				v-model="permissionData[v]"
				:key="v"
			></formdrf>
		</template>
	</div>
</template>

<script>
import { userRest } from "@/api/genericUser";
import formdrf from "@/components/form.vue";

export default {
	props: {
		user_id: {
			type: Number,
			default: 0
		}
	},
	components: {
		formdrf
	},
	data() {
		return {
			permissionData: {
				is_hot: "",
				is_active: "",
				is_manager: "",
				is_editor: "",
				is_guest: "",
				auth_email: "",
				auth_phone: ""
			},
			permissionModel: {
				is_hot: {
					label: "關注標記",
					type: "checkbox"
				},
				is_active: {
					label: "登錄權限",
					type: "checkbox"
				},
				is_manager: {
					label: "單位管理員權限",
					type: "checkbox"
				},
				is_editor: {
					label: "志工權限",
					type: "checkbox"
				},
				is_guest: {
					label: "視障者權限",
					type: "checkbox"
				},
				auth_email: {
					label: "信箱驗證",
					type: "checkbox"
				},
				auth_phone: {
					label: "手機驗證",
					type: "checkbox"
				}
			}
		};
	},
	watch: {
		user_id() {
			this.userIdSetted();
		}
	},
	mounted() {
		this.userIdSetted();
	},
	methods: {
		userIdSetted() {
			userRest.read(this.user_id).then(res => {
				_.each(this.permissionData, (v, k) => {
					this.permissionData[k] = res.data[k];
				});
			});
		},
		save() {
			userRest
				.partialupdate(this.user_id, this.permissionData)
				.then(res => {
					_.each(this.permissionData, (v, k) => {
						this.permissionData[k] = res.data[k];
					});
					this.$root.$message.open({ status: 200, message: "完成權限變更" });
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
table {
	tbody {
		td:nth-child(1) {
			--bootstrap-td-padding-x: 0.75rem;
			--safe-value: 0.2em;
			width: calc(
				var(--form-item-col1-width) + (var(--bootstrap-td-padding-x) * 2) +
					var(--safe-value)
			);
		}
		td:nth-child(2) {
			// *Because email is too long.
			// -----------------------------
			// *This don't work, don't know why
			// overflow-wrap: break-word;
			// *This is work around (though this css is a bit different purpose,
			// but can roughly achive my goal).
			word-break: break-all;

			> span {
				// overflow-wrap: break-word;
				word-break: break-all;
			}
		}
	}
	.btn-if-already-verified {
		margin-left: 0.7em;
	}
}

@media (max-width: media-query-length($em: $breakpoint_xs)) {
	// Purpose: To teak for mobile only, to prevent awkward line break looking.
	::v-deep .form-group.is-3-character-label {
		--form-item-col1-width: 3.7em;
	}
}
</style>
