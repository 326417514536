<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">上傳書籍審核</h2>
		<template v-if="aorg">
			<template v-if="mode === 'manager'">
				<manager-org :org="aorg"></manager-org>
			</template>
			<template v-if="mode === 'supermanager'">
				<panel-group :data="tab_data" :title="'單位'">
					<template #component="props">
						<manager-org :org="props.item"></manager-org>
					</template>
				</panel-group>
			</template>
		</template>
		<template v-else>
			使用者資料尚未準備好，可能是尚未登入平台
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";
import tab from "@/components/panelGroup.vue";
import managerOrg from "./components/bookReviewListOrg.vue";

export default {
	props: {
		mode: {
			type: String,
			default: "manager"
		}
	},
	components: {
		panelGroup: tab,
		managerOrg
	},
	data() {
		return {
			tab_data: []
		};
	},
	computed: {
		...mapState({
			orgs: "organizations"
		}),
		...mapState("user", ["user"]),
		aorg() {
			return this.readOrganization({ pk: this.user.org });
		}
	},
	watch: {
		orgs() {
			this.orgsDataSet();
		}
	},
	metaInfo: {
		title: "上傳書籍審核"
	},
	mounted() {
		this.orgsDataSet();
	},
	methods: {
		orgsDataSet() {
			this.tab_data = [];
			this.tab_data.push({
				order: 0,
				display_name: "全部",
				value: "all",
				type: "component",
				data: {
					id: "0",
					name: "全部"
				}
			});
			_.each(this.orgs, v => {
				this.tab_data.push({
					order: v.id,
					display_name: v.name,
					value: v.id,
					type: "component",
					data: v
				});
			});
		},
		readOrganization({ pk }) {
			let org = null;
			this.orgs.forEach(v => {
				if (v.id == pk) {
					org = v;
				}
			});
			return org;
		}
	}
};
</script>
