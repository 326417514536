<!--
*About: Roughly have the same purpose as countdown.vue, but there are multiple differences:
1. countdown.vue can't show  multiple message at once, while CountdownMultiple.vue could and will
handle the animation well, prevent the jamming and any other edge case related to animation.
For countdown.vue, if there is multiple message/popout to be shown at the same time,
each popout have to wait for previous one to end before they could be shown.
The process take much longer.
2. countdown.vue will block the whole viewport (because modal pop out), while
CountdownMultiple.vue don't do this, they only cover a small part on the top of viewport,
thus don't interrupt/block user's current operation and focus.
3. countdown.vue can handle accessibility well, while CountdownMultiple.vue not yet consider this at all.

*Usage example:
setTimeout(() => {
	this.$root.$countdownMultiple.open({ status: 200, message: 'Cat' });
}, 1234);
setTimeout(async function () {
	await this.$root.$countdownMultiple.open({ status: 300, message: 'Dog' });
	await this.$root.$countdownMultiple.open({ status: 400, message: 'Eagle' });
	await this.$root.$countdownMultiple.open({ status: 500, message: 'Fox' });
	await this.$root.$countdownMultiple.open({ status: 200, message: 'Giraffe' });
	await this.$root.$countdownMultiple.open({ status: 200, message: 'Horse' });
}.bind(this), 4234);
-->

<template>
	<div class="countdown-multiple" ref="countdown-multiple" v-show="isShow">
		<div :class="['moving-plate', { translateY: isPlateTranslateY }]">
			<div
				v-for="aMessage of messageQueue"
				:class="[
					'message',
					{ fadeIn: aMessage.classForFadeIn, fadeOut: aMessage.classForFadeOut }
				]"
				:key="aMessage.id"
			>
				<!-- <button class="close" @click="ok">&times;</button> -->
				<div class="content">
					<div class="statusCtnr">
						<font-awesome-icon
							class="status-icon"
							icon="check-circle"
							v-if="aMessage.promptType === 'success'"
						/>
						<font-awesome-icon
							class="status-icon"
							icon="times-circle"
							v-else-if="aMessage.promptType === 'error'"
						/>
					</div>
					<div class="messageText">{{ aMessage.message }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	name: "CountdownMultiple",
	components: {
		FontAwesomeIcon
	},
	data() {
		return {
			// messageQueue.message's type is always String/pure-text.
			messageQueue: [],
			isPlateTranslateY: false,
			// E.g. 700 (700ms). Will be set by javascipt, by css (the source is from css).
			fadeOutDuration: 0,
			// If set to 0, if .open() multiple message in the same moment,
			// these message will show at the same time.
			// If set to larger than 0, then these message will show sequently with this delay time for interval.
			// Notice, it is safe to set this value even to 0.
			fadeInRetardDelay: 100,
			cssTransitionSafeValue: 40,
			// How long will each message leave in messageQueue before it get pop out (shfit()). In ms.
			messageLifespan: 3500,
			// *Short: The "safe", fixed, mandatory, timestamp that each new incomming "message" have to follow.
			// *Long: The namming "virtual" means, although we can't force when a new incomming "message" comes/enter
			// (since it is always triggered by some place else), but we can calculate and get a safe timestamp
			// , a virtual timestap, that is safe enough when using timestap adding messageLifespan will not causing animation breaks, this is what "virtual" means here.
			// We use this safe/virtual timestamp as a basis, then decide how long setTimeout() need to delay.
			// *Compensate graph concept
			// (When incomming timestamp too dense/frequent)
			// (1, 2 ... means actual message incomming timestamp (crrTimestamp),
			// A, B ... means virtual/fixed timestamp (timestampVirtualBasis),
			// start from the thrid line means how much crrTimestamp have to compensate to be timestampVirtualBasis):
			//
			// 1...2...3...4...5...6...
			// A.....B.....C.....D.....E.....F.....
			// ----222
			// --------33333
			// ------------4444444
			// ----------------555555555
			// --------------------66666666666
			timestampVirtualBasis: 0
		};
	},
	computed: {
		isShow() {
			return this.messageQueue.length > 0 ? true : false;
		}
	},
	methods: {
		// Add to the end of the queue.
		// Also do some animation timestamp calculation. See comments at this.timestampVirtualBasis.
		addNewMessage({ status, message }) {
			console.log("status:", status);
			console.log("message:", message);
			let newMessage = {
				id: this.getNewUniqueId(),
				status,
				message,
				promptType: parseInt(status, 10) < 400 ? "success" : "error",
				classForFadeIn: false,
				classForFadeOut: false
			};
			this.messageQueue.push(newMessage);
			// Fade-in animation of .message element.
			setTimeout(() => {
				newMessage.classForFadeIn = true;
			}, this.cssTransitionSafeValue);

			let cssTransitionSafeValue = this.cssTransitionSafeValue;
			let crrTimestamp = moment().valueOf();

			if (this.timestampVirtualBasis === 0) {
				// Make a "fake" timestampVirtualBasis record, since we need to handle the exception for first mounted case.
				this.timestampVirtualBasis = crrTimestamp - this.fadeOutDuration;
			}
			// *Decide how much we need to compensate in the later logic.
			// *Might be nagative value!
			let deltaOfTwoRecentMessage = crrTimestamp - this.timestampVirtualBasis;
			let futureTimestamp =
				deltaOfTwoRecentMessage > this.fadeOutDuration + cssTransitionSafeValue
					? crrTimestamp
					: this.timestampVirtualBasis +
					  this.fadeOutDuration +
					  cssTransitionSafeValue;

			// Prepare pop-out/shift of each message in the future.
			setTimeout(
				() => {
					this.removeOldMessage();
				},
				deltaOfTwoRecentMessage > this.fadeOutDuration + cssTransitionSafeValue
					? this.messageLifespan
					: this.messageLifespan +
							-1 * (deltaOfTwoRecentMessage - this.fadeOutDuration) +
							cssTransitionSafeValue
			);
			this.timestampVirtualBasis = futureTimestamp;
		},
		// Remove from the start of the queue.
		removeOldMessage() {
			this.isPlateTranslateY = true;
			// Fade-out animation of .message element.
			this.messageQueue[0].classForFadeOut = true;
			setTimeout(() => {
				// Cautious! These 2 thing have to be done at the exact moment!
				// Otherwise user's eye will not see the smooth animation! They will feel something else.
				this.messageQueue.shift();
				this.isPlateTranslateY = false;
			}, this.fadeOutDuration);
		},
		getNewUniqueId() {
			return new Date().getTime();
		},
		setFadeOutDuration() {
			let cssCustomPropertyRaw = getComputedStyle(
				this.$refs["countdown-multiple"]
			).getPropertyValue("--fade-out-duration");
			this.fadeOutDuration = parseInt(
				cssCustomPropertyRaw.match(/^ *\d+/)[0],
				10
			);
		},
		open({ status, message }) {
			this.addNewMessage({ status, message });
			return new Promise(resolve => {
				setTimeout(() => {
					resolve();
				}, this.fadeInRetardDelay);
			});
		}
	},
	created() {
		this.$root.$countdownMultiple = this;
	},
	mounted() {
		this.setFadeOutDuration();
	}
};
</script>

<style scoped lang="scss">
.countdown-multiple {
	--moving-plate-width: 20em;
	--moving-plate-margin-top: 1.95em;
	--a-message-height: 40px;
	--fade-in-duration: 500ms;
	--fade-out-duration: 910ms;
	--message-row-gap: 16px;
	--message-background: #{scale-color(
			adjust-hue($color-orange-light, $degrees: -3),
			$lightness: 0%
		)};

	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: grid;
	grid-template-columns: auto;
	justify-content: center;
	align-content: start;
	// Make .moving-plate decide it's own height.
	align-items: start;
	z-index: 101;
	pointer-events: none;
}

.moving-plate {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: var(--a-message-height);
	align-content: space-between;
	grid-row-gap: 8px;
	grid-row-gap: var(--message-row-gap);
	margin-top: var(--moving-plate-margin-top);
	width: var(--moving-plate-width);
	transition: transform 0ms;

	&.translateY {
		transform: translateY(
			calc((var(--a-message-height) + var(--message-row-gap)) * -1)
		);
		transition: transform var(--fade-out-duration) ease-in-out !important;
	}
}

.message {
	padding: 16px 8px;
	border-radius: 4px;
	background-color: var(--message-background);
	color: white;

	// Optinal transition animation.
	// =============================
	opacity: 0;
	transform: translateY(-55%);
	// =============================

	pointer-events: auto;

	&.fadeIn {
		opacity: 1;
		transform: translateY(0%);
		transition: none var(--fade-in-duration) ease-out;
		transition-property: opacity, transform;
	}
	// Actually not related to .fadeIn, but .fadeOut.
	&.fadeIn.fadeOut {
		opacity: 0;
		transition: opacity var(--fade-out-duration);
	}

	.content {
		// Globally (downward) change font-size.
		font-size: 1.04em;
		width: 100%;
		height: 100%;
		display: grid;
		// Rows currently contains these 3 things: 1. .statusCtnr 2. .message .
		grid-template-columns: auto 1fr;
		grid-template-rows: auto;
		align-content: center;
		grid-column-gap: 0.5em;
	}

	.statusCtnr {
		--icon-font-size: 1.18em;

		position: relative;
		width: 1.8em;
		height: 1.8em;
	}
	.statusCtnr .status-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: var(--icon-font-size);

		// Purpose: To hide the underneath content that appears inside icon hollow part.
		// ===================================
		background-color: var(--message-background);
		border-radius: 50%;
		// ===================================
	}
	.messageText {
		text-align: left;
	}
}
</style>
