<template>
	<div>
		<h2 class="big-title">統計資料</h2>
		<div class="form-horizontal" style="margin: 1em 0;">
			<div class="form-group">
				<label class="control-label col-md-2 col-lg-1">
					請選擇單位
				</label>
				<div class="col-md-4 col-lg-4">
					<select class="form-control" v-model="org_id">
						<option v-for="(value, key) in orgs" :key="key" :value="value.id">{{
							value.name
						}}</option>
					</select>
				</div>
			</div>
		</div>
		<router-view :org="aorg" />
	</div>
</template>

<script>
import { organizationRest } from "@/api/genericUser.js";

export default {
	props: {},
	data() {
		return {
			orgs: [],
			org_id: "0"
		};
	},
	computed: {
		aorg() {
			return this.readOrganization({ pk: this.org_id });
		}
	},
	mounted() {
		organizationRest
			.list()
			.then(res => {
				this.orgs = [];
				this.orgs.push({
					id: 0,
					name: "全部"
				});
				_.each(res.data, v => {
					this.orgs.push({
						id: v.id,
						name: v.name
					});
				});
			})
			.catch(res => {
				// if (res.response && res.response.data) {
				// 	this.$root.$message.open({ status: 400, message: res.response.data });
				// } else {
				// 	this.$root.$message.open({
				// 		status: 400,
				// 		message: JSON.stringify(res)
				// 	});
				// }
				this.$errorHandler({ error: res });
			});
	},
	methods: {
		readOrganization({ pk }) {
			let org = null;
			this.orgs.forEach(v => {
				if (v.id == pk) {
					org = v;
				}
			});
			return org;
		}
	}
};
</script>
