<template>
	<div style="font-size=4rem">
		<h3 class="sub-title">{{ org.name }}</h3>
		<!--class="form-horizontal"-->
		<form-filter>
			<period v-model="period" />
			<form-item>
				<button class="btn btn-primary" @click="getData">查詢</button>
			</form-item>
		</form-filter>
		<table-div
			:header="instance_header"
			:datas="instance_datas"
			:tdwidths="tdwidths"
		>
		</table-div>
	</div>
</template>

<script>
import axios from "axios";
import period from "@/components/period.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";
import tableDiv from "@/components/tableDiv.vue";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		},
		url: {
			type: String,
			default: ""
		},
		instance_header: {
			type: Object,
			default: () => {}
		},
		instance_callback: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		tableDiv,
		period,
		formFilter,
		formItem
	},
	data() {
		const today = new Date();
		const date = today.toISOString().substr(0, 10);
		return {
			tdwidths: [5, 10, 30, 15, 15, 15, 5, 5],
			styleObject: {
				display: "inline-grid"
			},
			period: {
				begin: date,
				end: date
			},
			search_value: "",
			instance_datas: [],
			model: {
				search_value: {
					label: "查詢字",
					type: "text",
					required: true
				}
			}
		};
	},
	methods: {
		getData() {
			let rdate = /^\d{4,4}-\d{2,2}-\d{2,2}$/;
			if (!rdate.test(this.period.begin)) {
				this.$root.$message.open({
					status: 400,
					message: "開始日期格式錯誤"
				});
				return -1;
			}
			if (!rdate.test(this.period.end)) {
				this.$root.$message.open({
					status: 400,
					message: "結束日期格式錯誤"
				});
				return -1;
			}
			let params = {};
			if (!(this.org.id == 0)) {
				params = Object.assign(params, {
					org_id: this.org.id
				});
			}
			params = {
				...params,
				...{
					begin_time: this.period["begin"],
					end_time: this.period["end"]
				}
			};
			this.instance_datas = [];
			axios
				.get(this.url, {
					params: params
				})
				.then(res => {
					res.data.forEach((v, i) => {
						let temp = {};
						_.each(this.instance_header, (value, key) => {
							if (this.instance_callback && this.instance_callback[key]) {
								temp[key] = this.instance_callback[key](v);
							} else {
								temp[key] = v[key];
							}
						});
						temp["order"] = i + 1;
						this.instance_datas.push(temp);
					});
					this.$root.$message.open({
						status: 400,
						message:
							"查詢完成，共取得 " + this.instance_datas.length + " 筆資料"
					});
				})
				.catch(res => {
					console.log(res);
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.btn {
	&.btn-manage {
		margin-top: 0.7rem;
	}
}
</style>
