<template>
	<div>
		<h3 class="sub-title">{{ org.name }}</h3>
		<h3 style="margin-top:30px;">Step1: 勾選志工所提送的兌換項目</h3>
		<hr style="margin-top:5px;" />
		<div style="margin-bottom:60px;">
			<button class="btn btn-primary mr-sm-2" @click="serviceinfo_select_all()">
				全選
			</button>
			<button class="btn btn-primary mr-sm-2" @click="serviceinfo_checks = []">
				全不選
			</button>
			<button class="btn btn-primary mr-sm-2" @click="serviceinfo_select_inv()">
				反向選
			</button>
			<button class="btn btn-primary mr-sm-2" @click="exportExchange">
				匯出
			</button>
			<table-div :datas="serviceinfo_datas" :header="serviceinfo_header">
				<template slot="check" slot-scope="props">
					<input
						type="checkbox"
						v-model="serviceinfo_checks"
						:value="props.item.id"
					/>
				</template>
				<template slot="editrecord_set" slot-scope="props">
					<button
						class="btn btn-outline-secondary"
						@click="
							editrecords_detail(props.item);
							detailModalShow = true;
						"
					>
						詳細服務紀錄
					</button>
				</template>
			</table-div>
		</div>
		<h3>Step2: 確認後進行兌換</h3>
		<hr style="margin-top:5px;" />
		<div style="margin-bottom:60px;">
			<button
				@click="serviceinfo_cancel()"
				class="btn btn-table"
				style="margin-top:10px;"
			>
				退回兌換
			</button>
			<button
				@click="serviceinfo_exchange()"
				class="btn btn-table"
				style="margin-top:10px;"
			>
				同意兌換
			</button>
		</div>
		<modal v-if="detailModalShow" @closeModal="detailModalShow = false">
			<template #header>
				<h4 class="modal-title">詳細服務紀錄</h4>
			</template>
			<template #body>
				<table-div
					:datas="detail_editrecord_datas"
					:header="detail_editrecord_header"
				/>
			</template>
		</modal>
	</div>
</template>

<script>
import modal from "@/components/popup/modal.vue";
import tableDiv from "@/components/tableDiv.vue";
import { serviceInfoRest, serviceInfoAction } from "@/api/genericUser.js";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		modal,
		tableDiv
	},
	data() {
		return {
			detailModalShow: false,
			serviceinfo_header: {
				check: "核取",
				date: "兌換日期",
				service_hours: "服務時數",
				user: "服務者",
				org: "兌換單位",
				editrecord_set: "服務紀錄"
			},
			serviceinfo_datas: [],
			serviceinfo_checks: [],
			detail_editrecord_datas: [],
			detail_editrecord_header: {
				part: "文件",
				get_date: "服務時間",
				service_hours: "服務時數",
				stay_hours: "線上時數",
				category: "類型"
			}
		};
	},
	computed: {
		query() {
			let temp = { is_exchange: "false" };
			if (!(this.org.id == 0)) {
				temp["org_id"] = this.org.id;
			}
			return temp;
		}
	},
	mounted() {
		this.get_serviceinfo_data();
	},
	methods: {
		get_serviceinfo_data() {
			this.serviceinfo_datas = [];
			serviceInfoRest
				.filter(this.query)
				.then(res => {
					_.each(res.data, v => {
						this.serviceinfo_datas.push({
							check: v,
							date: v.date,
							service_hours: v.service_hours,
							user: v.userinfo.username,
							org: v.orginfo.name,
							editrecord_set: v.editrecordinfo_set
						});
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		serviceinfo_exchange() {
			if (this.serviceinfo_checks.length === 0) {
				this.$root.$message.open({
					status: 400,
					message: "請至少選擇一筆兌換紀錄。"
				});
				return -1;
			}
			let dfs = [];
			_.each(this.serviceinfo_checks, v => {
				dfs.push(serviceInfoRest.partialupdate(v, { is_exchange: true }));
			});
			Promise.all(dfs)
				.then(() => {
					this.$root.$message.open({ status: 200, message: "同意兌換成功" });
					this.get_serviceinfo_data();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		serviceinfo_cancel() {
			if (this.serviceinfo_checks.length === 0) {
				this.$root.$message.open({
					status: 400,
					message: "請至少選擇一筆兌換紀錄。"
				});
				return -1;
			}
			let dfs = [];
			_.each(this.serviceinfo_checks, v => {
				dfs.push(serviceInfoRest.delete(v));
			});
			Promise.all(dfs)
				.then(() => {
					this.$root.$message.open({ status: 200, message: "退回兌換申請" });
					this.get_serviceinfo_data();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		serviceinfo_select_all() {
			_.each(this.serviceinfo_datas, v => {
				this.serviceinfo_checks.push(v.check.id);
			});
		},
		serviceinfo_select_inv() {
			let temp = [];
			_.each(this.serviceinfo_datas, v => {
				if (!_.includes(this.serviceinfo_checks, v.check.id)) {
					temp.push(v.check.id);
				}
			});
			this.serviceinfo_checks = temp;
		},
		editrecords_detail(editrecords) {
			this.detail_editrecord_datas = editrecords;
		},
		exportExchange() {
			serviceInfoAction.exportExchangeFalse();
		}
	}
};
</script>

<style lang="scss" scoped>
//button邊框 先放此 之後再修改到scss內

.btn {
	&.btn-outline-secondary {
		border: 0.5px #696969 solid;
	}
}
</style>
