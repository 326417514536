<template>
	<div id="serviceinfo" class="container">
		<h2 v-focus tabindex="0" class="sr-only">服務紀錄</h2>
		<serviceinfo-record :user_id="user.id"></serviceinfo-record>
	</div>
</template>

<script>
import { mapState } from "vuex";
import serviceinfoRecord from "@/components/serviceinfoRecord.vue";

export default {
	components: {
		serviceinfoRecord
	},
	data() {
		return {
			pk: ""
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	metaInfo: {
		title: "服務紀錄"
	},
	mounted() {
		this.pk = window.location.pathname.split("/");
		this.pk = this.pk[this.pk.length - 2];
		if (this.pk === "serviceinfo_record") {
			this.pk = this.user.id;
		}
	}
};
</script>
