<template>
	<div id="sitemap">
		<h2 v-focus tabindex="0" class="big-title">網站導覽</h2>
		<ul>
			<template v-for="(item, index) in items">
				<template v-if="item.type == 'folder'">
					<li :key="index">
						<a href="#" class="title-href"
							>{{ index + 1 }}. {{ item.display_name }}</a
						>
						<ul>
							<template v-for="(item, index_folder) in item.items">
								<template v-if="item.type == 'item'">
									<li class="nav-item" :key="index_folder">
										<a
											tabindex="0"
											class="nav-link"
											@click.prevent="linkToUrlOrRouteName(item)"
											>{{ index + 1 }}-{{ index_folder + 1 }}.
											{{ item.display_name }}</a
										>
									</li>
								</template>
								<template v-if="item.type == 'action'">
									<li class="nav-item" :key="index_folder">
										<a class="nav-link" href="#" @click="item.action(that)"
											>{{ index + 1 }}-{{ index_folder + 1 }}.
											{{ item.display_name }}</a
										>
									</li>
								</template>
							</template>
						</ul>
					</li>
				</template>
				<template v-if="item.type == 'item'">
					<li class="nav-item" :key="item">
						<a
							tabindex="0"
							class="nav-link"
							@click.prevent="linkToUrlOrRouteName(item)"
							>{{ index + 1 }}. {{ item.display_name }}</a
						>
					</li>
				</template>
				<template v-if="item.type == 'action'">
					<li class="nav-item" :key="item">
						<a class="nav-link" href="#" @click="item.action(that)"
							>{{ index + 1 }}. {{ item.display_name }}</a
						>
					</li>
				</template>
			</template>
		</ul>
	</div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
	nav_item_mode_normal_main,
	nav_item_mode_normal_right
} from "./header/navItemData.js";
import { item_show } from "./header/navItemMethod.js";

export default {
	computed: {
		items() {
			return this.nav_item_main_computed.concat(this.nav_item_right_computed);
		},
		nav_item_main_computed() {
			return item_show(nav_item_mode_normal_main, this.user);
		},
		nav_item_right_computed() {
			return item_show(nav_item_mode_normal_right, this.user);
		},
		...mapState("user", ["user"]),
		...mapState("token", ["accessExpCountdown", "refreshExpCountdown"]),
		...mapState("user", ["user"])
	},
	metaInfo: {
		title: "網站導覽"
	},
	methods: {
		linkToUrlOrRouteName(item) {
			function collapseBootstrapNavbarManually() {
				let navbarToggleBtn = document.getElementsByClassName(
					"navbar-toggler"
				)[0];
				navbarToggleBtn.click();
			}
			// Link with "url" property.
			if (typeof item.url !== "undefined") {
				window.location.replace(item.url);
				// Link with "routePath" property.
			} else if (typeof item.routePath !== "undefined") {
				this.$router.push({ path: item.routePath });
				collapseBootstrapNavbarManually();
				// Link with "routeName" property.
			} else {
				this.$router.push({ name: item.routeName });
				collapseBootstrapNavbarManually();
			}
		}
	}
};
</script>

<style scoped lang="scss">
#sitemap > ul {
	line-height: 1.6em;
}

ul {
	list-style-type: none;
}
.title-href {
	margin-left: 4mm;
}
</style>
