<template>
	<div>
		<qa-block :datas="qanda_tutorial_datas" :title="question" />
	</div>
</template>

<script>
import { qAndARest } from "@/api/genericUser.js";
import qaBlock from "@/components/qapageBlock.vue";

export default {
	props: {
		category: {
			type: String
		}
	},
	components: {
		qaBlock
	},
	data() {
		return {
			qanda_instance: {
				category: "",
				question: "",
				answer: "",
				order: ""
			},
			qanda_tutorial_header: {
				order: "項次",
				question: "問題",
				answer: "回答"
			},
			qanda_tutorial_datas: []
		};
	},
	mounted() {
		this.qanda_list_refresh();
	},
	methods: {
		qanda_list_refresh() {
			qAndARest.filter({ category: this.category }).then(res => {
				this.qanda_tutorial_datas = [];
				_.each(res.data, v => {
					this.qanda_tutorial_datas.push({
						order: v,
						question: v.question,
						answer: v.answer
					});
				});
			});
		}
	}
};
</script>
