import axios from "axios";
import { restFactory, file_resolve, host } from "./base.js";
import { set_token } from "./token.js";

const bookSimple = axios.create({
	baseURL: host + "ebookSystem/api/booksimples/"
});

const book = axios.create({
	baseURL: host + "ebookSystem/api/books/"
});

const bookAdd = axios.create({
	baseURL: host + "ebookSystem/api/bookadds/"
});

const category = axios.create({
	baseURL: host + "ebookSystem/api/categorys/"
});

const indexCategory = axios.create({
	baseURL: host + "ebookSystem/api/indexcategorys/"
});

const ebook = axios.create({
	baseURL: host + "ebookSystem/api/ebooks/"
});

const bookInfo = axios.create({
	baseURL: host + "ebookSystem/api/bookinfos/"
});

const bookOrder = axios.create({
	baseURL: host + "ebookSystem/api/bookorders/"
});

const editRecord = axios.create({
	baseURL: host + "ebookSystem/api/editrecords/"
});

const libraryRecord = axios.create({
	baseURL: host + "ebookSystem/api/libraryrecords/"
});

const mediaResource = axios.create({
	baseURL: host + "ebookSystem/api/mediaresources/"
});

export const bookSimpleRest = restFactory(bookSimple);
export const bookRest = restFactory(book);
export const bookAddRest = restFactory(bookAdd);
export const ebookRest = restFactory(ebook);
export const bookInfoRest = restFactory(bookInfo);
export const bookOrderRest = restFactory(bookOrder);
export const editRecordRest = restFactory(editRecord);
export const libraryRecordRest = restFactory(libraryRecord);
export const categoryRest = restFactory(category);
export const indexCategoryRest = restFactory(indexCategory);
export const mediaResourceRest = restFactory(mediaResource);

export const indexCategoryAction = {
	structure: () => indexCategory.get(`action/structure/`),
	books: pks => indexCategory.get(`action/books/`, {params: {search: pks.join('-')}})
};
export const bookAction = {
	create: ({
		ISBN,
		org_id,
		category_id,
		index_category_id,
		format,
		object
	}) => {
		const formData = new FormData();
		formData.append("ISBN", ISBN);
		formData.append("org_id", org_id);
		formData.append("category_id", category_id);
		formData.append("index_category_id", index_category_id);
		formData.append("format", format);
		formData.append("fileObject", object);
		return book.post("action/create/", formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			},
			onUploadProgress: progressEvent => {
				const complete =
					((progressEvent.loaded / progressEvent.total) * 100) | 0;
				const event = new CustomEvent("progress", {
					detail: { percent: complete }
				});
				window.dispatchEvent(event);
			}
		});
	},
	upload: ({
		ISBN,
		org_id,
		index_category_id,
		category_id,
		format,
		object
	}) => {
		const formData = new FormData();
		formData.append("ISBN", ISBN);
		formData.append("org_id", org_id);
		formData.append("category_id", category_id);
		formData.append("index_category_id", index_category_id);
		formData.append("format", format);
		formData.append("fileObject", object);
		return book.post("action/upload/", formData, {
			headers: {
				"Content-Type": "multipart/form-data"
			},
			onUploadProgress: progressEvent => {
				const complete =
					((progressEvent.loaded / progressEvent.total) * 100) | 0;
				const event = new CustomEvent("progress", {
					detail: { percent: complete }
				});
				window.dispatchEvent(event);
			}
		});
	},
	feedback: (pk, { content }) =>
		book.post(`${pk}/action/feedback/`, { content }),
	review: ({ pk, result, reason }) =>
		book.post(`${pk}/action/review/`, { result, reason }),
	download: (pk, { fileformat, password }) => {
		return book
			.post(
				`/${pk}/action/download/`,
				{ fileformat, password },
				{ responseType: "blob" }
			)
			.then(file_resolve(`${pk}.${fileformat}`));
	},
	getEpub: pk => {
		const url = `${host}ebookSystem/api/books/${pk}/action/read/`;
		return book.get(url, { responseType: "blob" }).then(response => {
			return response.data;
		});
	}
};
export const ebookAction = {
	service: ({ org_id, category_id }) =>
		ebook.post(`action/service/`, { org_id, category_id }),
	statusInfo: ({ org_id }) =>
		ebook.get(`action/status_info/`, { params: { org_id } }),
	changeStatus: ({ pk, direction, status }) =>
		ebook.post(`${pk}/action/change_status/`, { direction, status }),
	review: ({ pk, result, reason, number_of_times }) =>
		ebook.post(`${pk}/action/review/`, { result, reason, number_of_times }),
	readEdit: ({ pk }) => ebook.get(`${pk}/action/edit/`),
	updateEdit: (pk, data) => ebook.post(`${pk}/action/edit/`, data),
	getImageData: (pk, page) => {
		return ebook.get(`${pk}/resource/source/${page}/`, {
			responseType: "blob"
		});
	},
	getContent: pk => ebook.get(`${pk}/resource/OCR/origin`),
	onactive: pk => ebook.post(`${pk}/action/onactive/`),
	recoverContent: ({ pk }) => ebook.post(`${pk}/action/recover_content/`),
	assign: (pk, { username, deadline }) =>
		ebook.post(`${pk}/action/assign/`, { username, deadline }),
	editLog: (pk, { online, page }) =>
		ebook.post(`${pk}/action/editlog/`, { online, page })
};
export const bookInfoAction = {
	books: pks => bookInfo.get(`action/books/`, {params: {search: pks.join('-')}}),
	guestPlatform: params => bookInfo.get(`action/guest_platform`, { params }),
	createUpdate: data => bookInfo.post(`action/create_update/`, data),
	isbn2bookinfo: ({ ISBN, source }) =>
		bookInfo.post(`action/isbn2bookinfo/`, { ISBN, source }),
	key2bookinfo: query => bookInfo.post(`action/key2bookinfo/`, query)
};
export const editRecordAction = {
	analysis: pk => editRecord.get(`${pk}/action/analysis/`)
};
export const libraryRecordAction = {
	checkCreate: ({ ISBN }) =>
		libraryRecord.post(`action/check_create/`, { ISBN }),
	checkInout: ({ pk, action }) =>
		libraryRecord.post(`${pk}/action/check_inout/`, { action }),
	download: (pk, { fileformat, password }) => {
		return libraryRecord.post(
			`/${pk}/action/download/`,
			{ fileformat, password },
			{ responseType: "blob" }
		);
	},
	getEpub: pk => {
		const url = `${host}ebookSystem/api/libraryrecords/${pk}/resource/source/epub/`;
		return libraryRecord.get(url, { responseType: "blob" }).then(response => {
			return response.data;
		});
	}
};

export const mediaResourceAction = {
	content: pk => {
		return `${host}ebookSystem/api/mediaresources/${pk}/action/content/`;
	}
	// content: (pk, {name}) => `${host}ebookSystem/api/mediaresources/${pk}/resource/content/${name}/`,
};
