<template>
	<div class="any-about-page container this-is-privacy-and-security">
		<h2 class="big-title">隱私權及資訊安全政策</h2>
		<p>
			當您開始使用eDocumentService網站，即表示您信賴我們對您個人資訊的處理方式。本《隱私權及資訊安全政策》旨在協助您瞭解本站如何蒐集、應用及保護您所提供的個人資訊，以下的隱私權政策，適用於您在本站活動時，所涉及的個人資料蒐集、運用與保護，當您註冊或是登入時，即視為您已閱讀本同意書，並同意遵守以下所有同意書之規範。
		</p>

		<div class="bs-callout bs-callout-danger">
			<h3>一、個人資料之蒐集與應用</h3>
			<ol class="often is-list-decimal">
				<li>
					若欲使用本站所提供之各項功能，必須先申請本站帳戶才能使用，在申請過程中，本站會請您提供個人正確且完整之必要資料，如姓名、電子郵件、聯絡電話、生日、學歷等個人資訊，儲存在您的帳戶中。
				</li>
				<li>
					進行網頁瀏覽時，伺服器會自行記錄相關行徑，包括日期和時間、您所擷取之網頁、您所在之網址、您的瀏覽器種類、您對本站網頁所做行動等，做為本站增進網站服務的參考依據，此記錄為內部應用，絕不對外公佈。
				</li>
				<li>
					為了向所有使用者提供更優質的服務，當您造訪本站時，本站會使用各種技術收集並儲存資訊，這可能包括使用
					Cookie
					或類似技術來識別您的瀏覽器或裝置。若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie紀錄，但可能會導致網站某些功能無法正常執行。
				</li>
			</ol>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>二、個人資訊更新及帳戶安全</h3>
			<ol class="often is-list-decimal">
				<li>
					無論您何時使用本站的服務，本站都致力讓您能夠維持並更新個人資訊。若該資訊有誤，我們會盡力提供管道讓您可以迅速更新或刪除資訊，但如果我們基於正當業務或法律目的考量必須保存該資訊時，則不在此列。凡為本站使用者，皆有義務維持並更新其所屬個人資料，並確保該資料為正確、最新以及完整。若您提供任何錯誤或不實的資料，本站有權拒絕您以該帳號使用網站之全部或部份功能。
				</li>
				<li>
					維護用戶資訊與密碼的資訊安全是本站與您共同的責任，本站會致力於保護用戶相關資訊，防止未經授權而遭到存取、竄改或揭露。也請您於每次關閉瀏覽器視窗時登出本站，以保護個人資料不會遭到他人讀取與濫用。
				</li>
				<li>
					凡為本站使用者之網路行為應遵循國內、外法律規範，並且對於個人所屬帳戶所發生之情事負全部責任。若您的密碼或帳號遭到盜用或有其他任何安全問題發生時，請立即以電子郵件通知本站，以便資訊人員盡快處理。
				</li>
			</ol>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>三、與第三人共用個人資料之政策</h3>
			<p>
				除非適用下列任一情況，否則我們不會與本站以外的其他公司、機構或個人分享使用者的個人資訊：
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.1 事先徵得您的同意</h4>
			<p>
				除非得到您的同意，本站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關。
			</p>
		</div>

		<div class="bs-callout bs-callout-default">
			<h4>3.2 基於法律原因</h4>
			<p>
				若為達到下列目的，對個人資訊的存取、使用、保存或揭露屬合理必要行為時，我們便將與本站以外的其他公司、機構或個人分享個人資訊：
			</p>
			<ol class="often is-list-upper-latin">
				<li>符合任何適用法律、法規、法律函狀或強制性政府調閱要求的規定。</li>
				<li>配合檢調或司法單位合法的調查。</li>
				<li>配合政府機關依法進行之調查或使用。</li>
				<li>
					依法律規應或在合法範圍內，保護本站、使用者或公眾之權利、財產或安全不致遭受危害。
				</li>
				<li>
					與公務機關或學術研究機構合作，基於公共利益為統計或學術研究之必要，僅分享不含身分識別內容的資訊，且蒐集者依其揭露方式無從識別特定之當事人。
				</li>
				<li>
					當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
				</li>
			</ol>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>四、資料安全</h3>
			<p>
				本站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以嚴格保護網站及您的個人資料。本站僅允許代表本站處理個人資訊而需要知悉該等資訊的本站團隊成員存取資訊，且相關人員均須遵守嚴格的保密義務合約，一旦未遵守義務便將受到相關的法律處分
			</p>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>五、《隱私權及資訊安全政策》之修正</h3>
			<p>
				本站《隱私權及資訊安全政策》將因應需求隨時進行修正，並且不會在未經您明確同意的情況下，即縮減本《隱私權及資訊安全政策》賦予您的權利。修正後的條款將刊登於網站上，若會員不同意修改的內容，請來信告知或暫停使用本站。若會員繼續使用，將視為會員已同意並接受本規範等增訂或修改內容之約束。
			</p>
		</div>

		<div class="bs-callout bs-callout-danger">
			<h3>六、《隱私權及資訊安全政策》之範圍及效力</h3>
			<p>
				本站的《隱私權及資訊安全政策》不適用於由其他網站連結或個人提供的服務，諸如由本站所連結到的其他網站皆不在此《隱私權及資訊安全政策》適用範圍之內。我們的《隱私權及資訊安全政策》不涵括其他為本站服務宣傳之公司或機構所採用的資訊做法。
			</p>
			<p>
				本同意書所定之任何會員條款之全部或一部無效時，不影響其他條款之效力。
				關於本會員條款之解釋或適用，均以中華民國之法律為基準。使用者因使用本站而生之爭議，同意本誠信原則解決之，如有訴訟之必要時，同意以台灣台北地方法院為第一審管轄法院。
			</p>
		</div>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "隱私權及資訊安全政策"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";

// *Useless:
// -------------------------
// .any-about-page {
//   // Purpose: Make it smaller than previous settings,
//   // because the number character change from 一 二 三 to (1) (2) (3):
//   // ----------------------------------------------------------------
//   --list-like-p-indent-spacing: 1.3em;
// }
</style>
