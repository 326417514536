import axios from "axios";
import { restFactory, host, file_resolve } from "./base.js";

const user = axios.create({
	baseURL: host + "genericUser/api/users/"
});

const userExtra = axios.create({
	baseURL: host + "genericUser/api/userextras/"
});

const disabilityCard = axios.create({
	baseURL: host + "genericUser/api/disabilitycards/"
});

const serviceInfo = axios.create({
	baseURL: host + "genericUser/api/serviceinfos/"
});

const announcement = axios.create({
	baseURL: host + "genericUser/api/announcements/"
});

export const announcementAction = {
	latest: ({ category }) => {
		let params = {};
		if (category) {
			params = { ...params, ...{ category } };
		}
		return announcement.get(`action/latest/`, { params });
	},
	attachmentUrl: pk =>
		`${host}genericUser/api/announcements/${pk}/resource/attachment/`
};

const qAndA = axios.create({
	baseURL: host + "genericUser/api/qandas/"
});

const organization = axios.create({
	baseURL: host + "genericUser/api/organizations/"
});

const businessContent = axios.create({
	baseURL: host + "genericUser/api/businesscontents/"
});

const bannerContent = axios.create({
	baseURL: host + "genericUser/api/bannercontents/"
});

const recommendationSubject = axios.create({
	baseURL: host + "genericUser/api/recommendationsubjects/"
});

export const userRest = restFactory(user);
export const userExtraRest = restFactory(userExtra);
export const disabilityCardRest = restFactory(disabilityCard);
export const serviceInfoRest = restFactory(serviceInfo);
export const serviceInfoAction = {
	exportExchangeFalse: () => {
		const url = `action/exchange_false_export/`;
		return serviceInfo
			.get(url, { responseType: "blob" })
			.then(file_resolve("list.csv"));
	}
};

export const announcementRest = restFactory(announcement);
export const qAndARest = restFactory(qAndA);
export const organizationRest = restFactory(organization);
export const businessContentRest = restFactory(businessContent);
export const bannerContentRest = restFactory(bannerContent);
export const bannerContentAction = {
	getBanner: pk => {
		const url = `${host}genericUser/api/bannercontents/${pk}/resource/cover/image`;
		return url;
	},
	postBanner: (pk, file) => {
		const url = `${host}genericUser/api/bannercontents/${pk}/resource/cover/image/`;
		const formData = new FormData();
		formData.append("object", file);
		return bannerContent
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(res => {
				return res;
			});
	}
};
export const recommendationSubjectRest = restFactory(recommendationSubject);
export const recommendationSubjectAction = {
	getCover: pk => {
		const url = `${host}genericUser/api/recommendationsubjects/${pk}/resource/cover/image`;
		return url;
	},
	postcover: (pk, file) => {
		const url = `${host}genericUser/api/recommendationsubjects/${pk}/resource/cover/image/`;
		const formData = new FormData();
		formData.append("object", file);
		return recommendationSubject
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(res => {
				return res;
			});
	}
};
export const userAction = {
	login: (username, password) =>
		user.post("action/login/", { username, password }),
	authenticate: (username, password) =>
		user.post("action/authenticate/", { username, password }),
	setPassword: (pk, { old_password, new_password1, new_password2 }) =>
		user.post(`${pk}/action/set_password/`, {
			old_password,
			new_password1,
			new_password2
		}),
	resetPassword: ({ action, username, birthday }) =>
		user.post("action/retrieve_up/", { action, username, birthday }),
	getUser: ({ action, email, birthday }) =>
		user.post("action/retrieve_up/", { action, email, birthday }),
	email: ({ category, subject, body }) =>
		user.post("action/email/", { category, subject, body }),
	verify: (pk, { type, action, code }) =>
		user.post(`${pk}/action/verify/`, { type, action, code }),
	editorExtraInfo: pk => user.get(`${pk}/action/editor_extra_info/`),
	guestExtraInfo: pk => user.get(`${pk}/action/guest_extra_info/`),
	select: params => {
		return user.get(`action/select/`, { params });
	}
};

export const disabilityCardAction = {
	createUpdate: data => disabilityCard.post(`action/create_update/`, data),
	getFront: pk => {
		const url = `${host}genericUser/api/disabilitycards/${pk}/resource/source/front/`;
		return disabilityCard.get(url, { responseType: "blob" }).then(response => {
			return response.data;
		});
	},
	getBack: pk => {
		const url = `${host}genericUser/api/disabilitycards/${pk}/resource/source/back/`;
		return disabilityCard.get(url, { responseType: "blob" }).then(response => {
			return response.data;
		});
	},
	postFront: (pk, file) => {
		const url = `${host}genericUser/api/disabilitycards/${pk}/resource/source/front/`;
		const formData = new FormData();
		formData.append("object", file);
		disabilityCard
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(res => {
				return [res];
			});
	},
	postBack: (pk, file) => {
		const url = `${host}genericUser/api/disabilitycards/${pk}/resource/source/back/`;
		const formData = new FormData();
		formData.append("object", file);
		disabilityCard
			.post(url, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			.then(res => {
				return [res];
			});
	}
};
