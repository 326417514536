export function addMark(strValue, editor) {
	var caretPos = getCursorPosition(editor);
	if (caretPos == -1) {
		return false;
	}

	var textAreaTxt = editor.getContent();
	var subCarePos = textAreaTxt.substring(0, caretPos);
	var lastLinePos = -1;
	var nextLine = ["\n", "<br />", "<br>", "</p>"];
	lastLinePos = -1;
	var lastNextLineIndex = 0;
	for (var index in nextLine) {
		if (lastLinePos < subCarePos.lastIndexOf(nextLine[index])) {
			lastLinePos = subCarePos.lastIndexOf(nextLine[index]);
			lastNextLineIndex = index;
		}
	}
	lastLinePos += nextLine[lastNextLineIndex].length;
	setCursorPosition(editor, lastLinePos);
	editor.insertContent(strValue);
	return true;
}

function setCursorPosition(editor, index) {
	//get the content in the editor before we add the bookmark...
	//use the format: html to strip out any existing meta tags
	var content = editor.getContent({ format: "html" });
	//split the content at the given index
	var part1 = content.substr(0, index);
	var part2 = content.substr(index);
	//create a bookmark... bookmark is an object with the id of the bookmark
	var bookmark = editor.selection.getBookmark(0);
	//this is a meta span tag that looks like the one the bookmark added... just make sure the ID is the same
	var positionString =
		'<span id="' +
		bookmark.id +
		'_start" data-mce-type="bookmark" data-mce-style="overflow:hidden;line-height:0px"></span>';
	//cram the position string inbetween the two parts of the content we got earlier
	var contentWithString = part1 + positionString + part2;
	//replace the content of the editor with the content with the special span
	//use format: raw so that the bookmark meta tag will remain in the content
	editor.setContent(contentWithString, { format: "raw" });
	//move the cursor back to the bookmark
	//this will also strip out the bookmark metatag from the html
	editor.selection.moveToBookmark(bookmark);
	//return the bookmark just because
	return bookmark;
}

function getCursorPosition(editor) {
	//set a bookmark so we can return to the current position after we reset the content later
	var bm = editor.selection.getBookmark(0);
	//select the bookmark element
	var selector = "[data-mce-type=bookmark]";
	var bmElements = editor.dom.select(selector);
	//put the cursor in front of that element
	editor.selection.select(bmElements[0]);
	editor.selection.collapse();
	//add in my special span to get the index...
	//we won't be able to use the bookmark element for this because each browser will put id and class attributes in different orders.
	var elementID = "######cursor######";
	var positionString = '<span id="' + elementID + '">cursor</span>';
	editor.selection.setContent(positionString);
	//get the content with the special span but without the bookmark meta tag
	var content = editor.getContent({ format: "html" });
	//find the index of the span we placed earlier
	var index = content.indexOf(positionString);
	/*if(index==-1){
    //find the index of the span we placed earlier
    index = content.indexOf("<p>&nbsp;</p>");
  }*/
	//remove my special span from the content
	editor.dom.remove(elementID, false);
	//move back to the bookmark
	editor.selection.moveToBookmark(bm);
	return index;
}
