<template>
	<div class="this-is-modify-person-info">
		<template v-if="mode == 'read'">
			<div class="btnn-group is-margin-y is-justify-content-start">
				<div class="layer-2">
					<button @click="mode = 'write'" class="btn btn-primary">編輯</button>
				</div>
			</div>
			<table class="table">
				<tbody>
					<template v-for="v in ['username', 'name']">
						<tr :key="v">
							<th>{{ model[v].label }} :</th>
							<td>
								<template v-if="model[v].choices">
									<template v-for="item in model[v].choices">
										<template v-if="value[v] === item.value">
											{{ item.display_name }}
										</template>
									</template>
								</template>
								<template v-else>
									{{ value[v] }}
								</template>
							</td>
						</tr>
					</template>
					<template v-for="v in ['email', 'phone']">
						<tr :key="v">
							<td scope="col">{{ model[v].label }} :</td>
							<td scope="col">
								<span>{{ value[v] }}</span>
								<button
									v-if="String(user['auth_' + v]) == 'true'"
									class="btn btn-success btn-if-already-verified"
									disabled
								>
									已驗證
								</button>
								<button
									v-else
									class="btn btn-warning btn-if-already-verified"
									@click="openAuthModal(v)"
								>
									未驗證
								</button>
							</td>
						</tr>
					</template>
					<template v-for="v in ['birthday', 'education', 'is_book', 'org']">
						<tr :key="v">
							<td scope="col">{{ model[v].label }} :</td>
							<td scope="col">
								<template v-if="model[v].choices">
									<template v-for="item in model[v].choices">
										<template v-if="value[v] === item.value">
											{{ item.display_name }}
										</template>
									</template>
								</template>
								<template v-else>
									{{ value[v] }}
								</template>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
		</template>
		<template v-if="mode == 'write'">
			<div class="btnn-group is-margin-y is-justify-content-start">
				<div class="layer-2">
					<button class="btn btn-primary" @click="cancel">取消</button>
					<button class="btn btn-primary" @click="save()">儲存</button>
				</div>
			</div>
			<template
				v-for="v in [
					'name',
					'email',
					'phone',
					'birthday',
					'education',
					'is_book',
					'org'
				]"
			>
				<formdrf
					:model_info="model[v]"
					:field="v"
					v-model="value[v]"
					:key="v"
				></formdrf>
			</template>
		</template>
		<modal v-if="authEmailShow" @closeModal="authEmailShow = false">
			<template slot="header">
				<h3 class="title">信箱驗證碼</h3>
			</template>
			<template slot="body">
				<formdrf
					class="is-3-character-label"
					:model_info="{ label: '驗證碼', type: 'string', required: true }"
					:field="'verification_code'"
					v-model="verification_code"
				></formdrf>
				<div class="btnn-group is-margin-y is-justify-content-start">
					<div class="layer-2">
						<button class="btn btn-primary" @click="verifyOTP('email')">
							送出驗證碼
						</button>
						<button
							class="btn btn-primary"
							:disabled="!isAllowedGetOTP"
							@click="getOTP('email')"
						>
							取得驗證碼
							<template v-if="!isAllowedGetOTP">
								<span class="badge">{{ finalCounts }}</span>
							</template>
						</button>
						<span v-if="!isAllowedGetOTP">已傳送</span>
					</div>
				</div>
			</template>
			<template slot="footer">
				<button
					class="btn btn-primary"
					onclick="closeDialog(this)"
					@click="resetOTP"
				>
					關閉
				</button>
			</template>
		</modal>
		<modal v-if="authPhoneShow" @closeModal="authPhoneShow = false">
			<template slot="header">
				<h3 class="title">手機驗證碼</h3>
			</template>
			<template slot="body">
				<formdrf
					class="is-3-character-label"
					:model_info="{ label: '驗證碼', type: 'string', required: true }"
					:field="'verification_code'"
					v-model="verification_code"
				></formdrf>
				<div class="btnn-group is-margin-y is-justify-content-start">
					<div class="layer-2">
						<button class="btn btn-primary" @click="verifyOTP('phone')">
							送出驗證碼
						</button>
						<button
							class="btn btn-primary"
							:disabled="!isAllowedGetOTP"
							@click="getOTP('phone')"
						>
							取得驗證碼
							<template v-if="!isAllowedGetOTP">
								<span class="badge">{{ finalCounts }}</span>
							</template>
						</button>
						<span v-if="!isAllowedGetOTP">已傳送</span>
					</div>
				</div>
			</template>
			<template slot="footer">
				<button
					class="btn btn-primary"
					onclick="closeDialog(this)"
					@click="resetOTP"
				>
					關閉
				</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { userRest, userAction } from "@/api/genericUser";
import formdrf from "@/components/form.vue";
import { mapState } from "vuex";
import modal from "@/components/popup/modal.vue";

export default {
	props: {
		user_id: {
			type: Number,
			default: 0
		}
	},
	components: {
		formdrf,
		modal
	},
	data() {
		return {
			mode: "read",
			authPhoneShow: false,
			authEmailShow: false,
			newRatio: "",
			percentageIndex: 0,
			isAllowedGetOTP: true,
			verification_code: "",
			finalCounts: 600,
			user: {
				id: "",
				username: "",
				password: "",
				confirm_password: "",
				name: "",
				email: "",
				phone: "",
				birthday: "",
				education: "",
				is_book: "",
				org: "",
				auth_email: false,
				auth_phone: false
			},
			value: {
				id: "",
				username: "",
				password: "",
				confirm_password: "",
				name: "",
				email: "",
				phone: "",
				birthday: "",
				education: "",
				is_book: "",
				org: ""
			},
			model: {
				username: {
					label: "帳號",
					type: "email",
					required: true,
					remark: "請填寫電子郵件"
				},
				password: {
					label: "密碼",
					type: "password",
					required: true,
					remark: ""
				},
				confirm_password: {
					label: "確認密碼",
					type: "password",
					required: true,
					remark: ""
				},
				name: {
					label: "名字",
					type: "text"
				},
				email: {
					label: "電子信箱",
					type: "text",
					required: true
				},
				phone: {
					label: "聯絡電話",
					type: "text",
					required: true
				},
				birthday: {
					label: "生日",
					type: "text",
					remark: "請填寫西元年(4位)-月(2位)-日(2位)。範例：2020-01-01"
				},
				education: {
					label: "教育程度",
					type: "select",
					choices: [
						{
							value: "",
							display_name: "---------"
						},
						{
							value: "國小",
							display_name: "國小"
						},
						{
							value: "國中",
							display_name: "國中"
						},
						{
							value: "高中",
							display_name: "高中"
						},
						{
							value: "學士",
							display_name: "學士"
						},
						{
							value: "碩士",
							display_name: "碩士(以上)"
						}
					]
				},
				is_book: {
					label: "訂閱訊息",
					type: "checkbox"
				},
				org: {
					label: "所屬單位",
					type: "select",
					choices: []
				}
			}
		};
	},
	computed: {
		...mapState({
			orgs: "organizations"
		})
	},
	watch: {
		orgs() {
			this.orgsSetted();
		},
		user_id() {
			this.userIdSetted();
		}
	},
	mounted() {
		this.orgsSetted();
		this.userIdSetted();
	},
	methods: {
		orgsSetted() {
			_.each(this.orgs, v => {
				this.model.org.choices.push({
					value: v.id,
					display_name: v.name
				});
			});
		},

		userIdSetted() {
			userRest.read(this.user_id).then(res => {
				_.each(this.user, (v, k) => {
					this.user[k] = res.data[k];
				});
				this.user2local();
			});
		},
		openAuthModal(field) {
			if (field === "email") {
				this.authEmailShow = true;
			}
			if (field === "phone") {
				this.authPhoneShow = true;
			}
		},
		getOTP(type) {
			userAction
				.verify(this.user.id, {
					type: type,
					action: "generate"
				})
				.then(res => {
					this.$root.$message.open({
						status: 200,
						message: res.data["detail"]
					});
					this.isAllowedGetOTP = false;
					let timer = setInterval(() => {
						if (this.finalCounts === 0) {
							this.isAllowedGetOTP = true;
							this.finalCounts = 600;
							clearInterval(timer); // stop setInterval
						}
						this.finalCounts -= 1;
					}, 1000);
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		verifyOTP(type) {
			userAction
				.verify(this.user.id, {
					type: type,
					action: "verify",
					code: this.verification_code
				})
				.then(res => {
					this.$root.$message.open({
						status: 200,
						message: res.data["detail"]
					});
					_.each(this.user, (v, k) => {
						this.user[k] = res.data[k];
					});
					this.user2local();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		resetOTP() {
			this.verification_code = "";
		},
		cancel() {
			_.each(this.value, (v, k) => {
				this.value[k] = this.user[k];
			});
			this.mode = "read";
		},
		save() {
			userRest
				.partialupdate(this.value.id, {
					...this.value
				})
				.then(res => {
					_.each(this.user, (v, k) => {
						this.user[k] = res.data[k];
					});
					_.each(this.value, (v, k) => {
						this.value[k] = res.data[k];
					});
					this.mode = "read";
					this.$root.$message.open({
						status: 200,
						message: "成功更新使用者資料"
					});
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		user2local() {
			_.each(this.value, (v, k) => {
				this.value[k] = this.user[k];
			});
		}
	}
};
</script>

<style lang="scss" scoped>
table {
	tbody {
		td:nth-child(1) {
			--bootstrap-td-padding-x: 0.75rem;
			--safe-value: 0.2em;
			width: calc(
				var(--form-item-col1-width) + (var(--bootstrap-td-padding-x) * 2) +
					var(--safe-value)
			);
		}
		td:nth-child(2) {
			// *Because email is too long.
			// -----------------------------
			// *This don't work, don't know why
			// overflow-wrap: break-word;
			// *This is work around (though this css is a bit different purpose,
			// but can roughly achive my goal).
			word-break: break-all;

			> span {
				// overflow-wrap: break-word;
				word-break: break-all;
			}
		}
	}
	.btn-if-already-verified {
		margin-left: 0.7em;
	}
}

@media (max-width: media-query-length($em: $breakpoint_xs)) {
	// Purpose: To teak for mobile only, to prevent awkward line break looking.
	::v-deep .form-group.is-3-character-label {
		--form-item-col1-width: 3.7em;
	}
}
</style>
