<template>
	<div class="layout">
		<div class="main-container">
			<h1 class="sr-only">雲端千眼平台</h1>
			<main class="container" role="main">
				<router-view></router-view>
			</main>
		</div>
		<com-token />
		<countdown />
		<countdown-multiple />
		<message />
		<confirm />
		<log />
		<api />
	</div>
</template>

<script>
import comToken from "./token.vue";
import countdown from "./popup/countdown";
import CountdownMultiple from "./popup/CountdownMultiple";
import message from "./popup/message";
import confirm from "./popup/confirm";
import log from "./popup/log";
import api from "./popup/api";

export default {
	name: "layout",
	components: {
		comToken,
		countdown,
		CountdownMultiple,
		message,
		confirm,
		log,
		api
	},
	data() {
		return {};
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
main {
	// Some empty spacing.
	margin-top: 1.2em;
}
.layout {
	height: 100%;

	.main-container {
		min-height: 100%;
		display: flex;
		flex-direction: column;
		align-content: stretch;
		align-items: stretch;

		> * {
			flex-basis: auto;
			flex-grow: 0;
		}
		// Stickey footer.
		> .purpse-for-flex-grow {
			flex-grow: 1;
		}
	}
}
</style>
