import { userAction } from "@/api/genericUser.js";

const nav_item_mode_normal_main = [
	{
		type: "item",
		display_name: "網站導覽",
		permission: ["true"],
		routeName: "siteMap"
	},
	{
		type: "item",
		display_name: "管理",
		permission: ["is_supermanager"],
		routeName: "manageItem"
	},
	{
		type: "folder",
		display_name: "書籍上傳",
		permission: ["login"],
		items: [
			{
				type: "item",
				display_name: "掃描檔上傳",
				permission: ["auth_guest", "is_supermanager", "is_manager"],
				routeName: "bookCreate"
			},
			{
				type: "item",
				display_name: "電子檔上傳",
				permission: ["is_supermanager"],
				routeName: "bookUpload"
			}
		]
	},
	{
		type: "item",
		display_name: "校對服務",
		permission: ["auth_editor"],
		routeName: "service"
	},
	{
		type: "item",
		display_name: "校對進度",
		permission: ["auth_guest"],
		routeName: "bookPerson"
	},
	{
		type: "item",
		display_name: "我的書櫃",
		permission: ["auth_guest"],
		routeName: "bookShelf"
	},
	{
		type: "item",
		display_name: "平台書庫",
		permission: ["true"],
		routeName: "bookRepository"
	},
	{
		type: "folder",
		display_name: "專案合作",
		permission: ["true"],
		items: [
			{
				type: "item",
				display_name: "法鼓全集",
				permission: ["true"],
				routeName: "ddm"
			},
			/*{
				type: 'item',
				'display_name': '法鼓文化',
				'permission': ['true'],
				'routeName': 'ddmCulture',
			},*/
			{
				type: "item",
				display_name: "法鼓山出版書籍",
				permission: ["true"],
				routeName: "ddmCulture2"
			},
			{
				type: "item",
				display_name: "用心看世界",
				permission: ["true"],
				routeName: "heart"
			},
			{
				type: "item",
				display_name: "目宿媒體",
				permission: ["true"],
				routeName: "fisfisa"
			}
		]
	}
];

const nav_item_mode_normal_right = [
	{
		type: "folder",
		display_name: "帳號",
		permission: ["login"],
		items: [
			{
				type: "item",
				display_name: "服務紀錄",
				permission: ["auth_editor"],
				routeName: "serviceRecord"
			},
			{
				type: "item",
				display_name: "會員資料",
				permission: ["true"],
				routeName: "person"
			},
			{
				type: "item",
				display_name: "平台管理",
				permission: ["is_supermanager"],
				routeName: "manageItem"
			},
			{
				type: "item",
				display_name: "校園管理",
				permission: ["is_manager"],
				routeName: "manageItemSchool"
			},
			{
				type: "action",
				display_name: "登出",
				permission: ["true"],
				action: that => {
					// Todo: .
					that.remove();
					window.location.replace(process.env.BASE_URL);
				}
			}
		]
	},
	{
		type: "item",
		display_name: "登入",
		permission: "anonymous",
		routeName: "login"
	},
	{
		type: "item",
		display_name: "註冊",
		permission: "anonymous",
		routeName: "register"
	},
	{
		type: "folder",
		display_name: "關於",
		permission: ["true"],
		items: [
			{
				type: "item",
				display_name: "平台使用指南",
				permission: ["true"],
				routePath: "/about/userGuide/"
			},
			{
				type: "item",
				display_name: "平台問與答",
				permission: ["true"],
				routeName: "qandaPage"
			},
			{
				type: "item",
				display_name: "聯絡資訊",
				permission: ["true"],
				routePath: "/about/contact/"
			},
			{
				type: "item",
				display_name: "服務條款",
				permission: ["true"],
				routePath: "/about/termsOfService/"
			},
			{
				type: "item",
				display_name: "隱私與資訊安全",
				permission: ["true"],
				routePath: "/about/privacyAndSecurity/"
			},
			{
				type: "item",
				display_name: "平台濫觴",
				permission: ["true"],
				routePath: "/about/origin/"
			}
			/*{
				type: 'item',
				'display_name': '開發資訊',
				'permission': ['true'],
				'routePath': '/about/development/',
			},*/
		]
	}
];

const itemManage = {
	business: [
		{
			type: "item",
			display_name: "上傳書籍審核",
			permission: ["is_supermanager"],
			routeName: "bookReviewListSuper"
		},
		{
			type: "item",
			display_name: "校對文件審核",
			permission: ["is_supermanager"],
			routeName: "ebookReviewListSuper"
		},
		{
			type: "item",
			display_name: "書籍管理",
			permission: ["is_supermanager"],
			routeName: "bookManagerSuper"
		},
		{
			type: "item",
			display_name: "書籍類型管理",
			permission: ["is_supermanager"],
			routeName: "categoryManagerSuper"
		},
		{
			type: "item",
			display_name: "使用者管理",
			permission: ["is_supermanager"],
			routeName: "userManagerSuper"
		},
		{
			type: "item",
			display_name: "身障手冊管理",
			permission: ["is_supermanager"],
			routeName: "disabilitycardManagerSuper"
		},
		{
			type: "item",
			display_name: "服務時數確認",
			permission: ["is_supermanager"],
			routeName: "serviceinfoListCheckSuper"
		},
		{
			type: "item",
			display_name: "書籍校對順序",
			permission: ["is_supermanager"],
			routeName: "bookOrder"
		}
	],
	statistics: [
		{
			type: "item",
			display_name: "統計摘要",
			permission: ["is_supermanager"],
			routeName: "statisticsSummary"
		},
		{
			type: "item",
			display_name: "校對事件紀錄",
			permission: ["is_supermanager"],
			routeName: "rawConditionEbookStatusEventlogSuper"
		},
		{
			type: "item",
			display_name: "書籍閱讀統計",
			permission: ["is_supermanager"],
			routeName: "statisticsConditionBookReadSuper"
		},
		{
			type: "item",
			display_name: "志工服務時數統計",
			permission: ["is_supermanager"],
			routeName: "statisticsConditionServiceinfoSuper"
		},
		{
			type: "item",
			display_name: "視障者閱讀統計",
			permission: ["is_supermanager"],
			routeName: "statisticsConditionUserReadSuper"
		},
		{
			type: "item",
			display_name: "統計書籍下載",
			permission: ["is_supermanager"],
			routeName: "statisticsMonthBookDownload"
		},
		{
			type: "item",
			display_name: "統計書籍下載(epub)",
			permission: ["is_supermanager"],
			route: {
				name: "statisticsMonthBookDownload",
				query: { file_format: "epub" }
			}
		},
		{
			type: "item",
			display_name: "統計書籍下載(txt)",
			permission: ["is_supermanager"],
			route: {
				name: "statisticsMonthBookDownload",
				query: { file_format: "txt" }
			}
		},
		{
			type: "item",
			display_name: "統計使用者下載",
			permission: ["is_supermanager"],
			routeName: "statisticsMonthUserDownload"
		},
		{
			type: "item",
			display_name: "統計使用者校對",
			permission: ["is_supermanager"],
			routeName: "statisticsMonthUserEditrecord"
		}
	],
	content: [
		{
			type: "item",
			display_name: "管理首頁 Banner",
			permission: ["is_supermanager"],
			routeName: "bannercontentManager"
		},
		{
			type: "item",
			display_name: "訊息傳送",
			permission: ["is_supermanager"],
			routeName: "sendUserEmail"
		},
		{
			type: "item",
			display_name: "公告發佈",
			permission: ["is_supermanager"],
			routeName: "announcementCreate"
		},
		{
			type: "item",
			display_name: "分段管理",
			permission: ["is_superuser"],
			routePath: "/routing/ebookSystem/ebook_manager/"
		},
		{
			type: "item",
			display_name: "索引分類",
			permission: ["is_supermanager"],
			routeName: "indexCategoryManager"
		},
		{
			type: "item",
			display_name: "問與答",
			permission: ["is_supermanager"],
			routeName: "qandaList"
		}
		/*{
			type: 'item',
			'display_name': '推薦主題',
			'permission': ['is_supermanager'],
			'routeName': 'RecommendationSubjectList',
		},*/
	]
};

const nav_item_manage = [
	{
		type: "item",
		display_name: "上傳書籍審核",
		permission: ["is_supermanager"],
		routeName: "bookReviewListSuper"
	},
	{
		type: "item",
		display_name: "校對文件審核",
		permission: ["is_supermanager"],
		routeName: "ebookReviewListSuper"
	},
	{
		type: "item",
		display_name: "書籍管理",
		permission: ["is_supermanager"],
		routeName: "bookManagerSuper"
	},
	{
		type: "item",
		display_name: "書籍類型管理",
		permission: ["is_supermanager"],
		routeName: "categoryManagerSuper"
	},
	{
		type: "item",
		display_name: "使用者管理",
		permission: ["is_supermanager"],
		routeName: "userManagerSuper"
	},
	{
		type: "item",
		display_name: "身障手冊管理",
		permission: ["is_supermanager"],
		routeName: "disabilitycardManagerSuper"
	},
	{
		type: "item",
		display_name: "服務時數確認",
		permission: ["is_supermanager"],
		routeName: "serviceinfoListCheckSuper"
	},
	{
		type: "item",
		display_name: "書籍校對順序",
		permission: ["is_supermanager"],
		routeName: "bookOrder"
	},
	{
		type: "item",
		display_name: "書籍閱讀統計",
		permission: ["is_supermanager"],
		routeName: "statisticsConditionBookReadSuper"
	},
	{
		type: "item",
		display_name: "志工服務時數統計",
		permission: ["is_supermanager"],
		routeName: "statisticsConditionServiceinfoSuper"
	},
	{
		type: "item",
		display_name: "視障者閱讀統計",
		permission: ["is_supermanager"],
		routeName: "statisticsConditionUserReadSuper"
	},
	{
		type: "item",
		display_name: "統計書籍下載",
		permission: ["is_supermanager"],
		routeName: "statisticsMonthBookDownload"
	},
	{
		type: "item",
		display_name: "統計書籍下載(epub)",
		permission: ["is_supermanager"],
		route: {
			name: "statisticsMonthBookDownload",
			query: { file_format: "epub" }
		}
	},
	{
		type: "item",
		display_name: "統計書籍下載(txt)",
		permission: ["is_supermanager"],
		route: {
			name: "statisticsMonthBookDownload",
			query: { file_format: "txt" }
		}
	},
	{
		type: "item",
		display_name: "統計使用者下載",
		permission: ["is_supermanager"],
		routeName: "statisticsMonthUserDownload"
	},
	{
		type: "item",
		display_name: "統計使用者校對",
		permission: ["is_supermanager"],
		routeName: "statisticsMonthUserEditrecord"
	},
	{
		type: "item",
		display_name: "管理首頁 Banner",
		permission: ["is_supermanager"],
		routeName: "bannercontentManager"
	},
	{
		type: "item",
		display_name: "訊息傳送",
		permission: ["is_supermanager"],
		routeName: "sendUserEmail"
	},
	{
		type: "item",
		display_name: "公告發佈",
		permission: ["is_supermanager"],
		routeName: "announcementCreate"
	},
	{
		type: "item",
		display_name: "分段管理",
		permission: ["is_superuser"],
		routePath: "/routing/ebookSystem/ebook_manager/"
	}
];

const nav_item_manage_school = [
	{
		type: "item",
		display_name: "上傳書籍審核",
		permission: ["is_manager"],
		routeName: "bookReviewList"
	},
	{
		type: "item",
		display_name: "校對文件審核",
		permission: ["is_manager"],
		routeName: "bookReviewList"
	},
	{
		type: "item",
		display_name: "書籍管理",
		permission: ["is_manager"],
		routeName: "bookManager"
	},
	{
		type: "item",
		display_name: "書籍類型管理",
		permission: ["is_manager"],
		routeName: "bookManager"
	},
	{
		type: "item",
		display_name: "使用者管理",
		permission: ["is_manager"],
		routeName: "userManager"
	},
	{
		type: "item",
		display_name: "身障手冊管理",
		permission: ["is_manager"],
		routeName: "disabilitycardManager"
	},
	{
		type: "item",
		display_name: "服務時數確認",
		permission: ["is_manager"],
		routeName: "serviceinfoListCheck"
	},
	{
		type: "item",
		display_name: "書籍閱讀統計",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "志工服務時數統計",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "視障者閱讀統計",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "統計書籍下載",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "統計書籍下載(epub)",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "統計書籍下載(txt)",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "統計使用者下載",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "統計使用者校對",
		permission: ["is_manager"],
		routeName: ""
	},
	{
		type: "item",
		display_name: "公告發佈",
		permission: ["is_manager"],
		routeName: ""
	}
];

export {
	nav_item_mode_normal_main,
	nav_item_mode_normal_right,
	nav_item_manage,
	nav_item_manage_school,
	itemManage
};
