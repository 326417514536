<!--
*Todo: Improve accessibility: Make keyboard refocus .overlay when first popout is quit
and second (sequential) popout is shown.
The problem now is when there is multiple popouts, the first one could be "esc",
but the second one coudn't until you "refocus" to some DOM/html element with pressing tab key one time.
*Usage example:

setTimeout(async function () {
	await this.$root.$countdown.open({ status: 200, message: '加入購物車1' });
	await this.$root.$countdown.open({ status: 300, message: '加入購物車2' });
	await this.$root.$countdown.open({ status: 400, message: '加入購物車3' });
}.bind(this), 1000);
-->
<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="ok"
			@click="ok"
		>
			<div class="countdownPopup">
				<!-- <button class="close" @click="ok">&times;</button> -->
				<div class="content">
					<div class="statusCtnr">
						<font-awesome-icon
							class="status-icon"
							icon="check-circle"
							v-if="promptType === 'success'"
						/>
						<font-awesome-icon
							class="status-icon"
							icon="times-circle"
							v-else-if="promptType === 'error'"
						/>
					</div>
					<div class="message">{{ data.message }}</div>
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import Trap from "vue-focus-lock";
import { isString, isArray, isObject } from "@/utils/type";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	data() {
		return {
			promptLastingTime: 2000
		};
	},
	created() {
		this.$root.$countdown = this;
	},
	methods: {
		open({ status, message, type }) {
			this.data.status = status;
			this.promptType = parseInt(status, 10) < 400 ? "success" : "error";
			this.data.message = message;
			if (!type) {
				if (isString(message)) {
					this.data.type = "string";
				} else if (isArray(message)) {
					this.data.type = "array";
				} else if (isObject(message)) {
					this.data.type = "object";
				} else {
					this.data.message = JSON.stringify(message);
					this.data.type = "string";
				}
			} else {
				this.data.type = type;
			}
			this.isShow = true;
			setTimeout(() => {
				// Will also execute this: "this.isShow = false;"
				this.ok();
			}, this.promptLastingTime);
			this.promise = new Promise(resolve => {
				this.$on("ok", () => {
					console.log("Ok!");
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
			});
			return this.promise;
		}
	}
};
</script>

<style scoped lang="scss">
.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}

.countdownPopup {
	// Purpose: Much "red-like" color variation. Rotate hue counter-clockwise a bit
	--popup-background: #{scale-color(
			adjust-hue($color-orange-light, $degrees: -3),
			$lightness: 0%
		)};

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 12px 16px;
	width: 20em;
	background-color: var(--popup-background);
	color: white;
	border-radius: 4px;
	transition: all 1s ease-in-out;

	.content {
		// Globally (downward) change font-size.
		font-size: 1.04em;
		display: grid;
		// Rows currently contains these 3 things: 1. .statusCtnr 2. .message .
		grid-template-columns: auto 1fr;
		grid-template-rows: auto;
		align-content: center;
		grid-column-gap: 0.5em;

		.statusCtnr {
			--icon-font-size: 1.18em;

			position: relative;
			width: 1.8em;
			height: 1.8em;
		}
		.statusCtnr .status-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: var(--icon-font-size);

			// Purpose: To hide the underneath content that appears inside icon hollow part.
			// ===================================
			background-color: var(--popup-background);
			border-radius: 50%;
			// ===================================
		}

		.message {
			text-align: left;
		}
	}
}
</style>
