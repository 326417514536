<template>
	<div class="container">
		<h2 class="big-title">管理首頁 Banner</h2>
		<div class="row">
			<div class="col-sm-3 col-md-3">
				<div class="panel-group">
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title">
								<span class="glyphicon glyphicon-folder-close"></span
								>&nbsp;&nbsp;Banner
							</h4>
						</div>
						<div class="panel-collapse">
							<ul class="list-group">
								<li class="list-group-item">
									<a class="btn btn-link" v-on:click="read(-1)" tabindex="0"
										>新增</a
									>
								</li>
								<li
									class="list-group-item"
									v-for="(item, index) in items"
									:key="index"
								>
									<a class="btn btn-link" v-on:click="read(index)" tabindex="0"
										>{{ index + 1 }}. {{ item.title }}
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-9 col-md-9">
				<div class="display-button" v-if="index != -1">
					<button
						type="button"
						class="btn btn-general"
						v-if="mode == 'read'"
						v-on:click="change_mode"
					>
						編輯
					</button>
					<button
						type="button"
						class="btn btn-general"
						v-if="mode == 'write'"
						v-on:click="change_mode"
					>
						檢視
					</button>
				</div>
				<div v-if="mode == 'read'">
					<p class="h5"><strong>摘要文字</strong></p>
					<p>{{ temp.title }}</p>
					<p class="h5"><strong>圖片內容</strong></p>
					<img
						class="img-content"
						v-if="index != -1"
						:alt="temp.title"
						:src="temp.src"
					/>
					<br />
					<p class="h5"><strong>詳細說明</strong></p>
					<p v-html="markdown2html(temp.content)"></p>
				</div>
				<div v-if="mode == 'write'">
					<form>
						<formdrf
							:model_info="bannerModel.title"
							v-model="temp.title"
							alignLeft
						/>
						<formdrf :model_info="bannerModel.src" v-model="temp.src" alignLeft>
							<img
								class="img-content"
								v-if="index != -1"
								v-bind:alt="temp.title"
								v-bind:src="temp.src"
								alignLeft
							/>
						</formdrf>
						<formdrf
							:model_info="bannerModel.content"
							v-model="temp.content"
							alignLeft
						/>
						<formdrf
							:model_info="bannerModel.order"
							v-model="temp.order"
							alignLeft
						/>
						<formdrf
							:model_info="bannerModel.url"
							v-model="temp.url"
							alignLeft
						/>
						<formdrf
							:model_info="bannerModel.upload"
							@change="changeBanner"
							alignLeft
						/>
					</form>
					<div>
						<button
							class="btn btn-primary"
							v-if="index == -1"
							v-on:click="create()"
						>
							新增
						</button>
						<button class="btn btn-primary" v-else v-on:click="update()">
							更新
						</button>
						<button
							class="btn btn-danger"
							v-if="index != -1"
							v-on:click="del()"
						>
							刪除
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import showdown from "showdown";
import formdrf from "@/components/form2.vue";
import { bannerContentRest, bannerContentAction } from "@/api/genericUser.js";

export default {
	data() {
		return {
			mode: "write", //read or write
			items: [],
			index: -1,
			localBanner: null,
			temp: {
				id: "",
				title: "",
				content: "",
				order: "",
				url: "",
				src: "",
				category: "all"
			},
			bannerModel: {
				title: {
					type: "text",
					label: "摘要文字"
				},
				src: {
					type: "none",
					label: "圖片內容"
				},
				content: {
					type: "textarea",
					label: "詳細說明"
				},
				order: {
					type: "text",
					label: "排序權眾"
				},
				url: {
					type: "text",
					label: "連結網址"
				},
				upload: {
					type: "file",
					label: "上傳圖檔"
				}
			}
		};
	},
	metaInfo: {
		title: "管理首頁 Banner"
	},
	components: {
		formdrf
	},
	mounted() {
		this.list();
	},
	methods: {
		upload(id) {
			if (!this.localBanner) {
				this.$root.$message.open({ status: 400, message: "尚未選擇上傳文件" });
				return -1;
			}
			bannerContentAction
				.postBanner(id, this.localBanner)
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "成功更新資料(檔案)"
					});
					this.clear();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	console.log(res);
					// 	// this.$root.$message.open({status: 400, message: JSON.stringify(res)})
					// }
					this.$errorHandler({ error: res });
				});
		},
		clear() {
			this.localBanner = null;
			this.index = -1;
			this.temp = {
				id: -1,
				title: "",
				content: "",
				order: -1,
				url: "",
				category: "all",
				src: ""
			};
		},
		list() {
			this.clear();
			bannerContentRest.list().then(res => {
				this.items = [];
				res.data.forEach(v => {
					this.items.push({
						id: v.id,
						title: v.title,
						content: v.content,
						order: v.order,
						url: v.url,
						category: v.category,
						src: bannerContentAction.getBanner(v.id)
					});
				});
				this.read(this.index);
			});
		},
		create() {
			bannerContentRest
				.create(this.temp)
				.then(res => {
					if (this.localBanner) {
						console.log(res.data.id);
						this.upload(res.data.id);
					} else {
						this.$root.$message.open({ status: 200, message: "成功建立資料" });
					}
					this.list();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		read(index) {
			this.index = index;
			if (index == -1) {
				this.mode = "write";
				this.clear();
			} else {
				this.mode = "read";
				this.temp = this.items[index];
			}
		},
		update() {
			bannerContentRest.update(this.temp.id, this.temp).then(res => {
				if (this.localBanner) {
					this.upload(res.data.id);
				} else {
					this.$root.$message.open({ status: 200, message: "成功更新資料" });
				}
				this.list();
			});
			/*.catch(res => {
				if(res.response && res.response.data){
					this.$root.$message.open({status: 400, message: res.response.data})
				}
				else {
					this.$root.$message.open({status: 400, message: JSON.stringify(res)})
				}
			})*/
		},
		del() {
			bannerContentRest
				.delete(this.temp.id)
				.then(() => {
					this.index = -1;
					this.list();
					this.$root.$message.open({ status: 200, message: "成功刪除資料" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		changeBanner(e) {
			const file = e.target.files[0];
			this.localBanner = file;
		},
		change_mode() {
			if (this.mode == "read") {
				this.mode = "write";
			} else if (this.mode == "write") {
				this.mode = "read";
			}
		},
		markdown2html(text) {
			let converter = new showdown.Converter();
			let html = converter.makeHtml(text);
			return html;
		}
	}
};
</script>

<style lang="scss" scoped>
//把放在template裡的程式整理到這裡 之後再統整到scss
.col-sm-9 {
	&.col-sm-9 {
		margin-top: auto;
	}
}
.display-button {
	float: right;
}

.img-content {
	height: 480px;
	width: 940px;
}
.btn {
	&.btn-link {
		text-align: left;
	}
}
</style>
