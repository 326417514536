<template>
	<div class="container">
		<h3 class="sub-title">{{ org.name }}</h3>

		<!--<div class="row">---->
		<div>
			<div class="panel-group">
				<div class="panel panel-default">
					<form-filter>
						<form-item label="請選擇類別">
							<select class="form-control" v-model="pointer">
								<option
									v-for="(item, index) in items"
									:value="item"
									:key="index"
									>{{ item.name }}</option
								>
							</select>
						</form-item>
						<form-item>
							<button class="btn btn-primary" @click="ccModalShow = true">
								新增
							</button>
						</form-item>
						<span v-if="index > 0">
							<i
								class="fa fa-pencil-square-o"
								role="button"
								title="更名"
								@click="
									category_id = item.id;
									category_name = item.name;
								"
							></i>

							<i
								class="fa fa-times"
								role="button"
								title="刪除"
								@click="category_delete(item)"
								style="font-size: 1.2em;"
							></i>
						</span>
					</form-filter>

					<!---<div class="panel-heading">  已轉換成下拉選單
							<h4 class="panel-title">
								<span class="glyphicon glyphicon-folder-close"></span>&nbsp;&nbsp;分類列表
							</h4>
							<button class="btn btn-outline-secondary" @click="
									ccModalShow = true;
								">新增</button>
						</div>
						<div class="panel-collapse">
							<ul class="list-group">
								<li class="list-group-item" v-for="(item, index) in items">
									<a @click="pointer=item" href='#'>{{ index+1 }}. {{ item.name }}</a>

									<span v-if="index > 0">
										<i class="fa fa-pencil-square-o" role="button" title="更名" @click="
												category_id = item.id;
												category_name = item.name;
											"></i>

										<i class="fa fa-times" role="button" title="刪除" @click="category_delete(item)"
											style="font-size: 1.2em;"></i>
									</span>
								</li>
							</ul>
						</div>--->
				</div>
			</div>
		</div>
		<!--<div class="col-sm-9 col-md-9">-->

		<div v-for="(item, index) in items" :key="index">
			<template v-if="pointer.id === item.id">
				<h4>{{ item.name }}書籍列表</h4>
				<!--item.name-->
				<table-div
					:datas="item.book_list"
					:header="bookinfo_columns"
					:tdwidths="tdwidths"
				>
					<template slot="action" slot-scope="props">
						<button
							class="btn btn-outline-secondary"
							@click="
								book_ISBN = props.item;
								book_category_id = pointer.id;
								bcuModalShow = true;
							"
						>
							變更
						</button>
					</template>
				</table-div>
			</template>
		</div>
		<!--</div>-->
		<!--</div>---->
		<modal v-if="bcuModalShow" @closeModal="bcuModalShow = false">
			<template #header>
				<h4 class="modal-title">書籍{{ book_ISBN }}類別變更</h4>
			</template>
			<template #body>
				<div class="form-horizontal">
					<formdrf
						:model_info="model_info.book_category_id"
						:input-class="'col-sm-6'"
						:offset-class="'col-sm-offset-1'"
						:field="'book_category_id'"
						v-model="book_category_id"
						@keyup.enter.native="book_category_update()"
					></formdrf>
				</div>
			</template>
			<template #footer>
				<button
					class="btn btn-outline-secondary"
					@click="book_category_update()"
				>
					變更
				</button>
			</template>
		</modal>
		<modal v-if="ccModalShow" @closeModal="ccModalShow = false">
			<template slot="header">
				<h4 class="modal-title">類別新增</h4>
			</template>
			<template slot="body">
				<div class="form-horizontal">
					<formdrf
						:model_info="model_info.category_name"
						:input-class="'col-sm-6'"
						:offset-class="'col-sm-offset-1'"
						:field="'category_name'"
						v-model="category_name"
						@keyup.enter.native="category_create()"
					></formdrf>
				</div>
			</template>
			<template slot="footer">
				<button class="btn btn-outline-secondary" @click="category_create()">
					新增
				</button>
			</template>
		</modal>
		<modal v-if="cuModalShow" @closeModal="cuModalShow = false">
			<template slot="header">
				<h4 class="modal-title">類別{{ category_id }}更名</h4>
			</template>
			<template slot="body">
				<div class="form-horizontal">
					<formdrf
						:model_info="model_info.category_name"
						:input-class="'col-sm-6'"
						:offset-class="'col-sm-offset-1'"
						:field="'category_name'"
						v-model="category_name"
						@keyup.enter.native="category_update()"
					></formdrf>
				</div>
			</template>
			<template slot="footer">
				<button class="btn btn-outline-secondary" @click="category_update()">
					變更
				</button>
			</template>
		</modal>
	</div>
</template>

<script>
import { mapState } from "vuex";
import formdrf from "@/components/form.vue";
import modal from "@/components/popup/modal.vue";
import tableDiv from "@/components/tableDiv.vue";
import { bookSimpleRest, categoryRest } from "@/api/ebookSystem.js";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		formdrf,
		modal,
		tableDiv,
		formFilter,
		formItem
	},
	data() {
		return {
			tdwidths: [21, 29, 12, 2, 12, 12, 11, 1],
			ccModalShow: false,
			cuModalShow: false,
			bcuModalShow: false,
			pointer: {},
			items: [
				{
					id: 0,
					name: "未分類",
					book_list: []
				}
			],
			book_ISBN: "", // use in bcu modal pointer
			book_category_id: 0, // use in bcu modal input
			category_id: "", // use in cu modal pointer
			category_name: "", // use in cu modal input
			model_info: {
				book_category_id: {
					label: "類別",
					type: "select",
					choices: [
						{
							value: null,
							display_name: "未分類"
						}
					]
				},
				category_name: {
					label: "名稱",
					type: "text"
				}
			}
		};
	},
	computed: {
		query() {
			let temp = {};
			if (!(this.org.id == 0)) {
				temp["org_id"] = this.org.id;
			}
			return temp;
		},
		bookinfo_columns() {
			if (this.user.auth_guest) {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					order: "版次",
					author: "作者",
					house: "出版社",
					date: "出版日期",
					action: "動作"
				};
			} else {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					order: "版次",
					author: "作者",
					house: "出版社",
					date: "出版日期"
				};
			}
		},
		...mapState("user", ["user"])
	},
	mounted() {
		this.refresh();
	},
	methods: {
		refresh() {
			this.items = [];
			this.pointer = {};
			this.model_info = {
				book_category_id: {
					label: "類別",
					type: "select",
					choices: [
						{
							value: null,
							display_name: "未分類"
						}
					]
				},
				category_name: {
					label: "名稱",
					type: "text"
				}
			};
			this.book_ISBN = "";
			this.book_category_id = 0;
			this.category_id = "";
			this.category_name = "";
			bookSimpleRest
				.filter({
					...this.query,
					category_id: "null"
				})
				.then(res => {
					let bookinfos = [];
					_.each(res.data, v => {
						(v.book_info["action"] = v.book_info["ISBN"]),
							bookinfos.push(v.book_info);
					});
					this.items.push({
						id: 0,
						name: "未分類",
						book_list: bookinfos
					});
					this.pointer = this.items[0];
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
			categoryRest
				.filter({
					...this.query
				})
				.then(res => {
					_.each(res.data, v => {
						let bookinfos = [];
						_.each(v.book_set, v => {
							(v.book_info["action"] = v.book_info["ISBN"]),
								bookinfos.push(v.book_info);
						});
						this.items.push({
							id: v.id,
							name: v.name,
							book_list: bookinfos
						});
						this.model_info["book_category_id"].choices.push({
							value: v.id,
							display_name: v.name
						});
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		book_category_update() {
			let param = {
				category: this.book_category_id
			};
			bookSimpleRest
				.partialupdate(this.book_ISBN, param)
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "書籍類別變更成功"
					});
					this.bcuModalShow = false;
					this.refresh();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		category_create() {
			categoryRest
				.create({
					org: this.org.id,
					name: this.category_name
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "類別新增成功"
					});
					this.ccModalShow = false;
					this.refresh();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		category_update() {
			categoryRest
				.partialupdate(this.category_id, {
					name: this.category_name
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "類別名稱變更成功"
					});
					this.cuModalShow = false;
					this.refresh();
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		category_delete(item) {
			this.$root.$confirm
				.open({
					status: 200,
					message: `確認刪除類別:${item.name}`
				})
				.then(() => {
					return categoryRest.delete(item.id);
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: `成功刪除類別:${item.name}`
					});
					this.refresh();
				});
		}
	}
};
</script>

<style lang="scss" scoped>
div.panel-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.list-group-item {
	display: flex;
	justify-content: space-between;
}
</style>
