<template>
	<div class="form-horizontal">
		<div class="form-group">
			<label class="control-label col-sm-4">搜尋</label>
			<div class="col-sm-4">
				<input type="text" class="form-control" v-model="filter_word" />
			</div>
			<div class="col-sm-2">
				<button class="btn btn-primary" @click="filter_user">確認</button>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-sm-4">選擇使用者</label>
			<div class="col-sm-4">
				<select class="form-control" v-model="assign_user">
					<option value="">---</option>
					<template v-for="(user, index) in users">
						<option :value="user.username" :key="index">
							{{ user.username }}
						</option>
					</template>
				</select>
			</div>
			<div class="col-sm-4">
				<input class="form-control" v-model="assign_user" />
			</div>
		</div>
		<div class="form-group">
			<label class="control-label col-sm-4">預定完成時間</label>
			<div class="col-sm-4">
				<input
					v-model="deadline"
					type="text"
					value-format="yyyy-MM-dd"
					placeholder="yyyy-MM-dd"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import addDays from "date-fns/addDays";
import formatISO from "date-fns/formatISO";
import { ebookAction } from "@/api/ebookSystem.js";
import { userAction } from "@/api/genericUser.js";

export default {
	props: {
		pk: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			// assign data
			assign_user: "",
			deadline: "",
			users: [], // present users
			orig_users: [], // save api source users
			filter_word: ""
		};
	},
	mounted() {
		userAction.select({ role: "editor" }).then(res => {
			_.each(res.data.result, v => {
				this.users.push({
					id: v.id,
					username: v.username
				});
			});
			this.orig_users = this.users;
		});
		this.deadline = formatISO(addDays(new Date(), 5)).slice(0, 10);
	},
	methods: {
		save_assign() {
			ebookAction
				.assign(this.pk, {
					username: this.assign_user,
					deadline: this.deadline
				})
				.then(() => {
					this.$root.$message.open({
						status: 200,
						message: "成功指派文件給使用者"
					});
				})
				.catch(err => {
					// if (err.response && err.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: err.response.data
					// 	});
					// } else {
					// 	console.log(err);
					// }
					this.$errorHandler({ error: err });
				});
		},
		filter_user() {
			this.filter_word = this.filter_word.trim();
			let filtered_users = this.orig_users.filter(item => {
				return item.username.indexOf(this.filter_word) !== -1;
			});
			this.users = filtered_users;
		}
	}
};
</script>
