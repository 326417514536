<template>
	<div class="login">
		<h2 v-focus tabindex="0">登入會員</h2>
		<template v-for="v in ['username', 'password']">
			<formdrf
				:model_info="loginModel[v]"
				:field="v"
				v-model="loginData[v]"
				:key="v"
			></formdrf>
		</template>
		<div
			class="btnn-group is-margin-y is-justify-content-start is-two-button-margin-x-bigger"
		>
			<div class="layer-2">
				<button class="btn btn-primary" @click="login">登入</button>
				<router-link :to="{ name: 'retrieve' }">忘記密碼</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import formdrf from "@/components/form.vue";
import { userAction } from "@/api/genericUser";

export default {
	components: {
		formdrf
	},
	data() {
		return {
			loginData: {
				username: "",
				password: ""
			},
			loginModel: {
				username: {
					label: "帳號",
					type: "text",
					required: true
				},
				password: {
					label: "密碼",
					type: "password",
					required: true
				}
			}
		};
	},
	computed: {
		...mapState("token", ["token"])
	},
	mounted() {},
	methods: {
		...mapActions("token", ["obtain"]),
		...mapMutations("token", [
			"calculateAccessExpCountdown",
			"calculateRefreshExpCountdown",
			"loadToken"
		]),
		login() {
			if (
				this.loginData["username"] == "" ||
				this.loginData["password"] == ""
			) {
				this.$root.$message.open({
					status: 400,
					message: ["帳號或密碼不可為空白"]
				});
				return -1;
			}
			const sessionLogin = userAction.login(
				this.loginData.username,
				this.loginData.password
			);
			const tokenLogin = this.obtain({
				username: this.loginData.username,
				password: this.loginData.password
			});

			Promise.all([tokenLogin, sessionLogin])
				.then(() => {
					return this.$root.$message.open({
						status: 200,
						message: "成功登入系統"
					});
				})
				.then(() => {
					this.calculateAccessExpCountdown();
					this.$router.push({ name: "home" });
				})
				.catch(error => {
					this.$errorHandler({ error });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.login {
	max-width: 24em;
}
</style>
