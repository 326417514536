export const popup = {
	data() {
		return {
			isShow: false,
			promise: null,
			data: {
				status: 200,
				message: "",
				type: "string"
			},
			promptType: "success"
		};
	},
	watch: {
		isShow() {
			if (!this.isShow) {
				//this.$emit('ok');
			}
		}
	},
	methods: {
		close() {
			this.isShow = false;
			this.$emit("cancel");
		},
		cancel() {
			this.isShow = false;
			this.$emit("cancel");
		},
		ok() {
			this.isShow = false;
			this.$emit("ok");
		}
	}
};
