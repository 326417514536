<template>
	<div class="container">
		<h2 class="big-title" v-focus tabindex="0">
			平台書庫
			<span class="heading-btns">
				<button
					class="btn-outline-secondary"
					v-if="!isSearch"
					@click="isSearch = true"
				>
					進入書籍查詢
				</button>
				<button
					class="btn-outline-secondary"
					v-if="isSearch"
					@click="isSearch = false"
				>
					進入書籍索引
				</button>
			</span>
		</h2>
		<div class="row" v-if="!isSearch">
			<!--  書籍索引 !-->
			<div class="col-sm-4 col-md-4 no-pd-left">
				<div class="panel-group">
					<div class="panel panel-default">
						<div class="panel-heading">
							<h3 class="panel-title" style="margin-top:10px">
								<font-awesome-icon icon="briefcase" />
								<span
									class="glyphicon glyphicon-folder-close"
									style="font-size:14px;"
								></span
								>&nbsp;&nbsp;分類列表
							</h3>
						</div>
						<div class="panel-collapse">
							<ul class="list-group">
								<li
									class="list-group-item"
									v-for="(org, index) in items"
									:key="index"
								>
									<a @click="orgClick(org)">
										<i
											class="fa fa-chevron-down"
											aria-hidden="true"
											v-if="selected_org === org.id"
										></i>
										<i
											class="fa fa-chevron-right"
											aria-hidden="true"
											v-else
										></i>
										&nbsp;{{ org.name }}
									</a>
									<transition name="fade">
										<ol
											style="list-style-image:none;"
											v-if="selected_org === org.id"
										>
											<li
												style="margin-top: 0.5em; cursor: pointer;"
												v-for="(category, index) in org.categorys"
												:key="index"
											>
												<a
													@click="
														$router.push({
															name: 'bookRepositorySchoolCategory',
															params: { categoryId: category.id }
														})
													"
													>{{ category.name }}</a
												>
											</li>
										</ol>
									</transition>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-8 col-md-8" style="text-align:center">
				<h3 class="depart-name">{{ pointer.name }}書籍列表</h3>
				<router-view />
			</div>
		</div>

		<template v-else>
			<book-repository-school-filter
				:organizations="items"
			></book-repository-school-filter>
		</template>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { categoryRest } from "@/api/ebookSystem.js";
import { organizationRest } from "@/api/genericUser.js";
import bookRepositorySchoolFilter from "./bookRepositorySchoolFilter.vue";

export default {
	props: ["org_id"],
	components: {
		bookRepositorySchoolFilter
	},
	data() {
		return {
			selected_org: null,
			pointer: {},
			items: [],
			orgs_books: [],
			categorys_books: [],
			books: [],
			isSearch: false
		};
	},
	computed: {
		...mapState("user", ["user"]),
		bookinfo_header() {
			if (this.user.auth_guest) {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					order: "版次",
					author: "作者",
					house: "出版社",
					date: "出版日期",
					action: "動作"
				};
			} else {
				return {
					ISBN: "ISBN",
					bookname: "書名",
					bookbinding: "裝訂冊數",
					order: "版次",
					author: "作者",
					house: "出版社",
					date: "出版日期"
				};
			}
		}
	},
	metaInfo: {
		title: "平台書庫"
	},
	mounted() {
		this.refresh();
	},
	methods: {
		refresh: function() {
			this.items = [];
			this.orgs_books = [];
			this.categorys_books = [];
			this.pointer = {};
			organizationRest
				.list()
				.then(res => {
					// org get category
					_.each(res.data, o => {
						// org 為 1 屬特殊情形，是一般版使用，故在選擇列表內不顯示
						if (0 && o.id == 1) {
							return -1;
						}
						let org_category = {
							id: o.id,
							name: o.name,
							categorys: []
						};
						let temp = {
							id: o.id + "-null",
							name: "未分類"
						};
						org_category.categorys.push(temp);
						this.categorys_books.push(temp);
						categoryRest
							.filter({ org_id: o.id })
							.then(res => {
								_.each(res.data, c => {
									let temp = {
										id: c.id,
										name: c.name
									};
									org_category.categorys.push(temp);
									this.categorys_books.push(temp);
								});
							})
							.catch(res => {
								this.$errorHandler({ error: res });
							});
						this.items.push(org_category);
						this.orgs_books.push({
							id: o.id,
							name: o.name
						});
					});
				})
				.catch(res => {
					// alertmessage("error", o2j(res.response.data));
					// this.$errorHandler({ error: res });
					this.$errorHandler({ error: res });
				});
		},
		orgClick(org) {
			if (this.selected_org === org.id) {
				this.selected_org = null;
			} else {
				this.selected_org = org.id;
			}
			this.books = [];
			this.pointer = {};
			_.each(this.orgs_books, o => {
				if (org.id === o.id) {
					this.pointer = o;
				}
			});
			this.$router.push({
				name: "bookRepositorySchoolOrg",
				params: { orgId: org.id }
			});
		}
	}
};
</script>

<style scoped lang="scss">
// .fade-enter-active, .fade-leave-active {
//   transition: opacity .5s;
// }

// .fade-leave-active {
//   transition: opacity .1s;
// }
// .fade-enter, .fade-leave-to {
//   opacity: 0;
// }

// .heading-btns {
// 	margin-left: 1.5rem;
// }

.btn-outline-secondary {
	background-color: #e7e7e7; /* Green */
	border: 2px solid black;
	color: black;
	padding: 13px 16px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 15px;
	transition-duration: 0.2s;
	border-radius: 8px;
	float: right;
}
.btn-outline-secondary:hover {
	background-color: whitesmoke;
}
h3 {
	font-size: 17pt;
}
/deep/ .table {
	margin-top: 23px !important;
}
a {
	text-decoration: none;
	color: blue;
	&:hover {
		text-decoration: none;
		a {
			color: #2d76b5;
		}
	}
}
.no-pd-left {
	padding-left: 0;
}
.panel-title {
	margin: 0px;
	padding: 12px 20px;
	font-size: 15px;
	font-weight: bold;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem 0.24rem 0 0;
}
.list-group-item {
	&:hover {
		background: darkgray;
		color: #225a8a;
	}
	&:first-child {
		border-top: 0px !important;
		border-radius: 0 0 0.25rem 0.25rem;
	}
}
.depart-name {
	margin-left: -13px;
}
</style>
