<template>
	<Trap v-if="isShow" :disabled="!isShow">
		<div
			class="overlay"
			role="dialog"
			aria-modal="true"
			tabindex="-1"
			@keydown.esc="close"
		>
			<div class="popup">
				<div class="title-area">
					<h3 class="title">權限異常</h3>
					<button class="close" @click="close">&times;</button>
				</div>
				<hr />
				<div class="content">
					<div class="msgCtnr">
						<div class="preWrap">
							您目前帳號的權限無法存取此頁面，請使用有權之帳號登入
						</div>
					</div>
				</div>
				<div class="btnn-group is-justify-content-end">
					<div class="layer-2">
						<button class="btn btn-primary" @click="ok">確定</button>
					</div>
				</div>
				<div class="statusCtnr">
					<font-awesome-icon class="status-icon" icon="times-circle" />
				</div>
			</div>
		</div>
	</Trap>
</template>

<script>
import { mapState } from "vuex";
import Trap from "vue-focus-lock";
import { checkItemPermission } from "@/utils/auth";
import { popup } from "./base";
import FontAwesomeIcon from "@/utils/fortawesomeSubset.js";

export default {
	mixins: [popup],
	components: {
		Trap,
		FontAwesomeIcon
	},
	created() {
		this.$root.$auth = this;
		window.addEventListener("auth", () => {
			this.isShow = true;
		});
		setInterval(async () => {
			const permission = this.$route.matched[this.$route.matched.length - 1]
				.meta.permission;
			let result;
			if (!permission) {
				result = true;
			} else {
				result = checkItemPermission(this.user, permission);
			}
			if (!result) {
				await this.open();
				this.$router.push({ name: "login" });
			}
		}, 10000);
	},
	data() {
		return {
			percent: 0
		};
	},
	computed: {
		...mapState("user", ["user"])
	},
	methods: {
		open() {
			this.isShow = true;
			this.promise = new Promise(resolve => {
				this.$on("ok", () => {
					resolve();
				});
			});
			this.promise.finally(() => {
				this.$off("ok");
			});
			return this.promise;
		}
	}
};
</script>

<style scoped lang="scss">
@import "./style/common.scss";

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	transition: opacity 500ms;
	opacity: 0;

	visibility: visible;
	opacity: 1;
	z-index: 999;
}
</style>
