<template>
	<div class="home">
		<h2 v-focus tabindex="0" class="sr-only">首頁</h2>
		<div class="slide-wrapper">
			<slideshow :slideshowImagesRaw="bannercontentlist"></slideshow>
		</div>
		<div class="social-link">
			<a
				class="icon-btn line"
				href="https://line.me/R/ti/g/eYf_3Ast89"
				target="_blank"
				aria-label="連至 Line 帳號(開啟新視窗)"
			></a>
			<a
				class="icon-btn fb"
				href="https://www.facebook.com/forblind/"
				target="_blank"
				aria-label="連至 Facebook 頁面(開啟新視窗)"
			></a>
			<a
				class="icon-btn yt"
				href="https://www.youtube.com/channel/UC8TV4EKxbSQPzx8nmTzfIag"
				target="_blank"
				aria-label="連至 Youtube 頁面(開啟新視窗)"
			></a>
		</div>
		<div class="row">
			<div class="col-lg-4">
				<button
					type="button"
					class="btn btn-primary fake"
					role="button"
					@click="$router.push({ name: 'announcementList' })"
				>
					最新消息
				</button>
				<button
					type="button"
					class="btn btn-primary fake"
					role="button"
					@click="$router.push('/book/promotion')"
				>
					主題選書
				</button>
				<!--<button type="button" class="btn btn-primary fake" role="button" @click="$root.$message.open({status: 400, message: '此連結維護中'})">主題選書</button>-->
				<button
					type="button"
					class="btn btn-primary fake"
					role="button"
					@click="$router.push({ name: 'qandaPage' })"
				>
					使用QA
				</button>
				<button
					type="button"
					class="btn btn-primary fake"
					role="button"
					@click="$router.push({ name: 'volunteer' })"
				>
					志工專區
				</button>
			</div>
			<div class="col-lg-7 news-wrapper">
				<span class="title">最新消息</span>
				<div class="new-body">
					<p v-for="(announcement, key) in showAnnouncements" :key="key">
						<a
							class="max-lines-1"
							role="button"
							@click="
								$router.push({
									name: 'announcement',
									params: { id: announcement.id }
								})
							"
							>{{ announcement.year }}年{{ announcement.month }}月{{
								announcement.day
							}}日 {{ announcement.title }}</a
						>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { compare } from "@/utils/dataProcess";
import {
	announcementRest,
	bannerContentRest,
	bannerContentAction
} from "@/api/genericUser.js";
import slideshow from "@/components/slideshow.vue";

export default {
	name: "home",
	components: {
		slideshow
	},
	data() {
		return {
			bannercontentlist: [],
			announcementlist: []
		};
	},
	computed: {
		showAnnouncements() {
			let target_announcements = [];
			if (this.announcementlist) {
				if (this.announcementlist.length < 6) {
					target_announcements = this.announcementlist;
				} else {
					target_announcements = this.announcementlist.slice(0, 5);
				}
			}
			return target_announcements.map(function(announcement) {
				let year, month, day;
				[year, month, day] = announcement.datetime.split("-");
				return {
					id: announcement.id,
					title: announcement.title,
					year,
					month,
					day
				};
			});
		}
	},
	mounted() {
		Promise.all([
			bannerContentRest.filter({ category: "all" }),
			announcementRest.list()
		]).then(res => {
			this.bannercontentlist = [];
			res[0].data.forEach(v => {
				this.bannercontentlist.push({
					id: v.id,
					title: v.title,
					src: bannerContentAction.getBanner(v.id),
					url: v.url,
					content: v.content,
					order: v.order
				});
			});
			this.bannercontentlist = this.bannercontentlist.sort(
				compare("order", "asc")
			);
			this.announcementlist = res[1].data.filter(v => {
				return v.category != "校園公告" && v.category != "校園平台消息";
			});
		});
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/panel.scss";

.home {
	.slide-wrapper {
		background-image: url("../assets/home/phone.png");
		background-size: 100% calc(100% - 30px); //calc(100% + 30px) ;//1100px 484px;
		@media screen and (max-width: 800px) {
			padding-left: 50px;
			padding-right: 50px;
		}
		background-repeat: no-repeat;
		position: relative;
		//padding: 100px;
		padding-bottom: 60px;
		padding-top: 30px;
		padding-left: 105px;
		padding-right: 108px;
	}
	//border: 1px solid gray;
	.social-link {
		//position: absolute;
		position: relative;
		//left:calc(70% - 20px);
		left: 0;
		height: 48px;
		// background: black;
		bottom: 0.5rem;
		right: 1rem;
		display: flex;
		.icon-btn {
			width: 48px;
			height: 48px;
			background-size: 100%;
			background-repeat: no-repeat;
			margin-left: 1rem;
			&.line {
				background-image: url("../assets/school/line.png");
			}
			&.fb {
				background-image: url("../assets/school/fb-icon.png");
			}
			&.yt {
				width: 70px;
				background-image: url("../assets/school/youtube.png");
			}
		}
	}
	.row {
		height: 300px;
		justify-content: space-around;
		margin-top: 10px;
		@media screen and (max-width: 900px) {
			//767
			height: auto;
		}
		.col-lg-4,
		.col-lg-7 {
			flex-direction: column;
			display: flex;
			&.news-wrapper {
				border: 1px solid gray;
				margin-left: 30px;
				background: url("../assets/home/read.png");
				background-size: auto 35%;
				background-repeat: no-repeat;
				background-position: 98% 100%;
				@media screen and (max-width: 800px) {
					padding-bottom: 80px;
					background-size: auto 30%;
					background-position: 95% 100%;
				}
			}
			.fake {
				font-size: 24px;
				font-weight: bold;
				padding: 10px;
				&::before {
					width: 45px;
					height: 32.4px;
					content: "";
					font-size: 15px;
					background: url("../assets/home/NEW.png");
					background-size: 100% 100%;
					color: black;
					margin-top: 5px;
					display: inline-block;
					transform: rotate(-40deg);
					position: absolute;
					left: 50px;
					padding: 5px;
				}
			}
			.max-lines-1 {
				cursor: pointer;
			}
			button {
				margin: 10px;
			}
			.title {
				font-size: 28px;
				color: cadetblue;
			}
			.new-body {
				height: 185px;
				overflow: hidden;
			}
			.all-news {
				text-align: center;
				cursor: pointer;
			}
		}
	}
	.square {
		height: 150px;
		overflow: auto;
	}
}
</style>
