<template>
	<div>
		<h2 v-if="mode === 'upload'" class="big-title" v-focus tabindex="0">
			電子檔上傳
		</h2>
		<h2 v-if="mode === 'create'" class="big-title" v-focus tabindex="0">
			掃描檔上傳
		</h2>
		<div class="form-horizontal">
			<formdrf
				:model_info="{
					type: 'text',
					label: 'ISBN'
				}"
				v-model="search_ISBN"
			>
				<div
					style="margin-left: 10px;"
					class="btn-group"
					role="group"
					slot="btns"
				>
					<btn-with-icon
						@click="search_ISBN_info()"
						icon-name="search"
						name="取得書籍資訊"
					></btn-with-icon>
					<btn-with-icon
						icon-name="ellipsis-h"
						@click="searchModalShow = true"
						name="更多查詢方式"
					></btn-with-icon>
					<btn-with-icon
						icon-name="university"
						aria-label="國家圖書館書目資料查詢(另開新分頁)"
						@click="
							window.open(
								'http://isbn.ncl.edu.tw/NCL_ISBNNet/H30_SearchBooks.php?PHPSESSID=3ovphpac0m41ducm3iiak2sfe5&Pact=DisplayAll',
								'_blank'
							)
						"
						name="國家圖書館書目資料查詢"
					></btn-with-icon>
				</div>
			</formdrf>
			<template
				v-for="v in [
					'ISBN',
					'bookname',
					'author',
					'house',
					'date',
					'bookbinding',
					'chinese_book_category',
					'order',
					'source'
				]"
			>
				<formdrf
					:model_info="bookModel[v]"
					:field="v"
					v-model="bookData[v]"
					:key="v"
					:readonly="bookModel[v].readonly"
				></formdrf>
			</template>
			<template v-if="mode === 'upload'">
				<formdrf
					:model_info="{
						label: '格式',
						type: 'select',
						choices: [
							{
								display_name: '---------',
								value: ''
							},
							{
								display_name: 'txt',
								value: 'txt'
							},
							{
								display_name: 'epub',
								value: 'epub'
							}
						]
					}"
					v-model="format"
				/>
			</template>
			<formdrf
				:model_info="{
					type: 'select',
					label: '單位',
					choices: computedOrg
				}"
				v-model="org_id"
			/>
			<formdrf
				:model_info="{
					label: '類別',
					type: 'select',
					choices: computedCategory
				}"
				v-model="category_id"
			/>
			<formdrf
				:model_info="{
					label: '文件',
					type: 'file'
				}"
				@change="changeFile"
			/>
			<formdrf
				:model_info="{
					label: '',
					type: 'none'
				}"
			>
				<div class="btnn-group is-justify-content-start">
					<div class="layer-2">
						<button class="btn btn-primary" @click="create">送出</button>
						<button class="btn btn-danger" @click="resetModel">重置</button>
					</div>
				</div>
			</formdrf>
		</div>
		<bookinfo-search
			:searchModalShow="searchModalShow"
			@closeModal="searchModalShow = false"
			@bookinfo-out="search_ISBN_info_more($event)"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import formdrf from "@/components/form2.vue";
import bookinfoSearch from "@/components/components/bookinfoSearch.vue";
import indexCategorySelector from "@/components/components/indexCategorySelector.vue";
import { organizationRest } from "@/api/genericUser";
import { bookAction, bookInfoAction, categoryRest } from "@/api/ebookSystem";
import lodash from "lodash";

export default {
	props: {
		mode: {
			type: String,
			default: ""
		}
	},
	components: {
		formdrf,
		bookinfoSearch,
		indexCategorySelector,
		btnWithIcon: () => import("@/components/buttonWithIcon.vue")
	},
	data() {
		return {
			window,
			indexStyle: {
				background: "gray",
				border: "none",
				margin: "0 0 0 1rem"
			},
			searchModalShow: false,
			searching: false,
			search_ISBN: "",
			format: "",
			fileObject: null,
			bookData: {
				ISBN: "",
				bookname: "",
				author: "",
				house: "",
				date: "",
				bookbinding: "",
				chinese_book_category: "",
				order: "",
				source: ""
			},
			bookDataResetPurpose: null,
			bookModel: {
				ISBN: {
					label: "ISBN",
					type: "text",
					remark: "請輸入 13 碼 ISBN",
					required: true,
					readonly: true
				},
				bookname: {
					label: "書名",
					type: "text",
					remark: "請輸入書名",
					required: true,
					readonly: true
				},
				author: {
					label: "作者",
					type: "text",
					remark: "請輸入作者",
					required: true,
					readonly: true
				},
				house: {
					label: "出版社",
					type: "text",
					remark: "請輸入出版社",
					required: true,
					readonly: true
				},
				date: {
					label: "出版日期",
					type: "date",
					remark: "請輸入出版日期",
					required: true,
					readonly: true
				},
				bookbinding: {
					label: "裝訂冊數",
					type: "text",
					remark: "請輸入裝訂冊數資訊，例：平裝",
					readonly: true
				},
				chinese_book_category: {
					label: "中文圖書分類",
					type: "text",
					remark: "請輸入中國圖書分類號前 3 碼，例：857 即為小說分類",
					readonly: true
				},
				order: {
					label: "版次",
					type: "text",
					remark: "請輸入版次資訊，例：初版、二版",
					readonly: true
				},
				source: {
					label: "來源",
					type: "text",
					remark: "系統自動填入無需填寫",
					readonly: true
				}
			},
			org_id: "",
			category_id: "",
		};
	},
	computed: {
		...mapState("user", ["user"]),
		...mapState(["organizations", "categorys"]),
		loading() {
			if (this.searching) return "inline-block";
			else return "none";
		},
		computedOrg() {
			return (
				[
					...this.organizations.map(t => {
						return {
							display_name: t.name,
							value: t.id
						};
					})
				]
			);
		},
		computedCategory() {
			return (
				[
					...[
						{
							display_name: "未選擇",
							value: ""
						}
					],
					...this.categorys.map(t => {
						return {
							display_name: t.name,
							value: t.id
						};
					})
				]
			);
		},
	},
	metaInfo() {
		return {
			title: "書籍上傳"
		};
	},
	watch: {
		user() {
			this.org_id = this.user.org;
		},
		mode() {
			this.clear();
		},
	},
	methods: {
		search_ISBN_info() {
			if (this.search_ISBN.length !== 10 && this.search_ISBN.length !== 13) {
				this.$root.$message.open({ status: 400, message: "ISBN長度錯誤" });
				return -1;
			}
			this.searching = true;
			Promise.all([
				bookInfoAction.isbn2bookinfo({ ISBN: this.search_ISBN, source: "NCL" })
				//bookInfoAction.isbn2bookinfo({ISBN: this.search_ISBN, source: 'douban'}),
			])
				.then(res => {
					let result = [];
					if (res[0].data.bookinfo.ISBN) {
						result = res[0].data.bookinfo;
						this.$root.$message.open({
							status: 200,
							message: "查詢已完成，有成功取得資料"
						});
						this.search_ISBN = result["ISBN"]; //若查詢ISBN10會自動轉ISBN13，故要重新更新
						_.each(this.bookData, (v, k) => {
							this.bookData[k] = result[k];
						});
					} else {
						this.$root.$message
							.open({ status: 200, message: "查詢已完成，無取得資料" })
							.then(() => {
								return this.$root.$confirm.open({
									status: 200,
									message: "查無書籍資料，是否手動輸入？"
								});
							})
							.then(() => {
								_.each(this.bookData, (v, k) => {
									this.bookData[k] = "";
									if (k === "ISBN") {
										this.bookData[k] = this.search_ISBN;
									} else if (k === "source") {
										this.bookData[k] = "other";
									} else {
										this.bookModel[k].readonly = false;
									}
								});
							});
					}
				})
				.catch(err => {
					this.$root.$confirm
						.open({ status: 200, message: "查無書籍資料，是否手動輸入？" })
						.then(() => {
							_.each(this.bookData, (v, k) => {
								this.bookData[k] = "";
								if (k === "ISBN") {
									this.bookModel[k].readonly = false;
									this.bookData[k] = this.search_ISBN;
								} else if (k === "source") {
									this.bookData[k] = "other";
								} else {
									this.bookModel[k].readonly = false;
								}
							});
						});
				})
				.finally(() => {
					this.searching = false;
				});
		},
		search_ISBN_info_more(data) {
			this.searchModalShow = false;
			let result = data;
			this.search_ISBN = result["ISBN"]; //若查詢ISBN10會自動轉ISBN13，故要重新更新
			_.each(this.bookData, (v, k) => {
				this.bookData[k] = result[k];
			});
		},
		check() {
			let err = [];

			if (!this.fileObject) {
				err.push("上傳文件尚未選擇");
			}
			if (!this.format || this.format == "") {
				err.push("格式尚未選擇");
			}
			if (!this.org_id || this.org_id == "") {
				err.push("單位尚未選擇");
			}
			if (!this.category_id || this.category_id == "") {
				err.push("類型尚未選擇");
			}
			if (err.length > 0) {
				this.$root.$message.open({ status: 400, message: err });
				return false;
			}
			return true;
		},
		create() {
			if (!this.check()) {
				return false;
			}
			let transferData = {};
			_.each(this.bookData, (v, k) => {
				if (!(v === "")) {
					transferData[k] = v;
				}
			});
			bookInfoAction
				.createUpdate(transferData)
				.then(() => {
					return bookAction[this.mode]({
						ISBN: this.bookData["ISBN"],
						org_id: this.org_id,
						category_id: this.category_id,
						index_category_id: this.ic_id,
						format: this.format,
						object: this.fileObject
					});
				})
				.then(() => {
					this.clear();
					this.$root.$message.open({
						status: 200,
						message: "成功上傳書籍文件"
					});
				})
				.catch(res => {
					console.log(res);
					this.$errorHandler({ error: res });
				});
		},
		clear() {
			this.search_ISBN = "";
			_.each(this.bookData, (v, k) => {
				this.bookData[k] = "";
			});
			if (this.mode == "upload") {
				this.format = "epub";
			} else if (this.mode == "create") {
				this.format = "zip";
			}
			this.fileObject = null;
		},
		changeFile(e) {
			const file = e.target.files[0];
			this.fileObject = file;
		},
		resetModel() {
			this.bookData = this.bookDataResetPurpose;
		},
	},
	mounted() {
		this.org_id = this.user.org;
		this.bookDataResetPurpose = lodash.cloneDeep(this.bookData);
		console.log(this.mode);
		if (this.mode == "upload") {
			this.format = "epub";
		} else if (this.mode == "create") {
			this.format = "zip";
		}
	}
};
</script>
<style lang="scss" scoped>
.search-wrap {
	display: flex;
	justify-content: left;
	@media screen and (max-width: 767px) {
		justify-content: space-between;
	}
	.form {
		display: flex;
		margin-right: 10px;
	}
}
</style>
