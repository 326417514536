<template>
	<div class="this-is-user">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {};
	},
	computed: {}
};
</script>

<style scoped lang="scss"></style>
