// Purpose: So css text-align: justify property will works on chinese character too.
// Example: Convert string
// "你好嗎??! abcd efg123"
// to
// "你 好 嗎 ? ? ! abcd efg123".
function labelCssTextJustifyEffect(oldText) {
	let old1Array = oldText.split("");
	let newText = "";
	old1Array.forEach((aCharacter, index, thisArray) => {
		if (index - 1 >= 0 && thisArray[index - 1].match(/(\w|\d)/) === null) {
			newText += " ";
			newText += aCharacter;
		} else {
			newText += aCharacter;
		}
	});
	// Prevent sequential whitespaces, convert them to single whitespace.
	newText = newText.replace(/\s\s+/g, " ");
	return newText;
}

function toggleTwak() {
	var Tawk_API =
		Tawk_API ||
		// Tawk_LoadStart = new Date();
		(function() {
			var s1 = document.createElement("script"),
				s0 = document.getElementsByTagName("script")[0];
			// s1.defer = true;
			s1.src = "https://embed.tawk.to/595dd26650fd5105d0c842ed/default";
			s1.charset = "UTF-8";
			s1.setAttribute("crossorigin", "*");
			s0.parentNode.insertBefore(s1, s0);
		})();
}

export { labelCssTextJustifyEffect, toggleTwak };
