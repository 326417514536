<template>
	<div>
		<div :id="'book_manager' + org.id" class="tab-content">
			<h3 class="sub-title">{{ org.name }}</h3>
			<div id="book_manager_search">
				<form-filter>
					<form-item label="狀態">
						<select
							class="form-control mr-sm-2"
							v-model="search_filter"
							id="id_search_choices"
							required
						>
							<option value="all" selected="selected">全部</option>
							<option
								v-for="(value, key) in search_choices"
								:value="key"
								:key="key"
								>{{ value }}</option
							>
						</select>
					</form-item>
					<form-item label="書名">
						<input
							v-model="search_value"
							class="form-control"
							id="search_value"
							type="text"
							placeholder="輸入欲查詢書名"
							maxlength="15"
						/>
					</form-item>
					<div>
						<button type="button" class="btn btn-primary" @click="search()">
							搜尋
						</button>
					</div>
				</form-filter>
				<table-div
					:header="book_header"
					:datas="book_datas"
					:tdwidths="tdwidths"
				>
					<template slot="action" slot-scope="props">
						<a
							role="button"
							class="btn btn-outline-secondary "
							:href="
								$router.resolve({
									name: 'bookReview',
									params: { id: props.item.ISBN }
								}).href
							"
							title="審核（開啟新視窗）"
							target="blank"
							>審核</a
						>
						<a
							role="button"
							class="btn btn-outline-secondary"
							:href="
								$router.resolve({
									name: 'bookDetail',
									params: { id: props.item.ISBN }
								}).href
							"
							title="詳細（開啟新視窗）"
							target="blank"
							>詳細</a
						>
						<button
							class="btn btn-outline-secondary"
							@click="updateModal(props)"
						>
							資料編輯
						</button>
					</template>
				</table-div>
			</div>
		</div>
	</div>
</template>

<script>
import tableDiv from "@/components/tableDiv.vue";
import { bookRest } from "@/api/ebookSystem.js";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		tableDiv,
		formFilter,
		formItem
	},
	data() {
		return {
			indexStyle: {
				background: "gray",
				border: "none",
				margin: "0 0 0 1rem"
			},
			tdwidths: [20, 15, 13, 12, 7, 33],
			book_update: {},
			bookModalShow: false,
			user_list: [],
			search_choices: {
				"0": "未審核",
				"1": "未校對",
				"2": "校對中",
				"3": "審核校對中",
				"4": "已完成"
			},
			search_filter: "all",
			search_value: "",
			book_header: {
				ISBN: "ISBN",
				bookname: "書名",
				page: "頁數/總頁數",
				finish_part_count: "已完成段數",
				service_hours: "時數",
				action: "動作"
			},
			book_datas: [],
			icselectorModalShow: false,
			ic_id: 0,
			ic_name: "不分類"
		};
	},
	computed: {
		query() {
			let temp = { bookname: this.search_value };
			if (!(this.search_filter == "all")) {
				temp["status"] = this.search_filter;
			}
			if (!(this.org.id == 0)) {
				temp["org_id"] = this.org.id;
			}
			return temp;
		}
	},
	mounted() {},
	methods: {
		search() {
			bookRest
				.filter(this.query)
				.then(res => {
					this.book_datas = [];
					_.each(res.data, v => {
						this.book_datas.push({
							ISBN: v.ISBN,
							bookname: v.book_info.bookname,
							page: v.finish_page_count + "/" + v.page_count,
							finish_part_count: v.finish_part_count,
							service_hours: v.service_hours,
							action: v
						});
					});
					this.$root.$message.open({
						status: 200,
						message: "查詢完成，共取得 " + this.book_datas.length + " 筆資料"
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		},
		updateModal(props) {
			this.$router.push({
				name: "bookEdit",
				params: { isbn: props.item.ISBN }
			});
		}
	}
};
</script>

<style scoped lang="scss">
//get the style part from the template firstly
//will be overwrote by scss

.form {
	// display: flex;
	.bm-sele {
		flex: 1;
	}
	.bm-ipt {
		flex: 2;
	}
	.form-control {
		width: 100%;
	}
}
label {
	float: left;
	display: block;
}
</style>
