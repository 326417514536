<template>
	<div>
		<h3 class="sub-title" v-if="org">{{ org.name }}的{{ title }}資料</h3>
		<table-div
			:header="statistics_header"
			:datas="statistics_datas"
			:tdwidths="[20, 10, 10, 10, 10, 10, 10, 10, 10]"
		></table-div>
	</div>
</template>

<script>
import axios from "axios";
import { host } from "@/api/base";
import { joinObjectArray, fillCell } from "@/utils/dataProcess.js";
import tableDiv from "@/components/tableDiv.vue";

function genMonth(v) {
	let today = new Date();
	let year, month, date;

	year = today.getFullYear();
	month = today.getMonth() + 1;
	date = today.getDate();

	let year_begin, month_begin, year_end, month_end;

	if (month - v <= 0) {
		year_begin = year - 1;
		month_begin = month - v + 12;
	} else {
		year_begin = year;
		month_begin = month - v;
	}

	if (month - v + 1 <= 0) {
		year_end = year - 1;
		month_end = month - v + 1 + 12;
	} else if (month - v + 1 > 12) {
		year_end = year + 1;
		month_end = month - v + 1 - 12;
	} else {
		year_end = year;
		month_end = month - v + 1;
	}

	let begin_time, end_time;
	if (Number(month_begin) < 10) {
		month_begin = "0" + month_begin;
	}
	if (Number(month_end) < 10) {
		month_end = "0" + month_end;
	}

	if (!(v === 0)) {
		begin_time = year_begin.toString() + "-" + month_begin.toString() + "-01";
		end_time = year_end.toString() + "-" + month_end.toString() + "-01";
	} else {
		begin_time = year.toString() + "-" + month.toString() + "-01";
		end_time = year.toString() + "-" + month.toString() + "-" + date.toString();
	}

	return {
		begin_time: begin_time,
		end_time: end_time
	};
}

export default {
	props: {
		org: {
			type: Object,
			default: () => {
				return { id: 0, name: "全部" };
			}
		},
		page: {
			type: String,
			default: ""
		},
		params: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		tableDiv
	},
	data() {
		return {
			url: "",
			urlPD: "",
			title: "",
			statistics_header: {
				groupfield: "項目",
				all: "全部期間"
			},
			statistics_datas: [],
			temp: {}
		};
	},
	watch: {
		org() {
			this.statistics();
		},
		page() {
			this.pageSetted();
			this.statistics();
		}
	},
	metaInfo() {
		return {
			title: `${this.title} - 統計資料`
		};
	},
	mounted() {
		this.pageSetted();
		this.statistics();
	},
	methods: {
		pageSetted() {
			let page = this.page;
			if (page === "bookDownload") {
				this.title = "統計書籍下載";
				this.url = `${host}api/statistics/book_download/`;
				this.urlPD = `${host}/api/statistics/book_download_pd/`;
			} else if (page === "userDownload") {
				this.title = "統計使用者下載";
				this.url = `${host}api/statistics/user_download/`;
				this.urlPD = `${host}/api/statistics/user_download_pd/`;
			} else if (page === "userEditrecord") {
				this.title = "統計使用者校對";
				this.url = `${host}api/statistics/user_editrecord/`;
				this.urlPD = `${host}/api/statistics/user_editrecord_pd/`;
			}
			document.title = this.title;
		},
		statistics() {
			if (!this.org) {
				return false;
			}
			let page_params = this.params;
			let querys = [];
			let keys = [];
			let params = {};
			params = Object.assign(params, page_params);
			if (!(this.org.id == 0)) {
				params = Object.assign(params, { org_id: this.org.id });
			}
			let all_query = axios.get(this.url, { params });
			querys.push(all_query);
			all_query
				.then(res => {
					let key = "all";
					this.temp[key] = [];
					_.each(res.data.result, item => {
						let temp_obj = {};
						temp_obj["groupfield"] = item.groupfield;
						temp_obj[key] = item.count;
						this["temp"][key].push(temp_obj);
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
			_.each([0, 1, 2, 3, 4, 5, 6], v => {
				let time = genMonth(v);
				let begin_time = time["begin_time"];
				let end_time = time["end_time"];
				let key = "month" + v;
				let month_query = axios.get(this.url, {
					params: {
						...params,
						...{ begin_time, end_time }
					}
				});
				querys.push(month_query);
				keys.push(key);
				this.statistics_header[key] =
					begin_time.split("-")[0] + "年" + begin_time.split("-")[1] + "月";
				month_query
					.then(res => {
						let k = key;
						this["temp"][k] = [];
						_.each(res.data.result, item => {
							let temp_obj = {};
							temp_obj["groupfield"] = item.groupfield;
							temp_obj[k] = item.count;
							this["temp"][k].push(temp_obj);
						});
					})
					.catch(res => {
						// if (res.response && res.response.data) {
						// 	this.$root.$message.open({
						// 		status: 400,
						// 		message: res.response.data
						// 	});
						// } else {
						// 	this.$root.$message.open({
						// 		status: 400,
						// 		message: JSON.stringify(res)
						// 	});
						// }
						this.$errorHandler({ error: res });
					});
			});
			Promise.all(querys)
				.then(() => {
					this.statistics_datas = this.temp["all"];
					_.each(keys, v => {
						this.statistics_datas = joinObjectArray(
							this.statistics_datas,
							this.temp[v],
							"groupfield"
						);
						//$.extendObjectArray(this.statistics_datas, this.temp[v], 'groupfield')
					});
					fillCell(
						this.statistics_datas,
						Object.keys(this.statistics_header),
						"0"
					);
				})
				.catch(res => {
					//alertmessage('error', o2j(res.response.data));
					this.$errorHandler({ error: res });
				});
		},
		statisticsPD() {
			if (!this.org) {
				return false;
			}
			let page_params = this.params;
			let params = {};
			params = Object.assign(params, page_params);
			if (!(this.org.id == 0)) {
				params = Object.assign(params, { org_id: this.org.id });
			}
			this.statistics_header = {
				index: "使用者",
				all: "全部期間"
			};
			_.each([0, 1, 2, 3, 4, 5, 6], v => {
				let time = genMonth(v);
				let begin_time = time["begin_time"];
				let key = begin_time;
				this.statistics_header[key] =
					begin_time.split("-")[0] + "年" + begin_time.split("-")[1] + "月";
			});

			let all_query = axios.get(this.urlPD, { params });
			all_query
				.then(res => {
					this.statistics_datas = res.data.result;
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
