<template>
	<div>
		<h2 v-focus tabindex="0">
			{{ $route.matched[this.$route.matched.length - 1].meta.label }}
		</h2>
		<div class="btn-group paddTopBtmSm the4btn">
			<button class="btn btn-primary mr-sm-2" @click="select_all">
				<font-awesome-icon icon="check" class="textAside" />全選
			</button>
			<button class="btn btn-primary mr-sm-2" @click="select_items = []">
				<font-awesome-icon icon="times" class="textAside" />全不選
			</button>
			<button class="btn btn-primary mr-sm-2" @click="select_inv">
				<font-awesome-icon icon="exchange-alt" class="textAside" />反向選
			</button>
			<template v-if="instance_filter_field.length > 0">
				<button class="btn btn-primary" @click="$refs['fc'].open()">
					<font-awesome-icon icon="filter" class="textAside" />查詢條件
				</button>
			</template>
			<template v-if="instance_import_fields.length > 0">
				<button class="btn btn-primary" @click="$refs['fi'].open()">
					匯入
				</button>
			</template>
		</div>
		<table-div :header="instance_header" :datas="instance_datas">
			<template #id="props">
				<label>
					<input
						type="checkbox"
						v-model="select_items"
						:value="props.item"
						class="visu_verticalAlign"
					/>
					<span class="visu_verticalAlign">{{ props.item }}</span>
				</label>
				<!-- ."序號" field, number and checkbox. -->
			</template>
			<template #question="props">
				<div v-html="props.item" />
			</template>
			<template #answer="props">
				<div v-html="props.item" />
			</template>
			<template #action="props">
				<div class="paddTopBtmSm">
					<a
						role="button"
						:href="
							$router.resolve({
								name: 'RecommendationSubjectDetail',
								params: { id: props.item }
							}).href
						"
						title="（開啟新視窗）"
						target="blank"
						class="btn btn-primary"
						>編輯</a
					>
				</div>
			</template>
		</table-div>
		<div class="row mx-2" v-if="instance_batch_options.length > 0">
			<div class="col">
				<select v-model="action" class="custom-select">
					<option
						v-for="(item, key) in instance_batch_options"
						:value="item.value"
						:key="key"
						>{{ item.display_name }}</option
					>
				</select>
			</div>
			<div class="col col-auto">
				<button @click="acton" class="btn btn-primary">GO !</button>
			</div>
		</div>
		<button
			style="margin:15px 0 0 22px"
			class="btn btn-primary"
			@click="
				$router.push({ name: 'RecommendationSubjectDetail', params: { id: 0 } })
			"
		>
			新增
		</button>
	</div>
</template>

<script>
import { recommendationSubjectRest as instanceRest } from "@/api/genericUser.js";
import tableDiv from "@/components/tableDiv.vue";
// import batchUpdate from './batch_update.vue';
// import fieldImport from './field_import.vue';
// import filters from './filter.vue';

export default {
	props: {
		instance_header: {
			type: Object,
			default() {
				return {};
			}
		},
		instance_callback: {
			type: Object,
			default: () => {}
		},
		instance_filter_field: {
			type: Array,
			default() {
				return [];
			}
		},
		instance_batch_fields: {
			type: Array,
			default() {
				return [];
			}
		},
		instance_import_fields: {
			type: Array,
			default() {
				return [];
			}
		},
		instance_batch_options: {
			type: Array,
			default() {
				return [];
			}
		},
		list_filter: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	components: {
		tableDiv
		/* batchUpdate,
		fieldImport,
		filters, */
	},
	data() {
		return {
			action: this.instance_batch_options[0].value,
			instances: [],
			instance_datas: [],
			select_items: []
		};
	},
	watch: {
		async list_filter() {
			await this.load();
		}
	},
	async mounted() {
		await this.load();
	},
	methods: {
		async load() {
			let res;
			this.instance_datas = [];
			try {
				res = await instanceRest.list();
				this.instances = res.data;
				this.instance_datas = [];
				this.instances.forEach((v, i) => {
					let temp = {};
					_.each(this.instance_header, (value, key) => {
						if (this.instance_callback && this.instance_callback[key]) {
							temp[key] = this.instance_callback[key](v);
						} else {
							temp[key] = v[key];
						}
					});
					temp["order"] = i + 1;
					this.instance_datas.push(temp);
				});
			} catch (error) {
				console.log(error);
				this.$errorHandler({ error });
			}
		},
		async acton() {
			if (this.action === "delete") {
				let dfs = [];
				try {
					await this.$root.$confirm.open({
						status: 200,
						message: "確認刪除選定的項目？"
					});
				} catch (error) {
					this.$errorHandler({ error });
					return -1;
				}
				this.select_items.forEach(v => {
					dfs.push(instanceRest.delete(v));
				});
				try {
					await Promise.all(dfs);
				} catch (error) {
					this.$errorHandler({ error });
					console.log(error);
				}
				this.load();
			}
		},
		async batchupdate(event) {
			try {
				await orderAction.batchupdate(this.select_items, event);
				await this.load();
				this.$root.$message.open(200, "成功批次更新資料");
			} catch (error) {
				// if (error.response.status) {
				// 	this.$root.$message.open(error.response.status, error.response.data);
				// } else {
				// 	console.log(error);
				// 	this.$root.$message.open(-1, error);
				// }
				this.$errorHandler({ error });
			}
		},
		async fieldimport(event) {
			try {
				await orderAction.fieldimport(event);
				await this.load();
				this.$root.$message.open(200, "成功匯入資料");
			} catch (error) {
				// if (error.response.status) {
				// 	this.$root.$message.open(error.response.status, error.response.data);
				// } else {
				// 	console.log(error);
				// 	this.$root.$message.open(-1, error);
				// }
				this.$errorHandler({ error });
			}
		},
		async search(event) {
			let res;
			try {
				res = await orderRest.filter(event);
				this.$root.$message.open(0, "成功查詢資料" + res.data.length, "");
				this.instances = res.data;
				this.instance_datas = [];
				this.instances.forEach(v => {
					this.instance_datas.push({
						id: v.id,
						name: v.name,
						create_date: v.create_date,
						status: v.status,
						action: v.id
					});
				});
				this.select_items = [];
			} catch (error) {
				console.log(error);
				// this.$root.$message.open(1, error.response, "");
				this.$errorHandler({ error });
			}
		},
		select_all() {
			this.instance_datas.forEach(v => {
				this.select_items.push(v.id);
			});
		},
		select_inv() {
			let temp = [];
			this.instance_datas.forEach(v => {
				if (!_.includes(this.select_items, v.id)) {
					temp.push(v.id);
				}
			});
			this.select_items = temp;
		}
	}
};
</script>

<style scoped lang="scss"></style>
