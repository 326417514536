<template>
	<div class="any-about-page container this-is-user-guide">
		<h2 class="big-title">平台使用指南</h2>
		<div class="bs-callout bs-callout-primary">
			<p class="text-primary">雲端千眼：校對志工 SOP</p>

			<div class="iframe-wrapper keeping-aspect-ratio-layer-1">
				<div class="keeping-aspect-ratio-layer-2">
					<div class="keeping-aspect-ratio-layer-3">
						<iframe
							src="https://www.youtube.com/embed/nlbI6mOXrp4"
							style="border:0;"
							allowfullscreen
						></iframe>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: "平台使用指南"
	}
};
</script>

<style scoped lang="scss">
@import "~@/style/general_optional/any-about-page.scss";
@import "~@/style/general_optional/callout.scss";
@import "~@/style/general_optional/video-iframe.scss";
</style>
