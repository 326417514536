<template>
	<div>
		<h3 class="sub-title">{{ org.name }}</h3>
		<div class="tab-content">
			<div id="book_unreview_list">
				<table-div
					:header="book_header"
					:datas="book_datas"
					:tdwidths="tdwidths"
				>
					<template slot="action" slot-scope="props">
						<a
							role="button"
							class="btn btn-outline-secondary"
							@click="
								$router.push({
									name: 'bookReview',
									params: { id: props.item.ISBN }
								})
							"
							>審核</a
						>
						<a
							role="button"
							class="btn btn-outline-secondary"
							:href="
								$router.resolve({
									name: 'bookDetail',
									params: { id: props.item.ISBN }
								}).href
							"
							title="詳細（開啟新視窗）"
							target="blank"
							>詳細</a
						>
					</template>
				</table-div>
			</div>
		</div>
	</div>
</template>

<script>
import tableDiv from "@/components/tableDiv.vue";
import { bookRest } from "@/api/ebookSystem.js";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		tableDiv
	},
	data() {
		return {
			tdwidths: [22, 40, 10, 6, 6, 16],
			book_header: {
				ISBN: "ISBN",
				bookname: "書名",
				page: "頁數/總頁數",
				finish_part_count: "已完成段數",
				service_hours: "時數",
				action: "動作"
			},
			book_datas: []
		};
	},
	computed: {
		query() {
			let temp = { status: 0 };
			if (!(this.org.id == 0)) {
				temp["org_id"] = this.org.id;
			}
			return temp;
		}
	},
	mounted() {
		bookRest
			.filter(this.query)
			.then(res => {
				this.book_datas = [];
				_.each(res.data, v => {
					this.book_datas.push({
						ISBN: v.ISBN,
						bookname: v.book_info.bookname,
						page: v.finish_page_count + "/" + v.page_count,
						finish_part_count: v.finish_part_count,
						service_hours: v.service_hours,
						action: v
					});
				});
			})
			.catch(res => {
				// if (res.response && res.response.data) {
				// 	this.$root.$message.open({ status: 400, message: res.response.data });
				// } else {
				// 	this.$root.$message.open({
				// 		status: 400,
				// 		message: JSON.stringify(res)
				// 	});
				// }
				this.$errorHandler({ error: res });
			});
	}
};
</script>

<style lang="scss" scoped>
//temporary change

.btn {
	//fix the problem of too close
	&.btn-primary {
		margin: 0.3rem;
	}
}
</style>
