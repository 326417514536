<template>
	<div :id="'disabilitycard_manager' + org.id">
		<h3 class="sub-title">{{ org.name }}</h3>
		<form-filter>
			<form-item>
				<select class="form-control" v-model="search_filter" required>
					<option value="all" selected="selected">全部</option>
					<option
						v-for="(value, key) in search_choices"
						:value="key"
						:key="key"
						>{{ value }}</option
					>
				</select>
			</form-item>
			<form-item>
				<input
					v-model="search_value"
					class="form-control"
					type="text"
					placeholder="輸入欲查詢資訊"
					maxlength="15"
				/>
			</form-item>
			<form-item>
				<button type="button" class="btn btn-primary" @click="search()">
					搜尋
				</button>
			</form-item>
		</form-filter>
		<table-div
			:datas="search_disabilitycard_datas"
			:header="disabilitycard_header"
		>
			<template #action="props">
				<button
					class="btn btn-outline-secondary"
					@click="
						dcPk = props.item;
						dcModalShow = true;
					"
				>
					查閱編修
				</button>
			</template>
		</table-div>
		<modal v-if="dcModalShow" @closeModal="dcModalShow = false">
			<template #header>
				<h4 class="modal-title">身心障礙手冊登錄</h4>
			</template>
			<template #body>
				<manage-disabilitycard :pk="dcPk" page="manager" />
			</template>
		</modal>
	</div>
</template>

<script>
import modal from "@/components/popup/modal.vue";
import tableDiv from "@/components/tableDiv.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";
import manageDisabilitycard from "@/components/components/manageDisabilitycard.vue";
import { disabilityCardRest } from "@/api/genericUser";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		modal,
		tableDiv,
		manageDisabilitycard,
		formItem,
		formFilter
	},
	data() {
		return {
			dcModalShow: false,
			dcPk: 0,
			dcData: {},
			search_choices: {
				false: "未啟用",
				true: "已啟用"
			},
			search_filter: "all",
			search_value: "",
			disabilitycard_header: {
				identity_card_number: "身份證字號",
				name: "姓名",
				action: "動作"
			},
			search_disabilitycard_datas: []
		};
	},
	computed: {
		query() {
			let temp = {};
			if (this.search_filter === "all") {
				temp = { search: this.search_value };
			} else {
				temp = { search: this.search_value, is_active: this.search_filter };
			}
			if (!(this.org.id == 0)) {
				temp["org_id"] = this.org.id;
			}
			return temp;
		}
	},
	methods: {
		search() {
			disabilityCardRest
				.filter(this.query)
				.then(res => {
					let filter_data = [];
					_.each(res.data, v => {
						let temp_data = {
							identity_card_number: v.identity_card_number,
							name: v.name,
							action: v.identity_card_number
						};
						filter_data.push(temp_data);
					});
					this.search_disabilitycard_datas = filter_data;
					this.$root.$message.open({
						status: 400,
						message:
							"查詢完成，共取得 " +
							this.search_disabilitycard_datas.length +
							" 筆資料"
					});
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.form {
	display: flex;
	flex-wrap: wrap;
	.form-group {
		display: flex;
		@media screen and (max-width: 767px) {
			justify-content: space-around;
			flex: 1;
		}
		justify-content: space-around;
	}
}
</style>
