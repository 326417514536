<template>
	<div>
		<h2 class="big-title" v-focus tabindex="0">志工專區</h2>
		<table-div :datas="tableData" :header="announcement_columns">
			<template #action="props">
				<a
					class="btn btn-manage"
					role="button"
					@click="
						$router.push({ name: 'announcement', params: { id: props.item } })
					"
					>閱讀全文</a
				>
			</template>
		</table-div>
	</div>
</template>

<script>
import tableDiv from "@/components/tableDiv.vue";
import { announcementRest } from "@/api/genericUser.js";

export default {
	components: {
		tableDiv
	},
	metaInfo: {
		title: "志工專區"
	},
	data() {
		return {
			tableData: [],
			announcement_columns: {
				title: "標題",
				datetime: "發佈日期",
				action: "動作"
			}
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			let query = { category: "志工快訊" };
			announcementRest.filter(query).then(res => {
				let filter_data = [];
				_.each(res.data, v => {
					filter_data.push({
						id: v.id,
						title: v["title"],
						datetime: v["datetime"],
						action: v.id
					});
				});
				this.tableData = filter_data;
			});
		}
	}
};
</script>
