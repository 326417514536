<template>
	<div id="user_email">
		<h2 class="big-title">訊息傳送</h2>
		<template v-for="v in ['category', 'subject', 'body']">
			<formdrf
				:model_info="sendModel[v]"
				:field="v"
				v-model="sendData[v]"
				:key="v"
			></formdrf>
		</template>
		<div
			class="btnn-group is-margin-y is-justify-content-start is-two-button-margin-x-bigger"
		>
			<div class="layer-2">
				<button class="btn btn-primary" @click="send">發送</button>
			</div>
		</div>
	</div>
</template>

<script>
import { userAction } from "@/api/genericUser.js";
import formdrf from "@/components/form.vue";

export default {
	components: {
		formdrf
	},
	data() {
		return {
			sendData: {
				category: "editor",
				subject: "",
				body: ""
			},
			sendModel: {
				category: {
					label: "類型",
					type: "radio",
					choices: [
						{
							value: "editor",
							display_name: "志工"
						},
						{
							value: "guest",
							display_name: "視障者"
						}
					]
				},
				subject: {
					label: "主旨",
					type: "text"
				},
				body: {
					label: "內容",
					type: "textarea"
				}
			}
		};
	},
	metaInfo: {
		title: "訊息傳送"
	},
	methods: {
		send() {
			userAction
				.email(this.sendData)
				.then(() => {
					this.$root.$message.open({ status: 200, message: "成功傳送訊息" });
				})
				.catch(res => {
					// if (res.response && res.response.data) {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: res.response.data
					// 	});
					// } else {
					// 	this.$root.$message.open({
					// 		status: 400,
					// 		message: JSON.stringify(res)
					// 	});
					// }
					this.$errorHandler({ error: res });
				});
		}
	}
};
</script>
